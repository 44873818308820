/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  districtTypes: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  districtTypesPaginated: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  districtTypesId: {
    data: null,
    loading: false,
    fail: null,
  },
  createDistrictType: {
    loading: false,
    fail: null,
    success: null,
  },
  updateDistrictType: {
    loading: false,
    fail: null,
    success: null,
  },
  inactivateDistrictType: {
    loading: false,
    fail: null,
    success: null,
  },
};

const metaCases: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_DISTRICT_TYPE:
        draft.districtTypes.loading = true;
        draft.districtTypes.empty = true;
        draft.districtTypes.fail = null;
        return;

      case c.FETCH_DISTRICT_TYPE_SUCCESS:
        draft.districtTypes.loading = false;
        draft.districtTypes.fail = null;
        if (payload && payload.data.length) {
          draft.districtTypes.data = payload.data;
          draft.districtTypes.empty = false;
        }
        return;

      case c.FETCH_DISTRICT_TYPE_FAIL:
        draft.districtTypes.loading = false;
        draft.districtTypes.fail = payload.error;
        draft.districtTypes.data = payload.data;
        return;

      case c.FETCH_DISTRICT_TYPE_PAGINATED:
        draft.districtTypesPaginated.loading = true;
        draft.districtTypesPaginated.empty = true;
        draft.districtTypesPaginated.fail = null;
        return;

      case c.FETCH_DISTRICT_TYPE_PAGINATED_SUCCESS:
        draft.districtTypesPaginated.loading = false;
        draft.districtTypesPaginated.fail = null;
        if (payload && payload.data.result.length) {
          draft.districtTypesPaginated.data = payload.data;
          draft.districtTypesPaginated.empty = false;
        }
        return;

      case c.FETCH_DISTRICT_TYPE_PAGINATED_FAIL:
        draft.districtTypesPaginated.loading = false;
        draft.districtTypesPaginated.fail = payload.error;
        draft.districtTypesPaginated.data = payload.data;
        return;

      case c.FETCH_DISTRICT_TYPE_ID:
        draft.districtTypesId.loading = true;
        draft.districtTypesId.fail = null;
        return;

      case c.FETCH_DISTRICT_TYPE_ID_SUCCESS:
        draft.districtTypesId.loading = false;
        draft.districtTypesId.fail = null;
        if (payload && payload.data.length) {
          draft.districtTypesId.data = payload.data;
        }
        return;

      case c.FETCH_DISTRICT_TYPE_ID_FAIL:
        draft.districtTypesId.loading = false;
        draft.districtTypesId.fail = payload.error;
        draft.districtTypesId.data = payload.data;
        return;

      case c.CREATE_DISTRICT_TYPE:
        draft.createDistrictType.loading = true;
        draft.createDistrictType.fail = null;
        draft.createDistrictType.success = null;
        return;

      case c.CREATE_DISTRICT_TYPE_SUCCESS:
        draft.createDistrictType.loading = false;
        draft.createDistrictType.success = payload.data;
        return;

      case c.CREATE_DISTRICT_TYPE_FAIL:
        draft.createDistrictType.loading = false;
        draft.createDistrictType.fail = payload.error;
        draft.createDistrictType.success = null;
        return;

      case c.UPDATE_DISTRICT_TYPE:
        draft.updateDistrictType.loading = true;
        draft.updateDistrictType.fail = null;
        draft.updateDistrictType.success = null;
        return;

      case c.UPDATE_DISTRICT_TYPE_SUCCESS:
        draft.updateDistrictType.loading = false;
        draft.updateDistrictType.fail = null;
        draft.updateDistrictType.success = payload.data;
        return;

      case c.UPDATE_DISTRICT_TYPE_FAIL:
        draft.updateDistrictType.loading = false;
        draft.updateDistrictType.fail = payload.error;
        draft.updateDistrictType.success = null;
        return;

      case c.INACTIVATE_DISTRICT_TYPE:
        draft.inactivateDistrictType.loading = true;
        draft.inactivateDistrictType.fail = null;
        draft.inactivateDistrictType.success = null;
        return;

      case c.INACTIVATE_DISTRICT_TYPE_SUCCESS:
        draft.inactivateDistrictType.loading = false;
        draft.inactivateDistrictType.fail = null;
        draft.inactivateDistrictType.success = payload.data;
        return;

      case c.INACTIVATE_DISTRICT_TYPE_FAIL:
        draft.inactivateDistrictType.loading = false;
        draft.inactivateDistrictType.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.createDistrictType.success = null;
        draft.updateDistrictType.success = null;
        draft.inactivateDistrictType.success = null;
        draft.createDistrictType.fail = null;
        draft.updateDistrictType.fail = null;
        draft.inactivateDistrictType.fail = null;
        return;

      default:
        return;
    }
  });

export default metaCases;
