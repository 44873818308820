import { IBreadcrumbProps } from "@blueprintjs/core";

export const CREATE_CASE = 'CREATE_CASE';
export const CREATE_CASE_SUCCESS = 'CREATE_CASE_SUCCESS';
export const CREATE_CASE_FAIL = 'CREATE_CASE_FAIL';

export const FETCH_LAWYERS = 'FETCH_LAWYERS';
export const FETCH_LAWYERS_SUCCESS = 'FETCH_LAWYERS_SUCCESS';
export const FETCH_LAWYERS_FAIL = 'FETCH_LAWYERS_FAIL';

export const FETCH_LAWYER_BY_PERSON_ID = 'FETCH_LAWYER_BY_PERSON_ID';
export const FETCH_LAWYER_BY_PERSON_ID_SUCCESS = 'FETCH_LAWYER_BY_PERSON_ID_SUCCESS';
export const FETCH_LAWYER_BY_PERSON_ID_FAIL = 'FETCH_LAWYER_BY_PERSON_ID_FAIL';

export const BREADCRUMB_PATHS: IBreadcrumbProps[] = [
  { href: '/painel/casos/lista', text: 'Casos' },
  window.location.pathname.includes('criar')
    ? { href: '/painel/casos/criar', text: 'Cadastro de caso' }
    : { href: '/painel/casos/lista', text: 'Listagem' },
];