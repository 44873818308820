import React from 'react';
import { Menu, Checkbox } from '@blueprintjs/core';
import { Filter } from './types';
import './styles.css';

interface IPopoverFilterOptionsProps {
  filters: Filter[];
}

const PopoverFilterOptions: React.FC<IPopoverFilterOptionsProps> = ({ filters }) => {

  return (
    <Menu className="filter__menu__list">
      {filters.map((filter) => (
        <Checkbox
          key={filter.text}
          checked={filter.value}
          onChange={() => filter.request()}
        >
          {filter.text}
        </Checkbox>
      ))}
    </Menu>
  );
};

export default PopoverFilterOptions;
