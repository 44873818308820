/* eslint-disable import/prefer-default-export */
export const FETCH_PEOPLE_RELATION = 'FETCH_PEOPLE_RELATION';
export const FETCH_PEOPLE_RELATION_SUCCESS = 'FETCH_PEOPLE_RELATION_SUCCESS';
export const FETCH_PEOPLE_RELATION_FAIL = 'FETCH_PEOPLE_RELATION_FAIL';

export const CREATE_PERSON_RELATION = 'CREATE_PERSON_RELATION';
export const CREATE_PERSON_RELATION_SUCCESS = 'CREATE_PERSON_RELATION_SUCCESS';
export const CREATE_PERSON_RELATION_FAIL = 'CREATE_PERSON_RELATION_FAIL';

export const UPDATE_PEOPLE_RELATION = 'UPDATE_PEOPLE_RELATION';
export const UPDATE_PEOPLE_RELATION_SUCCESS = 'UPDATE_PEOPLE_RELATION_SUCCESS';
export const UPDATE_PEOPLE_RELATION_FAIL = 'UPDATE_PEOPLE_RELATION_FAIL';

export const INACTIVATE_CASE_RELATED_PERSON = 'INACTIVATE_CASE_RELATED_PERSON';
export const INACTIVATE_CASE_RELATED_PERSON_SUCCESS = 'INACTIVATE_CASE_RELATED_PERSON_SUCCESS';
export const INACTIVATE_CASE_RELATED_PERSON_FAIL = 'INACTIVATE_CASE_RELATED_PERSON_FAIL';

export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
