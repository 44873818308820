import React from 'react';
import { Route } from 'react-router-dom';
import { Routes } from '../../routes';

interface IRouteContentProps {
  routes: Routes;
}

const RouteContent: React.FC<IRouteContentProps> = ({ routes }) => {
  return (
    <>
      {routes.map((route) => {
        return <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />;
      })}
    </>
  );
};

export default RouteContent;
