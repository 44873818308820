import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import { fastRegisterPerson, fetchAllPeople } from '../../modules/PeopleList/actions';
import './styles.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import 'moment/locale/pt-br';
import Input from '../../components/Input';
import Autocomplete from '../../components/Autocomplete';
import { reducers } from '../../store/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip, Button, Dialog, Spinner, Checkbox, Icon } from '@blueprintjs/core';
import { cnpjMask, cpfMask } from '../../utils/masks';
import MetaTable from '../../components/MetaTable';
import { fetchLegalCasePersonTypes } from '../MetaCasesLegalCasePerson/actions';
import Selector from '../../components/Selector';
import { RelationedPeopleType, LocalCasePersonType, PostCase } from '../CreateCase/types';
import { LegalCasePersonType } from '../MetaCasesLegalCasePerson/types';
import createToaster from '../../components/Toaster';

type StepEnvolvedPersonProps = {
  newCase: PostCase;
  setNewCase: Function;
  people: LocalCasePersonType[];
  setPeople: Function;
};

const StepEnvolvedPerson: React.FC<StepEnvolvedPersonProps> = ({ newCase, setNewCase, people, setPeople }) => {
  const dispatch = useDispatch();
  // BlueprintJS inputRef
  const nameInput = React.useRef() as any;
  const cpfInput = React.useRef() as any;
  const cnpjInput = React.useRef() as any;
  const allPeople = useSelector((state: typeof reducers) => state.peoplelist.allPeople);
  const { loading } = useSelector((state: typeof reducers) => state.peoplelist.peopleList);
  const fastRegister = useSelector((state: typeof reducers) => state.peoplelist.fastRegister);
  const legalCasePersonTypes = useSelector((state: typeof reducers) => state.metacaseslegalcaseperson.legalCasePersonTypes);
  const [envolvedPersonId, setEnvolvedPersonId] = useState('');
  const [envolvedPersonName, setEnvolvedPersonName] = useState('');
  const [isLegalPerson, setIsLegalPerson] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isClient, setIsClient] = useState(false);
  const [personTypeId, setPersonTypeId] = useState('');
  const [personTypeError, setPersonTypeError] = useState(false);

  useEffect(() => {
    if (fastRegister.success || fastRegister.fail) {
      const showToast = createToaster({
        message: fastRegister.success || fastRegister.fail,
        intent: fastRegister.success ? 'success' : 'danger',
        icon: fastRegister.success ? 'tick' : 'warning-sign',
      });
      showToast();
      setIsDialogOpen(false);
    }
    if (fastRegister.id) {
      setEnvolvedPersonId(fastRegister.id);
      dispatch(fetchAllPeople('name='));
    }
  }, [fastRegister, dispatch]);

  useEffect(() => {
    dispatch(fetchLegalCasePersonTypes());
    dispatch(fetchAllPeople('name='));
  }, [dispatch]);

  const handleSendFastRegister = () => {
    setEnvolvedPersonName(nameInput?.current?.value);
    if (isLegalPerson) {
      return dispatch(
        fastRegisterPerson({
          name: nameInput?.current?.value,
          legal_cnpj: cnpjInput?.current?.value,
          is_legal_person: isLegalPerson,
        })
      );
    }
    return dispatch(
      fastRegisterPerson({
        name: nameInput?.current?.value,
        natural_cpf: cpfInput?.current?.value,
        is_legal_person: isLegalPerson,
      })
    );
  };

  const dialogRender = () => (
    <Dialog
      className="register__dialog"
      isOpen={isDialogOpen}
      title="Cadastro rápido de pessoa"
      onClose={() => setIsDialogOpen(false)}
      canEscapeKeyClose
    >
      {loading || fastRegister?.loading ? (
        <Spinner intent="primary" size={25} />
      ) : (
        <>
          <div className="input__group">
            <Input inputRef={nameInput} placeholder="Nome" />
            {isLegalPerson && <Input inputRef={cnpjInput} mask={cnpjMask} placeholder="CNPJ" />}
            {!isLegalPerson && <Input inputRef={cpfInput} mask={cpfMask} placeholder="CPF" />}
          </div>
          <div className="checkbox__group">
            <Checkbox checked={isLegalPerson} onChange={() => setIsLegalPerson((prevState) => !prevState)}>
              Pessoa jurídica
            </Checkbox>
            <Checkbox checked={!isLegalPerson} onChange={() => setIsLegalPerson((prevState) => !prevState)}>
              Pessoa física
            </Checkbox>
          </div>
          <div className="button__group">
            {!fastRegister.loading ? (
              <>
                <Button intent="danger" onClick={() => setIsDialogOpen(false)} minimal text="Cancelar" />
                <Button intent="primary" onClick={() => handleSendFastRegister()} text="Salvar" />
              </>
            ) : (
              <Spinner intent="primary" size={25} />
            )}
          </div>
        </>
      )}
    </Dialog>
  );

  const clearFields = () => {
    setEnvolvedPersonId('');
    setEnvolvedPersonName('');
    setIsClient(false);
  };

  const getPersonTypeName = () => {
    const typeName = legalCasePersonTypes.data.find((item: LegalCasePersonType) => item.person_type_id === personTypeId);
    return typeName.name;
  };

  const mountConnectedPerson = (): RelationedPeopleType => ({
    person_id: envolvedPersonId,
    person_type_id: personTypeId,
    is_customer: isClient,
    is_applicant: false
  });

  const mountConnectedPersonLocal = (): LocalCasePersonType => ({
    person_id: envolvedPersonId,
    person_type_id: personTypeId,
    person_type_name: getPersonTypeName(),
    is_customer: isClient,
    person_name: envolvedPersonName,
  });

  useEffect(() => {
    if (personTypeId?.length && personTypeError) {
      setPersonTypeError(false);
    }
  }, [personTypeId, personTypeError]);

  const alreadyAdded = () => newCase.people.find((item: RelationedPeopleType) => item.person_id === envolvedPersonId);

  const connectPerson = () => {
    if (personTypeId && !alreadyAdded()) {
      const newArray = update(newCase.people, { $set: newCase.people.concat(mountConnectedPerson()) });
      setNewCase({
        ...newCase,
        people: newArray,
      })
      setPeople((result: any) => [...result, mountConnectedPersonLocal()]);
      clearFields();
    } else {
      setPersonTypeError(true);
    }
  };

  const addEnvolvedPersonTooltip = () => (
    <Tooltip captureDismiss content={'Clique aqui para cadastrar pessoa'}>
      <Button
        onClick={() => setIsDialogOpen(true)}
        className="input__button--add"
        name="add"
        icon="add"
      />
    </Tooltip>
  );

  const removePerson = (id: string) => {
    const newPeople = people.filter((item: any) => item.person_id !== id)
    setNewCase((state: PostCase) => ({
      ...state,
      people: newPeople
    }));
    setPeople(newPeople);
  };

  return (
    <div className="envolved__person__container">
      {dialogRender()}
      <h3>Passo 2 de 3</h3>
      <p className="left__p">
        Adicione pessoas à este caso. Caso a pessoa ainda não esteja cadastrada no sistema, você pode adicioná-la clicando no
        botão "+".
      </p>
      <form action="">
        <div className="left__side">
          <div className="autocomplete__input">
            <Autocomplete
              idKey="person_id"
              callbackMethod={setEnvolvedPersonId}
              request={fetchAllPeople}
              path="name"
              value={envolvedPersonName}
              setValue={setEnvolvedPersonName}
              data={allPeople?.data}
              placeholder="Pessoa"
              rightElement={addEnvolvedPersonTooltip()}
              keyOne="person_id"
              nameValue="name"
            />
          </div>
          <div className="input__combo">
            <Selector required setValue={setPersonTypeId} className="person__type__selector" personTypes={legalCasePersonTypes.data} />
            <Button
              onClick={() => connectPerson()}
              text="Relacionar pessoa"
              className="button__connect"
              icon="add"
              name="add"
            />
          </div>
          {personTypeError && (
            <h4 className="empty__attribute">
              <Icon icon="warning-sign"></Icon>
              Por favor, preencha todos os <strong>campos obrigatórios</strong> antes de prosseguir com o cadastro.
            </h4>
          )}
          <Checkbox checked={isClient} onChange={() => setIsClient((prevState) => !prevState)}>
            Cliente
          </Checkbox>
        </div>
      </form>
      <div className="table__container">
        <MetaTable
          loading={false}
          empty={!people?.length}
          data={people}
          headers={['Nome', 'Cliente', 'Tipo']}
          columns={['person_name', 'is_customer', 'person_type_name']}
          localId="person_id"
          removeRequest={removePerson}
        />
      </div>
    </div>
  );
};

export default StepEnvolvedPerson;
