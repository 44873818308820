/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';
import { fetchRelatedDocuments } from '../PeopleAttachments/actions';

export const clearAllStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_ALL_STATUS,
  });
};

export const fetchCaseRelatedDocuments = (
  offset: number,
  limit = DEFAULT_PER_PAGE_LIMIT,
  filtersPath: string,
) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_RELATED_DOCUMENTS,
    payload: {
      request: {
        url: `/documents/legal_case/${filtersPath}&offset=${offset}&limit=${limit}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_RELATED_DOCUMENTS_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_RELATED_DOCUMENTS_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const inactivateCaseDocumentRelation = (caseId: string, caseProgressId: string, documentId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_CASE_DOCUMENT_RELATION,
    payload: {
      request: {
        url: `/case_progress/${caseProgressId}/documents/${documentId}/inactivate`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_CASE_DOCUMENT_RELATION_SUCCESS,
          });
          dispatch(clearAllStatus());
          dispatch(fetchRelatedDocuments(caseId));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.INACTIVATE_CASE_DOCUMENT_RELATION_FAIL,
            payload: {
              error: resError,
            },
          });
          dispatch(clearAllStatus());
        },
      },
    },
  });
};
