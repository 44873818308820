import React, { useEffect } from 'react';
import { Button } from '@blueprintjs/core';
import '../../template.css';
import './styles.css';
import { WRITE_PERMISSION, CASES_MODULE_NAME } from '../Permissions/constants';
import { isMobile } from '../../utils/window';
import { verifyUserPermission } from '../../utils/permissions';
import { goToHere } from '../../utils/navigation';
import { CREATE_PATH } from './constants';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PATH } from '../CasesList/constants';

const Cases: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    if (window.location.pathname === DEFAULT_PATH) {
      history.push('/painel/casos/lista');
    }
  }, [history]);

  return (
    <>
      {!isMobile() && (
        <div className="sidebar__container">
          {verifyUserPermission(CASES_MODULE_NAME, WRITE_PERMISSION) && (
            <Button
              icon="add"
              onClick={() => goToHere(CREATE_PATH)}
              name="add"
              className="sidebar__button--blue full__width"
              text="Criar um novo caso"
            />
          )}
        </div>
      )}
    </>
  );
};

export default Cases;
