export const FETCH_LEGAL_CASE_PERSON_TYPE = 'FETCH_LEGAL_CASE_PERSON_TYPE';
export const FETCH_LEGAL_CASE_PERSON_TYPE_SUCCESS = 'FETCH_LEGAL_CASE_PERSON_TYPE_SUCCESS';
export const FETCH_LEGAL_CASE_PERSON_TYPE_FAIL = 'FETCH_LEGAL_CASE_PERSON_TYPE_FAIL';
export const FETCH_LEGAL_CASE_PERSON_TYPE_PAGINATED = 'FETCH_LEGAL_CASE_PERSON_TYPE_PAGINATED';
export const FETCH_LEGAL_CASE_PERSON_TYPE_PAGINATED_SUCCESS = 'FETCH_LEGAL_CASE_PERSON_TYPE_PAGINATED_SUCCESS';
export const FETCH_LEGAL_CASE_PERSON_TYPE_PAGINATED_FAIL = 'FETCH_LEGAL_CASE_PERSON_TYPE_PAGINATED_FAIL';
export const FETCH_LEGAL_CASE_PERSON_TYPE_ID = 'FETCH_LEGAL_CASE_PERSON_TYPE_ID';
export const FETCH_LEGAL_CASE_PERSON_TYPE_ID_SUCCESS = 'FETCH_LEGAL_CASE_PERSON_TYPE_ID_SUCCESS';
export const FETCH_LEGAL_CASE_PERSON_TYPE_ID_FAIL = 'FETCH_LEGAL_CASE_PERSON_TYPE__ID_FAIL';
export const CREATE_LEGAL_CASE_PERSON_TYPE = 'CREATE_LEGAL_CASE_PERSON_TYPE';
export const CREATE_LEGAL_CASE_PERSON_TYPE_SUCCESS = 'CREATE_LEGAL_CASE_PERSON_TYPE_SUCCESS';
export const CREATE_LEGAL_CASE_PERSON_TYPE_FAIL = 'CREATE_LEGAL_CASE_PERSON_TYPE_FAIL';
export const UPDATE_LEGAL_CASE_PERSON_TYPE = 'UPDATE_LEGAL_CASE_PERSON_TYPE';
export const UPDATE_LEGAL_CASE_PERSON_TYPE_SUCCESS = 'UPDATE_LEGAL_CASE_PERSON_TYPE_SUCCESS';
export const UPDATE_LEGAL_CASE_PERSON_TYPE_FAIL = 'UPDATE_LEGAL_CASE_PERSON_TYPE_FAIL';
export const INACTIVATE_LEGAL_CASE_PERSON_TYPE = 'INACTIVATE_LEGAL_CASE_PERSON_TYPE';
export const INACTIVATE_LEGAL_CASE_PERSON_TYPE_SUCCESS = 'INACTIVATE_LEGAL_CASE_PERSON_TYPE_SUCCESS';
export const INACTIVATE_LEGAL_CASE_PERSON_TYPE_FAIL = 'INACTIVATE_LEGAL_CASE_PERSON_TYPE_FAIL';
export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
