/* eslint-disable import/prefer-default-export */
import moment from 'moment/moment.js';

export const getMonthNameFromDate = (date: Date) => MONTH_NAMES[date.getMonth()];

const MONTH_NAMES = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const humanizeDate = (date: string | Date) => new Date(date).toLocaleDateString('pt-BR');

export const humanizeDateWithTime = (date: string | Date) =>
  `${new Date(date).toLocaleDateString('pt-BR')} ${new Date(date).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })}`;

export const formatDateToServer = (date: string) => {
  const day = date.split('/')[0];
  const month = date.split('/')[1];
  const year = date.split('/')[2];
  return `${year}-${month.slice(-2)}-${day.slice(-2)}`;
};

export const jsDateToHuman = (date: string) => {
  const splitted = date.split('/');
  return new Date(Number(splitted[2]), Number(splitted[1]) - 1, Number(splitted[0]));
};

export const getDateObjectApplyingGMT = (dateValue: string) => new Date(Date.parse(`${dateValue} GMT-3`));

export const momentFormatBrDate = (value: Date) => 
  moment(value).format('DD/MM/YYYY');

export const momentFormatServerDate = (value: Date) => moment(value).format('YYYY-MM-DD');

export const formatCalendarPickerInputValue = (date: Date) => `${getMonthNameFromDate(date)} ${date.getFullYear()}`;

export const rightFormat = (item: string) => {
  console.log(item);
  console.log(moment(item).format('YYYY-MM-DD HH:MM:ss.000'));
  debugger;
};

export const fixDateGmt = (item: string) => {
  if (item) {
    return item.replace('+00:00', '-03:00')
  }
  return item;
};

export const getNumbersForCreateDateObj = (date: string) => {
  if (date) {
    const dayAndTime = date.split(' ');
    const days = dayAndTime[0].split('/');
    const time = dayAndTime[1].split(':');
    return new Date(Number(days[2]), Number(days[1]), Number(days[0]), Number(time[0]), Number(time[1]));
  }
  return new Date();
};

export const checkTimeValue = (value: string): Boolean => {
  const splittedTime = value.split(':');
  return (
    Number(splittedTime[0]) > 23 ||
    Number(splittedTime[0]) < 0 ||
    Number(splittedTime[1]) < 0 ||
    Number(splittedTime[1]) > 60
  );
};

export const timeValidation = (value: string) => {
  return !((value.indexOf(':') !== 2 && value.indexOf(':') !== 1) || checkTimeValue(value))
}

export const formatWithoutTimezone = (newDate: Date) => {
  const date = moment(newDate).format('YYYY-MM-DD');
  let min = newDate.getMinutes().toString();
  let hours = newDate.getHours().toString();
  if (Number(min) < 10) {
    min = `0${newDate.getMinutes()}`
  }
  if (Number(hours) < 10) {
    hours = `0${newDate.getHours()}`
  }
  const time = `${hours}:${min}:00.000`;
  return [date, time].join(' ');
};


export const dateFormatter = (startDate: string, startTime: string) => {
  const startDateFormatter = moment(startDate).format('YYYY-MM-DD HH:MM:ss.000');
  const time = startDateFormatter.split(' ')[1].split(':');
  time[0] = startTime.split(':')[0];
  time[1] = startTime.split(':')[1];
  if (Number(time[0]) < 10 && time[0].length < 2) {
    time[0] = `0${time[0]}`
  }
  if (Number(time[1]) < 10 && time[1].length < 2) {
    time[1] = `0${time[1]}`
  }
  const startDateFormatterF = startDateFormatter.split(' ');
  startDateFormatterF[1] = time.join(':');
  return startDateFormatterF.join(' ');
};
