import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import { Classes, Checkbox, Spinner } from '@blueprintjs/core';
import Button from '../../components/Button';
import { Telephone } from './types';
import { useSelector, useDispatch } from 'react-redux';
import { reducers } from '../../store/reducers';
import { fetchPhoneRelations, createPhoneRelation, inactivatePhoneRelation } from './actions';
import { fetchPhoneTypes } from '../MetaPeoplePhones/actions';
import Selector from '../../components/Selector';
import { phoneMask } from '../../utils/masks';
import '../../components/PersonRelations/styles.css';
import MetaTable from '../../components/MetaTable';
import createToaster from '../../components/Toaster';

interface ITelephoneProps {
  personId: string;
}

const Telephones: React.FC<ITelephoneProps> = ({ personId }) => {
  const dispatch = useDispatch();
  const phoneTypes = useSelector((state: typeof reducers) => state.metapeoplephones.phoneTypes);
  const phoneRelations = useSelector((state: typeof reducers) => state.peopletelephones.phoneRelation);
  const inactivate = useSelector((state: typeof reducers) => state.peopletelephones.inactivate);
  const create = useSelector((state: typeof reducers) => state.peopletelephones.createPhoneRelation);
  const phoneTypeInput = React.createRef() as any;
  const cityInput = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const [isEdit, setIsEdit] = useState(false);
  const [numberInputValue, setNumberInputValue] = useState('');
  const [newPhoneRelation, setNewPhoneRelation] = useState<Telephone>({
    phone_id: '',
    phone_number: '',
    area_code: '',
    is_main: false,
    phone_type_id: '',
    city: '',
    phone_type: undefined,
  });

  useEffect(() => {
    if (personId) {
      dispatch(fetchPhoneRelations(personId));
    }
    dispatch(fetchPhoneTypes());
  }, [dispatch, personId]);

  useEffect(() => {
    if (
      inactivate?.success ||
      inactivate?.fail ||
      create?.success ||
      create?.fail
    ) {
      const showToast = createToaster({
        message:
          inactivate?.success ||
          inactivate?.fail ||
          create?.success ||
          create?.fail,
        intent: inactivate?.fail || create?.fail ? 'danger' : 'success',
        icon: inactivate?.fail || create?.fail ? 'warning-sign' : 'tick',
      });
      showToast();
    }
  }, [inactivate, create]);

  const getTable = () => (
    <div className="table__container">
      <MetaTable
        loading={phoneRelations?.loading || inactivate?.loading || create?.loading}
        empty={phoneRelations?.empty}
        data={phoneRelations?.data}
        headers={['Telefone', 'Tipo', 'Cidade', 'Principal']}
        columns={['phone_number', 'phone_type_obj', 'city', 'is_main']}
        pathId="phone_id"
        removeRequest={inactivatePhoneRelation}
        editRequest={handleEditRequest}
        personId={personId}
      />
    </div>
  );

  const handleEditRequest = (elem: Telephone) => {
    setNewPhoneRelation(elem);
    setIsEdit(true);
  };

  const clearForm = () => {
    setNewPhoneRelation(
      update(newPhoneRelation, {
        is_main: { $set: false },
        phone_number: { $set: '' },
        area_code: { $set: '' },
        phone_id: { $set: '' },
        phone_type_id: { $set: '' },
        city: { $set: '' },
      })
    );
    setNumberInputValue('');
    setIsEdit(false);
  };

  const getAreaCode = () => Number(numberInputValue.slice(1, 3));

  const getNumber = () => numberInputValue.slice(5, 15);

  const sendRelation = () => {
    if (!isEdit) {
      dispatch(
        createPhoneRelation(personId, {
          phone_type_id: phoneTypeInput?.current?.value,
          phone_number: getNumber(),
          area_code: getAreaCode(),
          is_main: newPhoneRelation.is_main,
          city: newPhoneRelation.city,
        })
      );
    } else {
      dispatch(
        createPhoneRelation(personId, {
          phone_id: newPhoneRelation.phone_id,
          phone_type_id: phoneTypeInput?.current?.value,
          phone_number: getNumber(),
          area_code: getAreaCode(),
          is_main: newPhoneRelation.is_main,
          city: newPhoneRelation.city,
        })
      );
    }
    clearForm();
  };

  const checkboxChange = () => {
    setNewPhoneRelation(
      update(newPhoneRelation, {
        is_main: { $set: !newPhoneRelation.is_main },
      })
    );
  };

  useEffect(() => {
    if (isEdit) {
      setNumberInputValue(phoneMask(`${newPhoneRelation.area_code.toString()}${newPhoneRelation.phone_number}`));
    }
  }, [newPhoneRelation, isEdit]);

  return (
    <>
      <div className="tabs__subline"></div>
      <div className="content__container">
        {!phoneRelations?.loading || !inactivate?.loading || !create?.loading ? (
          <>
            {getTable()}
            <div className="register__form">
              <h2>Adicionar telefone</h2>
              <input
                maxLength={15}
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNumberInputValue(phoneMask(e.target.value))}
                value={numberInputValue}
                className={`${Classes.INPUT} input__relations`}
                placeholder="Número de telefone"
              />
              <input
                ref={cityInput}
                required
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewPhoneRelation(update(newPhoneRelation, { city: { $set: e.target.value } }))
                }
                value={newPhoneRelation.city}
                className={`${Classes.INPUT} input__relations`}
                placeholder="Cidade"
              />
              <Selector
                inputRef={phoneTypeInput}
                required
                value={newPhoneRelation.phone_type_id}
                className={`${Classes.INPUT} relations__selector`}
                phoneTypes={phoneTypes.data}
              />
              <div className="checkbox">
                <Checkbox checked={newPhoneRelation.is_main} onChange={() => checkboxChange()}></Checkbox>
                <span>Telefone principal</span>
              </div>
              <div className="buttons__container">
                <Button name="add" text="Salvar telefone" onClick={sendRelation} type="button" />
                {isEdit && <Button name="default" text="Cancelar edição" onClick={clearForm} type="button" />}
              </div>
            </div>
          </>
        ) : (
          <div className="loading__container">
            <Spinner intent="primary" size={25} />
          </div>
        )}
      </div>
    </>
  );
};

export default Telephones;
