/* eslint-disable import/prefer-default-export */
export const FETCH_PERSON_DOCUMENTS = 'FETCH_PERSON_DOCUMENTS';
export const FETCH_PERSON_DOCUMENTS_SUCCESS = 'FETCH_PERSON_DOCUMENTS_SUCCESS';
export const FETCH_PERSON_DOCUMENTS_FAIL = 'FETCH_PERSON_DOCUMENTS_FAIL';

export const CREATE_PERSON_DOCUMENT = 'CREATE_PERSON_DOCUMENT';
export const CREATE_PERSON_DOCUMENT_SUCCESS = 'CREATE_PERSON_DOCUMENT_SUCCESS';
export const CREATE_PERSON_DOCUMENT_FAIL = 'CREATE_PERSON_DOCUMENT_FAIL';

export const UPDATE_PERSON_DOCUMENT = 'UPDATE_PERSON_DOCUMENT';
export const UPDATE_PERSON_DOCUMENT_SUCCESS = 'UPDATE_PERSON_DOCUMENT_SUCCESS';
export const UPDATE_PERSON_DOCUMENT_FAIL = 'UPDATE_PERSON_DOCUMENT_FAIL';

export const INACTIVATE_PERSON_DOCUMENT = 'INACTIVATE_PERSON_DOCUMENT';
export const INACTIVATE_PERSON_DOCUMENT_SUCCESS = 'INACTIVATE_PERSON_DOCUMENT_SUCCESS';
export const INACTIVATE_PERSON_DOCUMENT_FAIL = 'INACTIVATE_PERSON_DOCUMENT_FAIL';

export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
