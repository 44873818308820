export const FETCH_PROGRESS = 'FETCH_PROGRESS';
export const FETCH_PROGRESS_SUCCESS = 'FETCH_PROGRESS_SUCCESS';
export const FETCH_PROGRESS_FAIL = 'FETCH_PROGRESS_FAIL';

export const FETCH_PROGRESS_BY_ID = 'FETCH_PROGRESS_BY_ID';
export const FETCH_PROGRESS_BY_ID_SUCCESS = 'FETCH_PROGRESS_BY_ID_SUCCESS';
export const FETCH_PROGRESS_BY_ID_FAIL = 'FETCH_PROGRESS_BY_ID_FAIL';

export const CREATE_PROGRESS = 'CREATE_PROGRESS';
export const CREATE_PROGRESS_SUCCESS = 'CREATE_PROGRESS_SUCCESS';
export const CREATE_PROGRESS_FAIL = 'CREATE_PROGRESS_FAIL';

export const CREATE_PROGRESS_DOCUMENT = 'CREATE_PROGRESS_DOCUMENT';
export const CREATE_PROGRESS_DOCUMENT_SUCCESS = 'CREATE_PROGRESS_DOCUMENT_SUCCESS';
export const CREATE_PROGRESS_DOCUMENT_FAIL = 'CREATE_PROGRESS_DOCUMENT_FAIL';

export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
export const UPDATE_PROGRESS_SUCCESS = 'UPDATE_PROGRESS_SUCCESS';
export const UPDATE_PROGRESS_FAIL = 'UPDATE_PROGRESS_FAIL';

export const INACTIVATE_PROGRESS = 'INACTIVATE_PROGRESS';
export const INACTIVATE_PROGRESS_SUCCESS = 'INACTIVATE_PROGRESS_SUCCESS';
export const INACTIVATE_PROGRESS_FAIL = 'INACTIVATE_PROGRESS_FAIL';

export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
