import { StepPathType } from "../modules/CreateCase/types";
import { BasicUserForSchedule } from "../modules/Users/types";

export const setActiveNavTab = (route: string): string =>
  window.location.pathname.includes(route) ? 'nav__button bp3-button active' : 'nav__button';

export const isCurrentRoute = (path: string): boolean => window.location.pathname.includes(path);

export const goToHere = (path: string): string => window.location.href = path;

export const reloadPage = () => window.location.reload();

export const handleNextStepCreateCase = (tab: keyof StepPathType) => {
  let nextTab = {
    dataCase: 'envolvedPerson',
    envolvedPerson: 'envolvedLawyers'
  }
  return nextTab[tab];
};

export const genericCopyToClipboard = (value: string) => {
  const auxElement = document.createElement('textarea');
  auxElement.value = value;
  document.body.appendChild(auxElement);
  auxElement.select();
  document.execCommand('copy');
  document.body.removeChild(auxElement);
};

export const mountScheduleDashUserPath = (user: BasicUserForSchedule) => `${user.user_id}&${user.username}`
