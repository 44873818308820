import React, { useEffect } from 'react';
import '../../template.css';
import './styles.css';
import { Link, useHistory } from 'react-router-dom';
import { setButtonArrowActive, setActiveButton } from '../../utils/button';
import { PATH, DEFAULT_PATH } from '../PeopleList/constants';

const People: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    if (window.location.pathname === DEFAULT_PATH) {
      history.push('/painel/pessoas/lista');
    }
  }, [history]);

  return (
    <>
      <div className="sidebar__container">
        <Link to="/painel/pessoas/lista" className={setActiveButton(PATH)}>
          Listagem de pessoas
          <div className={setButtonArrowActive(PATH)} />
        </Link>
      </div>
    </>
  );
};

export default People;
