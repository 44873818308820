import React, { createRef, useEffect, useState, useCallback } from 'react';
import './styles.css';
import Calendar from '@toast-ui/react-calendar';
import MomentLocaleUtils from 'react-day-picker/moment';
import {
  calendarCustomTheme,
  CALENDAR_OBJECT,
  CALENDAR_DAYNAMES,
  CALENDAR_TIMEZONE,
  mountScheduleForModule,
} from '../../utils/calendar';
import 'tui-calendar/dist/tui-calendar.css';
import { Button, Icon, Tooltip } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import { formatCalendarPickerInputValue, formatDateToServer, momentFormatBrDate, momentFormatServerDate } from '../../utils/date';
import { reducers } from '../../store/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSchedulesByUser } from './actions';
import { GetCaseProgress } from '../CaseRelatedProgress/types';
import { fetchScheduleById } from '../CaseRelatedSchedules/actions';
import { Schedule } from '../CaseRelatedSchedules/types';
import { fetchProgressById } from '../CaseRelatedProgress/actions';
import ScheduleSideDialog from '../../components/ScheduleSideDialog';
import { genericCopyToClipboard } from '../../utils/navigation';

type Match = {
  path: string;
  url: string;
  isExact: boolean;
  params: any;
};
interface IScheduleDashboard {
  userId: string;
  match: Match;
};

const ScheduleDashboard: React.FC<IScheduleDashboard> = ({ userId, match }) => {
  const dispatch = useDispatch();
  const calendarRef = createRef() as any;
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const progressesByUser = useSelector((state: typeof reducers) => state.scheduledashboard.schedulesByUser);
  const scheduleById = useSelector((state: typeof reducers) => state.caserelatedschedules.scheduleById);
  const caseProgressById = useSelector((state: typeof reducers) => state.caserelatedprogress.progressById);
  const [calendarCurrentDate, setCalendarCurrentDate] = useState(new Date());
  const [calenderMode, setCalendarMode] = useState<'day' | 'week' | 'month'>('month');
  const [schedules, setSchedules] = useState<any[]>([]);
  const mountExportScheduleLink = () => `${process.env.REACT_APP_API}/schedules/generate_ics/${getUsernameFromQuery()}?current_date=${formatDateToServer(momentFormatBrDate(calendarCurrentDate))}`;
  const getUsernameFromQuery = () => match.params.id.split('&')[1];

  const handleRequestWithQueryId = useCallback(() => {
    dispatch(fetchSchedulesByUser(momentFormatServerDate(new Date()), match.params.id.split('&')[0]));
  }, [dispatch, match]);

  useEffect(() => {
    if (match) {
      handleRequestWithQueryId();
    }
  }, [dispatch, match, handleRequestWithQueryId]);

  useEffect(() => {
    if (calendarRef && calendarCurrentDate) {
      const calendarInstance = calendarRef.current.getInstance();
      calendarInstance.setDate(calendarCurrentDate);
    }
  }, [calendarCurrentDate, calendarRef]);

  useEffect(() => {
    if (scheduleById && scheduleById.data) {
      dispatch(fetchProgressById(scheduleById?.data?.case_progress_id));
    }
  }, [scheduleById, dispatch]);

  useEffect(() => {
    if (calenderMode === 'week' && calendarRef) {
      let calendar = calendarRef.current.getInstance();
      calendar.changeView('week', true);
    }
  }, [calenderMode, calendarRef]);

  useEffect(() => {
    if (progressesByUser?.data?.length) {
      const calendarSchedules = progressesByUser.data
        .filter((item: GetCaseProgress) => item.schedules.length)
        .map((item: GetCaseProgress) => item.schedules
        .map((item: Schedule) => mountScheduleForModule(item)))
        .flat();
      setSchedules(calendarSchedules);
    }
  }, [progressesByUser]);

  const handleClickSchedule = (event: any) => {
    setScheduleDialogOpen(true);
    dispatch(fetchScheduleById(event.schedule.id));
  };

  const handleChangeCalendarDate = (newDate: Date) => {
    setCalendarCurrentDate(newDate);
    dispatch(fetchSchedulesByUser(momentFormatServerDate(newDate), match.params.id.split('&')[0]));
  }; 

    return (
    <div className="schedule__dashboard">
      {caseProgressById.data && (
        <ScheduleSideDialog
          loading={caseProgressById?.loading}
          isDialogOpen={scheduleDialogOpen}
          setIsDialogOpen={setScheduleDialogOpen}
          data={caseProgressById?.data}
          selectedSchedule={scheduleById?.data?.schedule_id}
        />
      )}
      <div className="schedule__filters">
        <div className="calendar__picker__container">
          <DateInput
            formatDate={(date: Date) => formatCalendarPickerInputValue(date)}
            localeUtils={MomentLocaleUtils}
            fill
            locale="pt-BR"
            className="calendar__date__picker"
            rightElement={<Icon className="calendar" iconSize={Icon.SIZE_LARGE} icon="calendar"></Icon>}
            onChange={(newDate: Date) => handleChangeCalendarDate(newDate)}
            parseDate={(str) => new Date(str)}
            value={calendarCurrentDate}
          />
          <Icon icon="chevron-down"></Icon>
        </div>
        <div className="calendar__mode__buttons">
          <Button
            text="Mês"
            className={`calendar__button ${calenderMode === 'month' ? 'active' : ''}`}
            onClick={() => setCalendarMode('month')}
          />
          <Button
            text="Dia"
            className={`calendar__button ${calenderMode === 'day' ? 'active' : ''}`}
            onClick={() => setCalendarMode('day')}
          />
          <Button
            text="Semana"
            className={`calendar__button ${calenderMode === 'week' ? 'active' : ''}`}
            onClick={() => setCalendarMode('week')}
          />
        </div>
      </div>
      <div className="calendar__actions">
        <Tooltip content="Copiar link de exportação do calendário">
          <Icon icon="link" onClick={() => genericCopyToClipboard(mountExportScheduleLink())} />
        </Tooltip>
        <Tooltip content="Exportar calendário">
          <Icon icon="export" onClick={() => window.open(mountExportScheduleLink())} />
        </Tooltip>
      </div>
      <Calendar
        theme={calendarCustomTheme}
        ref={calendarRef}
        height="100vh"
        calendars={[CALENDAR_OBJECT]}
        isReadOnly={true}
        month={{
          startDayOfWeek: 0,
          narrowWeekend: true,
          daynames: CALENDAR_DAYNAMES,
        }}
        schedules={schedules}
        defaultView="week"
        scheduleView={true}
        taskView={false}
        template={{
          allday(schedule: any) {
            if (schedule.isAllDay) {
              return `Dia todo | ${schedule.title}`;  
            }
            const minutes = schedule.start.getMinutes();
            const hours = schedule.start.getHours();
            return `${hours - 3}:${minutes > 10 ? minutes : `0${minutes}`} | ${schedule.title}`;
          },
          schedule(schedule: any) {
            const minutes = schedule.start.getMinutes();
            const hours = schedule.start.getHours();
            return `${hours - 3}:${minutes > 10 ? minutes : `0${minutes}`} | ${schedule.title}`;
          },
          alldayTitle() {
            return '<h3><strong>Dia inteiro</strong></h3>';
          },
        }}
        view={calenderMode}
        week={{
          showTimezoneCollapseButton: true,
          timezonesCollapsed: true,
          narrowWeekend: false,
          daynames: CALENDAR_DAYNAMES,
          hourStart: 4,
          hourEnd: 23,
        }}
        timezones={CALENDAR_TIMEZONE}
        onClickSchedule={(e) => handleClickSchedule(e)}
      />
    </div>
  );
};

export default ScheduleDashboard;
