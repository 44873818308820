import { IBreadcrumbProps } from '@blueprintjs/core';

export const FETCH_CASE = 'FETCH_CASE';
export const FETCH_CASE_SUCCESS = 'FETCH_CASE_SUCCESS';
export const FETCH_CASE_FAIL = 'FETCH_CASE_FAIL';

export const UPDATE_CASE = 'UPDATE_CASE';
export const UPDATE_CASE_SUCCESS = 'UPDATE_CASE_SUCCESS';
export const UPDATE_CASE_FAIL = 'UPDATE_CASE_FAIL';

export const INACTIVATE_CASE = 'INACTIVATE_CASE';
export const INACTIVATE_CASE_SUCCESS = 'INACTIVATE_CASE_SUCCESS';
export const INACTIVATE_CASE_FAIL = 'INACTIVATE_CASE_FAIL';

export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';

export const BREADCRUMB_PATHS: IBreadcrumbProps[] = [
  { href: '/painel/casos/lista', text: 'Casos' },
  window.location.pathname.includes('detalhes')
    ? { href: '/painel/casos/detalhes', text: 'Detalhes do caso' }
    : { href: '/painel/casos/lista', text: 'Listagem' },
];
