import React from 'react';
import { Dialog, Spinner, Checkbox, Button } from '@blueprintjs/core';
import Input from '../Input';
import './styles.css';
import { cpfMask, cnpjMask } from '../../utils/masks';

interface IFastRegisterDialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: Function;
  isLegalPerson: boolean;
  setIsLegalPerson: Function;
  setIsLawyer?: Function;
  isLawyer?: boolean;
  loading: boolean;
  nameInput: any;
  cnpjInput: any;
  cpfInput: any;
  handleSendFastRegister: Function;
}

const FastRegisterDialog: React.FC<IFastRegisterDialogProps> = ({
  isDialogOpen,
  setIsDialogOpen,
  loading,
  nameInput,
  cpfInput,
  cnpjInput,
  handleSendFastRegister,
  isLegalPerson,
  setIsLegalPerson,
  setIsLawyer,
  isLawyer,
}) => {
  return (
    <Dialog
      className="register__dialog"
      isOpen={isDialogOpen}
      title="Cadastro rápido de pessoa"
      onClose={() => setIsDialogOpen(false)}
      canEscapeKeyClose
    >
      {loading ? (
        <Spinner intent="primary" size={25} />
      ) : (
        <>
          <div className="input__group">
            <Input inputRef={nameInput} placeholder="Nome" />
            {isLegalPerson && <Input inputRef={cnpjInput} mask={cnpjMask} placeholder="CNPJ" />}
            {!isLegalPerson && <Input inputRef={cpfInput} mask={cpfMask} placeholder="CPF" />}
          </div>
          <div className="checkbox__group">
            <Checkbox checked={isLegalPerson} onChange={() => setIsLegalPerson((prevState: boolean) => !prevState)}>
              Pessoa jurídica
            </Checkbox>
            <Checkbox checked={!isLegalPerson} onChange={() => setIsLegalPerson((prevState: boolean) => !prevState)}>
              Pessoa física
            </Checkbox>
            {setIsLawyer && (
              <Checkbox checked={isLawyer} onChange={() => setIsLawyer((prevState: boolean) => !prevState)}>
                Advogado externo
              </Checkbox>
            )}
          </div>
          <div className="button__group">
            <Button intent="danger" onClick={() => setIsDialogOpen(false)} minimal text="Cancelar" />
            <Button intent="primary" onClick={() => handleSendFastRegister()} text="Salvar" />
          </div>
        </>
      )}
    </Dialog>
  );
};

export default FastRegisterDialog;
