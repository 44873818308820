import { IBreadcrumbProps } from '@blueprintjs/core';

export const FETCH_CASES = 'FETCH_CASES';
export const FETCH_CASES_SUCCESS = 'FETCH_CASES_SUCCESS';
export const FETCH_CASES_FAIL = 'FETCH_CASES_FAIL';

export const FETCH_ALL_CASES = 'FETCH_ALL_CASES';
export const FETCH_ALL_CASES_SUCCESS = 'FETCH_ALL_CASES_SUCCESS';
export const FETCH_ALL_CASES_FAIL = 'FETCH_ALL_CASES_FAIL';

export const CLEAR_CREATE_STATUS = 'CLEAR_CREATE_STATUS';

export const COLUMNS = ['Número', 'Status', 'Pasta', 'Pessoas'];

export const PATH = '/casos/lista';
export const DEFAULT_PATH = '/painel/casos';

export const BREADCRUMB_PATHS: IBreadcrumbProps[] = [
  { href: '/painel/casos', text: 'Casos' },
  { href: '/painel/casos/lista', text: 'Listagem' }
];
