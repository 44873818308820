/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  justiceTypes: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  justiceTypesPaginated: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  justiceTypesId: {
    data: null,
    loading: false,
    fail: null,
  },
  createJusticeType: {
    loading: false,
    fail: null,
    success: null,
  },
  updateJusticeType: {
    loading: false,
    fail: null,
    success: null,
  },
  inactivateJusticeType: {
    loading: false,
    fail: null,
    success: null,
  },
  justiceTypeByCounty: {
    loading: false,
    fail: null,
    data: [],
    empty: true,
  },
};

const metaCases: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_JUSTICE_TYPE:
        draft.justiceTypes.loading = true;
        draft.justiceTypes.empty = true;
        draft.justiceTypes.fail = null;
        return;

      case c.FETCH_JUSTICE_TYPE_SUCCESS:
        draft.justiceTypes.loading = false;
        draft.justiceTypes.fail = null;
        if (payload && payload.data.length) {
          draft.justiceTypes.data = payload.data;
          draft.justiceTypes.empty = false;
        }
        return;

      case c.FETCH_JUSTICE_TYPE_FAIL:
        draft.justiceTypes.loading = false;
        draft.justiceTypes.fail = payload.error;
        draft.justiceTypes.data = payload.data;
        return;

        case c.FETCH_JUSTICE_TYPE_PAGINATED:
          draft.justiceTypesPaginated.loading = true;
          draft.justiceTypesPaginated.empty = true;
          draft.justiceTypesPaginated.fail = null;
          return;
  
        case c.FETCH_JUSTICE_TYPE_PAGINATED_SUCCESS:
          draft.justiceTypesPaginated.loading = false;
          draft.justiceTypesPaginated.fail = null;
          if (payload && payload.data.result.length) {
            draft.justiceTypesPaginated.data = payload.data;
            draft.justiceTypesPaginated.empty = false;
          }
          return;
  
        case c.FETCH_JUSTICE_TYPE_PAGINATED_FAIL:
          draft.justiceTypesPaginated.loading = false;
          draft.justiceTypesPaginated.fail = payload.error;
          draft.justiceTypesPaginated.data = payload.data;
          return;

      case c.FETCH_JUSTICE_TYPE_ID:
        draft.justiceTypesId.loading = true;
        draft.justiceTypesId.fail = null;
        return;

      case c.FETCH_JUSTICE_TYPE_ID_SUCCESS:
        draft.justiceTypesId.loading = false;
        draft.justiceTypesId.fail = null;
        if (payload && payload.data.length) {
          draft.justiceTypesId.data = payload.data;
        }
        return;

      case c.FETCH_JUSTICE_TYPE_ID_FAIL:
        draft.justiceTypesId.loading = false;
        draft.justiceTypesId.fail = payload.error;
        draft.justiceTypesId.data = payload.data;
        return;

      case c.CREATE_JUSTICE_TYPE:
        draft.createJusticeType.loading = true;
        draft.createJusticeType.fail = null;
        draft.createJusticeType.success = null;
        return;

      case c.CREATE_JUSTICE_TYPE_SUCCESS:
        draft.createJusticeType.loading = false;
        draft.createJusticeType.success = payload.data;
        return;

      case c.CREATE_JUSTICE_TYPE_FAIL:
        draft.createJusticeType.loading = false;
        draft.createJusticeType.fail = payload.error;
        draft.createJusticeType.success = null;
        return;

      case c.UPDATE_JUSTICE_TYPE:
        draft.updateJusticeType.loading = true;
        draft.updateJusticeType.fail = null;
        draft.updateJusticeType.success = null;
        return;

      case c.UPDATE_JUSTICE_TYPE_SUCCESS:
        draft.updateJusticeType.loading = false;
        draft.updateJusticeType.fail = null;
        draft.updateJusticeType.success = payload.data;
        return;

      case c.UPDATE_JUSTICE_TYPE_FAIL:
        draft.updateJusticeType.loading = false;
        draft.updateJusticeType.fail = payload.error;
        draft.updateJusticeType.success = null;
        return;

      case c.INACTIVATE_JUSTICE_TYPE:
        draft.inactivateJusticeType.loading = true;
        draft.inactivateJusticeType.fail = null;
        draft.inactivateJusticeType.success = null;
        return;

      case c.INACTIVATE_JUSTICE_TYPE_SUCCESS:
        draft.inactivateJusticeType.loading = false;
        draft.inactivateJusticeType.fail = null;
        draft.inactivateJusticeType.success = payload.data;
        return;

      case c.INACTIVATE_JUSTICE_TYPE_FAIL:
        draft.inactivateJusticeType.loading = false;
        draft.inactivateJusticeType.fail = payload.error;
        return;

      case c.FETCH_JUSTICE_TYPE_BY_COUNTY:
        draft.justiceTypeByCounty.data = [];
        draft.justiceTypeByCounty.loading = true;
        draft.justiceTypeByCounty.empty = true;
        return;

      case c.FETCH_JUSTICE_TYPE_BY_COUNTY_SUCCESS:
        draft.justiceTypeByCounty.loading = false;
        if (payload && payload.data.length) {
          draft.justiceTypeByCounty.data = payload.data;
          draft.justiceTypeByCounty.empty = false;
        }
        return;

      case c.FETCH_JUSTICE_TYPE_BY_COUNTY_FAIL:
        draft.justiceTypeByCounty.loading = false;
        draft.justiceTypeByCounty.loading = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.createJusticeType.success = null;
        draft.updateJusticeType.success = null;
        draft.inactivateJusticeType.success = null;
        draft.createJusticeType.fail = null;
        draft.updateJusticeType.fail = null;
        draft.inactivateJusticeType.fail = null;
        draft.justiceTypeByCounty.empty = true;
        draft.justiceTypeByCounty.data = [];
        return;

      default:
        return;
    }
  });

export default metaCases;
