export const formElements2Array = (elements: HTMLFormControlsCollection): HTMLInputElement[] => [].slice.call(elements);

export const getValuesFromForm = (target: HTMLFormElement) => {
  const elements = formElements2Array(target.elements);
  const inputElements = elements.filter((elem) => elem.value && elem.type);
  const inputValues = inputElements.map((elem) => elem.value);
  return inputValues;
};

export const elementFocus = (target: React.MutableRefObject<any>) => target.current.focus();

export const formatNumberToCurrency = (currencyValue: number) =>
  currencyValue?.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

export const handleCurrencyChange = (formattedValue: string) => 
  Number(
    [formattedValue.slice(0, formattedValue.length - 2), '.', formattedValue.slice(formattedValue.length - 2)].join('')
  );

export const clearValue = (value: string) => value.replace(/\D+/g, '');
