/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';
import { goToHere } from '../../utils/navigation';

const INITIAL_STATE: State = {
  person: {
    fail: null,
    empty: true,
    loading: false,
    data: {},
  },
  personUpdate: {
    failUpdate: null,
    loadingUpdate: false,
    messageUpdate: null,
    dataUpdate: {},
  },
  personInactivate: {
    fail: null,
    loading: false,
    success: null,
  },
};

const person: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_PERSON:
        draft.person.loading = true;
        draft.person.fail = null;
        return;

      case c.FETCH_PERSON_SUCCESS:
        draft.person.loading = false;
        draft.person.fail = null;
        draft.person.data = payload.data;
        return;

      case c.FETCH_PERSON_FAIL:
        draft.person.loading = false;
        draft.person.fail = 'Houve uma falha ao buscar esta pessoa!';
        return;

      case c.UPDATE_PERSON:
        draft.personUpdate.loadingUpdate = true;
        draft.personUpdate.failUpdate = null;
        draft.personUpdate.messageUpdate = null;
        return;

      case c.UPDATE_PERSON_SUCCESS:
        draft.personUpdate.loadingUpdate = false;
        draft.personUpdate.messageUpdate = 'Pessoa atualizada com sucesso!';
        goToHere(`/painel/pessoas/individuo/${payload.data.person_id}`);
        return;

      case c.UPDATE_PERSON_FAIL:
        draft.personUpdate.loadingUpdate = false;
        draft.personUpdate.messageUpdate = null;
        draft.personUpdate.failUpdate = payload.error;
        return;

      case c.INACTIVATE_PERSON:
        draft.personInactivate.loading = true;
        draft.personInactivate.fail = null;
        return;

      case c.INACTIVATE_PERSON_SUCCESS:
        draft.personInactivate.loading = false;
        draft.personInactivate.fail = null;
        draft.personInactivate.success = "Pessoa arquivada com sucesso!";
        setTimeout(() => {
          goToHere('/painel/pessoas/lista');
        }, 1000);
        return;

      case c.INACTIVATE_PERSON_FAIL:
        draft.personInactivate.loading = false;
        draft.personInactivate.fail = 'Houve uma falha ao buscar esta pessoa!';
        return;

      case c.CLEAR_ALL_STATUS:
        draft.personUpdate.loadingUpdate = false;
        draft.personUpdate.messageUpdate = null;
        draft.personUpdate.failUpdate = null;
        return;

      default:
        return;
    }
  });

export default person;
