import React, { useState, useEffect } from 'react';
import { InputGroup, IInputGroupProps, Button } from '@blueprintjs/core';
import './styles.css';
import { cpfMask, cnpjMask } from '../../utils/masks';

interface IInput extends IInputGroupProps {
  button?: {
    text: string;
    icon?: IInputGroupProps['leftIcon'];
    disabledValidation?: boolean;
    onClick?: any;
  };
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  mask?: (value: string) => string;
  value?: string;
  defaultValue?: string;
  maxLength?: number;
  type?: string;
}

const Input: React.FC<IInput> = (props) => {
  const { button, required, mask, placeholder, inputRef, onChange, defaultValue, maxLength, disabled, value, type } = props;
  const [inputValue, setInputValue] = useState(defaultValue);
  const handleMask = (e: any) => {
    if (mask) {
      setInputValue(mask(e.target.value));
    }
  };

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  if (button) {
    const rightButton = (
      <Button
        className="input__button"
        disabled={button.disabledValidation}
        type="submit"
        intent="primary"
        icon={button.icon}
        onClick={() => button.onClick()}
      >
        {button.text}
      </Button>
    );
    return (
      <>
        <InputGroup
          onChange={onChange}
          className="input__group"
          large
          rightElement={rightButton}
          inputRef={inputRef}
          required={required}
          placeholder={placeholder}
          type="text"
          maxLength={maxLength}
        />
      </>
    );
  }

  if (mask) {
    if (defaultValue) {
      return (
        <InputGroup
          className="input__component"
          defaultValue={cpfMask(defaultValue) || cnpjMask(defaultValue)}
          onChange={handleMask}
          inputRef={inputRef}
          required={required}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={disabled}
          value={inputValue}
        />
      );
    }
    return (
      <InputGroup
        className="input__component"
        value={inputValue}
        onChange={handleMask}
        required={required}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        inputRef={inputRef}
      />
    );
  }

  return <InputGroup className="input__component" type={type} maxLength={maxLength} value={value} {...props} required={required} />;
};

export default Input;
