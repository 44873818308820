/* eslint-disable import/prefer-default-export */
export const COLUMNS = ['Nome do arquivo', 'Rótulo', 'Tipo'];

export const FETCH_RELATED_DOCUMENTS = 'FETCH_RELATED_DOCUMENTS';
export const FETCH_RELATED_DOCUMENTS_SUCCESS = 'FETCH_RELATED_DOCUMENTS_SUCCESS';
export const FETCH_RELATED_DOCUMENTS_FAIL = 'FETCH_RELATED_DOCUMENTS_FAIL';

export const INACTIVATE_CASE_DOCUMENT_RELATION = 'INACTIVATE_CASE_DOCUMENT_RELATION';
export const INACTIVATE_CASE_DOCUMENT_RELATION_SUCCESS = 'INACTIVATE_CASE_DOCUMENT_RELATION_SUCCESS';
export const INACTIVATE_CASE_DOCUMENT_RELATION_FAIL = 'INACTIVATE_CASE_DOCUMENT_RELATION_FAIL';

export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
