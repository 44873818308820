import produce from 'immer';
import { Reducer, State } from './types';
import * as c from './constants';

const INITIAL_STATE: State = {
  fail: null,
  loading: false,
  success: null,
};

const registeruser: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.POST_NEW_USER:
        draft.loading = true;
        draft.fail = null;
        return;

      case c.POST_NEW_USER_SUCCESS:
        draft.loading = false;
        draft.success = 'Usuário cadastrado com sucesso!';
        return;

      case c.POST_NEW_USER_FAIL:
        draft.loading = false;
        draft.fail = payload.error;
        return;

      default:
        return;
    }
  });

export default registeruser;
