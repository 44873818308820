import { IBreadcrumbProps } from '@blueprintjs/core';

export const BREADCRUMB_PATHS: IBreadcrumbProps[] = [
  { href: '/painel/grupos/lista', text: 'Grupos e usuários' },
  { href: '/painel/grupos/lista', text: 'Listagem de grupos' },
  window.location.pathname.includes('permissoes')
    ? { href: '/painel/grupos/lista/permissoes', text: 'Grupo' }
    : { href: '/painel/grupos/lista', text: 'Grupos' },
];

export const CASES_MODULE_NAME = 'legal_cases';
export const PEOPLE_MODULE_NAME = 'person';
export const SCHEDULE_MODULE_NAME = 'schedule';
export const DOCUMENT_MODULE_NAME = 'document';
export const USER_MODULE_NAME = 'user';
export const ROLE_MODULE_NAME = 'role';
export const META_MODULE_NAME = 'meta_data';

export const WRITE_PERMISSION = 'w';
export const READ_PERMISSION = 'r';
export const INACTIVATE_PERMISSION = 'd';

export const NON_REMOVABLE_ROLES = ['Advogado Externo', 'Cliente Externo', 'Administrador'];
export const NON_REMOVABLE_OCCUPATION = 'Advogado';

export const FETCH_ENTITIES = 'FETCH_ENTITIES';
export const FETCH_ENTITIES_SUCCESS = 'FETCH_ENTITIES_SUCCESS';
export const FETCH_ENTITIES_FAIL = 'FETCH_ENTITIES_FAIL';
export const FETCH_ENTITIES_BY_ID = 'FETCH_ENTITIES_BY_ID';
export const FETCH_ENTITIES_BY_ID_SUCCESS = 'FETCH_ENTITIES_BY_ID_SUCCESS';
export const FETCH_ENTITIES_BY_ID_FAIL = 'FETCH_ENTITIES_BY_ID_FAIL';
export const UPDATE_ROLE = 'UPDATE_ROLES';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLES_SUCCESS';
export const UPDATE_ROLE_FAIL = 'UPDATE_ROLES_FAIL';
export const CREATE_ROLE = 'CREATE_ROLE';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAIL = 'CREATE_ROLE_FAIL';
export const INACTIVATE_ROLE = 'INACTIVATE_ROLE';
export const INACTIVATE_ROLE_SUCCESS = 'INACTIVATE_ROLE_SUCCESS';
export const INACTIVATE_ROLE_FAIL = 'INACTIVATE_ROLE_FAIL';

export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
