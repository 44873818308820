import React, { useState, useEffect, SyntheticEvent } from 'react';
import Button from '../../components/Button';
import { useSelector, useDispatch } from 'react-redux';
import { reducers } from '../../store/reducers';
import '../../components/PersonRelations/styles.css';
import createToaster from '../../components/Toaster';
import { Classes, TextArea, Icon, Tooltip, Spinner, NonIdealState } from '@blueprintjs/core';
import Selector from '../../components/Selector';
import { fetchDocumentTypes } from '../MetaDocuments/actions';
import { createDocumentRelation, fetchRelatedDocuments, inactivateDocumentRelation } from './actions';
import Input from '../../components/Input';
import PopoverEditRemove from '../../components/PopoverEditRemove';
import { goToHere } from '../../utils/navigation';
import { GetPersonAttachments } from './types';
import ConfirmationDialog from '../../components/ConfirmationDialog';

interface IConnectedPeople {
  personId: string;
}

const PeopleAttachments: React.FC<IConnectedPeople> = ({ personId }) => {
  const dispatch = useDispatch();
  const create = useSelector((state: typeof reducers) => state.peopleattachments.create);
  const personDocuments = useSelector((state: typeof reducers) => state.peopleattachments.personDocuments);
  const inactivate = useSelector((state: typeof reducers) => state.peopleconnectedpeople.inactivate);
  const fastRegister = useSelector((state: typeof reducers) => state.peoplelist.fastRegister);
  const documentTypes = useSelector((state: typeof reducers) => state.metadocuments.documentTypes);
  const formField = React.useRef() as React.MutableRefObject<HTMLFormElement>;
  const [files, setFiles] = useState<File[]>([]);
  const [fileType, setFileType] = useState<string | null>();
  const [title, setTitle] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState<string | null>(null);

  useEffect(() => {
    if (personId?.length) {
      dispatch(fetchDocumentTypes());
      dispatch(fetchRelatedDocuments(personId));
    }
  }, [dispatch, personId]);


  useEffect(() => {
    if (inactivate?.success || inactivate?.fail || create?.success || create?.fail) {
      const showToast = createToaster({
        message: inactivate?.success || inactivate?.fail || create?.success || create?.fail,
        intent: inactivate?.fail || create?.fail ? 'danger' : 'success',
        icon: inactivate?.fail || create?.fail ? 'warning-sign' : 'tick',
      });
      showToast();
      if (create?.success) {
        clearInputs();
      }
    }
  }, [inactivate, create]);

  useEffect(() => {
    if (fastRegister.fail || fastRegister.success) {
      const showToast = createToaster({
        message: fastRegister.fail || fastRegister.success,
        intent: fastRegister.fail ? 'danger' : 'success',
        icon: fastRegister.fail ? 'warning-sign' : 'tick',
      });
      showToast();
    }
  }, [fastRegister.fail, fastRegister.success]);

  const documentInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length) {
      Array.prototype.forEach.call(e.target.files, (file) => {
        setFiles((state: File[]) => [...state, file]);
      });
    }
  };

  const clearInputs = () => {
    setFiles([]);
    setFileType(null);
    setDescription(null);
    setTitle(null);
  };

  const mountFormData = () => {
    let formData = new FormData();
    Array.prototype.forEach.call(files, (file) => {
      formData.append('files', file);
    });
    formData.append('name', title!);
    formData.append('document_type_id', fileType!);
    if (description) {
      formData.append('description', description);
    }
    return formData;
  };

  const handleSubmitDocuments = (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(createDocumentRelation(personId, mountFormData()));
  };

  const handleRemoveMethod = (documentId: string) => {
    setIsDialogOpen(true);
    return setDocumentToDelete(documentId);
  };

  const handleConfirmation = () => {
    setIsDialogOpen(false);
    return dispatch(inactivateDocumentRelation(personId, documentToDelete!));
  };

  return (
    <>
      <div className="tabs__subline"></div>
      <ConfirmationDialog
        headerText="Tem certeza que deseja excluir este item?"
        confirmMethod={() => handleConfirmation()}
        setIsOpen={setIsDialogOpen}
        isOpen={isDialogOpen}
      />
      <div className="content__container">
        <div className="files__list--container">
          {personDocuments.loading || create.loading || inactivate.loading ? (
            <Spinner intent="primary" size={25} />
          ) : (
              <>
                {!personDocuments.empty ? (
                  <ul className="file__list">
                    {personDocuments?.data?.map((item: GetPersonAttachments) => (
                      <li key={item.document_id} className="file__list__item">
                        <Tooltip content={item?.description || ''}>
                          <Icon icon="document" />
                        </Tooltip>
                        <span onClick={() => goToHere(item.url)} className="document__name">{item.name} ({item.document_type?.document_type})</span>
                        <PopoverEditRemove
                          className="popover__edit-remove"
                          primaryButtonText="Baixar"
                          primaryButtonIcon="download"
                          removeRequest={() => handleRemoveMethod(item.document_id)}
                        />
                      </li>
                    ))}
                  </ul>
                ) : (
                    <NonIdealState icon="inbox" title="Nenhum documento relacionado" />
                  )}
              </>
            )}
        </div>
        <form className="register__form documents__form" onSubmit={handleSubmitDocuments} ref={formField}>
          <h2>Anexar Documentos</h2>
          <input className={`${Classes.INPUT} file__input`} type="file" onChange={documentInputChange} multiple />
          <Input
            required
            value={title || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
            placeholder="Rótulo *"
            maxLength={30}
          />
          <Selector
            required
            value={fileType || ''}
            className={`${Classes.INPUT} relations__selector`}
            documentTypes={documentTypes.data}
            setValue={setFileType}
          />
          <TextArea
            large
            placeholder="Descrição"
            maxLength={400}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
          />
          <div className="buttons__container">
            <Button name="add" text="Salvar relação" type="submit" />
          </div>
        </form>
      </div>
    </>
  );
};

export default PeopleAttachments;
