/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  tagTypes: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  tagTypesPaginated: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  tagTypesId: {
    data: null,
    loading: false,
    fail: null,
  },
  createTagType: {
    loading: false,
    fail: null,
    success: null,
  },
  updateTagType: {
    loading: false,
    fail: null,
    success: null,
  },
  inactivateTagType: {
    loading: false,
    fail: null,
    success: null,
  },
};

const metaCases: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_TAG_TYPE:
        draft.tagTypes.loading = true;
        draft.tagTypes.empty = true;
        draft.tagTypes.fail = null;
        return;

      case c.FETCH_TAG_TYPE_SUCCESS:
        draft.tagTypes.loading = false;
        draft.tagTypes.fail = null;
        if (payload && payload.data.length) {
          draft.tagTypes.data = payload.data;
          draft.tagTypes.empty = true;
        }
        return;

      case c.FETCH_TAG_TYPE_FAIL:
        draft.tagTypes.loading = false;
        draft.tagTypes.fail = payload.error;
        draft.tagTypes.data = payload.data;
        return;

      case c.FETCH_TAG_TYPE_PAGINATED:
        draft.tagTypesPaginated.loading = true;
        draft.tagTypesPaginated.empty = true;
        draft.tagTypesPaginated.fail = null;
        return;

      case c.FETCH_TAG_TYPE_PAGINATED_SUCCESS:
        draft.tagTypesPaginated.loading = false;
        draft.tagTypesPaginated.fail = null;
        if (payload && payload.data.result.length) {
          draft.tagTypesPaginated.data = payload.data;
          draft.tagTypesPaginated.empty = false;
        }
        return;

      case c.FETCH_TAG_TYPE_PAGINATED_FAIL:
        draft.tagTypesPaginated.loading = false;
        draft.tagTypesPaginated.fail = payload.error;
        draft.tagTypesPaginated.data = payload.data;
        return;

      case c.FETCH_TAG_TYPE_ID:
        draft.tagTypesId.loading = true;
        draft.tagTypesId.fail = null;
        return;

      case c.FETCH_TAG_TYPE_ID_SUCCESS:
        draft.tagTypesId.loading = false;
        draft.tagTypesId.fail = null;
        if (payload && payload.data.length) {
          draft.tagTypesId.data = payload.data;
        }
        return;

      case c.FETCH_TAG_TYPE_ID_FAIL:
        draft.tagTypesId.loading = false;
        draft.tagTypesId.fail = payload.error;
        draft.tagTypesId.data = payload.data;
        return;

      case c.CREATE_TAG_TYPE:
        draft.createTagType.loading = true;
        draft.createTagType.fail = null;
        draft.createTagType.success = null;
        return;

      case c.CREATE_TAG_TYPE_SUCCESS:
        draft.createTagType.loading = false;
        draft.createTagType.success = payload.data;
        return;

      case c.CREATE_TAG_TYPE_FAIL:
        draft.createTagType.loading = false;
        draft.createTagType.fail = payload.error;
        draft.createTagType.success = null;
        return;

      case c.UPDATE_TAG_TYPE:
        draft.updateTagType.loading = true;
        draft.updateTagType.fail = null;
        draft.updateTagType.success = null;
        return;

      case c.UPDATE_TAG_TYPE_SUCCESS:
        draft.updateTagType.loading = false;
        draft.updateTagType.fail = null;
        draft.updateTagType.success = payload.data;
        return;

      case c.UPDATE_TAG_TYPE_FAIL:
        draft.updateTagType.loading = false;
        draft.updateTagType.fail = payload.error;
        draft.updateTagType.success = null;
        return;

      case c.INACTIVATE_TAG_TYPE:
        draft.inactivateTagType.loading = true;
        draft.inactivateTagType.fail = null;
        draft.inactivateTagType.success = null;
        return;

      case c.INACTIVATE_TAG_TYPE_SUCCESS:
        draft.inactivateTagType.loading = false;
        draft.inactivateTagType.fail = null;
        draft.inactivateTagType.success = payload.data;
        return;

      case c.INACTIVATE_TAG_TYPE_FAIL:
        draft.inactivateTagType.loading = false;
        draft.inactivateTagType.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.createTagType.success = null;
        draft.updateTagType.success = null;
        draft.inactivateTagType.success = null;
        draft.createTagType.fail = null;
        draft.updateTagType.fail = null;
        draft.inactivateTagType.fail = null;
        return;

      default:
        return;
    }
  });

export default metaCases;
