export const FETCH_JUSTICE_TYPE = 'FETCH_JUSTICE_TYPE';
export const FETCH_JUSTICE_TYPE_SUCCESS = 'FETCH_JUSTICE_TYPE_SUCCESS';
export const FETCH_JUSTICE_TYPE_FAIL = 'FETCH_JUSTICE_TYPE_FAIL';
export const FETCH_JUSTICE_TYPE_PAGINATED = 'FETCH_JUSTICE_TYPE_PAGINATED';
export const FETCH_JUSTICE_TYPE_PAGINATED_SUCCESS = 'FETCH_JUSTICE_TYPE_PAGINATED_SUCCESS';
export const FETCH_JUSTICE_TYPE_PAGINATED_FAIL = 'FETCH_JUSTICE_TYPE_PAGINATED_FAIL';
export const FETCH_JUSTICE_TYPE_ID = 'FETCH_JUSTICE_TYPE_ID';
export const FETCH_JUSTICE_TYPE_ID_SUCCESS = 'FETCH_JUSTICE_TYPE_ID_SUCCESS';
export const FETCH_JUSTICE_TYPE_ID_FAIL = 'FETCH_JUSTICE_TYPE__ID_FAIL';
export const CREATE_JUSTICE_TYPE = 'CREATE_JUSTICE_TYPE';
export const CREATE_JUSTICE_TYPE_SUCCESS = 'CREATE_JUSTICE_TYPE_SUCCESS';
export const CREATE_JUSTICE_TYPE_FAIL = 'CREATE_JUSTICE_TYPE_FAIL';
export const UPDATE_JUSTICE_TYPE = 'UPDATE_JUSTICE_TYPE';
export const UPDATE_JUSTICE_TYPE_SUCCESS = 'UPDATE_JUSTICE_TYPE_SUCCESS';
export const UPDATE_JUSTICE_TYPE_FAIL = 'UPDATE_JUSTICE_TYPE_FAIL';
export const INACTIVATE_JUSTICE_TYPE = 'INACTIVATE_JUSTICE_TYPE';
export const INACTIVATE_JUSTICE_TYPE_SUCCESS = 'INACTIVATE_JUSTICE_TYPE_SUCCESS';
export const INACTIVATE_JUSTICE_TYPE_FAIL = 'INACTIVATE_JUSTICE_TYPE_FAIL';
export const FETCH_JUSTICE_TYPE_BY_COUNTY = 'FETCH_JUSTICE_TYPE_BY_COUNTY';
export const FETCH_JUSTICE_TYPE_BY_COUNTY_SUCCESS = 'FETCH_JUSTICE_TYPE_BY_COUNTY_SUCCESS';
export const FETCH_JUSTICE_TYPE_BY_COUNTY_FAIL = 'FETCH_JUSTICE_TYPE_BY_COUNTY_FAIL';
export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
