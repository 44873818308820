/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import update from 'immutability-helper';
import Input from '../../components/Input';
import { reducers } from '../../store/reducers';
import { createJusticeType, inactivateJusticeType, updateJusticeType, clearAllStatus, fetchJusticeTypesPaginated } from './actions';
import MetaTable from '../../components/MetaTable';
import { JusticeType } from './types';
import '../Meta/styles.css';
import Button from '../../components/Button';
import createToaster from '../../components/Toaster';
import { WRITE_PERMISSION, META_MODULE_NAME } from '../Permissions/constants';
import { verifyUserPermission } from '../../utils/permissions';
import { fetchDistrictTypes } from '../MetaCasesDistrictType/actions';
import Pagination from '../../components/Pagination';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

const MetaCasesJusticeType: React.FC = () => {
  const dispatch = useDispatch();
  // BlueprintJS inputRef
  const input = React.useRef() as any;
  const justiceTypesPaginated = useSelector((state: typeof reducers) => state.metacasesjusticetype.justiceTypesPaginated);
  const createJusticeTypes = useSelector((state: typeof reducers) => state.metacasesjusticetype.createJusticeType);
  const updateJusticeTypes = useSelector((state: typeof reducers) => state.metacasesjusticetype.updateJusticeType);
  const inactivateJusticeTypes = useSelector((state: typeof reducers) => state.metacasesjusticetype.inactivateJusticeType);
  const [isEdit, setIsEdit] = useState(false);
  const [stateJusticeType, setStateJusticeType] = useState<JusticeType>({
    justice_type_id: '',
    name: '',
    is_active: false,
    created_at: '',
    county: {},
    county_id: '',
  });

  useEffect(() => {
    if (createJusticeTypes.success) {
      const showToast = createToaster({
        message: createJusticeTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createJusticeTypes.success, dispatch]);

  useEffect(() => {
    if (updateJusticeTypes.success) {
      const showToast = createToaster({
        message: updateJusticeTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateJusticeTypes.success, dispatch]);

  useEffect(() => {
    if (inactivateJusticeTypes.success) {
      const showToast = createToaster({
        message: inactivateJusticeTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateJusticeTypes.success, dispatch]);

  useEffect(() => {
    if (createJusticeTypes.fail) {
      const showToast = createToaster({
        message: createJusticeTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createJusticeTypes.fail, dispatch]);

  useEffect(() => {
    if (updateJusticeTypes.fail) {
      const showToast = createToaster({
        message: updateJusticeTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateJusticeTypes.fail, dispatch]);

  useEffect(() => {
    if (inactivateJusticeTypes.fail) {
      const showToast = createToaster({
        message: inactivateJusticeTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateJusticeTypes.fail, dispatch]);

  const setInputValue = (value: string) => (input.current.value = value);

  const setUpNewState = (elem: JusticeType) =>
    update(stateJusticeType, {
      name: { $set: elem.name },
      justice_type_id: { $set: elem.justice_type_id },
      is_active: { $set: elem.is_active },
      created_at: { $set: elem.created_at },
    });

  const handleEditRequest = (elem: JusticeType) => {
    setInputValue(elem.name);
    setIsEdit(true);
    setStateJusticeType(setUpNewState(elem));
  };

  useEffect(() => {
    dispatch(fetchJusticeTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
    dispatch(fetchDistrictTypes());
  }, [dispatch]);

  const resetForm = () => {
    setInputValue('');
    setStateJusticeType((prevState) => ({
      ...prevState,
      name: '',
    }));
    setIsEdit(false);
  };

  const addJusticeType = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isEdit) {
      dispatch(createJusticeType(stateJusticeType.name));
    } else {
      const newElem = update(stateJusticeType, {
        name: { $set: stateJusticeType.name },
      });
      dispatch(updateJusticeType(newElem));
    }
    resetForm();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateJusticeType(
      update(stateJusticeType, {
        name: { $set: e.target.value },
      })
    );
  };

  const handleTextButton = () => (isEdit ? 'Atualizar justiça' : 'Adicionar justiça');

  const disabledValidation = () => stateJusticeType.name === '';

  return (
    <form className="component__container" onSubmit={addJusticeType}>
      {verifyUserPermission(META_MODULE_NAME, WRITE_PERMISSION) && (
        <Input
          required
          value={stateJusticeType?.name}
          onChange={onChangeInput}
          inputRef={input}
          placeholder="Digite o tipo de justiça que gostaria de adicionar... *"
          button={{
            text: handleTextButton(),
            icon: 'plus',
            disabledValidation: disabledValidation(),
          }}
        />
      )}
      {isEdit && (
        <div>
          <Button name="backwhite" onClick={resetForm}>
            Cancelar edição
          </Button>
        </div>
      )}
      <h3>Tipos de justiça já cadastrados:</h3>
      <MetaTable
        loading={
          justiceTypesPaginated?.loading ||
          updateJusticeTypes?.loading ||
          inactivateJusticeTypes?.loading ||
          createJusticeTypes?.loading
        }
        empty={justiceTypesPaginated.empty}
        data={justiceTypesPaginated.data.result}
        headers={['Nome', 'Criado em']}
        columns={['name', 'created_at']}
        pathId="justice_type_id"
        removeRequest={inactivateJusticeType}
        editRequest={handleEditRequest}
        group
      />
      {!justiceTypesPaginated?.empty && (
        <Pagination
          request={fetchJusticeTypesPaginated}
          loading={justiceTypesPaginated?.loading}
          data={justiceTypesPaginated?.data}
        />
      )}
    </form>
  );
};

export default React.memo(MetaCasesJusticeType);
