import axios, { AxiosResponse } from 'axios';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { logout } from '../utils/auth';

const clients = {
  default: {
    client: axios.create({
      baseURL: process.env.REACT_APP_API,
      responseType: 'json',
    }),
    options: {
      interceptors: {
        request: [
          ({ getState, dispatch, getSourceAction }: any, config: any) => {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = JSON.parse(sessionStorage.getItem('token') as string);
            return config;
          },
        ],
        response: [
          {
            error: (_: any, error: { response: AxiosResponse }) => {
              let code;
              if (error && error.response) {
                ({
                  response: { status: code },
                } = error);
              }

              if (code === 401) {
                logout();
              }

              return Promise.reject(error);
            },
          },
        ],
      },
    },
  },
};

export default multiClientMiddleware(clients);
