import React, { useEffect, useState, createRef } from 'react';
import 'tui-calendar/dist/tui-calendar.css';
import '../../components/CaseRelations/styles.css';
import './styles.css';
import { useSelector, useDispatch } from 'react-redux';
import { reducers } from '../../store/reducers';
import MomentLocaleUtils from 'react-day-picker/moment';
import Calendar from '@toast-ui/react-calendar';
import { fetchCaseSchedules, fetchScheduleById } from './actions';
import { DateInput } from '@blueprintjs/datetime';
import { Icon } from '@blueprintjs/core';
import createToaster from '../../components/Toaster';
import { calendarCustomTheme, CALENDAR_DAYNAMES, CALENDAR_TIMEZONE, CALENDAR_OBJECT } from '../../utils/calendar';
import ScheduleSideDialog from '../../components/ScheduleSideDialog';
import { mountScheduleArray } from '../../utils/schedules';
import { formatCalendarPickerInputValue } from '../../utils/date';
import { fetchProgressById } from '../CaseRelatedProgress/actions';

interface ICaseRelatedProgress {
  caseId: string;
}

const CaseRelatedProgress: React.FC<ICaseRelatedProgress> = ({ caseId }) => {
  const dispatch = useDispatch();
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const schedules = useSelector((state: typeof reducers) => state.caserelatedschedules.caseSchedules);
  const scheduleState = useSelector((state: typeof reducers) => state.caserelatedschedules.scheduleState);
  const scheduleById = useSelector((state: typeof reducers) => state.caserelatedschedules.scheduleById);
  const caseProgressById = useSelector((state: typeof reducers) => state.caserelatedprogress.progressById);
  const caseProgresses = useSelector((state: typeof reducers) => state.caserelatedprogress.caseProgresses);
  const calendarRef = createRef() as any;
  const [calendarCurrentDate, setCalendarCurrentDate] = useState(new Date());
  const [calendarSchedules, setCalendarSchedules] = useState<any>([]);

  useEffect(() => {
    if (caseId?.length) {
      dispatch(fetchCaseSchedules(caseId));
    }
  }, [dispatch, caseProgresses, caseId]);

  useEffect(() => {
    if (schedules?.fail) {
      const showToast = createToaster({
        message: schedules?.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
    }
    if (schedules?.data.length) {
      setCalendarSchedules(mountScheduleArray(schedules.data));
    }
  }, [schedules]);

  useEffect(() => {
    if (scheduleById && scheduleById.data) {
      dispatch(fetchProgressById(scheduleById?.data?.case_progress_id));
    }
  }, [scheduleById, dispatch]);

  useEffect(() => {
    if (scheduleState?.success || scheduleState?.fail) {
      const showToast = createToaster({
        message: scheduleState?.success || scheduleState?.fail,
        intent: scheduleState?.fail ? 'danger' : 'success',
        icon: scheduleState?.fail ? 'warning-sign' : 'tick',
      });
      showToast();
    }
  }, [scheduleState]);

  const handleClickSchedule = (event: any) => {
    setScheduleDialogOpen(true);
    dispatch(fetchScheduleById(event.schedule.id));
  };

  useEffect(() => {
    if (calendarRef && calendarCurrentDate) {
      const calendarInstance = calendarRef.current.getInstance();
      calendarInstance.setDate(calendarCurrentDate);
    }
  }, [calendarCurrentDate, calendarRef]);

  return (
    <div className="case__relations--progress">
      {caseProgressById?.data && (
        <ScheduleSideDialog
          loading={false}
          isDialogOpen={scheduleDialogOpen}
          setIsDialogOpen={setScheduleDialogOpen}
          data={caseProgressById?.data}
          selectedSchedule={scheduleById?.data?.schedule_id}
        />
      )}
      <div className="tabs__subline"></div>
      <div className="calendar__picker__container">
      <Icon className="picker__icon" iconSize={Icon.SIZE_LARGE} icon="calendar"></Icon>
        <DateInput
          formatDate={(date: Date) => formatCalendarPickerInputValue(date)}
          localeUtils={MomentLocaleUtils}
          fill
          locale="pt-BR"
          className="calendar__date__picker"
          onChange={(newDate: Date) => setCalendarCurrentDate(newDate)}
          parseDate={(str) => new Date(str)}
          value={calendarCurrentDate}
        />
        <Icon icon="chevron-up"></Icon>
      </div>
      <Calendar
        theme={calendarCustomTheme}
        ref={calendarRef}
        height="100vh"
        calendars={[CALENDAR_OBJECT]}
        isReadOnly={true}
        month={{
          startDayOfWeek: 0,
          narrowWeekend: true,
          daynames: CALENDAR_DAYNAMES,
        }}
        schedules={calendarSchedules}
        scheduleView={['time']}
        taskView={false}
        template={{
          milestone(schedule) {
            return `<span style="color:#000;background-color: ${schedule.bgColor};">${schedule.title}</span>`;
          },
          milestoneTitle() {
            return 'Milestone';
          },
          allday(schedule: any) {
            const minutes = schedule.start.getMinutes();
            const hours = schedule.start.getHours();
            return `${hours}:${minutes > 10 ? minutes : `0${minutes}`} | ${schedule.title}`;
          },
          alldayTitle() {
            return 'Todo dia';
          },
          task(schedule) {
            return '#';
          },
        }}
        view="month"
        week={{
          showTimezoneCollapseButton: true,
          timezonesCollapsed: true,
          narrowWeekend: false,
          daynames: CALENDAR_DAYNAMES,
        }}
        timezones={CALENDAR_TIMEZONE}
        onClickSchedule={(e) => handleClickSchedule(e)}
      />
    </div>
  );
};

export default CaseRelatedProgress;
