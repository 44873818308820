import { PostRole } from '../modules/UserGroups/types';
import { EMPTY_PERMISSIONS } from '../components/PermissionsTable/constants';

export const mountEntitiesPermissionsObject = (roleName?: string): PostRole => ({
  name: roleName || '',
  permissions: EMPTY_PERMISSIONS,
});

export const entitiesTranslates = (entityName: string) => {
  switch (entityName) {
    case 'role':
      return 'Grupos e usuários';
    case 'legal_cases':
      return 'Casos';
    case 'person':
      return 'Pessoas';
    case 'user':
      return 'Usuário';
    case 'meta_data':
      return 'Meta dados';
    case 'schedule':
      return 'Agenda';
    case 'document':
      return 'Documentos';
    default:
      return null;
  }
};

export const isPermissionWrite = (permissionIndex: string) => permissionIndex === 'w';
