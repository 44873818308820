/* eslint-disable import/prefer-default-export */
import { Entity } from '../modules/Permissions/types';
import { READ_PERMISSION, WRITE_PERMISSION, NON_REMOVABLE_OCCUPATION, NON_REMOVABLE_ROLES } from '../modules/Permissions/constants';
import { Route as RouteType, permissionDenied } from '../routes';

export const getModuleName = () => {
  const currentPath = window.location.pathname;
  if (currentPath.includes('painel/agenda')) return 'schedule';
  if (currentPath.includes('painel/pessoas')) return 'person';
  if (currentPath.includes('painel/casos')) return 'legal_cases';
  if (currentPath.includes('painel/anexos')) return 'document';
  if (currentPath.includes('painel/users')) return 'user';
  if (currentPath.includes('painel/grupos')) return 'role';
  if (currentPath.includes('painel/meta')) return 'meta_data';
  return '';
};

export const isExternalLawyer = () =>
  JSON.parse(sessionStorage.getItem('user')!).roles[0].name.toLowerCase().includes('externo');

export const isAdmin = () =>
  JSON.parse(sessionStorage.getItem('user')!).roles[0].name.toLowerCase().includes('admin');

export const renderByPermission = (r: RouteType) => {
  if (
    (!verifyUserPermission(getModuleName(), pathToPermissionType()) || (getModuleName() === 'role' && isExternalLawyer())) &&
    !isDefaultRoute()
  ) {
    return permissionDenied.component;
  }
  return r.component;
};

export const isDefaultRoute = () => {
  const currentPath = window.location.pathname;
  return currentPath.includes('login') || currentPath.includes('password') || currentPath === '/';
};

export const pathToPermissionType = () => {
  const currentPath = window.location.pathname;
  if (currentPath.includes('lista')) return READ_PERMISSION;
  if (currentPath.includes('criar')) return WRITE_PERMISSION;
  return '';
};

export const verifyUserPermission = (moduleName: string, permissionType: string) => {
  const userPermissions = JSON.parse(sessionStorage.getItem('permissions')!);
  if (userPermissions) {
    const entity = userPermissions.find((item: { entity: Entity }) => item.entity.name === moduleName);
    if (entity) {
      return entity.permission.includes(permissionType);
    }
    return false;
  }
  return false;
};

export const isNonRemovableRole = (roleName?: string) => NON_REMOVABLE_ROLES.includes(roleName!);

export const isNonRemovableOccupation = (occupationName: string) => occupationName === NON_REMOVABLE_OCCUPATION;
