import React from 'react';
import Tabs from '../../components/Tabs';
import ProgressType from '../MetaCasesProgressType';
import JusticeType from '../MetaCasesJusticeType';
import CourtType from '../MetaCasesCourtType';
import SecretaryType from '../MetaCasesSecretaryType';
import DistrictType from '../MetaCasesDistrictType';
import LegalCasePersonType from '../MetaCasesLegalCasePerson';
import MetaCasesTag from '../MetaCasesTag';
import MetaCasesProgressType from '../MetaCasesProgressStatus';

const MetaCases: React.FC = () => {
  const tabsList = [
    {
      title: 'Tipos de Assuntos',
      panel: <MetaCasesTag />,
    },
    {
      title: 'Situações do caso',
      panel: <MetaCasesProgressType />,
    },
    {
      title: 'Tipos de andamento',
      panel: <ProgressType />,
    },
    {
      title: 'Comarcas',
      panel: <DistrictType />,
    },
    {
      title: 'Tipos de justiça',
      panel: <JusticeType />,
    },
    {
      title: 'Tipos de vara',
      panel: <CourtType />,
    },
    {
      title: 'Tipos de secretaria',
      panel: <SecretaryType />,
    },
    {
      title: 'Tipos de pessoa no caso',
      panel: <LegalCasePersonType />,
    },
  ];

  return (
    <div className="background__container meta__data meta__tabs">
      <h1>Use o menu abaixo para alterar entre as tabelas</h1>
      <Tabs id="CaseTabs" tabs={tabsList}>
        {' '}
      </Tabs>
    </div>
  );
};

export default MetaCases;
