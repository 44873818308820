/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  addresses: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  create: {
    fail: null,
    success: null,
    loading: false,
  },
  inactivate: {
    fail: null,
    success: null,
    loading: false,
  }
};

const peopleRelation: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_ADDRESS_RELATION:
        draft.addresses.loading = true;
        draft.addresses.empty = true;
        draft.addresses.fail = null;
        return;

      case c.FETCH_ADDRESS_RELATION_SUCCESS:
        draft.addresses.loading = false;
        draft.addresses.fail = null;
        if (payload && payload.data.length) {
          draft.addresses.data = payload.data;
          draft.addresses.empty = false;
        }
        return;

      case c.FETCH_ADDRESS_RELATION_FAIL:
        draft.addresses.loading = false;
        draft.addresses.fail = payload.error;
        draft.addresses.data = payload.data;
        return;

      case c.CREATE_ADDRESS_RELATION:
        draft.create.loading = true;
        draft.create.fail = null;
        draft.create.success = null;
        return;

      case c.CREATE_ADDRESS_RELATION_SUCCESS:
        draft.create.loading = false;
        draft.create.success = 'Relação criada com sucesso!';
        return;

      case c.CREATE_ADDRESS_RELATION_FAIL:
        draft.create.loading = false;
        draft.create.fail = payload.error;
        draft.create.success = null;
        return;

      case c.INACTIVATE_ADDRESS_RELATION:
        draft.inactivate.loading = true;
        draft.inactivate.fail = null;
        draft.inactivate.success = null;
        return;

      case c.INACTIVATE_ADDRESS_RELATION_SUCCESS:
        draft.inactivate.loading = false;
        draft.inactivate.fail = null;
        draft.inactivate.success = 'Relação arquivada com sucesso!';
        return;

      case c.INACTIVATE_ADDRESS_RELATION_FAIL:
        draft.inactivate.loading = false;
        draft.inactivate.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.create.success = null;
        draft.inactivate.success = null;
        draft.create.fail = null;
        draft.inactivate.fail = null;
        return;

      default:
        return;
    }
  });

export default peopleRelation;
