import React from 'react';
import { Breadcrumb, Breadcrumbs, IBreadcrumbProps } from '@blueprintjs/core';
import './styles.css';

interface IBBreadcrumbProps {
  breads: IBreadcrumbProps[];
}

const BreadCrumb: React.FC<IBBreadcrumbProps> = ({ breads }) => {
  const renderCurrentBreadcrumb = ({ text }: IBreadcrumbProps) => {
    return <Breadcrumb className="current">{text}</Breadcrumb>;
  };

  return (
    <div className="breadcrumb__component">
      <Breadcrumbs currentBreadcrumbRenderer={renderCurrentBreadcrumb} items={breads} />
    </div>
  );
};

export default BreadCrumb;
