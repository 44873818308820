/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  case: {
    fail: null,
    empty: true,
    loading: false,
    data: {},
  },
  caseUpdate: {
    failUpdate: null,
    loadingUpdate: false,
    messageUpdate: null,
    dataUpdate: {},
  },
  caseInactivate: {
    fail: null,
    loading: false,
    message: null
  },
};

const person: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_CASE:
        draft.case.loading = true;
        draft.case.fail = null;
        return;

      case c.FETCH_CASE_SUCCESS:
        draft.case.loading = false;
        draft.case.fail = null;
        draft.case.data = payload.data;
        return;

      case c.FETCH_CASE_FAIL:
        draft.case.loading = false;
        draft.case.fail = 'Houve uma falha ao buscar este caso!';
        return;

      case c.UPDATE_CASE:
        draft.caseUpdate.loadingUpdate = true;
        draft.caseUpdate.failUpdate = null;
        draft.caseUpdate.messageUpdate = null;
        return;

      case c.UPDATE_CASE_SUCCESS:
        draft.caseUpdate.loadingUpdate = false;
        draft.caseUpdate.messageUpdate = 'Caso atualizado com sucesso!';
        draft.caseUpdate.dataUpdate = payload.data;
        return;

      case c.UPDATE_CASE_FAIL:
        draft.caseUpdate.loadingUpdate = false;
        draft.caseUpdate.messageUpdate = null;
        draft.caseUpdate.failUpdate = payload.error;
        return;

      case c.INACTIVATE_CASE:
        draft.caseInactivate.loading = true;
        return;

      case c.INACTIVATE_CASE_SUCCESS:
        draft.caseInactivate.loading = false;
        draft.caseInactivate.message = 'Caso arquivado com sucesso!'
        return;

      case c.INACTIVATE_CASE_FAIL:
        draft.caseInactivate.loading = false;
        draft.caseInactivate.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.caseUpdate.loadingUpdate = false;
        draft.caseUpdate.messageUpdate = null;
        draft.caseUpdate.failUpdate = null;
        return;
        
      default:
        return;
    }
  });

export default person;
