import Calendar from '@toast-ui/react-calendar';
import { fixDateGmt } from './date';
import { Schedule } from '../modules/CaseRelatedSchedules/types';

export const CALENDAR_DAYNAMES = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
];

export const CALENDAR_TIMEZONE = [
  {
    timezoneOffset: -180,
    displayLabel: 'GMT+03:00',
    tooltip: 'Brasília',
  },
];

export const CALENDAR_OBJECT = {
  id: '0',
  name: 'Private',
  bgColor: '#106BA3',
  borderColor: '#106BA3',
  color: '#F0F0F0',
};

export const calendarInstance = (calendarRef: React.MutableRefObject<Calendar>) => calendarRef.current.getInstance();

export const findCalendarById = (calendarRef: React.MutableRefObject<Calendar>, calendarId: string) =>
  calendarRef.current.props.calendars?.find((x) => x.id === calendarId);

export const mountSchedule = (scheduleData: any, scheduleId: number, calendar: any) => [
  {
    id: scheduleId.toString(),
    title: scheduleData.title,
    isAllDay: scheduleData.isAllDay,
    start: scheduleData.start,
    end: scheduleData.end,
    category: 'time',
    dueDateClass: '',
    color: calendar.color,
    bgColor: calendar.bgColor,
    dragBgColor: calendar.bgColor,
    borderColor: calendar.borderColor,
    location: scheduleData.location,
    calendarId: calendar.id,
    raw: {
      class: scheduleData.raw['class'],
    },
    state: scheduleData.state,
  },
];

export const mountScheduleForModule = (item: Schedule) => ({
  id: item.schedule_id,
  title: item.title,
  isAllDay: item.is_all_day,
  start: fixDateGmt(item.date_start),
  end: fixDateGmt(item.date_end),
  category: 'time',
  dueDateClass: '',
  color: '#fff',
  borderColor: item.status === 'Finalizado' ? '#60C6A4' : '#EEA052',
  bgColor: item.status === 'Finalizado' ? '#60C6A4' : '#EEA052',
});

export const calendarCustomTheme = {
  'common.border': '1px solid #e5e5e5',
  'common.backgroundColor': 'white',
  'common.holiday.color': '#106BA3',
  'common.saturday.color': '#106BA3',
  'common.dayname.color': '#106BA3',
  'common.today.color': '#333',
  'common.schedule.color': '#F0F0F0',

  // month header 'dayname'
  'month.dayname.height': '70px',
  'month.schedule.height': '25px',
  'month.dayname.borderLeft': '1px solid #e5e5e5',
  'month.dayname.backgroundColor': 'inherit',
  'month.dayname.fontSize': '12px',
  'month.dayname.fontWeight': 'bold',
  'month.dayname.textAlign': 'center',
};
