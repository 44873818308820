/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  legalCasePersonTypes: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  legalCasePersonTypesPaginated: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  legalCasePersonTypeId: {
    data: null,
    loading: false,
    fail: null,
  },
  createLegalCasePersonType: {
    loading: false,
    fail: null,
    success: null,
  },
  updateLegalCasePersonType: {
    loading: false,
    fail: null,
    success: null,
  },
  inactivateLegalCasePersonType: {
    loading: false,
    fail: null,
    success: null,
  },
};

const metaCases: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_LEGAL_CASE_PERSON_TYPE:
        draft.legalCasePersonTypes.loading = true;
        draft.legalCasePersonTypes.empty = true;
        draft.legalCasePersonTypes.fail = null;
        return;

      case c.FETCH_LEGAL_CASE_PERSON_TYPE_SUCCESS:
        draft.legalCasePersonTypes.loading = false;
        draft.legalCasePersonTypes.fail = null;
        if (payload && payload.data.length) {
          draft.legalCasePersonTypes.data = payload.data;
          draft.legalCasePersonTypes.empty = false;
        }
        return;

      case c.FETCH_LEGAL_CASE_PERSON_TYPE_FAIL:
        draft.legalCasePersonTypes.loading = false;
        draft.legalCasePersonTypes.fail = payload.error;
        draft.legalCasePersonTypes.data = payload.data;
        return;

        case c.FETCH_LEGAL_CASE_PERSON_TYPE_PAGINATED:
          draft.legalCasePersonTypesPaginated.loading = true;
          draft.legalCasePersonTypesPaginated.empty = true;
          draft.legalCasePersonTypesPaginated.fail = null;
          return;
  
        case c.FETCH_LEGAL_CASE_PERSON_TYPE_PAGINATED_SUCCESS:
          draft.legalCasePersonTypesPaginated.loading = false;
          draft.legalCasePersonTypesPaginated.fail = null;
          if (payload && payload.data.result.length) {
            draft.legalCasePersonTypesPaginated.data = payload.data;
            draft.legalCasePersonTypesPaginated.empty = false;
          }
          return;
  
        case c.FETCH_LEGAL_CASE_PERSON_TYPE_PAGINATED_FAIL:
          draft.legalCasePersonTypesPaginated.loading = false;
          draft.legalCasePersonTypesPaginated.fail = payload.error;
          draft.legalCasePersonTypesPaginated.data = payload.data;
          return;

      case c.FETCH_LEGAL_CASE_PERSON_TYPE_ID:
        draft.legalCasePersonTypeId.loading = true;
        draft.legalCasePersonTypeId.fail = null;
        return;

      case c.FETCH_LEGAL_CASE_PERSON_TYPE_ID_SUCCESS:
        draft.legalCasePersonTypeId.loading = false;
        draft.legalCasePersonTypeId.fail = null;
        if (payload && payload.data) {
          draft.legalCasePersonTypeId.data = payload.data;
        }
        return;

      case c.FETCH_LEGAL_CASE_PERSON_TYPE_ID_FAIL:
        draft.legalCasePersonTypeId.loading = false;
        draft.legalCasePersonTypeId.fail = payload.error;
        draft.legalCasePersonTypeId.data = payload.data;
        return;

      case c.CREATE_LEGAL_CASE_PERSON_TYPE:
        draft.createLegalCasePersonType.loading = true;
        draft.createLegalCasePersonType.fail = null;
        draft.createLegalCasePersonType.success = null;
        return;

      case c.CREATE_LEGAL_CASE_PERSON_TYPE_SUCCESS:
        draft.createLegalCasePersonType.loading = false;
        draft.createLegalCasePersonType.fail = null;
        draft.createLegalCasePersonType.success = payload.data;
        return;

      case c.CREATE_LEGAL_CASE_PERSON_TYPE_FAIL:
        draft.createLegalCasePersonType.loading = false;
        draft.createLegalCasePersonType.fail = payload.error;
        draft.createLegalCasePersonType.success = null;
        return;

      case c.UPDATE_LEGAL_CASE_PERSON_TYPE:
        draft.updateLegalCasePersonType.loading = true;
        draft.updateLegalCasePersonType.fail = null;
        draft.updateLegalCasePersonType.success = null;
        return;

      case c.UPDATE_LEGAL_CASE_PERSON_TYPE_SUCCESS:
        draft.updateLegalCasePersonType.loading = false;
        draft.updateLegalCasePersonType.fail = null;
        draft.updateLegalCasePersonType.success = payload.data;
        return;

      case c.UPDATE_LEGAL_CASE_PERSON_TYPE_FAIL:
        draft.updateLegalCasePersonType.loading = false;
        draft.updateLegalCasePersonType.fail = payload.error;
        draft.updateLegalCasePersonType.success = null;
        return;

      case c.INACTIVATE_LEGAL_CASE_PERSON_TYPE:
        draft.inactivateLegalCasePersonType.loading = true;
        draft.inactivateLegalCasePersonType.fail = null;
        draft.inactivateLegalCasePersonType.success = null;
        return;

      case c.INACTIVATE_LEGAL_CASE_PERSON_TYPE_SUCCESS:
        draft.inactivateLegalCasePersonType.loading = false;
        draft.inactivateLegalCasePersonType.fail = null;
        draft.inactivateLegalCasePersonType.success = payload.data;
        return;

      case c.INACTIVATE_LEGAL_CASE_PERSON_TYPE_FAIL:
        draft.inactivateLegalCasePersonType.loading = false;
        draft.inactivateLegalCasePersonType.fail = payload.error;
        draft.inactivateLegalCasePersonType.success = null;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.createLegalCasePersonType.success = null;
        draft.updateLegalCasePersonType.success = null;
        draft.inactivateLegalCasePersonType.success = null;
        draft.createLegalCasePersonType.fail = null;
        draft.updateLegalCasePersonType.fail = null;
        draft.inactivateLegalCasePersonType.fail = null;
        return;

      default:
        return;
    }
  });

export default metaCases;
