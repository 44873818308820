/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';
import { ProgressStatus } from './types';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

export const clearAllStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_ALL_STATUS,
  });
};

export const fetchProgressStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_PROGRESS_STATUS,
    payload: {
      request: {
        url: '/case_progress/status',
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_PROGRESS_STATUS_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_PROGRESS_STATUS_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchProgressStatusPaginated = (offset: number, limit: number) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_PROGRESS_STATUS_PAGINATED,
    payload: {
      request: {
        url: `/case_progress/status/paginated?offset=${offset}&limit=${limit}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_PROGRESS_STATUS_PAGINATED_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_PROGRESS_STATUS_PAGINATED_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchCaseProgressId = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_PROGRESS_STATUS_ID,
    payload: {
      request: {
        url: `/case_progress/status/${id}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_PROGRESS_STATUS_ID_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_PROGRESS_STATUS_ID_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const createProgressStatus = (progressStatus: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.CREATE_PROGRESS_STATUS,
    payload: {
      request: {
        url: '/case_progress/status',
        method: 'POST',
        data: { status: progressStatus },
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.CREATE_PROGRESS_STATUS_SUCCESS,
            payload: {
              data: 'Situação de caso criada com sucesso!',
            },
          });
          dispatch(fetchProgressStatusPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.CREATE_PROGRESS_STATUS_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const updateProgressStatus = (progressStatus: ProgressStatus) => (dispatch: Dispatch) => {
  dispatch({
    type: c.UPDATE_PROGRESS_STATUS,
    payload: {
      request: {
        url: `/case_progress/status/${progressStatus.case_progress_status_id}`,
        method: 'PUT',
        data: { status: progressStatus.status },
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.UPDATE_PROGRESS_STATUS_SUCCESS,
            payload: {
              data: 'Situação de caso atualizada com sucesso!',
            },
          });
          dispatch(fetchProgressStatusPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.UPDATE_PROGRESS_STATUS_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const inactivateProgressStatus = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_PROGRESS_STATUS,
    payload: {
      request: {
        url: `/case_progress/status/${id}/inactivate`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_PROGRESS_STATUS_SUCCESS,
            payload: {
              data: 'Situação de caso arquivada com sucesso!',
            },
          });
          dispatch(fetchProgressStatusPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.INACTIVATE_PROGRESS_STATUS_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};
