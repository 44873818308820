import React, { SyntheticEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { fetchLogin } from './actions';
import { reducers } from '../../store/reducers';
import './styles.css';
import { getValuesFromForm } from '../../utils/form';
import createToaster from '../../components/Toaster';
import { getHomePageConsideringUserPermissions } from '../../utils/auth';

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { fail, loading, user } = useSelector((state: typeof reducers) => state.login);

  const sendLogin = (e: SyntheticEvent) => {
    e.preventDefault();
    if (e.target instanceof HTMLFormElement) {
      const inputValues = getValuesFromForm(e.target);
      dispatch(
        fetchLogin({
          email: inputValues[0],
          password: inputValues[1],
        })
      );
    }
  };

  useEffect(() => {
    if (user !== null) {
      sessionStorage.setItem('permissions', JSON.stringify(user?.roles[0].permission));
      getHomePageConsideringUserPermissions();
    }
  }, [user, dispatch]);

  const showToast = createToaster({
    message: 'Usuário ou senha invalido(s)',
    intent: 'danger',
    icon: 'warning-sign',
  });

  return (
    <>
      {fail ? showToast() : null}
      <Header />
      <div className="container" id="login">
        <h2>Preencha suas informações para acessar a plataforma.</h2>
        <form onSubmit={sendLogin}>
          <Input placeholder="Usuário" type="email" />
          <Input placeholder="Senha" type="password" />
          <Button name="primary" loading={loading} intent="primary">
            Entrar
          </Button>
          <Button type="submit" href="/login/recover-password" name="secondary">
            Esqueceu sua senha?
          </Button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Login;
