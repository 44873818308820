/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';
import { POSTAddress } from './types';

export const clearAllStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_ALL_STATUS,
  });
};

export const fetchAddress = (personId: string, ) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_ADDRESS_RELATION,
    payload: {
      request: {
        url: `/persons/${personId}/addresses`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_ADDRESS_RELATION_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_ADDRESS_RELATION_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const createAddress = (personId: string, person: POSTAddress) => (dispatch: Dispatch) => {
  dispatch({
    type: c.CREATE_ADDRESS_RELATION,
    payload: {
      request: {
        url: `/persons/${personId}/addresses`,
        method: 'PUT',
        data: person,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.CREATE_ADDRESS_RELATION_SUCCESS,
          });
          dispatch(fetchAddress(personId));
          dispatch(clearAllStatus())
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.CREATE_ADDRESS_RELATION_FAIL,
            payload: {
              error: resError,
            },
          });
          dispatch(clearAllStatus());
        },
      },
    },
  });
};

export const inactivateAddressRelation = (personId: string, relationId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_ADDRESS_RELATION,
    payload: {
      request: {
        url: `/persons/${personId}/addresses/${relationId}/inactivate`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_ADDRESS_RELATION_SUCCESS,
          });
          dispatch(fetchAddress(personId));
          dispatch(clearAllStatus())
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.INACTIVATE_ADDRESS_RELATION_FAIL,
            payload: {
              error: resError,
            },
          });
          dispatch(clearAllStatus());
        },
      },
    },
  });
};
