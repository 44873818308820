/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';
import { POSTConnectedPeople } from './types';

export const clearAllStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_ALL_STATUS,
  });
};

export const fetchConnectedPeople = (personId: string, ) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_PEOPLE_RELATION,
    payload: {
      request: {
        url: `/persons/${personId}/related`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_PEOPLE_RELATION_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_PEOPLE_RELATION_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const createPeopleRelation = (personId: string, person: POSTConnectedPeople) => (dispatch: Dispatch) => {
  dispatch({
    type: c.CREATE_PEOPLE_RELATION,
    payload: {
      request: {
        url: `/persons/${personId}/related`,
        method: 'PUT',
        data: person,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.CREATE_PEOPLE_RELATION_SUCCESS,
          });
          dispatch(fetchConnectedPeople(personId));
          dispatch(clearAllStatus())
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.CREATE_PEOPLE_RELATION_FAIL,
            payload: {
              error: resError,
            },
          });
          dispatch(clearAllStatus());
        },
      },
    },
  });
};

export const inactivatePeopleRelation = (personId: string, connectionId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_PEOPLE_RELATION,
    payload: {
      request: {
        url: `/persons/${personId}/related/${connectionId}/inactivate`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_PEOPLE_RELATION_SUCCESS,
          });
          dispatch(fetchConnectedPeople(personId));
          dispatch(clearAllStatus());
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.INACTIVATE_PEOPLE_RELATION_FAIL,
            payload: {
              error: resError,
            },
          });
          dispatch(clearAllStatus());
        },
      },
    },
  });
};
