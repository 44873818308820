export const FETCH_PROGRESS_STATUS = 'FETCH_PROGRESS_STATUS';
export const FETCH_PROGRESS_STATUS_SUCCESS = 'FETCH_PROGRESS_STATUS_SUCCESS';
export const FETCH_PROGRESS_STATUS_FAIL = 'FETCH_PROGRESS_STATUS_FAIL';
export const FETCH_PROGRESS_STATUS_PAGINATED = 'FETCH_PROGRESS_STATUS_PAGINATED';
export const FETCH_PROGRESS_STATUS_PAGINATED_SUCCESS = 'FETCH_PROGRESS_STATUS_PAGINATED_SUCCESS';
export const FETCH_PROGRESS_STATUS_PAGINATED_FAIL = 'FETCH_PROGRESS_STATUS_PAGINATED_FAIL';
export const FETCH_PROGRESS_STATUS_ID = 'FETCH_PROGRESS_STATUS_ID';
export const FETCH_PROGRESS_STATUS_ID_SUCCESS = 'FETCH_PROGRESS_STATUS_ID_SUCCESS';
export const FETCH_PROGRESS_STATUS_ID_FAIL = 'FETCH_PROGRESS_STATUS__ID_FAIL';
export const CREATE_PROGRESS_STATUS = 'CREATE_PROGRESS_STATUS';
export const CREATE_PROGRESS_STATUS_SUCCESS = 'CREATE_PROGRESS_STATUS_SUCCESS';
export const CREATE_PROGRESS_STATUS_FAIL = 'CREATE_PROGRESS_STATUS_FAIL';
export const UPDATE_PROGRESS_STATUS = 'UPDATE_PROGRESS_STATUS';
export const UPDATE_PROGRESS_STATUS_SUCCESS = 'UPDATE_PROGRESS_STATUS_SUCCESS';
export const UPDATE_PROGRESS_STATUS_FAIL = 'UPDATE_PROGRESS_STATUS_FAIL';
export const INACTIVATE_PROGRESS_STATUS = 'INACTIVATE_PROGRESS_STATUS';
export const INACTIVATE_PROGRESS_STATUS_SUCCESS = 'INACTIVATE_PROGRESS_STATUS_SUCCESS';
export const INACTIVATE_PROGRESS_STATUS_FAIL = 'INACTIVATE_PROGRESS_STATUS_FAIL';
export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
