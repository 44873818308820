import React, { useState, useEffect, SyntheticEvent } from 'react';
import Button from '../../components/Button';
import { useSelector, useDispatch } from 'react-redux';
import { reducers } from '../../store/reducers';
import { fetchConnectedPeople, createPeopleRelation, inactivatePeopleRelation } from './actions';
import Input from '../../components/Input';
import '../../components/PersonRelations/styles.css';
import MetaTable from '../../components/MetaTable';
import createToaster from '../../components/Toaster';
import { ConnectedPeople } from './types';
import Autocomplete from '../../components/Autocomplete';
import { Tooltip } from '@blueprintjs/core';
import { fastRegisterPerson, fetchAllPeople } from '../PeopleList/actions';
import FastRegisterDialog from '../../components/FastRegisterDialog';

interface IConnectedPeople {
  personId: string;
}

const PeopleConnectedPeople: React.FC<IConnectedPeople> = ({ personId }) => {
  const dispatch = useDispatch();
  const people = useSelector((state: typeof reducers) => state.peopleconnectedpeople.connectedPeople);
  const create = useSelector((state: typeof reducers) => state.peopleconnectedpeople.createPeopleRelation);
  const inactivate = useSelector((state: typeof reducers) => state.peopleconnectedpeople.inactivate);
  const allPeople = useSelector((state: typeof reducers) => state.peoplelist.allPeople);
  const fastRegister = useSelector((state: typeof reducers) => state.peoplelist.fastRegister);
  const relationInput = React.createRef() as any;
  const nameInput = React.useRef() as any;
  const cpfInput = React.useRef() as any;
  const cnpjInput = React.useRef() as any;
  const [relation, setRelation] = useState('');
  const [connectionId, setConnectionId] = useState('');
  const [spouseName, setSpouseName] = useState('');
  const [isLegalPerson, setIsLegalPerson] = useState(false);
  const { loading } = useSelector((state: typeof reducers) => state.peoplelist.peopleList);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState(false);
  const [oldConnection, setOldConnection] = useState('');

  useEffect(() => {
    dispatch(fetchAllPeople('name='));
  }, [dispatch]);

  useEffect(() => {
    if (personId?.length) {
      dispatch(fetchConnectedPeople(personId));
    }
  }, [dispatch, personId]);

  useEffect(() => {
    if (create?.success || create?.fail) {
      const showToast = createToaster({
        message: create?.success || create?.fail,
        intent: create?.fail ? 'danger' : 'success',
        icon: create?.fail ? 'warning-sign' : 'tick',
      });
      showToast();
    }
  }, [create]);

  useEffect(() => {
    if (fastRegister.fail || fastRegister.success) {
      const showToast = createToaster({
        message: fastRegister.fail || fastRegister.success,
        intent: fastRegister.fail ? 'danger' : 'success',
        icon: fastRegister.fail ? 'warning-sign' : 'tick',
      });
      showToast();
    }
  }, [fastRegister.fail, fastRegister.success])

  useEffect(() => {
    if (fastRegister?.id.length) {
      setConnectionId(fastRegister.id)
    }
  }, [fastRegister]);

  const handleSendFastRegister = () => {
    setIsDialogOpen(false);
    setSpouseName(nameInput?.current?.value);
    if (isLegalPerson) {
      return dispatch(fastRegisterPerson({
        name: nameInput?.current?.value,
        legal_cnpj: cnpjInput?.current?.value,
        is_legal_person: isLegalPerson
      }));
    }
    return dispatch(fastRegisterPerson({
      name: nameInput?.current?.value,
      natural_cpf: cpfInput?.current?.value,
      is_legal_person: isLegalPerson
    }))
  }

  const getTable = () => (
    <div className="table__container">
      <MetaTable
        loading={inactivate?.loading || create?.loading}
        empty={people?.empty}
        data={people?.data}
        headers={['Nome', 'Relação']}
        columns={['connected_name', 'relationship_type']}
        pathId="descendant_id"
        removeRequest={inactivatePeopleRelation}
        editRequest={handleEditRequest}
        personId={personId}
      />
    </div>
  );

  const handleEditRequest = (elem: ConnectedPeople) => {
    setOldConnection(elem.descendant_id);
    setConnectionId(elem.descendant_id);
    setSpouseName(elem.descendant.name);
    setRelation(elem.relationship_type);
    setIsEdit(true);
  };

  const clearForm = () => {
    setOldConnection('');
    setConnectionId('');
    setRelation('');
    setIsEdit(false);
  };

  const sendUpdateRequest = () => {
    if (connectionId !== oldConnection) {
      return dispatch(
        createPeopleRelation(personId, {
          relationship_type: relation,
          old_descendant_id: oldConnection,
          descendant_id: connectionId,
        })
      );
    }
    return dispatch(
      createPeopleRelation(personId, {
        relationship_type: relation,
        descendant_id: connectionId,
      })
    );
  }

  const sendRelation = () => {
    if (isEdit) {
      sendUpdateRequest();
    } else {
      dispatch(
        createPeopleRelation(personId, {
          relationship_type: relation,
          descendant_id: connectionId,
        })
      );
    }
    clearForm();
  };

  const addSpouseTooltip = () => (
    <Tooltip
      captureDismiss
      content={'Clique aqui para cadastrar pessoa'}
    >
      <Button onClick={(e: SyntheticEvent) => setIsDialogOpen(true)} name="add" className="sidebar__button--blue" icon="add" />
    </Tooltip>
  );

  return (
    <>
      <div className="tabs__subline"></div>
        <FastRegisterDialog
         isDialogOpen={isDialogOpen}
         setIsDialogOpen={setIsDialogOpen}
         loading={loading}
         nameInput={nameInput}
         cpfInput={cpfInput}
         cnpjInput={cnpjInput}
         handleSendFastRegister={handleSendFastRegister}
         isLegalPerson={isLegalPerson}
         setIsLegalPerson={setIsLegalPerson}
        />
      <div className="content__container">
        {getTable()}
        <div className="register__form">
          <h2>Adicionar relação</h2>
          <Autocomplete 
            value={spouseName} 
            setValue={setSpouseName} 
            idKey="person_id" 
            callbackMethod={setConnectionId} 
            request={fetchAllPeople}
            path="name"
            data={allPeople?.data} 
            placeholder="Pessoa"
            rightElement={addSpouseTooltip()}
            keyOne="person_id"
            nameValue="name"
          />
          <Input
            inputRef={relationInput}
            required
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRelation(e.target.value)}
            value={relation}
            placeholder="Relação"
          />
          <div className="buttons__container">
            <Button name="add" text="Salvar relação" onClick={sendRelation} type="button" />
            {isEdit && <Button name="default" text="Cancelar edição" onClick={clearForm} type="button" />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PeopleConnectedPeople;
