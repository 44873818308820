import React, { useState, useEffect, SyntheticEvent } from 'react';
import Button from '../../components/Button';
import { useSelector, useDispatch } from 'react-redux';
import { reducers } from '../../store/reducers';
import { fetchAddress, createAddress, inactivateAddressRelation } from './actions';
import Input from '../../components/Input';
import '../../components/PersonRelations/styles.css';
import MetaTable from '../../components/MetaTable';
import createToaster from '../../components/Toaster';
import { Address } from './types';
import { Checkbox } from '@blueprintjs/core';
import Selector from '../../components/Selector';
import { STATE_LIST } from '../../constants/states';

interface IConnectedPeople {
  personId: string;
}

const PeopleAddresses: React.FC<IConnectedPeople> = ({ personId }) => {
  const dispatch = useDispatch();
  const addresses = useSelector((state: typeof reducers) => state.peopleaddresses.addresses);
  const create = useSelector((state: typeof reducers) => state.peopleaddresses.create);
  const inactivate = useSelector((state: typeof reducers) => state.peopleaddresses.inactivate);
  const [zip, setZip] = useState('');
  const [street, setStreet] = useState('');
  const [localNumber, setLocalNumber] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [cityName, setCityName] = useState('');
  const [cityState, setCityState] = useState('');
  const [complement, setComplement] = useState('');
  const [mainAddress, setMainAddress] = useState(false);
  const [addressId, setAddressId] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [brState, setBrState] = useState();
  
  useEffect(() => {
    if (personId?.length) {
      dispatch(fetchAddress(personId));
    }
  }, [dispatch, personId]);

  useEffect(() => {
    if (
      inactivate?.success ||
      inactivate?.fail ||
      create?.success ||
      create?.fail
    ) {
      const showToast = createToaster({
        message:
          inactivate?.success ||
          inactivate?.fail ||
          create?.success ||
          create?.fail,
        intent: inactivate?.fail || create?.fail ? 'danger' : 'success',
        icon: inactivate?.fail || create?.fail ? 'warning-sign' : 'tick',
      });
      showToast();
    }
  }, [inactivate, create]);

  const getTable = () => (
    <div className="table__container">
      <MetaTable
        loading={inactivate?.loading || create?.loading}
        empty={addresses?.empty}
        data={addresses?.data}
        headers={['Logradouro', 'Nº', 'Bairro', 'Cidade', 'Cep', 'Principal']}
        columns={['street_name', 'number', 'neighborhood', 'city', 'zip_code', 'is_main']}
        pathId="address_id"
        removeRequest={inactivateAddressRelation}
        editRequest={handleEditRequest}
        personId={personId}
      />
    </div>
  );

  const handleEditRequest = (elem: Address) => {
    setStreet(elem.street_name);
    setNeighborhood(elem.neighborhood);
    setComplement(elem.complement);
    setLocalNumber(elem.number.toString());
    setCityName(elem.city);
    setCityState(elem.state);
    setZip(elem.zip_code);
    setMainAddress(elem.is_main);
    setAddressId(elem.address_id);
    setIsEdit(true);
  };

  const clearForm = () => {
    setStreet('');
    setNeighborhood('');
    setComplement('');
    setLocalNumber('');
    setCityName('');
    setCityState('');
    setZip('');
    setMainAddress(false);
    setAddressId('');
    setIsEdit(false);
  };

  const sendRelation = (e: SyntheticEvent) => {
    e.preventDefault();
    if (isEdit) {
      dispatch(
        createAddress(personId, {
          address_id: addressId,
          street_name: street,
          neighborhood: neighborhood,
          complement: complement,
          number: parseInt(localNumber),
          city: cityName,
          state: cityState,
          zip_code: zip,
          is_main: mainAddress
        })
      );
    } else {
      dispatch(
        createAddress(personId, {
          street_name: street,
          neighborhood: neighborhood,
          complement: complement,
          number: parseInt(localNumber),
          city: cityName,
          state: cityState,
          zip_code: zip,
          is_main: mainAddress
        })
      );
    }
    clearForm();
  };

  return (
    <>
      <div className="tabs__subline"></div>
      <div className="content__container">
        {getTable()}
        <form className="register__form" onSubmit={sendRelation}>
          <h2>Adicionar endereço</h2>
          <Input
            required
            type="text"
            maxLength={9}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setZip(e.target.value)}
            value={zip}
            placeholder="Cep"
          />
          <Input
            required
            type="text"
            maxLength={48}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStreet(e.target.value)}
            value={street}
            placeholder="Logradouro"
          />
          <Input
            required
            type="number"
            maxLength={6}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLocalNumber(e.target.value)}
            value={localNumber}
            placeholder="Número"
          />
          <Input
            required
            type="text"
            maxLength={33}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNeighborhood(e.target.value)}
            value={neighborhood}
            placeholder="Bairro"
          />
          <Input
            required
            type="text"
            maxLength={30}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCityName(e.target.value)}
            value={cityName}
            placeholder="Cidade"
          />
          <Selector required setValue={setBrState} value={brState} className="state__selector" options={STATE_LIST} statesList />
          <Input
            type="text"
            maxLength={35}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComplement(e.target.value)}
            value={complement}
            placeholder="Complemento"
          />
          <Checkbox checked={mainAddress} onChange={() => setMainAddress((prevState) => !prevState)}>
            Endereço principal
          </Checkbox>
          <div className="buttons__container">
            <Button name="add" type="submit" text="Salvar relação" />
            {isEdit && <Button name="default" text="Cancelar edição" onClick={clearForm} type="button" />}
          </div>
        </form>
      </div>
    </>
  );
};

export default PeopleAddresses;
