/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-underscore-dangle */
import { Dispatch } from 'redux';
import * as c from './constants';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';
import { NaturalPersonRequired, LegalPersonRequired } from './types';
import handleApiError from '../../utils/errors';

export const fetchPeople = (
  offset: number,
  limit = DEFAULT_PER_PAGE_LIMIT,
  path: string,
) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_PEOPLE,
    payload: {
      request: {
        url: `/persons/paginated?offset=${offset}&limit=${limit}${path}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_PEOPLE_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.FETCH_PEOPLE_FAIL,
            payload: {
              error: 'Ops! Tivemos algum problema durante o login, tente novamente.',
            },
          });
        },
      },
    },
  });
};

export const fetchAllPeople = (path: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_ALL_PEOPLE,
    payload: {
      request: {
        url: `/persons?${path}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_ALL_PEOPLE_SUCCESS,
            payload: {
              data: response.data,
            },
          });
          dispatch({
            type: c.CLEAR_CREATE_STATUS,
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.FETCH_ALL_PEOPLE_FAIL,
            payload: {
              error: 'Ops! Tivemos algum problema durante o cadastro, por favor tente novamente.',
            },
          });
          dispatch({
            type: c.CLEAR_CREATE_STATUS,
          });
        },
      },
    },
  });
};

export const clearSuggestionsList = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_SUGGESTIONS_LIST,
  })
}

export const register = (person: LegalPersonRequired | NaturalPersonRequired) => (dispatch: Dispatch) => {
  dispatch({
    type: c.REGISTER_PERSON,
    payload: {
      request: {
        url: '/persons',
        method: 'POST',
        data: person,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.REGISTER_PERSON_SUCCESS,
            payload: {
              data: response.data,
            },
          });
          dispatch({
            type: c.CLEAR_CREATE_STATUS,
          });
          dispatch(fetchPeople(1, DEFAULT_PER_PAGE_LIMIT, ''));
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.REGISTER_PERSON_FAIL,
            payload: {
              error: handleApiError(error.toString(), error?.response?.data?.detail),
            },
          });
          dispatch({
            type: c.CLEAR_CREATE_STATUS,
          });
        },
      },
    },
  });
};

export const fastRegisterPerson = (person: LegalPersonRequired | NaturalPersonRequired | {}) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FAST_REGISTER_PERSON,
    payload: {
      request: {
        url: '/persons',
        method: 'POST',
        data: person,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FAST_REGISTER_PERSON_SUCCESS,
            payload: {
              id: response.data,
            },
          });
          dispatch({
            type: c.CLEAR_CREATE_STATUS,
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.FAST_REGISTER_PERSON_FAIL,
            payload: {
              error: handleApiError(error.toString()),
            },
          });
          dispatch({
            type: c.CLEAR_CREATE_STATUS,
          });
        },
      },
    },
  });
};
