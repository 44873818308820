/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import update from 'immutability-helper';
import Input from '../../components/Input';
import { reducers } from '../../store/reducers';
import {
  createActivityType,
  inactivateActivityType,
  updateActivityType,
  clearAllStatus,
  fetchActivityTypesPaginated,
} from './actions';
import MetaTable from '../../components/MetaTable';
import { ActivityType } from './types';
import '../Meta/styles.css';
import Button from '../../components/Button';
import createToaster from '../../components/Toaster';
import { WRITE_PERMISSION, META_MODULE_NAME } from '../Permissions/constants';
import { verifyUserPermission } from '../../utils/permissions';
import Pagination from '../../components/Pagination';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

const MetaActivities: React.FC = () => {
  const dispatch = useDispatch();
  // BlueprintJS inputRef
  const input = React.useRef() as any;
  const activityTypesPaginated = useSelector((state: typeof reducers) => state.metapeopleactivities.activityTypesPaginated);
  const createActivityTypes = useSelector((state: typeof reducers) => state.metapeopleactivities.createActivityType);
  const updateActivityTypes = useSelector((state: typeof reducers) => state.metapeopleactivities.updateActivityType);
  const inactivateActivityTypes = useSelector((state: typeof reducers) => state.metapeopleactivities.inactivateActivityType);
  const [isEdit, setIsEdit] = useState(false);
  const [stateActivityType, setStateActivityType] = useState<ActivityType>({
    business_activity: '',
    business_activity_id: '',
    is_active: false,
    created_at: '',
  });

  useEffect(() => {
    if (createActivityTypes.success) {
      const showToast = createToaster({
        message: createActivityTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createActivityTypes.success, dispatch]);

  useEffect(() => {
    if (updateActivityTypes.success) {
      const showToast = createToaster({
        message: updateActivityTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateActivityTypes.success, dispatch]);

  useEffect(() => {
    if (inactivateActivityTypes.success) {
      const showToast = createToaster({
        message: inactivateActivityTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateActivityTypes.success, dispatch]);

  useEffect(() => {
    if (createActivityTypes.fail) {
      const showToast = createToaster({
        message: createActivityTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createActivityTypes.fail, dispatch]);

  useEffect(() => {
    if (updateActivityTypes.fail) {
      const showToast = createToaster({
        message: updateActivityTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateActivityTypes.fail, dispatch]);

  useEffect(() => {
    if (inactivateActivityTypes.fail) {
      const showToast = createToaster({
        message: inactivateActivityTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateActivityTypes.fail, dispatch]);

  const setInputValue = (value: string) => (input.current.value = value);

  const handleEditRequest = (elem: ActivityType) => {
    setInputValue(elem.business_activity);
    setIsEdit(true);
    setStateActivityType(elem);
  };

  useEffect(() => {
    dispatch(fetchActivityTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
  }, [dispatch]);

  const resetForm = () => {
    setInputValue('');
    setStateActivityType((prevState) => ({
      ...prevState,
      business_activity: '',
    }));
    setIsEdit(false);
  };

  const addActivityType = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isEdit) {
      dispatch(createActivityType(stateActivityType.business_activity));
    } else {
      const newElem = update(stateActivityType, {
        business_activity: { $set: stateActivityType.business_activity },
      });
      dispatch(updateActivityType(newElem));
    }
    resetForm();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateActivityType(
      update(stateActivityType, {
        business_activity: { $set: e.target.value },
      })
    );
  };

  const handleTextButton = () => (isEdit ? 'Atualizar atividade' : 'Adicionar atividade');

  const disabledValidation = () => stateActivityType.business_activity === '';

  return (
    <form className="component__container" onSubmit={addActivityType}>
      {verifyUserPermission(META_MODULE_NAME, WRITE_PERMISSION) && (
        <Input
          value={stateActivityType?.business_activity}
          onChange={onChangeInput}
          required
          inputRef={input}
          placeholder="Digite a atividade que gostaria de adicionar... *"
          button={{
            text: handleTextButton(),
            icon: 'plus',
            disabledValidation: disabledValidation(),
          }}
        />
      )}
      {isEdit && (
        <Button name="backwhite" onClick={resetForm}>
          Cancelar edição
        </Button>
      )}
      <h3>Atividades já cadastradas:</h3>
      <MetaTable
        loading={
          activityTypesPaginated?.loading ||
          updateActivityTypes?.loading ||
          inactivateActivityTypes?.loading ||
          createActivityTypes?.loading
        }
        empty={activityTypesPaginated.empty}
        data={activityTypesPaginated.data.result}
        headers={['Nome', 'Criado em']}
        columns={['business_activity', 'created_at']}
        pathId="business_activity_id"
        removeRequest={inactivateActivityType}
        editRequest={handleEditRequest}
        group
      />
      {!activityTypesPaginated?.empty && (
        <Pagination
          request={fetchActivityTypesPaginated}
          loading={activityTypesPaginated?.loading}
          data={activityTypesPaginated?.data}
        />
      )}
    </form>
  );
};

export default React.memo(MetaActivities);
