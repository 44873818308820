import React from 'react';
import { Button as BButton, IButtonProps } from '@blueprintjs/core';
import './styles.css';
import { goToHere } from '../../utils/navigation';

interface IButton extends IButtonProps {
  name?: string;
  href?: string;
  intent?: IButtonProps['intent'];
  path?: string;
}

const Button: React.FC<IButton> = (props) => {
  const { href, children, name, type, intent, path, text } = props;

  if (href && name !== 'primary') {
    return (
      <a className={`button ${name}`} href={href}>
        {children}
      </a>
    );
  }

  if (name === 'add') {
    return (
      <BButton className="sidebar__button--blue" icon="add" type={type} {...props} intent={intent}>
        {children}
      </BButton>
    );
  }

  if (name === 'default') {
    return (
      <BButton className="sidebar__button--blue" type={type} {...props} intent={intent}>
        {children}
      </BButton>
    );
  }

  if (name === 'backwhite') {
    return (
      <BButton className="back__white" type={type} {...props} intent={intent}>
        {children}
      </BButton>
    );
  }

  if (path) {
    return (
      <BButton
        text={text || 'Expandir'}
        onClick={() => goToHere(path)}
        rightIcon="caret-right"
        className="button__expand table__button--expand"
        {...props}
      />
    );
  }

  if (name === 'mobileAdd') {
    return <BButton icon="add" className="button__add--mobile" {...props} />;
  }

  return (
    <BButton className={`button ${name}`} type={type} {...props} intent={intent}>
      {children}
    </BButton>
  );
};

export default Button;
