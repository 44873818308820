import { GetCase, TemporaryCaseType } from "../modules/CreateCase/types";
import { TagType } from "../modules/MetaCasesTag/types";

export const mountTagsList = (tagsList?: TagType[]) => tagsList?.map((item: TagType) => item.tag_id)

export const mountCaseDetails = (data?: GetCase): TemporaryCaseType => ({
    title: data?.title || '',
    lawsuit_name: data?.lawsuit_name || null,
    legal_case_id: data?.legal_case_id || null,
    process_originating: data?.process_originating || null,
    case_status_id: data?.case_status_id || data?.case_status?.case_progress_status_id || null,
    is_legal_process: data?.is_legal_process || false,
    process_id: data?.process_id || null,
    process_county_id: data?.process_county?.county_id || null,
    process_justice_type_id: data?.process_justice_type?.justice_type_id || null,
    process_court_id: data?.process_court?.court_id || null,
    process_secretary_id: data?.process_secretary?.secretary_id || null,
    lawsuit_value: data?.lawsuit_value || 0,
    date_dispatch: data?.date_dispatch || null,
    page_last_copy: data?.page_last_copy?.toString() || null,
    tags: data?.tags || [],
    additional_info: data?.additional_info || null,
});
