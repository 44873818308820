/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox } from '@blueprintjs/core';
import update from 'immutability-helper';
import Input from '../../components/Input';
import { reducers } from '../../store/reducers';
import {
  createProgressType,
  inactivateProgressType,
  updateProgressType,
  clearAllStatus,
  fetchProgressTypesPaginated,
} from './actions';
import { mountProgressType } from '../../utils/metaCasesProgressType';
import MetaTable from '../../components/MetaTable';
import { ProgressType } from './types';
import '../Meta/styles.css';
import Button from '../../components/Button';
import createToaster from '../../components/Toaster';
import { WRITE_PERMISSION, META_MODULE_NAME } from '../Permissions/constants';
import { verifyUserPermission } from '../../utils/permissions';
import Pagination from '../../components/Pagination';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

const MetaCasesProgressType: React.FC = () => {
  const dispatch = useDispatch();
  // BlueprintJS inputRef
  const input = React.useRef() as any;
  const progressTypesPaginated = useSelector((state: typeof reducers) => state.metacasesprogresstype.progressTypesPaginated);
  const createProgressTypes = useSelector((state: typeof reducers) => state.metacasesprogresstype.createProgressType);
  const updateProgressTypes = useSelector((state: typeof reducers) => state.metacasesprogresstype.updateProgressType);
  const inactivateProgressTypes = useSelector(
    (state: typeof reducers) => state.metacasesprogresstype.inactivateProgressType
  );
  const [isEdit, setIsEdit] = useState(false);
  const [isSecret, setIsSecret] = useState(false);
  const [stateProgressType, setStateProgressType] = useState<ProgressType>({
    case_progress_type_id: '',
    progress_type: '',
    is_secret: false,
    created_at: '',
  });

  useEffect(() => {
    dispatch(fetchProgressTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
  }, [dispatch]);

  useEffect(() => {
    if (createProgressTypes.success) {
      const showToast = createToaster({
        message: createProgressTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
      dispatch(fetchProgressTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
    }
  }, [createProgressTypes.success, dispatch]);

  useEffect(() => {
    if (updateProgressTypes.success) {
      const showToast = createToaster({
        message: updateProgressTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
      dispatch(fetchProgressTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
    }
  }, [updateProgressTypes.success, dispatch]);

  useEffect(() => {
    if (inactivateProgressTypes.success) {
      const showToast = createToaster({
        message: inactivateProgressTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateProgressTypes.success, dispatch]);

  useEffect(() => {
    if (createProgressTypes.fail) {
      const showToast = createToaster({
        message: createProgressTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createProgressTypes.fail, dispatch]);

  useEffect(() => {
    if (updateProgressTypes.fail) {
      const showToast = createToaster({
        message: updateProgressTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateProgressTypes.fail, dispatch]);

  useEffect(() => {
    if (inactivateProgressTypes.fail) {
      const showToast = createToaster({
        message: inactivateProgressTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateProgressTypes.fail, dispatch]);

  const setInputValue = (value: string) => (input.current.value = value);

  const setUpNewState = (elem: ProgressType) =>
    update(stateProgressType, {
      progress_type: { $set: elem.progress_type },
      case_progress_type_id: { $set: elem.case_progress_type_id },
      is_secret: { $set: elem.is_secret },
      created_at: { $set: elem.created_at },
    });

  const handleEditRequest = (elem: ProgressType) => {
    setInputValue(elem.progress_type);
    setIsEdit(true);
    setIsSecret(elem.is_secret);
    setStateProgressType(setUpNewState(elem));
  };

  const resetForm = () => {
    setInputValue('');
    const newState = update(stateProgressType, {
      is_secret: { $set: false },
      progress_type: { $set: '' },
    });
    setStateProgressType(newState);
    setIsSecret(false);
    setIsEdit(false);
  };

  const addProgressType = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isEdit) {
      const progressType = mountProgressType(input.current.value, isSecret);
      dispatch(createProgressType(progressType));
    } else {
      const newElem = update(stateProgressType, {
        progress_type: { $set: input.current.value },
        is_secret: { $set: isSecret },
      });
      dispatch(updateProgressType(newElem));
    }
    resetForm();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateProgressType(
      update(stateProgressType, {
        progress_type: { $set: e.target.value },
      })
    );
  };

  const handleTextButton = () => (isEdit ? 'Atualizar andamento' : 'Adicionar andamento');

  const disabledValidation = () => stateProgressType.progress_type === '';

  return (
    <form className="component__container" onSubmit={addProgressType}>
      {verifyUserPermission(META_MODULE_NAME, WRITE_PERMISSION) && (
        <Input
          value={stateProgressType?.progress_type}
          onChange={onChangeInput}
          required
          inputRef={input}
          placeholder="Digite o tipo de andamento que gostaria de adicionar... *"
          button={{
            text: handleTextButton(),
            icon: 'plus',
            disabledValidation: disabledValidation(),
          }}
        />
      )}
      <Checkbox className="isSecret" checked={isSecret} onChange={() => setIsSecret((prevState) => !prevState)}>
        Sigiloso
      </Checkbox>
      {isEdit && (
        <Button name="backwhite" onClick={resetForm}>
          Cancelar edição
        </Button>
      )}
      <h3>Tipos de andamento já cadastrados:</h3>
      <MetaTable
        loading={
          progressTypesPaginated?.loading ||
          updateProgressTypes?.loading ||
          inactivateProgressTypes?.loading ||
          createProgressTypes?.loading
        }
        empty={progressTypesPaginated?.empty}
        data={progressTypesPaginated?.data?.result}
        headers={['Nome', 'Sigiloso', 'Criado em']}
        columns={['progress_type', 'is_secret', 'created_at']}
        pathId="case_progress_type_id"
        removeRequest={inactivateProgressType}
        editRequest={handleEditRequest}
        group
      />
      {!progressTypesPaginated?.empty && (
        <Pagination
          request={fetchProgressTypesPaginated}
          loading={progressTypesPaginated?.loading}
          data={progressTypesPaginated?.data}
        />
      )}
    </form>
  );
};

export default MetaCasesProgressType;
