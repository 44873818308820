import { Schedule } from "../modules/CaseRelatedSchedules/types";
import { fixDateGmt } from "./date";

export const mountScheduleArray = (defaultSchedules: Schedule[]) =>
  defaultSchedules.map((item: any) => {
    return {
      id: item.schedule_id,
      calendarId: '0',
      title: item.title,
      body: item.text,
      category: 'time',
      start: fixDateGmt(item.date_start),
      end: fixDateGmt(item.date_end),
      isAllDay: item.is_all_day,
    };
  });
