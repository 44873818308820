/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  secretaryTypes: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  secretaryTypesPaginated: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  secretariesByCountyId: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  secretaryTypesId: {
    data: null,
    loading: false,
    fail: null,
  },
  createSecretaryType: {
    loading: false,
    fail: null,
    success: null,
  },
  updateSecretaryType: {
    loading: false,
    fail: null,
    success: null,
  },
  inactivateSecretaryType: {
    loading: false,
    fail: null,
    success: null,
  }
};

const metaCases: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_SECRETARY_TYPE:
        draft.secretaryTypes.loading = true;
        draft.secretaryTypes.empty = true;
        draft.secretaryTypes.fail = null;
        return;

      case c.FETCH_SECRETARY_TYPE_SUCCESS:
        draft.secretaryTypes.loading = false;
        draft.secretaryTypes.fail = null;
        if (payload && payload.data.length) {
          draft.secretaryTypes.data = payload.data;
          draft.secretaryTypes.empty = false;
        }
        return;

      case c.FETCH_SECRETARY_TYPE_FAIL:
        draft.secretaryTypes.loading = false;
        draft.secretaryTypes.fail = payload.error;
        draft.secretaryTypes.data = payload.data;
        return;

        case c.FETCH_SECRETARY_TYPE_PAGINATED:
          draft.secretaryTypesPaginated.loading = true;
          draft.secretaryTypesPaginated.empty = true;
          draft.secretaryTypesPaginated.fail = null;
          return;
  
        case c.FETCH_SECRETARY_TYPE_PAGINATED_SUCCESS:
          draft.secretaryTypesPaginated.loading = false;
          draft.secretaryTypesPaginated.fail = null;
          if (payload && payload.data.result.length) {
            draft.secretaryTypesPaginated.data = payload.data;
            draft.secretaryTypesPaginated.empty = false;
          }
          return;
  
        case c.FETCH_SECRETARY_TYPE_PAGINATED_FAIL:
          draft.secretaryTypesPaginated.loading = false;
          draft.secretaryTypesPaginated.fail = payload.error;
          draft.secretaryTypesPaginated.data = payload.data;
          return;

      case c.FETCH_SECRETARIES_BY_COUNTY_ID:
        draft.secretariesByCountyId.data = [];
        draft.secretariesByCountyId.loading = true;
        draft.secretariesByCountyId.empty = true;
        draft.secretariesByCountyId.fail = null;
        return;

      case c.FETCH_SECRETARIES_BY_COUNTY_ID_SUCCESS:
        draft.secretariesByCountyId.loading = false;
        draft.secretariesByCountyId.fail = null;
        if (payload && payload.data.length) {
          draft.secretariesByCountyId.data = payload.data;
          draft.secretariesByCountyId.empty = false;
        }
        return;

      case c.FETCH_SECRETARIES_BY_COUNTY_ID_FAIL:
        draft.secretariesByCountyId.loading = false;
        draft.secretariesByCountyId.fail = payload.error;
        draft.secretariesByCountyId.data = payload.data;
        return;

      case c.FETCH_SECRETARY_TYPE_ID:
        draft.secretaryTypesId.loading = true;
        draft.secretaryTypesId.fail = null;
        return;

      case c.FETCH_SECRETARY_TYPE_ID_SUCCESS:
        draft.secretaryTypesId.loading = false;
        draft.secretaryTypesId.fail = null;
        if (payload && payload.data.length) {
          draft.secretaryTypesId.data = payload.data;
        }
        return;

      case c.FETCH_SECRETARY_TYPE_ID_FAIL:
        draft.secretaryTypesId.loading = false;
        draft.secretaryTypesId.fail = payload.error;
        draft.secretaryTypesId.data = payload.data;
        return;

      case c.CREATE_SECRETARY_TYPE:
        draft.createSecretaryType.loading = true;
        draft.createSecretaryType.fail = null;
        draft.createSecretaryType.success = null;
        return;

      case c.CREATE_SECRETARY_TYPE_SUCCESS:
        draft.createSecretaryType.loading = false;
        draft.createSecretaryType.success = payload.data;
        return;

      case c.CREATE_SECRETARY_TYPE_FAIL:
        draft.createSecretaryType.loading = false;
        draft.createSecretaryType.fail = payload.error;
        draft.createSecretaryType.success = null;
        return;

      case c.UPDATE_SECRETARY_TYPE:
        draft.updateSecretaryType.loading = true;
        draft.updateSecretaryType.fail = null;
        draft.updateSecretaryType.success = null;
        return;

      case c.UPDATE_SECRETARY_TYPE_SUCCESS:
        draft.updateSecretaryType.loading = false;
        draft.updateSecretaryType.fail = null;
        draft.updateSecretaryType.success = payload.data;
        return;

      case c.UPDATE_SECRETARY_TYPE_FAIL:
        draft.updateSecretaryType.loading = false;
        draft.updateSecretaryType.fail = payload.error;
        draft.updateSecretaryType.success = null;
        return;

      case c.INACTIVATE_SECRETARY_TYPE:
        draft.inactivateSecretaryType.loading = true;
        draft.inactivateSecretaryType.fail = null;
        draft.inactivateSecretaryType.success = null;
        return;

      case c.INACTIVATE_SECRETARY_TYPE_SUCCESS:
        draft.inactivateSecretaryType.loading = false;
        draft.inactivateSecretaryType.fail = null;
        draft.inactivateSecretaryType.success = payload.data;
        return;

      case c.INACTIVATE_SECRETARY_TYPE_FAIL:
        draft.inactivateSecretaryType.loading = false;
        draft.inactivateSecretaryType.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.createSecretaryType.success = null;
        draft.updateSecretaryType.success = null;
        draft.inactivateSecretaryType.success = null;
        draft.createSecretaryType.fail = null;
        draft.updateSecretaryType.fail = null;
        draft.inactivateSecretaryType.fail = null;
        return;

      default:
        return;
    }
  });

export default metaCases;
