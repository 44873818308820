import React, { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { reducers } from '../../store/reducers';
import { getValuesFromForm } from '../../utils/form';
import createToaster from '../../components/Toaster';
// eslint-disable-next-line @typescript-eslint/camelcase
import { recoveryPassword } from './actions';

const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();
  const { fail, loading, message } = useSelector((state: typeof reducers) => state.forgotpassword);

  const sendEmail = (e: SyntheticEvent) => {
    e.preventDefault();
    if (e.target instanceof HTMLFormElement) {
      const inputValues = getValuesFromForm(e.target);
      dispatch(recoveryPassword(inputValues[0]));
    }
  };

  if (fail || message) {
    const showToast = createToaster({
      message: fail || message,
      intent: fail ? 'danger' : 'success',
      icon: fail ? 'warning-sign' : 'tick',
    });
    showToast();
  }

  return (
    <>
      <Header />
      <div className="container" id="forgot-password">
        <h2>Informe seu email para a recuperação de sua senha</h2>
        <form onSubmit={sendEmail}>
          <Input placeholder="Email" type="email" />
          <Button name="primary" type="submit" loading={loading}>
            Redefinir senha
          </Button>
          <Button name="warning" href="/login">
            Cancelar
          </Button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
