import React, { useState, useRef, useEffect } from 'react';
import './styles.css';
import update from 'immutability-helper';
import Button from '../../components/Button';
import { goToHere, handleNextStepCreateCase } from '../../utils/navigation';
import { BREADCRUMB_PATHS } from './constants';
import BreadCrumb from '../../components/Breadcrumb';
import { Tabs, Tab, Tooltip, Spinner } from '@blueprintjs/core';
import './styles.css';
import StepEnvolvedPerson from '../StepEnvolvedPerson';
import StepDataCaseForm from '../StepDataCaseForm';
import { StepPathType, CasesBackButton, PostCase, LocalCasePersonType, RelationedLawyer } from './types';
import StepEnvolvedLawyers from '../StepEnvolvedLawyers';
import { createCase } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { reducers } from '../../store/reducers';
import createToaster from '../../components/Toaster';

const CreateCase: React.FC = () => {
  const dispatch = useDispatch();
  const create = useSelector((state: typeof reducers) => state.createcase.createCase)
  const [selectedTabId, setSelectedTabId] = useState('dataCase');
  const dataCaseForm = useRef() as React.MutableRefObject<HTMLFormElement>;
  const handleChangeTab = (tab: string) => setSelectedTabId(tab);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [people, setPeople] = useState<LocalCasePersonType[]>([]);
  const [lawyers, setLawyers] = useState<RelationedLawyer[]>([]);
  const [trySubmit, setTrySubmit] = useState(false);
  const [newCase, setNewCase] = useState<PostCase>({
    title: '',
    lawsuit_name: '',
    is_legal_process: false,
    case_status_id: null,
    process_originating: null,
    process_id: null,
    process_county_id: null,
    process_justice_type_id: null,
    process_court_id: null,
    process_secretary_id: null,
    lawsuit_value: null,
    date_dispatch: null,
    page_last_copy: null,
    tags: [],
    people: [],
    lawyers: [],
  });

  useEffect(() => {
    if (create.fail || create.success) {
      const showToast = createToaster({
        message: create.fail || create.success,
        intent: create.fail ? 'danger' : 'success',
        icon: create.fail ? 'warning-sign' : 'tick',
      });
      showToast();
    }
  }, [create]);

  const handleStyleBasedOnPersonTab = () => selectedTabId === 'envolvedPerson' && 'active--tab';

  const handleStyleBasedOnLawyersTab = () => selectedTabId === 'envolvedLawyers' && 'active--tab';

  const handleNextButton = () => {
    if (!selectedTabId.includes('envolvedLawyers')) {
      if (!dataCaseForm?.current?.checkValidity()) {
        return (
          <Tooltip captureDismiss content={'Por favor preencha todos os campos obrigatórios (com *).'}>
          <Button
            type="button"
            className="button--save"
            onClick={() => setSelectedTabId(handleNextStepCreateCase(selectedTabId as keyof StepPathType))}
            large
            rightIcon="chevron-right"
            text="Próximo passo"
            disabled
          />
          </Tooltip>
        );
      }
      return (
        <Button
          type="button"
          className="button--save"
          onClick={() => setSelectedTabId(handleNextStepCreateCase(selectedTabId as keyof StepPathType))}
          large
          rightIcon="chevron-right"
          text="Próximo passo"
        />
      );
    }
    return <Button type="button" disabled={newCase.lawyers.length < 1} className="button--save" onClick={() => submitCase()} large text="Salvar caso" />;
  };

  useEffect(() => {
    if (newCase.process_id !== null) {
      setNewCase((state) => update(state, { is_legal_process: { $set: true } }));
    }
  }, [newCase.process_id]);

  const submitCase = () => {
    setTrySubmit(true);
    if (!dataCaseForm.current.checkValidity()) {
      return setSelectedTabId('dataCase');
    }
    return dispatch(createCase(newCase));
  };

  const handleBackButton = () => {
    const backButton: CasesBackButton = {
      dataCase: <Button large intent="danger" minimal onClick={() => setIsDialogOpen(true)} text="Cancelar criação" />,
      envolvedPerson: <Button large intent="primary" minimal onClick={() => setSelectedTabId('dataCase')} text="Voltar" />,
      envolvedLawyers: (
        <Button large intent="primary" minimal onClick={() => setSelectedTabId('envolvedPerson')} text="Voltar" />
      ),
    };
    return (
      backButton[selectedTabId as keyof CasesBackButton] ?? (
        <Button large intent="danger" minimal onClick={() => setIsDialogOpen(true)} text="Cancelar criação" />
      )
    );
  };

  return (
    <div className="cases__container">
      <ConfirmationDialog
        headerText="Tem certeza que deseja abandonar a criação deste caso?"
        confirmMethod={() => goToHere('/painel/casos')}
        setIsOpen={setIsDialogOpen}
        isOpen={isDialogOpen}
      />
      <div className="content__head">
        <BreadCrumb breads={BREADCRUMB_PATHS} />
        <div className="create__cases--header">
          <h3>Cadastro de caso</h3>
          <div className="button__group">
            {handleBackButton()}
            {handleNextButton()}
            {create.loading && (
               <Spinner intent="primary" size={25} />
            )}
          </div>
        </div>
      </div>
      <Tabs className="tabs--create__case" selectedTabId={selectedTabId} onChange={handleChangeTab}>
        <Tab
          id="dataCase"
          title="Dados do Caso"
          className={`${handleStyleBasedOnPersonTab()} ${handleStyleBasedOnLawyersTab()}`}
          panel={<StepDataCaseForm trySubmit={trySubmit} formRef={dataCaseForm} newCase={newCase} setNewCase={setNewCase} />}
        />
        <Tab
          id="envolvedPerson"
          disabled={!dataCaseForm?.current?.checkValidity()}
          title="Pessoas envolvidas"
          className={`${handleStyleBasedOnLawyersTab()}`}
          panel={<StepEnvolvedPerson people={people} setPeople={setPeople} newCase={newCase} setNewCase={setNewCase} />}
        />
        <Tab
          id="envolvedLawyers"
          disabled={!dataCaseForm?.current?.checkValidity()}
          title="Advogados envolvidos"
          panel={<StepEnvolvedLawyers lawyers={lawyers} setLawyers={setLawyers} newCase={newCase} setNewCase={setNewCase} />}
        />
      </Tabs>
    </div>
  );
};

export default CreateCase;
