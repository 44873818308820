/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import update from 'immutability-helper';
import BreadCrumb from '../../components/Breadcrumb';
import { BREADCRUMB_PATHS, COLUMNS } from './constants';
import './styles.css';
import { isMobile } from '../../utils/window';
import Input from '../../components/Input';
import { reducers } from '../../store/reducers';
import Table from '../../components/GenericTable';
import Button from '../../components/Button';
import { inactivateUser, fetchUsersPaginated } from './actions';
import RegisterUser from '../../components/RegisterUser';
import createToaster from '../../components/Toaster';
import { User } from './types';
import { verifyUserPermission } from '../../utils/permissions';
import { ROLE_MODULE_NAME, WRITE_PERMISSION } from '../Permissions/constants';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';
import Pagination from '../../components/Pagination';

const Users: React.FC = () => {
  const dispatch = useDispatch();
  const [toggleRegisterForm, setToggleRegisterForm] = useState(false);
  const inactivate = useSelector((state: typeof reducers) => state.users.inactivate);
  const updateStatus = useSelector((state: typeof reducers) => state.users.update);
  const usersPaginatedData = useSelector((state: typeof reducers) => state.users.usersPaginated);
  const handleFormVisibility = () => (isMobile() && !toggleRegisterForm ? 'none' : 'register__container');
  const handleTableVisibility = () => (isMobile() && toggleRegisterForm ? 'none' : 'users__list');
  // BlueprintJS inputRef
  const searchInput = React.useRef() as any;
  const [editUser, setEditUser] = useState<User>({
    roles: [],
    username: '',
    user_id: '',
    password: '',
  });
  const [editable, setEditable] = useState<boolean>(false);

  const handleEditUser = (user: User) => {
    setEditUser(update(editUser, { $set: user }));
    setEditable(true);
  };

  useEffect(() => {
    dispatch(fetchUsersPaginated(1, DEFAULT_PER_PAGE_LIMIT, ''));
  }, [dispatch]);

  if (inactivate.message !== null) {
    const showToast = createToaster({
      message: inactivate.message,
      intent: inactivate.message && 'success',
      icon: 'tick',
    });
    showToast();
  }

  if (updateStatus.message !== null) {
    const showToast = createToaster({
      message: updateStatus.message,
      intent: updateStatus.message && 'success',
      icon: 'tick',
    });
    showToast();
  }

  const submitSearch = (e: SyntheticEvent) =>
    dispatch(fetchUsersPaginated(1, DEFAULT_PER_PAGE_LIMIT, `&username=${searchInput?.current?.value}`))

  return (
    <div className="users__container">
      <div className={`${handleTableVisibility()} ${
          !verifyUserPermission(ROLE_MODULE_NAME, WRITE_PERMISSION) ? 'non__write__permission' : ''
        }`}>
        <BreadCrumb breads={BREADCRUMB_PATHS} />
        <p className="description">
          Estes são os usuários cadastrados na plataforma. Na plataforma, clique em &#34;Nome&#34; para filtros de ordenação.
        </p>
        <Input
          inputRef={searchInput}
          placeholder="Insira uma parte do email do usuário"
          button={{ text: 'Buscar usuário', icon: 'search', onClick: submitSearch }}
        />
        <Button name="mobileAdd" onClick={() => setToggleRegisterForm((state) => !state)} text="Cadastrar usuário" />
        <Table
          loading={usersPaginatedData?.loading}
          empty={usersPaginatedData?.empty}
          data={usersPaginatedData?.data?.result}
          headers={COLUMNS}
          columns={['username', 'roles']}
          moreButton={{ removeRequest: inactivateUser, pathId: 'user_id', editRequest: handleEditUser }}
          expandButton={{ path: '/painel/pessoas/individuo', pathId: 'user_id' }}
        />
        {!usersPaginatedData?.empty && (
        <Pagination
          request={fetchUsersPaginated}
          loading={usersPaginatedData?.loading}
          data={usersPaginatedData?.data}
          optionsPath={`&username=${searchInput?.current?.value}`}
        />
      )}
      </div>

      <div
        className={handleFormVisibility()}
      >
        {isMobile() && (
          <Button
            className="bp3-button sidebar__button--blue"
            onClick={() => setToggleRegisterForm(false)}
            icon="chevron-left"
            text="Voltar"
          />
        )}
        {verifyUserPermission(ROLE_MODULE_NAME, WRITE_PERMISSION) && <RegisterUser user={editUser} isEditable={editable} />}
      </div>
    </div>
  );
};

export default Users;
