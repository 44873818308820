import React from 'react';
import { Dialog, Button } from '@blueprintjs/core';
import './styles.css';

interface IConfirmationDialog {
    headerText: string;
    confirmMethod: Function;
    isOpen: boolean;
    setIsOpen: Function;
};

const ConfirmationDialog: React.FC<IConfirmationDialog> = ({ headerText, confirmMethod, isOpen, setIsOpen }) => {
  return (
    <Dialog className="logout__confirmation" isOpen={isOpen} onClose={() => setIsOpen(false)} canEscapeKeyClose>
      <>
        <h2>{ headerText }</h2>
        <div className="button__group">
          <Button intent="danger" minimal text="Não" onClick={() => setIsOpen(false)} />
          <Button intent="primary" onClick={() => confirmMethod()} text="Sim" />
        </div>
      </>
    </Dialog>
  );
};

export default React.memo(ConfirmationDialog);
