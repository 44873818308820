/* eslint-disable import/prefer-default-export */
export const COLUMNS = ['Telefone', 'Tipo', 'Cidade'];

export const FETCH_PHONE_RELATION = 'FETCH_PHONE_RELATION';
export const FETCH_PHONE_RELATION_SUCCESS = 'FETCH_PHONE_RELATION_SUCCESS';
export const FETCH_PHONE_RELATION_FAIL = 'FETCH_PHONE_RELATION_FAIL';
export const FETCH_PHONE_RELATION_ID = 'FETCH_PHONE_RELATION_ID';
export const FETCH_PHONE_RELATION_ID_SUCCESS = 'FETCH_PHONE_RELATION_ID_SUCCESS';
export const FETCH_PHONE_RELATION_ID_FAIL = 'FETCH_PHONE_RELATION__ID_FAIL';
export const CREATE_PHONE_RELATION = 'CREATE_PHONE_RELATION';
export const CREATE_PHONE_RELATION_SUCCESS = 'CREATE_PHONE_RELATION_SUCCESS';
export const CREATE_PHONE_RELATION_FAIL = 'CREATE_PHONE_RELATION_FAIL';
export const INACTIVATE_PHONE_RELATION = 'INACTIVATE_PHONE_RELATION';
export const INACTIVATE_PHONE_RELATION_SUCCESS = 'INACTIVATE_PHONE_RELATION_SUCCESS';
export const INACTIVATE_PHONE_RELATION_FAIL = 'INACTIVATE_PHONE_RELATION_FAIL';
export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
