/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import update from 'immutability-helper';
import Input from '../../components/Input';
import { reducers } from '../../store/reducers';
import { createTagType, inactivateTagType, updateTagType, clearAllStatus, fetchTagTypesPaginated } from './actions';
import MetaTable from '../../components/MetaTable';
import { TagType } from './types';
import '../Meta/styles.css';
import Button from '../../components/Button';
import createToaster from '../../components/Toaster';
import { WRITE_PERMISSION, META_MODULE_NAME } from '../Permissions/constants';
import { verifyUserPermission } from '../../utils/permissions';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';
import Pagination from '../../components/Pagination';

const MetaCasesTagType: React.FC = () => {
  const dispatch = useDispatch();
  // BlueprintJS inputRef
  const input = React.useRef() as any;
  // BlueprintJS inputRef
  const description = React.useRef() as any;
  const tagTypesPaginated = useSelector((state: typeof reducers) => state.metacasestag.tagTypesPaginated);
  const createTagTypes = useSelector((state: typeof reducers) => state.metacasestag.createTagType);
  const updateTagTypes = useSelector((state: typeof reducers) => state.metacasestag.updateTagType);
  const inactivateTagTypes = useSelector((state: typeof reducers) => state.metacasestag.inactivateTagType);
  const [isEdit, setIsEdit] = useState(false);
  const [stateTagType, setStateTagType] = useState<TagType>({
    tag: '',
    description: '',
    tag_id: '',
    created_at: '',
    is_active: false,
  });

  useEffect(() => {
    if (createTagTypes.success) {
      const showToast = createToaster({
        message: createTagTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createTagTypes.success, dispatch]);

  useEffect(() => {
    if (updateTagTypes.success) {
      const showToast = createToaster({
        message: updateTagTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateTagTypes.success, dispatch]);

  useEffect(() => {
    if (inactivateTagTypes.success) {
      const showToast = createToaster({
        message: inactivateTagTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateTagTypes.success, dispatch]);

  useEffect(() => {
    if (createTagTypes.fail) {
      const showToast = createToaster({
        message: createTagTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createTagTypes.fail, dispatch]);

  useEffect(() => {
    if (updateTagTypes.fail) {
      const showToast = createToaster({
        message: updateTagTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateTagTypes.fail, dispatch]);

  useEffect(() => {
    if (inactivateTagTypes.fail) {
      const showToast = createToaster({
        message: inactivateTagTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateTagTypes.fail, dispatch]);

  const setUpNewState = (elem: TagType) =>
    update(stateTagType, {
      tag: { $set: elem.tag },
      description: { $set: elem.description },
      tag_id: { $set: elem.tag_id },
      is_active: { $set: elem.is_active },
      created_at: { $set: elem.created_at },
    });

  const setInputValue = (value: string) => (input.current.value = value);
  const setDescriptionValue = (value: string) => (description.current.value = value);

  const handleEditRequest = (elem: TagType) => {
    setInputValue(elem.tag);
    setIsEdit(true);
    setStateTagType(setUpNewState(elem));
  };

  useEffect(() => {
    dispatch(fetchTagTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
  }, [dispatch]);

  const resetForm = () => {
    setInputValue('');
    setStateTagType(
      update(stateTagType, {
        tag: { $set: '' },
        description: { $set: '' },
      })
    );
    setIsEdit(false);
    setDescriptionValue('');
  };

  const addTagType = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isEdit) {
      dispatch(createTagType(stateTagType.tag, stateTagType.description));
    } else {
      dispatch(updateTagType(stateTagType));
    }
    resetForm();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateTagType(
      update(stateTagType, {
        tag: { $set: e.target.value },
      })
    );
  };

  const onChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateTagType(
      update(stateTagType, {
        description: { $set: e.target.value },
      })
    );
  };

  const handleTextButton = () => (isEdit ? 'Atualizar assunto' : 'Adicionar assunto');

  const disabledValidation = () => stateTagType.tag === '';

  return (
    <form className="component__container" onSubmit={addTagType}>
      {verifyUserPermission(META_MODULE_NAME, WRITE_PERMISSION) && (
        <Input
          value={stateTagType?.tag}
          onChange={onChangeInput}
          required
          inputRef={input}
          placeholder="Digite o tipo de assunto que gostaria de adicionar... *"
          button={{
            text: handleTextButton(),
            icon: 'plus',
            disabledValidation: disabledValidation(),
          }}
        />
      )}
      <Input
        maxLength={60}
        value={stateTagType?.description}
        onChange={onChangeDescription}
        inputRef={description}
        placeholder="Descrição (60 caracteres)"
      />
      <div>
        {isEdit && (
          <Button name="backwhite" onClick={resetForm}>
            Cancelar edição
          </Button>
        )}
      </div>
      <h3>Tipos de assunto já cadastrados:</h3>
      <MetaTable
        loading={tagTypesPaginated?.loading || updateTagTypes?.loading || inactivateTagTypes?.loading || createTagTypes?.loading}
        empty={tagTypesPaginated.empty}
        data={tagTypesPaginated.data.result}
        headers={['Nome', 'Descrição', 'Criado em']}
        columns={['tag', 'description', 'created_at']}
        pathId="tag_id"
        removeRequest={inactivateTagType}
        editRequest={handleEditRequest}
        group
      />
      {!tagTypesPaginated?.empty && (
        <Pagination
          request={fetchTagTypesPaginated}
          loading={tagTypesPaginated?.loading}
          data={tagTypesPaginated?.data}
        />
      )}
    </form>
  );
};

export default React.memo(MetaCasesTagType);
