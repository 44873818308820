/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import update from 'immutability-helper';
import Input from '../../components/Input';
import { reducers } from '../../store/reducers';
import { createCourtType, inactivateCourtType, updateCourtType, fetchCourtTypesPaginated } from './actions';
import MetaTable from '../../components/MetaTable';
import { CourtType } from './types';
import '../Meta/styles.css';
import Button from '../../components/Button';
import createToaster from '../../components/Toaster';
import { clearAllStatus, fetchJusticeTypes } from '../MetaCasesJusticeType/actions';
import Selector from '../../components/Selector';
import './styles.css';
import { META_MODULE_NAME, WRITE_PERMISSION } from '../Permissions/constants';
import { verifyUserPermission } from '../../utils/permissions';
import { fetchDistrictTypes } from '../MetaCasesDistrictType/actions';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';
import Pagination from '../../components/Pagination';

const MetaCasesCourtType: React.FC = () => {
  const dispatch = useDispatch();
  // BlueprintJS inputRef
  const input = React.useRef() as any;
  const courtTypesPaginated = useSelector((state: typeof reducers) => state.metacasescourttype.courtTypesPaginated);
  const justiceTypes = useSelector((state: typeof reducers) => state.metacasesjusticetype.justiceTypes);
  const counties = useSelector((state: typeof reducers) => state.metacasesdistricttype.districtTypes);
  const createCourtTypes = useSelector((state: typeof reducers) => state.metacasescourttype.createCourtType);
  const updateCourtTypes = useSelector((state: typeof reducers) => state.metacasescourttype.updateCourtType);
  const inactivateCourtTypes = useSelector((state: typeof reducers) => state.metacasescourttype.inactivateCourtType);
  const [isEdit, setIsEdit] = useState(false);
  const [stateCourtType, setStateCourtType] = useState({
    name: '',
    justice_type_id: '',
    county_id: '',
    court_id: '',
    created_at: '',
    justice_type: {},
    county: {},
    is_active: true,
  });
  const [justiceId, setJusticeId] = useState('');
  const [countyId, setCountyId] = useState('');

  useEffect(() => {
    dispatch(fetchCourtTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
    dispatch(fetchDistrictTypes());
    dispatch(fetchJusticeTypes());
  }, [dispatch]);

  useEffect(() => {
    if (createCourtTypes.success) {
      const showToast = createToaster({
        message: createCourtTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createCourtTypes.success, dispatch]);

  useEffect(() => {
    if (updateCourtTypes.success) {
      const showToast = createToaster({
        message: updateCourtTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateCourtTypes.success, dispatch]);

  useEffect(() => {
    if (inactivateCourtTypes.success) {
      const showToast = createToaster({
        message: inactivateCourtTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateCourtTypes.success, dispatch]);

  useEffect(() => {
    if (createCourtTypes.fail) {
      const showToast = createToaster({
        message: createCourtTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createCourtTypes.fail, dispatch]);

  useEffect(() => {
    if (updateCourtTypes.fail) {
      const showToast = createToaster({
        message: updateCourtTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateCourtTypes.fail, dispatch]);

  useEffect(() => {
    if (inactivateCourtTypes.fail) {
      const showToast = createToaster({
        message: inactivateCourtTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateCourtTypes.fail, dispatch]);

  const setInputValue = (value: string) => (input.current.value = value);

  const setUpNewState = (elem: CourtType) =>
    update(stateCourtType, {
      name: { $set: elem.name },
      justice_type_id: { $set: elem.justice_type_id },
      county_id: { $set: elem.county_id },
      court_id: { $set: elem.court_id },
      created_at: { $set: elem.created_at },
      justice_type: { $set: elem.justice_type },
      county: { $set: elem.county },
      is_active: { $set: elem.is_active },
    });

  const handleEditRequest = (elem: CourtType) => {
    setInputValue(elem.name);
    setIsEdit(true);
    setStateCourtType(setUpNewState(elem));
    setJusticeId(elem.justice_type_id);
    if (elem.justice_type?.county_id) {
      setCountyId(elem.justice_type.county_id);
    }
  };

  const resetForm = () => {
    setInputValue('');
    setCountyId('');
    setJusticeId('');
    dispatch(clearAllStatus());
    setStateCourtType((prevState) => ({
      ...prevState,
      name: '',
    }));
    setIsEdit(false);
  };

  const addCourtType = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isEdit) {
      dispatch(createCourtType(stateCourtType.name, justiceId, countyId));
    } else {
      const newElem = update(stateCourtType, {
        name: { $set: stateCourtType.name },
      });
      dispatch(updateCourtType(newElem, justiceId, countyId));
    }
    resetForm();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateCourtType(
      update(stateCourtType, {
        name: { $set: e.target.value },
      })
    );
  };

  const handleTextButton = () => (isEdit ? 'Atualizar vara' : 'Adicionar vara');

  const disabledValidation = () => justiceId === '' || countyId === '' || stateCourtType.name === '';

  return (
    <form className="component__container" onSubmit={addCourtType}>
      {verifyUserPermission(META_MODULE_NAME, WRITE_PERMISSION) && (
        <Input
          required
          value={stateCourtType?.name}
          onChange={onChangeInput}
          inputRef={input}
          placeholder="Digite o tipo de vara que gostaria de adicionar... *"
          button={{
            text: handleTextButton(),
            icon: 'plus',
            disabledValidation: disabledValidation(),
          }}
        />
      )}
      {counties.data && (
        <div>
          <Selector
            setValue={setCountyId}
            required
            value={countyId}
            className="cases__court__selector"
            counties={counties.data}
          />
        </div>
      )}
      {!justiceTypes.empty ? (
        <Selector setValue={setJusticeId} className="cases__court__selector" justiceTypes={justiceTypes.data} />
      ) : (
        <h4 className="empty__attribute">
          Por favor, insira um <strong>tipos de justiça</strong> antes de inserir uma vara.
        </h4>
      )}
      <div>
        {isEdit && (
          <Button name="backwhite" onClick={resetForm}>
            Cancelar edição
          </Button>
        )}
      </div>
      <h3>Tipos de vara já cadastradas:</h3>
      <MetaTable
        loading={
          courtTypesPaginated?.loading || updateCourtTypes?.loading || inactivateCourtTypes?.loading || createCourtTypes?.loading
        }
        empty={courtTypesPaginated.empty}
        data={courtTypesPaginated.data.result}
        headers={['Nome', 'Tipo de justiça', 'Comarca', 'Criado em']}
        columns={['name', 'justice_type', 'justice_county', 'created_at']}
        pathId="court_id"
        removeRequest={inactivateCourtType}
        editRequest={handleEditRequest}
        group
      />
      {!courtTypesPaginated?.empty && (
        <Pagination
          request={fetchCourtTypesPaginated}
          loading={courtTypesPaginated?.loading}
          data={courtTypesPaginated?.data}
        />
      )}
    </form>
  );
};

export default React.memo(MetaCasesCourtType);
