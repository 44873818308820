/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';
import { SecretaryType } from './types';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

export const clearAllStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_ALL_STATUS,
  });
};

export const fetchSecretaryTypes = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_SECRETARY_TYPE,
    payload: {
      request: {
        url: '/secretaries',
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_SECRETARY_TYPE_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_SECRETARY_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchSecretaryTypesPaginated = (offset: number, limit: number) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_SECRETARY_TYPE_PAGINATED,
    payload: {
      request: {
        url: `/secretaries/paginated?offset=${offset}&${limit}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_SECRETARY_TYPE_PAGINATED_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_SECRETARY_TYPE_PAGINATED_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchSecretaryTypesId = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_SECRETARY_TYPE_ID,
    payload: {
      request: {
        url: `/secretaries/${id}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_SECRETARY_TYPE_ID_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_SECRETARY_TYPE_ID_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const createSecretaryType = (secretaryType: string, countyId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.CREATE_SECRETARY_TYPE,
    payload: {
      request: {
        url: '/secretaries',
        method: 'POST',
        data: { name: secretaryType, county_id: countyId },
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.CREATE_SECRETARY_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de secretaria criado com sucesso!',
            },
          });
          dispatch(fetchSecretaryTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.CREATE_SECRETARY_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const updateSecretaryType = (oldState: SecretaryType, countyId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.UPDATE_SECRETARY_TYPE,
    payload: {
      request: {
        url: `/secretaries/${oldState.secretary_id}`,
        method: 'PUT',
        data: { name: oldState.name, county_id: countyId },
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.UPDATE_SECRETARY_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de secretaria atualizado com sucesso!',
            },
          });
          dispatch(fetchSecretaryTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.UPDATE_SECRETARY_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const inactivateSecretaryType = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_SECRETARY_TYPE,
    payload: {
      request: {
        url: `/secretaries/${id}/inactivate`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_SECRETARY_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de secretaria arquivado com sucesso!',
            },
          });
          dispatch(fetchSecretaryTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.INACTIVATE_SECRETARY_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchSecretariesByCounty = (countyId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_SECRETARIES_BY_COUNTY_ID,
    payload: {
      request: {
        url: `/secretaries/counties/${countyId}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_SECRETARIES_BY_COUNTY_ID_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_SECRETARIES_BY_COUNTY_ID_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};
