/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-underscore-dangle */
import { Dispatch } from 'redux';
import * as c from './constants';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';
import handleApiError from '../../utils/errors';

export const fetchCases = (
  offset: number,
  limit = DEFAULT_PER_PAGE_LIMIT,
  filtersPath: string,
) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_CASES,
    payload: {
      request: {
        url: `/legal_cases/paginated?offset=${offset}&limit=${limit}${filtersPath}
        `,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          const res = response.data;
          dispatch({
            type: c.FETCH_CASES_SUCCESS,
            payload: {
              data: res,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.FETCH_CASES_FAIL,
            payload: {
              error: handleApiError(error.toString()),
            },
          });
        },
      },
    },
  });
};

export const fetchCasesByProcessNumber = (
  processId: string
) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_CASES,
    payload: {
      request: {
        url: `/legal_cases/process/${processId}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          const res = response.data;
          dispatch({
            type: c.FETCH_CASES_SUCCESS,
            payload: {
              data: res,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.FETCH_CASES_FAIL,
            payload: {
              error: handleApiError(error.toString()),
            },
          });
        },
      },
    },
  });
};

export const fetchAllActiveCases = (path: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_ALL_CASES,
    payload: {
      request: {
        url: `/legal_cases?${path}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_ALL_CASES_SUCCESS,
            payload: {
              data: response,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.FETCH_ALL_CASES_FAIL,
            payload: {
              error: handleApiError(error.toString()),
            },
          });
        },
      },
    },
  });
};
