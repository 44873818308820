/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  personDocuments: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  create: {
    fail: null,
    success: null,
    loading: false,
  },
  inactivate: {
    fail: null,
    success: null,
    loading: false,
  }
};

const peopleRelation: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_PERSON_DOCUMENTS:
        draft.personDocuments.loading = true;
        draft.personDocuments.empty = true;
        draft.personDocuments.fail = null;
        return;

      case c.FETCH_PERSON_DOCUMENTS_SUCCESS:
        draft.personDocuments.loading = false;
        draft.personDocuments.fail = null;
        if (payload && payload.data.length) {
          draft.personDocuments.data = payload.data;
          draft.personDocuments.empty = false;
        }
        return;

      case c.FETCH_PERSON_DOCUMENTS_FAIL:
        draft.personDocuments.loading = false;
        draft.personDocuments.fail = payload.error;
        draft.personDocuments.data = payload.data;
        return;

      case c.CREATE_PERSON_DOCUMENT:
        draft.create.loading = true;
        draft.create.fail = null;
        draft.create.success = null;
        return;

      case c.CREATE_PERSON_DOCUMENT_SUCCESS:
        draft.create.loading = false;
        draft.create.success = 'Relação salva com sucesso!';
        return;

      case c.CREATE_PERSON_DOCUMENT_FAIL:
        draft.create.loading = false;
        draft.create.fail = payload.error;
        draft.create.success = null;
        return;

      case c.INACTIVATE_PERSON_DOCUMENT:
        draft.inactivate.loading = true;
        draft.inactivate.fail = null;
        draft.inactivate.success = null;
        return;

      case c.INACTIVATE_PERSON_DOCUMENT_SUCCESS:
        draft.inactivate.loading = false;
        draft.inactivate.fail = null;
        draft.inactivate.success = 'Relação arquivada com sucesso!';
        return;

      case c.INACTIVATE_PERSON_DOCUMENT_FAIL:
        draft.inactivate.loading = false;
        draft.inactivate.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.create.success = null;
        draft.create.fail = null;
        draft.inactivate.fail = null;
        draft.inactivate.success = null;
        return;

      default:
        return;
    }
  });

export default peopleRelation;
