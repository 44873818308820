import React from 'react';
import { Popover, Position, Icon, Menu, Button, IconName } from '@blueprintjs/core';
import './styles.css';
import { useHistory } from 'react-router-dom';

interface IPopoverEditRemoveProps {
  formDisabled?: boolean;
  removeRequest?: (arg: Record<string, any>) => void;
  editRequest?: (arg: Record<string, any>) => void;
  mobilePath?: string;
  mobileText?: string;
  className?: string;
  elem?: any;
  disabled?: boolean;
  primaryButtonText?: string;
  primaryButtonIcon?: IconName;
  secondaryButtonText?: string;
}

const PopoverEditRemove: React.FC<IPopoverEditRemoveProps> = (props) => {
  const {
    removeRequest,
    editRequest,
    mobilePath,
    mobileText,
    className,
    elem,
    disabled,
    primaryButtonText,
    secondaryButtonText,
    primaryButtonIcon
  } = props;

  const history = useHistory();

  const getEditButton = () =>
    editRequest && (
      <Button
        className="button__edit"
        text={primaryButtonText || 'Editar'}
        icon={primaryButtonIcon || 'edit'}
        intent="primary"
        minimal
        onClick={() => editRequest(elem)}
      />
    );

  const getRemoveButton = () =>
    removeRequest && (
      <Button
        className="button__remove"
        text={secondaryButtonText || 'Excluir'}
        icon="trash"
        intent="danger"
        minimal
        onClick={() => removeRequest(elem)}
      />
    );

  const getMobileButton = () =>
    mobilePath && (
      <Button
        className="mobile__button"
        text={mobileText}
        rightIcon="caret-right"
        intent="primary"
        minimal
        onClick={() => history.push(mobilePath)}
      />
    );

  const getOptions = () => (
    <Menu className="menu__list">
      {getMobileButton()}
      {getEditButton()}
      {getRemoveButton()}
    </Menu>
  );

  return (
    <Popover
      disabled={disabled}
      canEscapeKeyClose
      captureDismiss
      content={getOptions()}
      className={`${className} popover__component`}
      position={Position.BOTTOM}
    >
      <Icon icon="more" />
    </Popover>
  );
};

export default PopoverEditRemove;
