/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  peopleList: {
    fail: null,
    empty: false,
    loading: false,
    data: [],
  },
  allPeople: {
    fail: null,
    empty: false,
    loading: false,
    data: [],
  },
  createPerson: {
    fail: null,
    loading: false,
    message: null,
  },
  fastRegister: {
    loading: false,
    success: null,
    fail: null,
    id: '',
  }
};

const people: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_PEOPLE:
        draft.peopleList.loading = true;
        return;

      case c.FETCH_PEOPLE_SUCCESS:
        draft.peopleList.loading = false;
        if (payload.data && payload.data.result.length) {
          draft.peopleList.data = payload.data;
          draft.peopleList.empty = false;
        } else {
          draft.peopleList.empty = true;
        }
        return;

      case c.FETCH_PEOPLE_FAIL:
        draft.peopleList.loading = false;
        draft.peopleList.fail = payload.error;
        return;

      case c.FETCH_ALL_PEOPLE:
        draft.allPeople.loading = true;
        return;

      case c.FETCH_ALL_PEOPLE_SUCCESS:
        draft.allPeople.loading = false;
        if (payload.data && payload.data.length) {
          draft.allPeople.data = payload.data;
        } else {
          draft.allPeople.empty = true;
        }
        return;

      case c.FETCH_ALL_PEOPLE_FAIL:
        draft.allPeople.loading = false;
        draft.allPeople.fail = payload.error;
        return;

        case c.CLEAR_SUGGESTIONS_LIST:
          draft.allPeople.data = [];
          draft.allPeople.empty = true;
          return;

      case c.REGISTER_PERSON:
        draft.createPerson.loading = true;
        draft.createPerson.fail = null;
        draft.createPerson.message = null;
        return;

      case c.REGISTER_PERSON_SUCCESS:
        draft.createPerson.loading = false;
        draft.createPerson.fail = null;
        draft.createPerson.message = 'Pessoa cadastrada com sucesso!';
        return;

      case c.REGISTER_PERSON_FAIL:
        draft.createPerson.loading = false;
        draft.createPerson.message = null;
        draft.createPerson.fail = payload.error;
        return;

      case c.FAST_REGISTER_PERSON:
      draft.fastRegister.loading = true;
      draft.fastRegister.fail = null;
      draft.fastRegister.success = null;
        return;

      case c.FAST_REGISTER_PERSON_SUCCESS:
        draft.fastRegister.loading = false;
        draft.fastRegister.fail = null;
        if (payload.data) {
          draft.fastRegister.id = payload.data
        }
        if (payload.id) {
          draft.fastRegister.id = payload.id.data
        }
        draft.fastRegister.success = 'Pessoa cadastrada com sucesso!';
        return;

      case c.FAST_REGISTER_PERSON_FAIL:
        draft.fastRegister.loading = false;
        draft.fastRegister.success = null;
        draft.fastRegister.fail = payload.error;
        return;

      case c.CLEAR_CREATE_STATUS:
        draft.createPerson.message = null;
        draft.createPerson.fail = null;
        draft.fastRegister.fail = null;
        draft.fastRegister.success = null;
        return;

      default:
        return;
    }
  });

export default people;
