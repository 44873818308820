/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  schedulesByUser: {
    fail: null,
    loading: false,
    data: [],
  },
};

const scheduledashboard: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_SCHEDULES_BY_USER:
        draft.schedulesByUser.loading = true;
        draft.schedulesByUser.fail = null;
        return;

      case c.FETCH_SCHEDULES_BY_USER_SUCCESS:
        draft.schedulesByUser.loading = false;
        draft.schedulesByUser.fail = null;
        draft.schedulesByUser.data = payload.data;
        return;

      case c.FETCH_SCHEDULES_BY_USER_FAIL:
        draft.schedulesByUser.loading = false;
        draft.schedulesByUser.fail = payload.error;
        return;

      default:
        return;
    }
  });

export default scheduledashboard;
