/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  occupationTypes: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  occupationTypesPaginated: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  occupationTypeId: {
    data: null,
    loading: false,
    fail: null,
  },
  createOccupationType: {
    loading: false,
    fail: null,
    success: null,
  },
  updateOccupationType: {
    loading: false,
    fail: null,
    success: null,
  },
  inactivateOccupationType: {
    loading: false,
    fail: null,
    success: null,
  },
};

const metaCases: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_OCCUPATIONS_TYPE:
        draft.occupationTypes.loading = true;
        draft.occupationTypes.empty = true;
        draft.occupationTypes.fail = null;
        return;

      case c.FETCH_OCCUPATIONS_TYPE_SUCCESS:
        draft.occupationTypes.loading = false;
        draft.occupationTypes.fail = null;
        if (payload && payload.data.length) {
          draft.occupationTypes.data = payload.data;
          draft.occupationTypes.empty = false;
        }
        return;

      case c.FETCH_OCCUPATIONS_TYPE_FAIL:
        draft.occupationTypes.loading = false;
        draft.occupationTypes.fail = payload.error;
        draft.occupationTypes.data = payload.data;
        return;

      case c.FETCH_OCCUPATIONS_TYPE_PAGINATED:
        draft.occupationTypesPaginated.loading = true;
        draft.occupationTypesPaginated.empty = true;
        draft.occupationTypesPaginated.fail = null;
        return;

      case c.FETCH_OCCUPATIONS_TYPE_PAGINATED_SUCCESS:
        draft.occupationTypesPaginated.loading = false;
        draft.occupationTypesPaginated.fail = null;
        if (payload && payload.data.result.length) {
          draft.occupationTypesPaginated.data = payload.data;
          draft.occupationTypesPaginated.empty = false;
        }
        return;

      case c.FETCH_OCCUPATIONS_TYPE_PAGINATED_FAIL:
        draft.occupationTypesPaginated.loading = false;
        draft.occupationTypesPaginated.fail = payload.error;
        draft.occupationTypesPaginated.data = payload.data;
        return;

      case c.FETCH_OCCUPATIONS_TYPE_ID:
        draft.occupationTypeId.loading = true;
        draft.occupationTypeId.fail = null;
        return;

      case c.FETCH_OCCUPATIONS_TYPE_ID_SUCCESS:
        draft.occupationTypeId.loading = false;
        draft.occupationTypeId.fail = null;
        if (payload && payload.data.length) {
          draft.occupationTypeId.data = payload.data;
        }
        return;

      case c.FETCH_OCCUPATIONS_TYPE_ID_FAIL:
        draft.occupationTypeId.loading = false;
        draft.occupationTypeId.fail = payload.error;
        draft.occupationTypeId.data = payload.data;
        return;

      case c.CREATE_OCCUPATIONS_TYPE:
        draft.createOccupationType.loading = true;
        draft.createOccupationType.fail = null;
        draft.createOccupationType.success = null;
        return;

      case c.CREATE_OCCUPATIONS_TYPE_SUCCESS:
        draft.createOccupationType.loading = false;
        draft.createOccupationType.success = payload.data;
        return;

      case c.CREATE_OCCUPATIONS_TYPE_FAIL:
        draft.createOccupationType.loading = false;
        draft.createOccupationType.fail = payload.error;
        draft.createOccupationType.success = null;
        return;

      case c.UPDATE_OCCUPATIONS_TYPE:
        draft.updateOccupationType.loading = true;
        draft.updateOccupationType.fail = null;
        draft.updateOccupationType.success = null;
        return;

      case c.UPDATE_OCCUPATIONS_TYPE_SUCCESS:
        draft.updateOccupationType.loading = false;
        draft.updateOccupationType.fail = null;
        draft.updateOccupationType.success = payload.data;
        return;

      case c.UPDATE_OCCUPATIONS_TYPE_FAIL:
        draft.updateOccupationType.loading = false;
        draft.updateOccupationType.fail = payload.error;
        draft.updateOccupationType.success = null;
        return;

      case c.INACTIVATE_OCCUPATIONS_TYPE:
        draft.inactivateOccupationType.loading = true;
        draft.inactivateOccupationType.fail = null;
        draft.inactivateOccupationType.success = null;
        return;

      case c.INACTIVATE_OCCUPATIONS_TYPE_SUCCESS:
        draft.inactivateOccupationType.loading = false;
        draft.inactivateOccupationType.fail = null;
        draft.inactivateOccupationType.success = payload.data;
        return;

      case c.INACTIVATE_OCCUPATIONS_TYPE_FAIL:
        draft.inactivateOccupationType.loading = false;
        draft.inactivateOccupationType.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.createOccupationType.success = null;
        draft.updateOccupationType.success = null;
        draft.inactivateOccupationType.success = null;
        draft.createOccupationType.fail = null;
        draft.updateOccupationType.fail = null;
        draft.inactivateOccupationType.fail = null;
        return;

      default:
        return;
    }
  });

export default metaCases;
