/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';
import { DistrictType } from './types';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

export const clearAllStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_ALL_STATUS,
  });
};

export const fetchDistrictTypes = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_DISTRICT_TYPE,
    payload: {
      request: {
        url: '/counties',
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_DISTRICT_TYPE_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_DISTRICT_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchDistrictTypesPaginated = (offset: number, limit: number) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_DISTRICT_TYPE_PAGINATED,
    payload: {
      request: {
        url: `/counties/paginated?offset=${offset}&limit=${limit}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_DISTRICT_TYPE_PAGINATED_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_DISTRICT_TYPE_PAGINATED_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchDistrictTypesId = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_DISTRICT_TYPE_ID,
    payload: {
      request: {
        url: `/counties/${id}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_DISTRICT_TYPE_ID_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_DISTRICT_TYPE_ID_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const createDistrictType = (districtType: string, selectedState: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.CREATE_DISTRICT_TYPE,
    payload: {
      request: {
        url: '/counties',
        method: 'POST',
        data: { name: districtType, state: selectedState },
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.CREATE_DISTRICT_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de comarca criado com sucesso!',
            },
          });
          dispatch(fetchDistrictTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.CREATE_DISTRICT_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const updateDistrictType = (districtType: DistrictType) => (dispatch: Dispatch) => {
  dispatch({
    type: c.UPDATE_DISTRICT_TYPE,
    payload: {
      request: {
        url: `/counties/${districtType.county_id}`,
        method: 'PUT',
        data: { name: districtType.name, state: districtType.state }
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.UPDATE_DISTRICT_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de comarca atualizado com sucesso!',
            },
          });
          dispatch(fetchDistrictTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.UPDATE_DISTRICT_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const inactivateDistrictType = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_DISTRICT_TYPE,
    payload: {
      request: {
        url: `/counties/${id}/inactivate`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_DISTRICT_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de comarca arquivado com sucesso!',
            },
          });
          dispatch(fetchDistrictTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.INACTIVATE_DISTRICT_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};
