/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import './styles.css';
import { Checkbox, Button, Icon } from '@blueprintjs/core';
import update from 'immutability-helper';
import { RolePermissions, PostRole, EntityPermissions, GranularPermission } from '../../modules/UserGroups/types';
import { entitiesTranslates, isPermissionWrite } from '../../utils/permissionsTable';
import { EMPTY_PERMISSIONS, DEFAULT_PERMISSIONS, modulesIcons } from './constants';
import { ROLE_MODULE_NAME, WRITE_PERMISSION } from '../../modules/Permissions/constants';
import { verifyUserPermission, isNonRemovableRole } from '../../utils/permissions';

interface IPermissionsTable {
  newRole: PostRole;
  role: RolePermissions;
  callBack: React.SetStateAction<any>;
  create: boolean;
}

const PermissionsTable: React.FC<IPermissionsTable> = ({ newRole, role, callBack, create }) => {
  const handleCheck = (permissions: string, permissionIndex: string): boolean => permissions.includes(permissionIndex);
  const [checkAll, setCheckAll] = useState(false);
  const [alreadyGetOldPermissions, setAlreadyGetOldPermissions] = useState(false);
  const [oldPermissions, setOldPermissions] = useState(role.permissions);

  useEffect(() => {
    if (role.permissions.length) {
      if (!alreadyGetOldPermissions) {
        setAlreadyGetOldPermissions(true);
        setOldPermissions(role.permissions);
      }
    }
  }, [role, alreadyGetOldPermissions]);

  function getGranularPermissions(permissionIndex: keyof GranularPermission) {
    const granularPermissions: GranularPermission = {
      d: 'r&w&d',
      w: 'r&w',
      r: 'r',
    };
    return granularPermissions[permissionIndex];
  }

  const buildNewState = (permissionIndex: keyof GranularPermission, entityIndex: number, rolePermissions: string) => {
    let granularPermission = '';
    if (!rolePermissions.includes(permissionIndex)) {
      granularPermission = getGranularPermissions(permissionIndex);
    } else {
      isPermissionWrite(permissionIndex) ? (granularPermission = 'r') : (granularPermission = '');
    }
    return getUpdatedState(entityIndex, granularPermission);
  };

  const getUpdatedState = (entityIndex: number, granularPermission: string) =>
    !create
      ? update(role, {
          permissions: { [entityIndex]: { permission: { $set: granularPermission } } },
        })
      : update(newRole, {
          permissions: { [entityIndex]: { permission: { $set: granularPermission } } },
        });

  const handleChange = (permissionIndex: keyof GranularPermission, entityIndex: number) => {
    let rolePermissions = null;
    create
      ? (rolePermissions = newRole.permissions[entityIndex].permission)
      : (rolePermissions = role.permissions[entityIndex].permission);

    const newState = buildNewState(permissionIndex, entityIndex, rolePermissions);
    return callBack(newState);
  };

  const getRoleEntities = () =>
    role?.permissions.map(({ permission, entity }: EntityPermissions, entityIndex: number) => (
      <li key={`${entity.name}${entityIndex}`}>
        <div className="half">
          <Icon icon={modulesIcons[entityIndex]} /> {entitiesTranslates(entity.name)}
        </div>
        <div className="half">
          <Checkbox
            disabled={!verifyUserPermission(ROLE_MODULE_NAME, WRITE_PERMISSION) || isNonRemovableRole(role.role!.name)}
            key={`${entity.name}d`}
            checked={handleCheck(permission, 'd')}
            onChange={() => handleChange('d', entityIndex)}
          >
            Deletar
          </Checkbox>
          <Checkbox
            disabled={!verifyUserPermission(ROLE_MODULE_NAME, WRITE_PERMISSION) || isNonRemovableRole(role.role!.name)}
            key={`${entity.name}w`}
            checked={handleCheck(permission, 'w')}
            onChange={() => handleChange('w', entityIndex)}
          >
            Escrever
          </Checkbox>
          <Checkbox
            disabled={!verifyUserPermission(ROLE_MODULE_NAME, WRITE_PERMISSION) || isNonRemovableRole(role.role!.name)}
            key={`${entity.name}r`}
            checked={handleCheck(permission, 'r')}
            onChange={() => handleChange('r', entityIndex)}
          >
            Ler
          </Checkbox>
        </div>
      </li>
    ));

  const getEmptyEntities = () =>
    newRole?.permissions.map(({ permission, entity }: EntityPermissions, entityIndex: number) => (
      <li>
        <div className="half">
          <Icon icon={modulesIcons[entityIndex]} /> {entitiesTranslates(entity.name)}
        </div>
        <div className="half">
          <Checkbox
            disabled={!verifyUserPermission(ROLE_MODULE_NAME, WRITE_PERMISSION)}
            key={`${entity.name}d`}
            checked={handleCheck(permission, 'd')}
            onChange={() => handleChange('d', entityIndex)}
          >
            Deletar
          </Checkbox>
          <Checkbox
            disabled={!verifyUserPermission(ROLE_MODULE_NAME, WRITE_PERMISSION)}
            key={`${entity.name}w`}
            checked={handleCheck(permission, 'w')}
            onChange={() => handleChange('w', entityIndex)}
          >
            Escrever
          </Checkbox>
          <Checkbox
            disabled={!verifyUserPermission(ROLE_MODULE_NAME, WRITE_PERMISSION)}
            key={`${entity.name}r`}
            checked={handleCheck(permission, 'r')}
            onChange={() => handleChange('r', entityIndex)}
          >
            Ler
          </Checkbox>
        </div>
      </li>
    ));

  const defineCheckAllClass = () => (checkAll ? 'checkAll checkAll--white' : 'checkAll checkAll--blue');

  const handleCheckAll = () => {
    setCheckAll((state) => !state);
    if (!checkAll) {
      if (create) {
        const newState = update(newRole, { permissions: { $set: DEFAULT_PERMISSIONS } });
        callBack(newState);
      } else {
        const newState = update(role, { permissions: { $set: DEFAULT_PERMISSIONS } });
        callBack(newState);
      }
    } else if (create) {
      const newState = update(role, { permissions: { $set: EMPTY_PERMISSIONS } });
      callBack(newState);
    } else {
      const newState = update(role, { permissions: { $set: EMPTY_PERMISSIONS } });
      callBack(newState);
    }
  };

  const resetPermissions = () => {
    const newState = update(newRole, { permissions: { $set: oldPermissions } });
    callBack(newState);
  };

  return (
    <div className="permissionsTable__container">
      <ul>{create ? getEmptyEntities() : getRoleEntities()}</ul>
      {!isNonRemovableRole(role?.role?.name) && (
        <>
          <div className={defineCheckAllClass()}>
            <Checkbox checked={checkAll} onChange={handleCheckAll}>
              Marcar todas
            </Checkbox>
          </div>
          {!create && (
            <Button
              className="undo__changes"
              icon="undo"
              text="Desfazer alterações"
              onClick={() => resetPermissions()}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PermissionsTable;
