import React from 'react';
import Logo from '../../assets/images/grey-logo.png';
import './styles.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <a href="/login">
        <img src={Logo} alt="Logo" />
      </a>
      <div className="footer-text">
        <span>Luiz Carlos de Arruda Advogados 2019.</span>
        <span>Todos os direitos reservados.</span>
      </div>
    </footer>
  );
};

export default Footer;
