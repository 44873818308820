import React from 'react';
import Logo from '../../assets/images/white-logo.png';
import './styles.css';

const Header: React.FC = () => {
  return (
    <header className="header">
      <a href="/login">
        <img src={Logo} alt="Logo" />
      </a>
    </header>
  );
};

export default Header;
