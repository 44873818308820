import React from 'react';
import { Tabs as TTabs, Tab, ITabsProps, ITabProps } from '@blueprintjs/core';
import './styles.css';

type TabForList = {
  title: string;
  panel: ITabProps['panel'];
};

interface ITabs extends ITabsProps {
  id: string;
  tabs: TabForList[];
}

const Tabs: React.FC<ITabs> = (props) => {
  const { id, tabs } = props;

  const mapTabs = tabs.map((tab, i) => <Tab key={tab.title} id={i} title={tab.title} panel={tab.panel} />);

  return (
    <TTabs animate id={id} key="horizontal" large renderActiveTabPanelOnly>
      {mapTabs}
      <TTabs.Expander />
    </TTabs>
  );
};

export default Tabs;
