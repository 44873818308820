/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  users: {
    data: [],
    loading: false,
    fail: null,
  },
  usersWithPerson: {
    data: [],
    loading: false,
    fail: null,
  },
  usersPaginated: {
    data: [],
    loading: false,
    fail: null,
  },
  inactivate: {
    message: null,
    loading: false,
  },
  update: {
    message: null,
    loading: false,
  },
};

const groups: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_USERS:
        draft.users.loading = true;
        draft.users.fail = null;
        return;

      case c.FETCH_USERS_SUCCESS:
        draft.users.loading = false;
        draft.users.fail = null;
        if (payload && payload.data.length) {
          draft.users.data = payload.data;
        }
        return;

      case c.FETCH_USERS_FAIL:
        draft.users.loading = false;
        draft.users.fail = payload.error;
        draft.users.data = payload.data;
        return;

      case c.FETCH_USERS_PERSON:
        draft.usersWithPerson.loading = true;
        draft.users.fail = null;
        return;

      case c.FETCH_USERS_PERSON_SUCCESS:
        draft.usersWithPerson.loading = false;
        draft.usersWithPerson.fail = null;
        if (payload && payload.data.length) {
          draft.usersWithPerson.data = payload.data;
        }
        return;

      case c.FETCH_USERS_PERSON_FAIL:
        draft.usersWithPerson.loading = false;
        draft.usersWithPerson.fail = payload.error;
        return;

      case c.FETCH_USERS_PAGINATED:
        draft.usersPaginated.loading = true;
        draft.usersPaginated.fail = null;
        return;

      case c.FETCH_USERS_PAGINATED_SUCCESS:
        draft.usersPaginated.loading = false;
        draft.usersPaginated.fail = null;
        if (payload && payload.data.result.length) {
          draft.usersPaginated.data = payload.data;
        }
        return;

      case c.FETCH_USERS_PAGINATED_FAIL:
        draft.usersPaginated.loading = false;
        draft.usersPaginated.fail = payload.error;
        draft.usersPaginated.data = payload.data;
        return;

      case c.INACTIVATE_USER:
        draft.inactivate.loading = true;
        draft.inactivate.message = null;
        return;

      case c.INACTIVATE_USER_SUCCESS:
        draft.inactivate.loading = false;
        draft.inactivate.message = 'Usuário inativado com sucesso!';
        return;

      case c.INACTIVATE_USER_FAIL:
        draft.inactivate.loading = false;
        draft.inactivate.message = payload.error;
        draft.users.data = payload.data;
        return;

      case c.UPDATE_USER:
        draft.update.loading = true;
        draft.update.message = null;
        return;

      case c.UPDATE_USER_SUCCESS:
        draft.update.loading = false;
        draft.update.message = 'Usuário atualizado com sucesso!';
        return;

      case c.UPDATE_USER_FAIL:
        draft.update.loading = false;
        draft.update.message = payload.error;
        draft.users.data = payload.data;
        return;

      default:
        return;
    }
  });

export default groups;
