/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import { Dispatch } from 'redux';
import * as c from './constants';
import { PostRole } from '../UserGroups/types';
import { UpdateEntity } from './types';
import handleApiError from '../../utils/errors';

export const fetchEntities = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_ENTITIES,
    payload: {
      request: {
        url: '/roles/entities',
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          const res = response.data;
          dispatch({
            type: c.FETCH_ENTITIES_SUCCESS,
            payload: {
              data: res,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.FETCH_ENTITIES_FAIL,
            payload: {
              error: 'Ops! Tivemos algump problema durante o login, tente novamente.',
            },
          });
        },
      },
    },
  });
};

export const fetchEntitiesById = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_ENTITIES_BY_ID,
    payload: {
      request: {
        url: `/roles/${id}/permissions`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_ENTITIES_BY_ID_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.FETCH_ENTITIES_BY_ID_FAIL,
            payload: {
              error: 'Ops! Tivemos algump problema durante o login, tente novamente.',
            },
          });
        },
      },
    },
  });
};

export const updateRole = (newPermissions: UpdateEntity[], roleId?: string ) => (dispatch: Dispatch) => {
  dispatch({
    type: c.UPDATE_ROLE,
    payload: {
      request: {
        url: `/roles/${roleId}/permissions`,
        method: 'PUT',
        data: newPermissions,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.UPDATE_ROLE_SUCCESS,
            payload: {
              data: response,
            },
          });
          clearAllStatus();
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.UPDATE_ROLE_FAIL,
            payload: {
              error: handleApiError(error.toString()),
            },
          });
          clearAllStatus();
        },
      },
    },
  });
};

export const createRole = (role: PostRole) => (dispatch: Dispatch) => {
  dispatch({
    type: c.CREATE_ROLE,
    payload: {
      request: {
        url: `/roles/`,
        method: 'POST',
        data: role,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.CREATE_ROLE_SUCCESS,
            payload: {
              data: response,
            },
          });
          clearAllStatus();
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.CREATE_ROLE_FAIL,
            payload: {
              error: handleApiError(error.toString()),
            },
          });
          clearAllStatus();
        },
      },
    },
  });
};

export const inactivateRole = (role: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_ROLE,
    payload: {
      request: {
        url: `/roles/${role}`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_ROLE_SUCCESS,
            payload: {
              data: response,
            },
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.INACTIVATE_ROLE_FAIL,
            payload: {
              error: 'Ops! Tivemos algump problema durante a atualização.',
            },
          });
        },
      },
    },
  });
};

export const clearAllStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_ALL_STATUS,
  });
};
