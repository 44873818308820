/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  caseProgresses: {
    fail: null,
    empty: true,
    loading: false,
    data: [],
  },
  progressById: {
    fail: null,
    loading: false,
    data: {},
  },
  updateCaseProgress: {
    fail: null,
    loading: false,
    message: null,
  },
  inactivateCaseProgress: {
    fail: null,
    loading: false,
    message: null,
  },
  createCaseProgress: {
    fail: null,
    loading: false,
    message: null,
  },
  addDocument: {
    fail: null,
    loading: false,
    message: null,
  },
};

const person: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_PROGRESS:
        draft.caseProgresses.loading = true;
        draft.caseProgresses.fail = null;
        return;

      case c.FETCH_PROGRESS_SUCCESS:
        draft.caseProgresses.loading = false;
        draft.caseProgresses.fail = null;
        if (payload.data && payload.data.length) {
          draft.caseProgresses.empty = false;
        }
        draft.caseProgresses.data = payload.data;
        return;

      case c.FETCH_PROGRESS_FAIL:
        draft.caseProgresses.loading = false;
        draft.caseProgresses.fail = 'Houve uma falha ao buscar esta pessoa!';
        return;

      case c.FETCH_PROGRESS_BY_ID:
        draft.progressById.loading = true;
        draft.progressById.fail = null;
        return;

      case c.FETCH_PROGRESS_BY_ID_SUCCESS:
        draft.progressById.loading = false;
        draft.progressById.fail = null;
        draft.progressById.data = payload.data;
        return;

      case c.FETCH_PROGRESS_BY_ID_FAIL:
        draft.progressById.loading = false;
        draft.progressById.fail = 'Houve uma falha ao buscar esta pessoa!';
        return;

      case c.CREATE_PROGRESS:
        draft.createCaseProgress.loading = true;
        draft.createCaseProgress.message = null;
        draft.createCaseProgress.fail = null;
        return;

      case c.CREATE_PROGRESS_SUCCESS:
        draft.createCaseProgress.loading = false;
        draft.createCaseProgress.message = 'Relação criada com sucesso!';
        return;

      case c.CREATE_PROGRESS_FAIL:
        draft.createCaseProgress.loading = false;
        draft.createCaseProgress.fail = payload.error;
        return;

      case c.CREATE_PROGRESS_DOCUMENT:
        draft.addDocument.loading = true;
        draft.addDocument.message = null;
        draft.addDocument.fail = null;
        return;

      case c.CREATE_PROGRESS_DOCUMENT_SUCCESS:
        draft.addDocument.loading = false;
        draft.addDocument.message = 'Documento anexado com sucesso!';
        return;

      case c.CREATE_PROGRESS_DOCUMENT_FAIL:
        draft.addDocument.loading = false;
        draft.addDocument.fail = payload.error;
        return;

      case c.UPDATE_PROGRESS:
        draft.updateCaseProgress.loading = true;
        draft.updateCaseProgress.fail = null;
        draft.updateCaseProgress.message = null;
        return;

      case c.UPDATE_PROGRESS_SUCCESS:
        draft.updateCaseProgress.loading = false;
        draft.updateCaseProgress.message = 'Andamento atualizado com sucesso!';
        return;

      case c.UPDATE_PROGRESS_FAIL:
        draft.updateCaseProgress.loading = false;
        draft.updateCaseProgress.message = null;
        draft.updateCaseProgress.fail = payload.error;
        return;

      case c.INACTIVATE_PROGRESS:
        draft.inactivateCaseProgress.loading = true;
        draft.inactivateCaseProgress.fail = null;
        draft.inactivateCaseProgress.message = null;
        return;

      case c.INACTIVATE_PROGRESS_SUCCESS:
        draft.inactivateCaseProgress.loading = false;
        draft.inactivateCaseProgress.message = 'Andamento atualizado com sucesso!';
        draft.inactivateCaseProgress.fail = null;
        return;

      case c.INACTIVATE_PROGRESS_FAIL:
        draft.inactivateCaseProgress.loading = false;
        draft.inactivateCaseProgress.message = null;
        draft.inactivateCaseProgress.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.inactivateCaseProgress.message = null;
        draft.inactivateCaseProgress.fail = null;
        draft.updateCaseProgress.message = null;
        draft.updateCaseProgress.fail = null;
        draft.addDocument.message = null;
        draft.addDocument.fail = null;
        draft.createCaseProgress.message = null;
        draft.createCaseProgress.fail = null;
        return;

      default:
        return;
    }
  });

export default person;
