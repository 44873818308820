/* eslint-disable @typescript-eslint/camelcase */
import React, { SyntheticEvent, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { getValuesFromForm } from '../../utils/form';
import createToaster from '../../components/Toaster';
import { reducers } from '../../store/reducers';
import { fetchResetPassword } from './actions';
import { goToHere } from '../../utils/navigation';
import './styles.css';

type Match = {
  path: string;
  url: string;
  isExact: boolean;
  params: any;
};

interface IPermissionsProps {
  match: Match;
}

const ResetPassword: React.FC<IPermissionsProps> = ({ match }, props) => {
  const dispatch = useDispatch();
  const { fail, loading, message } = useSelector((state: typeof reducers) => state.resetpassword);
  const [pass, setPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');

  const passToken = new URLSearchParams(useLocation().search).get('token');
  if (passToken) {
    sessionStorage.setItem('PASS_TOKEN', passToken);
  }

  const resetPassword = (e: SyntheticEvent) => {
    e.preventDefault();
    if (e.target instanceof HTMLFormElement) {
      const inputValues = getValuesFromForm(e.target);
      dispatch(fetchResetPassword(inputValues[0]));
    }
  };

  const onChangePass = (e: ChangeEvent<HTMLInputElement>) => setPass(e.target.value);
  const onChangeConfirmPass = (e: ChangeEvent<HTMLInputElement>) => setConfirmPass(e.target.value);

  if (fail || message) {
    const showToast = createToaster({
      message: fail || message,
      intent: fail ? 'danger' : 'success',
      icon: fail ? 'warning-sign' : 'tick',
    });
    showToast();
    setTimeout(() => {
      goToHere('/login');
    }, 1500);
  }

  const validPassword = () => pass === confirmPass;

  return (
    <>
      <Header />
      <div className="container" id="reset-password">
        <h2>Digite sua nova senha</h2>
        <form onSubmit={resetPassword}>
          <Input onChange={onChangePass} value={pass} placeholder="Nova senha" type="password" />
          <Input onChange={onChangeConfirmPass} value={confirmPass} placeholder="Confirmação da senha" type="password" />
          <Button name="primary" disabled={!validPassword()} loading={loading} intent="primary">
            Redefinir senha
          </Button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default ResetPassword;
