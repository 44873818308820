import React, { useState } from 'react';
import { Navbar, NavbarGroup, NavbarDivider, Button, NavbarHeading, Alignment } from '@blueprintjs/core';
import { Switch } from 'react-router-dom';
import Logo from '../../logo.svg';
import './styles.css';
import '../../template.css';
import routes from '../../routes';
import { endWithRoutes, includeStringRoutes } from '../../utils/home';
import { setActiveNavTab, goToHere } from '../../utils/navigation';
import RouteContent from '../../components/RouteContent';
import MobileMenu from '../../components/MobileMenu';
import { logout, getHomePageConsideringUserPermissions } from '../../utils/auth';
import {
  SCHEDULE_MODULE_NAME,
  READ_PERMISSION,
  PEOPLE_MODULE_NAME,
  CASES_MODULE_NAME,
  ROLE_MODULE_NAME,
  META_MODULE_NAME,
} from '../Permissions/constants';
import { verifyUserPermission, isExternalLawyer } from '../../utils/permissions';
import ConfirmationDialog from '../../components/ConfirmationDialog';

const Home: React.FC = () => {
  const buttonRoutes = endWithRoutes(routes, 2);
  const contentRoutes = includeStringRoutes(routes, 3);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  return (
    <div className="container">
      <ConfirmationDialog
        headerText="Tem certeza que deseja sair do sistema?"
        confirmMethod={logout}
        setIsOpen={setIsDialogOpen}
        isOpen={isDialogOpen}
      />
      <Navbar className="navbar">
        <NavbarGroup align={Alignment.LEFT} className="nav__group">
          <NavbarHeading className="heading">
            <img src={Logo} alt="Logo" onClick={() => getHomePageConsideringUserPermissions()} />
          </NavbarHeading>
          <NavbarDivider />
          {verifyUserPermission(SCHEDULE_MODULE_NAME, READ_PERMISSION) && (
            <Button
              className={setActiveNavTab('/agenda')}
              icon="calendar"
              text="Agenda"
              onClick={() => goToHere(`/painel/agenda`)}
            />
          )}
          {verifyUserPermission(PEOPLE_MODULE_NAME, READ_PERMISSION) && (
            <Button
              className={setActiveNavTab('/pessoas/lista')}
              icon="people"
              text="Pessoas"
              onClick={() => goToHere(`/painel/pessoas/lista`)}
            />
          )}
          {verifyUserPermission(CASES_MODULE_NAME, READ_PERMISSION) && (
            <Button
              className={setActiveNavTab('/painel/casos')}
              icon="box"
              text="Casos"
              onClick={() => goToHere(`/painel/casos`)}
            />
          )}
          {verifyUserPermission(ROLE_MODULE_NAME, READ_PERMISSION) && !isExternalLawyer() && (
            <Button
              className={setActiveNavTab('/grupos')}
              icon="user"
              text="Grupos e Usuários"
              onClick={() => goToHere(`/painel/grupos/lista`)}
            />
          )}
          {verifyUserPermission(META_MODULE_NAME, READ_PERMISSION) && (
            <Button
              className={setActiveNavTab('/meta')}
              icon="database"
              text="Meta-Dados"
              onClick={() => goToHere(`/painel/meta/pessoas`)}
            />
          )}
          <div>
            <Button className="logout__button" onClick={() => setIsDialogOpen(true)}>
              Sair
            </Button>
          </div>
        </NavbarGroup>
      </Navbar>
      <MobileMenu />
      <nav className="sidebar" id="sidebar">
        <Switch>
          <RouteContent routes={buttonRoutes} />
        </Switch>
      </nav>
      <nav className="nav__tabs">
        <div className="bp3-tabs tabs__modifier">
          <ul className="bp3-tab-list" role="tablist">
            <Switch>
              <RouteContent routes={buttonRoutes} />
            </Switch>
          </ul>
        </div>
      </nav>
      <main className="main__content">
        <Switch>
          <RouteContent routes={contentRoutes} />
        </Switch>
      </main>
    </div>
  );
};

export default Home;
