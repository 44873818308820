import { IBreadcrumbProps } from '@blueprintjs/core';

export const FETCH_PEOPLE = 'FETCH_PEOPLE';
export const FETCH_PEOPLE_SUCCESS = 'FETCH_PEOPLE_SUCCESS';
export const FETCH_PEOPLE_FAIL = 'FETCH_PEOPLE_FAIL';

export const FETCH_ALL_PEOPLE = 'FETCH_ALL_PEOPLE';
export const FETCH_ALL_PEOPLE_SUCCESS = 'FETCH_ALL_PEOPLE_SUCCESS';
export const FETCH_ALL_PEOPLE_FAIL = 'FETCH_ALL_PEOPLE_FAIL';
export const CLEAR_SUGGESTIONS_LIST = 'CLEAR_SUGGESTIONS_LIST';

export const REGISTER_PERSON = 'REGISTER_PERSON';
export const REGISTER_PERSON_SUCCESS = 'REGISTER_PERSON_SUCCESS';
export const REGISTER_PERSON_FAIL = 'REGISTER_PERSON_FAIL';

export const FAST_REGISTER_PERSON = 'FAST_REGISTER_PERSON';
export const FAST_REGISTER_PERSON_SUCCESS = 'FAST_REGISTER_PERSON_SUCCESS';
export const FAST_REGISTER_PERSON_FAIL = 'FAST_REGISTER_PERSON_FAIL';

export const CLEAR_CREATE_STATUS = 'CLEAR_CREATE_STATUS';

export const COLUMNS = ['Cpf/Cnpj', 'Email'];

export const PATH = '/pessoas/lista';
export const DEFAULT_PATH = '/painel/pessoas';

export const BREADCRUMB_PATHS: IBreadcrumbProps[] = [
  { href: '/painel/pessoas/lista', text: 'Pessoas' },
  window.location.pathname.includes('lista')
    ? { href: '/painel/pessoas/lista', text: 'Listagem' }
    : { href: '/painel/pessoas/lista', text: 'Listagem' },
];
