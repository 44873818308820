/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  phoneTypes: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  phoneTypesPaginated: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  phoneTypeId: {
    data: null,
    loading: false,
    fail: null,
  },
  createPhoneType: {
    loading: false,
    fail: null,
    success: null,
  },
  updatePhoneType: {
    loading: false,
    fail: null,
    success: null,
  },
  inactivatePhoneType: {
    loading: false,
    fail: null,
    success: null,
  },
};

const metaCases: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_PHONES_TYPE:
        draft.phoneTypes.loading = true;
        draft.phoneTypes.empty = true;
        draft.phoneTypes.fail = null;
        return;

      case c.FETCH_PHONES_TYPE_SUCCESS:
        draft.phoneTypes.loading = false;
        draft.phoneTypes.fail = null;
        if (payload && payload.data.length) {
          draft.phoneTypes.data = payload.data;
          draft.phoneTypes.empty = false;
        }
        return;

      case c.FETCH_PHONES_TYPE_FAIL:
        draft.phoneTypes.loading = false;
        draft.phoneTypes.fail = payload.error;
        draft.phoneTypes.data = payload.data;
        return;

      case c.FETCH_PHONES_TYPE_PAGINATED:
        draft.phoneTypesPaginated.loading = true;
        draft.phoneTypesPaginated.empty = true;
        draft.phoneTypesPaginated.fail = null;
        return;

      case c.FETCH_PHONES_TYPE_PAGINATED_SUCCESS:
        draft.phoneTypesPaginated.loading = false;
        draft.phoneTypesPaginated.fail = null;
        if (payload && payload.data.result.length) {
          draft.phoneTypesPaginated.data = payload.data;
          draft.phoneTypesPaginated.empty = false;
        }
        return;

      case c.FETCH_PHONES_TYPE_PAGINATED_FAIL:
        draft.phoneTypesPaginated.loading = false;
        draft.phoneTypesPaginated.fail = payload.error;
        draft.phoneTypesPaginated.data = payload.data;
        return;

      case c.FETCH_PHONES_TYPE_ID:
        draft.phoneTypeId.loading = true;
        draft.phoneTypeId.fail = null;
        return;

      case c.FETCH_PHONES_TYPE_ID_SUCCESS:
        draft.phoneTypeId.loading = false;
        draft.phoneTypeId.fail = null;
        if (payload && payload.data.length) {
          draft.phoneTypeId.data = payload.data;
        }
        return;

      case c.FETCH_PHONES_TYPE_ID_FAIL:
        draft.phoneTypeId.loading = false;
        draft.phoneTypeId.fail = payload.error;
        draft.phoneTypeId.data = payload.data;
        return;

      case c.CREATE_PHONES_TYPE:
        draft.createPhoneType.loading = true;
        draft.createPhoneType.fail = null;
        draft.createPhoneType.success = null;
        return;

      case c.CREATE_PHONES_TYPE_SUCCESS:
        draft.createPhoneType.loading = false;
        draft.createPhoneType.success = payload.data;
        return;

      case c.CREATE_PHONES_TYPE_FAIL:
        draft.createPhoneType.loading = false;
        draft.createPhoneType.fail = payload.error;
        draft.createPhoneType.success = null;
        return;

      case c.UPDATE_PHONES_TYPE:
        draft.updatePhoneType.loading = true;
        draft.updatePhoneType.fail = null;
        draft.updatePhoneType.success = null;
        return;

      case c.UPDATE_PHONES_TYPE_SUCCESS:
        draft.updatePhoneType.loading = false;
        draft.updatePhoneType.fail = null;
        draft.updatePhoneType.success = payload.data;
        return;

      case c.UPDATE_PHONES_TYPE_FAIL:
        draft.updatePhoneType.loading = false;
        draft.updatePhoneType.fail = payload.error;
        draft.updatePhoneType.success = null;
        return;

      case c.INACTIVATE_PHONES_TYPE:
        draft.inactivatePhoneType.loading = true;
        draft.inactivatePhoneType.fail = null;
        draft.inactivatePhoneType.success = null;
        return;

      case c.INACTIVATE_PHONES_TYPE_SUCCESS:
        draft.inactivatePhoneType.loading = false;
        draft.inactivatePhoneType.fail = null;
        draft.inactivatePhoneType.success = payload.data;
        return;

      case c.INACTIVATE_PHONES_TYPE_FAIL:
        draft.inactivatePhoneType.loading = false;
        draft.inactivatePhoneType.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.createPhoneType.success = null;
        draft.updatePhoneType.success = null;
        draft.inactivatePhoneType.success = null;
        draft.createPhoneType.fail = null;
        draft.updatePhoneType.fail = null;
        draft.inactivatePhoneType.fail = null;
        return;

      default:
        return;
    }
  });

export default metaCases;
