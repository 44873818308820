/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import update from 'immutability-helper';
import { Icon } from '@blueprintjs/core';
import { generatePageNumbers } from '../../utils/pagination';
import './styles.css';
import { DEFAULT_PER_PAGE_LIMIT, DEFAULT_DISPLAYED_PAGES } from './constants';
import { FullPersonDataPaginated } from '../../modules/PeopleList/types';

interface IPaginationProps {
  request: Function;
  data: FullPersonDataPaginated;
  loading: boolean;
  optionsPath?: string;
}

const Pagination: React.FC<IPaginationProps> = ({ request, loading, data, optionsPath }) => {
  const dispatch = useDispatch();
  const [pageNumbers, setPageNumbers] = useState<number[]>();

  useEffect(() => {
    if (data?.count) {
      const pagesArray = update([], { $set: generatePageNumbers(data.count, DEFAULT_PER_PAGE_LIMIT) });
      setPageNumbers(pagesArray);
    }
  }, [data]);

  const handleClick = (pageNumber: number) => dispatch(request(pageNumber, DEFAULT_PER_PAGE_LIMIT, optionsPath || ''));

  const setActivePage = (pageNumber: number) => pageNumber === data.offset && 'current';

  const handleNext = () => dispatch(request(data.offset + 1, DEFAULT_PER_PAGE_LIMIT, optionsPath || ''));

  const handlePrevious = () => dispatch(request(data.offset - 1, DEFAULT_PER_PAGE_LIMIT, optionsPath || ''));

  const isPageOverflow = () => pageNumbers && pageNumbers.length > DEFAULT_DISPLAYED_PAGES;

  const renderNumbers = () => {
    if (pageNumbers && isPageOverflow()) {
      const beginNumber = Math.sign(data?.offset - 3) === -1 ? 0 : data?.offset - 3;
      const endNumber = Math.sign(data?.offset - 3) === -1 ? 5 : data?.offset + 2;
      return pageNumbers?.slice(beginNumber, endNumber).map((number) => (
        <li key={number}>
          <button type="button" onClick={() => handleClick(number)} className={`${setActivePage(number)}`}>
            {number}
          </button>
        </li>
      ));
    } else {
      return pageNumbers?.map((number) => (
        <li key={number}>
          <button type="button" onClick={() => handleClick(number)} className={`${setActivePage(number)}`}>
            {number}
          </button>
        </li>
      ));
    }
  };

  const fetchFirstPage = () => dispatch(request(1, DEFAULT_PER_PAGE_LIMIT, optionsPath || ''));

  const fetchLastPage = () => dispatch(request(pageNumbers?.length, DEFAULT_PER_PAGE_LIMIT, optionsPath || ''));

  return (
    <>
      {!loading && pageNumbers && (
        <div className="pagination">
          <ul>
            {isPageOverflow() && (
              <li>
                <button className="default__button" type="button" onClick={() => fetchFirstPage()}>
                  Primeira
                </button>
              </li>
            )}
            <li>
              <button
                className="default__button"
                disabled={!data.has_previous}
                type="button"
                onClick={() => handlePrevious()}
              >
                <Icon icon="caret-left" />
                Anterior
              </button>
            </li>
            {renderNumbers()}
            <li>
              <button className="default__button" disabled={!data.has_next} type="button" onClick={() => handleNext()}>
                Próxima
                <Icon className="right" icon="caret-right" />
              </button>
            </li>
            {isPageOverflow() && (
              <li>
                <button className="default__button" type="button" onClick={() => fetchLastPage()}>
                  Última
                </button>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default Pagination;
