/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Button } from '@blueprintjs/core';
import '../../template.css';
import './styles.css';
import { setButtonArrowActive, setActiveButton } from '../../utils/button';
import { USER_GROUPS_PATH, USERS_PATH } from './constants';
import { isMobile } from '../../utils/window';
import { isCurrentRoute, goToHere } from '../../utils/navigation';
import { verifyUserPermission } from '../../utils/permissions';
import { ROLE_MODULE_NAME, WRITE_PERMISSION } from '../Permissions/constants';

const Groups: React.FC = () => {
  return (
    <>
      {!isMobile() ? (
        <div className="sidebar__container">
          {verifyUserPermission(ROLE_MODULE_NAME, WRITE_PERMISSION) && (
            <Button
              icon="add"
              onClick={() => goToHere('/painel/grupos/lista/criar')}
              name="add"
              className="sidebar__button--blue full__width"
              text="Criar grupo de usuário"
            />
          )}
          <button
            type="button"
            aria-label="Usuários"
            className={setActiveButton(USERS_PATH)}
            onClick={() => goToHere(USERS_PATH)}
          >
            Usuários
            <div className={setButtonArrowActive(USERS_PATH)} />
          </button>
          <button
            type="button"
            aria-label="Grupos de usuários"
            className={setActiveButton(USER_GROUPS_PATH)}
            onClick={() => goToHere(USER_GROUPS_PATH)}
          >
            Grupos de usuários
            <div className={setButtonArrowActive(USER_GROUPS_PATH)} />
          </button>
        </div>
      ) : (
        <>
          <li className="bp3-tab" role="tab" aria-selected={isCurrentRoute(USERS_PATH)} onClick={() => goToHere(USERS_PATH)}>
            Usuários
          </li>
          <li
            className="bp3-tab"
            role="tab"
            aria-selected={isCurrentRoute(USER_GROUPS_PATH)}
            onClick={() => goToHere(USER_GROUPS_PATH)}
          >
            Grupos de usuários
          </li>
        </>
      )}
    </>
  );
};

export default Groups;
