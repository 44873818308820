import React from 'react';
import Tabs from '../../components/Tabs';
import Phones from '../MetaPeoplePhones';
import Occupations from '../MetaPeopleOccupations';
import MetaPeopleActivities from '../MetaPeopleActivities';

const MetaPeople: React.FC = () => {
  const tabsList = [
    {
      title: 'Telefones',
      panel: <Phones />,
    },
    {
      title: 'Profissões',
      panel: <Occupations />,
    },
    {
      title: 'Atividades',
      panel: <MetaPeopleActivities />,
    },
  ];

  return (
    <div className="background__container meta__data">
      <h1>Use o menu abaixo para alterar entre as tabelas</h1>
      <Tabs id="PeopleTabs" tabs={tabsList}>
        {' '}
      </Tabs>
    </div>
  );
};

export default MetaPeople;
