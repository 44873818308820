/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';
import { PostCase } from './types';
import { goToHere } from '../../utils/navigation';


export const createCase = (legalCase: PostCase) => (dispatch: Dispatch) => {
  dispatch({
    type: c.CREATE_CASE,
    payload: {
      request: {
        url: '/legal_cases',
        method: 'POST',
        data: legalCase,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.CREATE_CASE_SUCCESS,
            payload: {
              data: 'Caso criado com sucesso!',
            },
          });
          setTimeout(() => {
            goToHere('/painel/casos');
          }, 1000);
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.CREATE_CASE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchLawyers = (path: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_LAWYERS,
    payload: {
      request: {
        url: `/lawyers?${path}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_LAWYERS_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_LAWYERS_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchLawyerByPersonId = (person_id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_LAWYER_BY_PERSON_ID,
    payload: {
      request: {
        url: `/lawyers/person/${person_id}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_LAWYER_BY_PERSON_ID_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_LAWYER_BY_PERSON_ID_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};
