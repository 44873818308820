/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  progressTypes: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  progressTypesPaginated: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  progressTypeId: {
    data: null,
    loading: false,
    fail: null,
  },
  createProgressType: {
    loading: false,
    fail: null,
    success: null,
  },
  updateProgressType: {
    loading: false,
    fail: null,
    success: null,
  },
  inactivateProgressType: {
    loading: false,
    fail: null,
    success: null,
  },
};

const metaCases: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_PROGRESS_TYPE:
        draft.progressTypes.loading = true;
        draft.progressTypes.empty = true;
        draft.progressTypes.fail = null;
        return;

      case c.FETCH_PROGRESS_TYPE_SUCCESS:
        draft.progressTypes.loading = false;
        draft.progressTypes.fail = null;
        if (payload && payload.data.length) {
          draft.progressTypes.data = payload.data;
          draft.progressTypes.empty = false;
        }
        return;

      case c.FETCH_PROGRESS_TYPE_FAIL:
        draft.progressTypes.loading = false;
        draft.progressTypes.fail = payload.error;
        draft.progressTypes.data = payload.data;
        return;

      case c.FETCH_PROGRESS_TYPE_PAGINATED:
        draft.progressTypesPaginated.loading = true;
        draft.progressTypesPaginated.empty = true;
        draft.progressTypesPaginated.fail = null;
        return;

      case c.FETCH_PROGRESS_TYPE_PAGINATED_SUCCESS:
        draft.progressTypesPaginated.loading = false;
        draft.progressTypesPaginated.fail = null;
        if (payload && payload.data.result.length) {
          draft.progressTypesPaginated.data = payload.data;
          draft.progressTypesPaginated.empty = false;
        }
        return;

      case c.FETCH_PROGRESS_TYPE_PAGINATED_FAIL:
        draft.progressTypesPaginated.loading = false;
        draft.progressTypesPaginated.fail = payload.error;
        draft.progressTypesPaginated.data = payload.data;
        return;

      case c.FETCH_PROGRESS_TYPE_ID:
        draft.progressTypeId.loading = true;
        draft.progressTypeId.fail = null;
        return;

      case c.FETCH_PROGRESS_TYPE_ID_SUCCESS:
        draft.progressTypeId.loading = false;
        draft.progressTypeId.fail = null;
        if (payload && payload.data) {
          draft.progressTypeId.data = payload.data;
        }
        return;

      case c.FETCH_PROGRESS_TYPE_ID_FAIL:
        draft.progressTypeId.loading = false;
        draft.progressTypeId.fail = payload.error;
        draft.progressTypeId.data = payload.data;
        return;

      case c.CREATE_PROGRESS_TYPE:
        draft.createProgressType.loading = true;
        draft.createProgressType.fail = null;
        draft.createProgressType.success = null;
        return;

      case c.CREATE_PROGRESS_TYPE_SUCCESS:
        draft.createProgressType.loading = false;
        draft.createProgressType.fail = null;
        draft.createProgressType.success = payload.data;
        return;

      case c.CREATE_PROGRESS_TYPE_FAIL:
        draft.createProgressType.loading = false;
        draft.createProgressType.fail = payload.error;
        draft.createProgressType.success = null;
        return;

      case c.UPDATE_PROGRESS_TYPE:
        draft.updateProgressType.loading = true;
        draft.updateProgressType.fail = null;
        draft.updateProgressType.success = null;
        return;

      case c.UPDATE_PROGRESS_TYPE_SUCCESS:
        draft.updateProgressType.loading = false;
        draft.updateProgressType.fail = null;
        draft.updateProgressType.success = payload.data;
        return;

      case c.UPDATE_PROGRESS_TYPE_FAIL:
        draft.updateProgressType.loading = false;
        draft.updateProgressType.fail = payload.error;
        draft.updateProgressType.success = null;
        return;

      case c.INACTIVATE_PROGRESS_TYPE:
        draft.inactivateProgressType.loading = true;
        draft.inactivateProgressType.fail = null;
        draft.inactivateProgressType.success = null;
        return;

      case c.INACTIVATE_PROGRESS_TYPE_SUCCESS:
        draft.inactivateProgressType.loading = false;
        draft.inactivateProgressType.fail = null;
        draft.inactivateProgressType.success = payload.data;
        return;

      case c.INACTIVATE_PROGRESS_TYPE_FAIL:
        draft.inactivateProgressType.loading = false;
        draft.inactivateProgressType.fail = payload.error;
        draft.inactivateProgressType.success = null;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.createProgressType.success = null;
        draft.updateProgressType.success = null;
        draft.inactivateProgressType.success = null;
        draft.createProgressType.fail = null;
        draft.updateProgressType.fail = null;
        draft.inactivateProgressType.fail = null;
        return;

      default:
        return;
    }
  });

export default metaCases;
