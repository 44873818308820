/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { combineReducers } from 'redux';

// @ts-ignore
const context = require.context('../', true, /reducer\.tsx?/);

// auto importing all reducer.ts files
export const reducers: { [key: string]: any } = context.keys().reduce(
  (result: {}, current: string) => ({
    ...result,
    [current.split('/').slice(-2)[0].toLowerCase()]: context(current).default,
  }),
  {}
);

export default combineReducers(reducers);
