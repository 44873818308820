import React from 'react';
import './styles.css';
import { CreateUser, UpdateUser } from '../RegisterUser/types';
import { Role } from '../../modules/UserGroups/types';
import { JusticeType } from '../../modules/MetaCasesJusticeType/types';
import { CourtType } from '../../modules/MetaCasesCourtType/types';
import { OccupationType } from '../../modules/MetaPeopleOccupations/types';
import { ActivityType } from '../../modules/MetaPeopleActivities/types';
import { SecretaryType } from '../../modules/MetaCasesSecretaryType/types';
import { ProgressStatus } from '../../modules/MetaCasesProgressStatus/types';
import { TagType } from '../../modules/MetaCasesTag/types';
import { PhoneType } from '../../modules/MetaPeoplePhones/types';
import { FullPersonData } from '../../modules/PeopleList/types';
import { DistrictType } from '../../modules/MetaCasesDistrictType/types';
import { ConnectedPeople } from '../../modules/PeopleConnectedPeople/types';
import { LegalCasePersonType } from '../../modules/MetaCasesLegalCasePerson/types';
import { ProgressType } from '../../modules/MetaCasesProgressType/types';
import { DocumentType } from '../../modules/MetaDocuments/types';
import { UserWithPerson } from '../../modules/Users/types';
import { GetCaseProgress } from '../../modules/CaseRelatedProgress/types';

interface ISelectorProps {
  options?: string[];
  className?: string;
  value?: string;
  setValue?: any;
  roles?: Role[];
  userValue?: CreateUser | UpdateUser;
  justiceTypes?: JusticeType[];
  courtTypes?: CourtType[];
  statesList?: boolean;
  occupationTypes?: OccupationType[];
  activityTypes?: ActivityType[];
  secretaries?: SecretaryType[];
  caseProgressSituation?: ProgressStatus[];
  progressTypes?: ProgressType[];
  tags?: TagType[];
  changeMethod?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  phoneTypes?: PhoneType[];
  counties?: DistrictType[];
  connectedPeople?: ConnectedPeople[];
  personTypes?: LegalCasePersonType[];
  documentTypes?: DocumentType[];
  usersWithPerson?: UserWithPerson[];
  caseRelatedProgresses?: GetCaseProgress[];
  disabled?: boolean;
  inputRef?: any;
  person?: FullPersonData;
  required?: boolean;
}

const Selector: React.FC<ISelectorProps> = ({
  options,
  className,
  value,
  setValue,
  roles,
  userValue,
  justiceTypes,
  courtTypes,
  statesList,
  occupationTypes,
  activityTypes,
  secretaries,
  caseProgressSituation,
  progressTypes,
  tags,
  changeMethod,
  phoneTypes,
  person,
  counties,
  connectedPeople,
  personTypes,
  documentTypes,
  usersWithPerson,
  caseRelatedProgresses,
  disabled,
  inputRef,
  required,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => setValue(e.target.value);

  const handleUserChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const rolesArray = [];
    rolesArray.push(roles?.find((role: Role) => role.role_id === e.target.value));
    setValue({
      ...userValue,
      roles: rolesArray,
    });
  };

  const handleDefaultChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (setValue) {
      setValue(e.target.value);
    }
  };

  const handleList = () => {
    if (options) {
      return (
        <select value={value} disabled={disabled} required={required} className={`selector ${className}`} onChange={handleChange}>
          {statesList && (
            <option value="" disabled selected>
              Selecione um estado *
            </option>
          )}
          {options?.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
      );
    }
    if (roles?.length) {
      return (
        <select className={`selector ${className}`} required={required} onChange={handleUserChange}>
          <option value="" disabled selected>
            Selecione um grupo de usuário *
          </option>
          {roles?.map((role: Role) => {
            return (
              <option key={role.role_id} value={role.role_id}>
                {role.name}
              </option>
            );
          })}
        </select>
      );
    }
    if (justiceTypes?.length) {
      return (
        <select
          className={`selector ${className}`}
          value={value}
          required={required}
          disabled={disabled}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleDefaultChange(e)}
        >
          {!value && (
            <option value="" disabled selected>
              Tipo de justiça
            </option>
          )}
          {justiceTypes?.map((justice: JusticeType) => {
            return (
              <option key={justice.justice_type_id} value={justice.justice_type_id}>
                {justice.name}
              </option>
            );
          })}
        </select>
      );
    }
    if (courtTypes?.length) {
      return (
        <select
          className={`selector ${className}`}
          value={value}
          disabled={disabled}
          required={required}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleDefaultChange(e)}
        >
          {!value && (
            <option value="" disabled selected>
              Selecione um tipo de vara
            </option>
          )}
          {courtTypes?.map((court: CourtType) => {
            return (
              <option key={court.court_id} value={court.court_id}>
                {court.name}
              </option>
            );
          })}
        </select>
      );
    }
    if (occupationTypes?.length) {
      return (
        <select
          className={`selector ${className}`}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleDefaultChange(e)}
          disabled={disabled}
        >
          {!value && (
            <option value="" disabled selected>
              Profissão
            </option>
          )}
          {occupationTypes?.map((occupation: OccupationType) => {
            return (
              <option
                key={occupation.occupation_id}
                value={occupation.occupation_id}
                selected={occupation.occupation_id === value}
              >
                {occupation.occupation}
              </option>
            );
          })}
        </select>
      );
    }
    if (activityTypes?.length) {
      return (
        <select
          required={required}
          disabled={disabled}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleDefaultChange(e)}
          className={`selector ${className}`}
        >
          {!value && (
            <option value="" disabled selected>
              Atividade *
            </option>
          )}
          {activityTypes?.map((activity: ActivityType) => {
            return (
              <option
                key={activity.business_activity_id}
                selected={activity.business_activity_id === value}
                value={activity.business_activity_id}
              >
                {activity.business_activity}
              </option>
            );
          })}
        </select>
      );
    }
    if (phoneTypes?.length) {
      return (
        <select
          disabled={disabled}
          required={required}
          ref={inputRef}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleDefaultChange(e)}
          className={`selector ${className}`}
        >
          {phoneTypes?.map((phone: PhoneType) => {
            return (
              <option key={phone.phone_type_id} selected={phone.phone_type_id === value} value={phone.phone_type_id}>
                {phone.phone_type}
              </option>
            );
          })}
        </select>
      );
    }
    if (secretaries?.length) {
      return (
        <select
          disabled={disabled}
          className={`selector ${className}`}
          required={required}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleDefaultChange(e)}
        >
          {!value && (
            <option value="" disabled selected>
              Secretaria
            </option>
          )}
          {secretaries?.map((secretary: SecretaryType) => {
            return (
              <option key={secretary.secretary_id} value={secretary.secretary_id}>
                {secretary.name}
              </option>
            );
          })}
        </select>
      );
    }
    if (caseProgressSituation?.length) {
      return (
        <select
          disabled={disabled}
          className={`selector ${className}`}
          required={required}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleDefaultChange(e)}
        >
          {!value && (
            <option value="" disabled selected>
              Situação do caso *
            </option>
          )}
          {caseProgressSituation?.map(progress => {
            return (
              <option key={progress.case_progress_status_id} value={progress.case_progress_status_id}>
                {progress.status}
              </option>
            );
          })}
        </select>
      );
    } 
    if (caseRelatedProgresses?.length) {
      return (
        <select
          disabled={disabled}
          className={`selector ${className}`}
          required={required}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleDefaultChange(e)}
        >
          {!value && (
            <option value="" disabled selected>
              Andamentos deste caso *
            </option>
          )}
          {caseRelatedProgresses?.map(progress => {
            return (
              <option key={progress.case_progress_id} value={progress.case_progress_id}>
                {progress.case_progress_type.progress_type}
              </option>
            );
          })}
        </select>
      );
    }
    if (progressTypes?.length) {
      return (
        <select
          disabled={disabled}
          className={`selector ${className}`}
          required={required}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleDefaultChange(e)}
        >
          {!value && (
            <option value="" disabled selected>
              Tipo do Andamento *
            </option>
          )}
          {progressTypes?.map(type => {
            return (
              <option key={type.case_progress_type_id} value={type.case_progress_type_id}>
                {type.progress_type}
              </option>
            );
          })}
        </select>
      );
    }
    if (counties?.length) {
      return (
        <select
          disabled={disabled}
          className={`selector ${className}`}
          required={required}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleDefaultChange(e)}
        >
          {!value && (
            <option value="" disabled selected>
              Comarca
            </option>
          )}
          {counties?.map((county: DistrictType) => {
            return (
              <option key={county.county_id} value={county.county_id}>
                {county.name}
              </option>
            );
          })}
        </select>
      );
    }
    if (personTypes?.length) {
      return (
        <select
          disabled={disabled}
          className={`selector ${className}`}
          required={required}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleDefaultChange(e)}
        >
          {!value && (
            <option value="" disabled selected>
              Tipo da pessoa *
            </option>
          )}
          {personTypes?.map((personType: LegalCasePersonType) => {
            return (
              <option
                key={personType.person_type_id}
                selected={personType.person_type_id === value}
                value={personType.person_type_id}
              >
                {personType.name}
              </option>
            );
          })}
        </select>
      );
    }
    if (documentTypes?.length) {
      return (
        <select
          disabled={disabled}
          className={`selector ${className}`}
          required={required}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleDefaultChange(e)}
        >
          {!value && (
            <option value="" disabled selected>
              Tipo do Documento *
            </option>
          )}
          {documentTypes?.map((documentType: DocumentType) => {
            return (
              <option
                key={documentType.document_type_id}
                selected={documentType.document_type_id === value}
                value={documentType.document_type_id}
              >
                {documentType.document_type}
              </option>
            );
          })}
        </select>
      );
    }
    if (caseProgressSituation?.length && value) {
      return (
        <select required={required} disabled={disabled} className={`selector ${className}`} value={value}>
          {caseProgressSituation?.map((progress) => {
            return (
              <option
                key={progress.case_progress_status_id}
                selected={progress.case_progress_status_id === value}
                value={progress.case_progress_status_id}
              >
                {progress.status}
              </option>
            );
          })}
        </select>
      );
    }
    if (usersWithPerson?.length) {
      return (
        <select
          disabled={disabled}
          className={`selector ${className}`}
          required={required}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleDefaultChange(e)}
        >
          {!value && (
            <option value="" disabled selected>
              Usuários
            </option>
          )}
          {usersWithPerson?.map((user: UserWithPerson) => {
            return (
              <option
                key={user.user_id}
                selected={user.user_id === value}
                value={user.user_id}
              >
                {user.person.name}
              </option>
            );
          })}
        </select>
      );
    }
    if (tags?.length && !value) {
      return (
        <select required={required} disabled={disabled} onChange={changeMethod} className={`selector ${className}`}>
          <option value="" disabled selected>
            Assuntos
          </option>
          {tags?.map((tag) => {
            return (
              <option key={tag.tag_id} value={tag.tag_id}>
                {tag.tag}
              </option>
            );
          })}
        </select>
      );
    }
    if (connectedPeople?.length && value) {
      return (
        <select
          disabled={disabled}
          className={`selector ${className}`}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleDefaultChange(e)}
          value={value}
        >
          {connectedPeople?.map((connectedPerson: ConnectedPeople) => {
            return (
              <option
                key={connectedPerson.ancestor_id}
                selected={connectedPerson.ancestor_id === value}
                value={connectedPerson.ancestor_id}
              >
                {connectedPerson.descendant.name}
              </option>
            );
          })}
        </select>
      );
    }
    return <></>;
  };

  return <>{handleList()}</>;
};

export default Selector;
