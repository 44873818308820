/* eslint-disable import/prefer-default-export */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-underscore-dangle */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';

export const fetchSchedulesByUser = (date: string, userId?: string) => (dispatch: Dispatch) => {
  const PATH = userId ? `?date=${date}&user_id=${userId}` : `?date=${date}`
  dispatch({
    type: c.FETCH_SCHEDULES_BY_USER,
    payload: {
      request: {
        url: `/case_progress${PATH}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_SCHEDULES_BY_USER_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.FETCH_SCHEDULES_BY_USER_FAIL,
            payload: {
              error: handleApiError(error.toString()),
            },
          });
        },
      },
    },
  });
};
