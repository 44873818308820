import { Toaster, IToastProps } from '@blueprintjs/core';

type ToasterParams = {
  message: string;
  intent: IToastProps['intent'];
  icon: IToastProps['icon'];
};

const createdToaster = Toaster.create({
  className: 'recipe-toaster',
  position: 'top',
});

const createToaster = ({ message, intent, icon }: ToasterParams) => () => createdToaster.show({ message, intent, icon });

export default createToaster;
