/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';
import { CourtType, PostCourtType } from './types';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

export const clearAllStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_ALL_STATUS,
  });
};

export const fetchCourtTypes = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_COURT_TYPE,
    payload: {
      request: {
        url: '/courts',
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_COURT_TYPE_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_COURT_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchCourtTypesPaginated = (offset: number, limit: number) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_COURT_TYPE_PAGINATED,
    payload: {
      request: {
        url: `/courts/paginated?offset=${offset}&limit=${limit}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_COURT_TYPE_PAGINATED_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_COURT_TYPE_PAGINATED_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchCourtTypesId = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_COURT_TYPE_ID,
    payload: {
      request: {
        url: `/courts/${id}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_COURT_TYPE_ID_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_COURT_TYPE_ID_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchCourtsByJusticeAndCounty = (justiceId: string, countyId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_COURTS_BY_JUSTICE_AND_COUNTY,
    payload: {
      request: {
        url: `/courts/counties/${countyId}/justice_type/${justiceId}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_COURTS_BY_JUSTICE_AND_COUNTY_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_COURTS_BY_JUSTICE_AND_COUNTY_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const createCourtType = (courtType: string, justiceId: string, countyId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.CREATE_COURT_TYPE,
    payload: {
      request: {
        url: '/courts',
        method: 'POST',
        data: { name: courtType, justice_type_id: justiceId, county_id: countyId },
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.CREATE_COURT_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de justiça criado com sucesso!',
            },
          });
          dispatch(fetchCourtTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.CREATE_COURT_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const updateCourtType = (oldState: CourtType | PostCourtType, justiceId: string, countyId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.UPDATE_COURT_TYPE,
    payload: {
      request: {
        url: `/courts/${oldState.court_id}`,
        method: 'PUT',
        data: { name: oldState.name, justice_type_id: justiceId, county_id: countyId },
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.UPDATE_COURT_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de justiça atualizado com sucesso!',
            },
          });
          dispatch(fetchCourtTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.UPDATE_COURT_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const inactivateCourtType = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_COURT_TYPE,
    payload: {
      request: {
        url: `/courts/${id}/inactivate`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_COURT_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de justiça arquivado com sucesso!',
            },
          });
          dispatch(fetchCourtTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.INACTIVATE_COURT_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};
