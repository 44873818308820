/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';

export const fetchRoles = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_ROLES,
    payload: {
      request: {
        url: '/roles',
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_ROLES_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_ROLES_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchRolesPaginated = (offset: number, limit: number, optionsPath: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_ROLES_PAGINATED,
    payload: {
      request: {
        url: `/roles/paginated?offset=${offset}&limit=${limit}${optionsPath}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_ROLES_PAGINATED_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_ROLES_PAGINATED_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};
