/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import update from 'immutability-helper';
import Input from '../../components/Input';
import { reducers } from '../../store/reducers';
import {
  createProgressStatus,
  inactivateProgressStatus,
  updateProgressStatus,
  clearAllStatus,
  fetchProgressStatusPaginated,
} from './actions';
import MetaTable from '../../components/MetaTable';
import { ProgressStatus } from './types';
import '../Meta/styles.css';
import Button from '../../components/Button';
import createToaster from '../../components/Toaster';
import { WRITE_PERMISSION, META_MODULE_NAME } from '../Permissions/constants';
import { verifyUserPermission } from '../../utils/permissions';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';
import Pagination from '../../components/Pagination';

const MetaCasesJusticeType: React.FC = () => {
  const dispatch = useDispatch();
  // BlueprintJS inputRef
  const input = React.useRef() as any;
  const progressStatusPaginated = useSelector((state: typeof reducers) => state.metacasesprogressstatus.progressStatusPaginated);
  const createStatus = useSelector((state: typeof reducers) => state.metacasesprogressstatus.createProgressStatus);
  const updateStatus = useSelector((state: typeof reducers) => state.metacasesprogressstatus.updateProgressStatus);
  const inactivateStatus = useSelector((state: typeof reducers) => state.metacasesprogressstatus.inactivateProgressStatus);
  const [isEdit, setIsEdit] = useState(false);
  const [stateProgressStatus, setStateProgressStatus] = useState<ProgressStatus>({
    case_progress_status_id: '',
    status: '',
    is_active: false,
    created_at: '',
  });

  useEffect(() => {
    if (createStatus.success) {
      const showToast = createToaster({
        message: createStatus.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createStatus.success, dispatch]);

  useEffect(() => {
    if (updateStatus.success) {
      const showToast = createToaster({
        message: updateStatus.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateStatus.success, dispatch]);

  useEffect(() => {
    if (inactivateStatus.success) {
      const showToast = createToaster({
        message: inactivateStatus.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateStatus.success, dispatch]);

  useEffect(() => {
    if (createStatus.fail) {
      const showToast = createToaster({
        message: createStatus.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createStatus.fail, dispatch]);

  useEffect(() => {
    if (updateStatus.fail) {
      const showToast = createToaster({
        message: updateStatus.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateStatus.fail, dispatch]);

  useEffect(() => {
    if (inactivateStatus.fail) {
      const showToast = createToaster({
        message: inactivateStatus.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateStatus.fail, dispatch]);

  const setInputValue = (value: string) => (input.current.value = value);

  const setUpNewState = (elem: ProgressStatus) =>
    update(stateProgressStatus, {
      status: { $set: elem.status },
      case_progress_status_id: { $set: elem.case_progress_status_id },
      is_active: { $set: elem.is_active },
      created_at: { $set: elem.created_at },
    });

  const handleEditRequest = (elem: ProgressStatus) => {
    setInputValue(elem.status);
    setIsEdit(true);
    setStateProgressStatus(setUpNewState(elem));
  };

  useEffect(() => {
    dispatch(fetchProgressStatusPaginated(1, DEFAULT_PER_PAGE_LIMIT));
  }, [dispatch]);

  const resetForm = () => {
    setInputValue('');
    setStateProgressStatus((prevState) => ({
      ...prevState,
      status: '',
    }));
    setIsEdit(false);
  };

  const addProgressStatus = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isEdit) {
      dispatch(createProgressStatus(stateProgressStatus.status));
    } else {
      const newElem = update(stateProgressStatus, {
        status: { $set: stateProgressStatus.status },
      });
      dispatch(updateProgressStatus(newElem));
    }
    resetForm();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateProgressStatus(
      update(stateProgressStatus, {
        status: { $set: e.target.value },
      })
    );
  };

  const handleTextButton = () => (isEdit ? 'Atualizar situação' : 'Adicionar situação');

  const disabledValidation = () => stateProgressStatus.status === '';

  return (
    <form className="component__container" onSubmit={addProgressStatus}>
      {verifyUserPermission(META_MODULE_NAME, WRITE_PERMISSION) && (
        <Input
          value={stateProgressStatus?.status}
          onChange={onChangeInput}
          required
          inputRef={input}
          placeholder="Digite a situação de caso que gostaria de adicionar... *"
          button={{
            text: handleTextButton(),
            icon: 'plus',
            disabledValidation: disabledValidation(),
          }}
        />
      )}
      {isEdit && (
        <Button name="backwhite" onClick={resetForm}>
          Cancelar edição
        </Button>
      )}
      <h3>Situações de caso já cadastrados:</h3>
      <MetaTable
        loading={progressStatusPaginated?.loading || updateStatus?.loading || inactivateStatus?.loading || createStatus?.loading}
        empty={progressStatusPaginated.empty}
        data={progressStatusPaginated.data.result}
        headers={['Situação', 'Criado em']}
        columns={['status', 'created_at']}
        pathId="case_progress_status_id"
        removeRequest={inactivateProgressStatus}
        editRequest={handleEditRequest}
        group
      />
      {!progressStatusPaginated?.empty && (
        <Pagination
          request={fetchProgressStatusPaginated}
          loading={progressStatusPaginated?.loading}
          data={progressStatusPaginated?.data}
        />
      )}
    </form>
  );
};

export default React.memo(MetaCasesJusticeType);
