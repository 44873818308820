import { IBreadcrumbProps } from '@blueprintjs/core';

export const FETCH_PERSON = 'FETCH_PERSON';
export const FETCH_PERSON_SUCCESS = 'FETCH_PERSON_SUCCESS';
export const FETCH_PERSON_FAIL = 'FETCH_PERSON_FAIL';

export const UPDATE_PERSON = 'UPDATE_PERSON';
export const UPDATE_PERSON_SUCCESS = 'UPDATE_PERSON_SUCCESS';
export const UPDATE_PERSON_FAIL = 'UPDATE_PERSON_FAIL';

export const INACTIVATE_PERSON = 'INACTIVATE_PERSON';
export const INACTIVATE_PERSON_SUCCESS = 'INACTIVATE_PERSON_SUCCESS';
export const INACTIVATE_PERSON_FAIL = 'INACTIVATE_PERSON_FAIL';

export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';

export const BREADCRUMB_PATHS: IBreadcrumbProps[] = [
  { href: '/painel/pessoas/lista', text: 'Pessoas' },
  window.location.pathname.includes('individuo')
    ? { href: '/painel/pessoas/individuo', text: 'Pessoa' }
    : { href: '/painel/pessoas/lista', text: 'Listagem' },
];
