/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import update from 'immutability-helper';
import Input from '../../components/Input';
import { reducers } from '../../store/reducers';
import { createPhoneType, inactivatePhoneType, updatePhoneType, clearAllStatus, fetchPhoneTypesPaginated } from './actions';
import MetaTable from '../../components/MetaTable';
import { PhoneType } from './types';
import '../Meta/styles.css';
import Button from '../../components/Button';
import createToaster from '../../components/Toaster';
import { WRITE_PERMISSION, META_MODULE_NAME } from '../Permissions/constants';
import { verifyUserPermission } from '../../utils/permissions';
import Pagination from '../../components/Pagination';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

const MetaPeoplePhones: React.FC = () => {
  const dispatch = useDispatch();
  // BlueprintJS inputRef
  const input = React.useRef() as any;
  const phoneTypesPaginated = useSelector((state: typeof reducers) => state.metapeoplephones.phoneTypesPaginated);
  const createPhoneTypes = useSelector((state: typeof reducers) => state.metapeoplephones.createPhoneType);
  const updatePhoneTypes = useSelector((state: typeof reducers) => state.metapeoplephones.updatePhoneType);
  const inactivatePhoneTypes = useSelector((state: typeof reducers) => state.metapeoplephones.inactivatePhoneType);
  const [isEdit, setIsEdit] = useState(false);
  const [statePhoneType, setStatePhoneType] = useState<PhoneType>({
    phone_type: '',
    phone_type_id: '',
    is_active: false,
    created_at: '',
  });

  useEffect(() => {
    if (createPhoneTypes.success) {
      const showToast = createToaster({
        message: createPhoneTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createPhoneTypes.success, dispatch]);

  useEffect(() => {
    if (updatePhoneTypes.success) {
      const showToast = createToaster({
        message: updatePhoneTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updatePhoneTypes.success, dispatch]);

  useEffect(() => {
    if (inactivatePhoneTypes.success) {
      const showToast = createToaster({
        message: inactivatePhoneTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivatePhoneTypes.success, dispatch]);

  useEffect(() => {
    if (createPhoneTypes.fail) {
      const showToast = createToaster({
        message: createPhoneTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createPhoneTypes.fail, dispatch]);

  useEffect(() => {
    if (updatePhoneTypes.fail) {
      const showToast = createToaster({
        message: updatePhoneTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updatePhoneTypes.fail, dispatch]);

  useEffect(() => {
    if (inactivatePhoneTypes.fail) {
      const showToast = createToaster({
        message: inactivatePhoneTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivatePhoneTypes.fail, dispatch]);

  const setInputValue = (value: string) => (input.current.value = value);

  const handleEditRequest = (elem: PhoneType) => {
    setInputValue(elem.phone_type);
    setIsEdit(true);
    setStatePhoneType(elem);
  };

  useEffect(() => {
    dispatch(fetchPhoneTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
  }, [dispatch]);

  const resetForm = () => {
    setInputValue('');
    setStatePhoneType((prevState) => ({
      ...prevState,
      phone_type: '',
    }));
    setIsEdit(false);
  };

  const addPhoneType = (e: SyntheticEvent) => {
    if (e) {
      e.preventDefault();
    }
    if (!isEdit) {
      dispatch(createPhoneType(statePhoneType.phone_type));
    } else {
      const newElem = update(statePhoneType, {
        phone_type: { $set: statePhoneType.phone_type },
      });
      dispatch(updatePhoneType(newElem));
    }
    resetForm();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatePhoneType(
      update(statePhoneType, {
        phone_type: { $set: e.target.value },
      })
    );
  };

  const handleTextButton = () => (isEdit ? 'Atualizar telefone' : 'Adicionar telefone');

  const disabledValidation = () => statePhoneType.phone_type === '';

  return (
    <form className="component__container" onSubmit={addPhoneType}>
      {verifyUserPermission(META_MODULE_NAME, WRITE_PERMISSION) && (
        <Input
          value={statePhoneType?.phone_type}
          onChange={onChangeInput}
          inputRef={input}
          required
          placeholder="Digite o tipo de telefone que gostaria de adicionar... *"
          button={{
            text: handleTextButton(),
            icon: 'plus',
            disabledValidation: disabledValidation(),
            onClick: addPhoneType,
          }}
        />
      )}
      {isEdit && (
        <Button name="backwhite" onClick={resetForm}>
          Cancelar edição
        </Button>
      )}
      <h3>Tipos de telefone já cadastrados:</h3>
      <MetaTable
        loading={phoneTypesPaginated?.loading || updatePhoneTypes?.loading || inactivatePhoneTypes?.loading || createPhoneTypes?.loading}
        empty={phoneTypesPaginated.empty}
        data={phoneTypesPaginated.data.result}
        headers={['Nome', 'Criado em']}
        columns={['phone_type', 'created_at']}
        pathId="phone_type_id"
        removeRequest={inactivatePhoneType}
        editRequest={handleEditRequest}
        group
      />
      {!phoneTypesPaginated?.empty && (
        <Pagination
          request={fetchPhoneTypesPaginated}
          loading={phoneTypesPaginated?.loading}
          data={phoneTypesPaginated?.data}
        />
      )}
    </form>
  );
};

export default React.memo(MetaPeoplePhones);
