/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import { Tabs, Tab } from '@blueprintjs/core';
import './styles.css';
import CaseRelatedPeople from '../../modules/CaseRelatedPeople';
import CaseRelatedLawyers from '../../modules/CaseRelatedLawyers';
import CaseRelatedFolders from '../../modules/CaseRelatedFolders';
import CaseRelatedProgress from '../../modules/CaseRelatedProgress';
import CaseRelatedDocuments from '../../modules/CaseRelatedDocuments';
import CaseRelatedSchedules from '../../modules/CaseRelatedSchedules';

interface ICaseRelationsProps {
  caseId: string;
}

const CaseRelations: React.FC<ICaseRelationsProps> = ({ caseId }) => {
  const [selectedTabId, setSelectedTabId] = useState('caseRelatedPeople');
  const handleChangeTab = (tab: string) => setSelectedTabId(tab);

  return (
    <>
      <Tabs renderActiveTabPanelOnly className="case__relations" selectedTabId={selectedTabId} onChange={handleChangeTab}>
        <Tab
          id="caseRelatedPeople"
          title="Pessoas envolvidas"
          panel={<CaseRelatedPeople caseId={caseId} />}
        />
        <Tab
          id="caseRelatedLawyers"
          title="Advogados envolvidos"
          panel={<CaseRelatedLawyers caseId={caseId} />}
        />
        <Tab
          id="caseRelatedProgresses"
          title="Andamentos"
          panel={<CaseRelatedProgress caseId={caseId} />}
        />
        <Tab
          id="caseRelatedDocuments"
          title="Documentos"
          panel={<CaseRelatedDocuments caseId={caseId} />}
        />
        <Tab
          id="caseRelatedFolders"
          title="Pastas"
          panel={<CaseRelatedFolders caseId={caseId} />}
        />
        <Tab
          id="caseRelatedSchedules"
          title="Agendamentos"
          panel={<CaseRelatedSchedules caseId={caseId} />}
        />
      </Tabs>
    </>
  );
};

export default CaseRelations;
