/* eslint-disable consistent-return */
const handleApiError = (error: string, detail?: string) => {
  if (error.includes('403')) {
    return 'Você não possui permissão para executar esta ação';
  }
  if (error.includes('500')) {
    return 'Ops, tivemos algum problema interno durante a execução';
  }
  if (error.includes('409') && detail?.includes('originated')) {
    return 'Número de processo originário inválido.';
  }
  if (error.includes('409') && detail?.toLocaleLowerCase().includes('file related already exists')) {
    return 'Um dos arquivos que você selecionou já está inserido na base.'
  }
  if (error.includes('409')) {
    return 'Cadastro já existente';
  }
  if (error.includes('422') && detail?.includes('mail')) {
    return 'O email que você inseriu é inválido.'
  }
  if (error.includes('422') && detail?.includes('oab_id')) {
    return 'O número de OAB que você inseriu é inválido.';
  }
  if (error.includes('422')) {
    return 'Ops, encontramos um problema nos dados que você preencheu. Por favor, tente novamente.';
  }
  if (error.toLowerCase().includes('network')) {
    return 'Ops, tivemos algum problema no servidor. Por favor, tente novamente.';
  }
};

export default handleApiError;
