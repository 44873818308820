/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';

export const clearAllStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_ALL_STATUS,
  });
};

export const fetchCaseProgresses = (caseId: string, ) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_PROGRESS,
    payload: {
      request: {
        url: `/legal_cases/${caseId}/case_progress`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_PROGRESS_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_PROGRESS_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const createProgressRelation = (caseId: string, progress: any) => (dispatch: Dispatch) => {
  dispatch({
    type: c.CREATE_PROGRESS,
    payload: {
      request: {
        url: `/legal_cases/${caseId}/case_progress`,
        method: 'PUT',
        data: progress,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.CREATE_PROGRESS_SUCCESS,
          });
          dispatch(fetchCaseProgresses(caseId));
          dispatch(clearAllStatus());
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.CREATE_PROGRESS_FAIL,
            payload: {
              error: resError,
            },
          });
          dispatch(clearAllStatus());
        },
      },
    },
  });
};

export const createDocumentProgressRelation = (caseId: string, progressId: string, formData: any) => (dispatch: Dispatch) => {
  dispatch({
    type: c.CREATE_PROGRESS_DOCUMENT,
    payload: {
      request: {
        url: `/case_progress/${progressId}/documents`,
        method: 'POST',
        data: formData,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.CREATE_PROGRESS_DOCUMENT_SUCCESS,
          });
          dispatch(clearAllStatus())
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString(), error.response.data?.detail);
          dispatch({
            type: c.CREATE_PROGRESS_DOCUMENT_FAIL,
            payload: {
              error: resError,
            },
          });
          dispatch(clearAllStatus());
        },
      },
    },
  });
};

export const inactivateProgressRelation = (caseId: string, progressId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_PROGRESS,
    payload: {
      request: {
        url: `/case_progress/${progressId}/inactivate`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_PROGRESS_SUCCESS,
          });
          dispatch(fetchCaseProgresses(caseId));
          dispatch(clearAllStatus())
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.INACTIVATE_PROGRESS_FAIL,
            payload: {
              error: resError,
            },
          });
          dispatch(clearAllStatus());
        },
      },
    },
  });
};

export const fetchProgressById = (progressId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_PROGRESS_BY_ID,
    payload: {
      request: {
        url: `/case_progress/${progressId}/`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_PROGRESS_BY_ID_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_PROGRESS_BY_ID_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};
