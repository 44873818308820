import React, { useState, useEffect, SyntheticEvent } from 'react';
import Button from '../../components/Button';
import { useSelector, useDispatch } from 'react-redux';
import { reducers } from '../../store/reducers';
import { inactivateProgressRelation, createProgressRelation, fetchCaseProgresses } from './actions';
import '../../components/CaseRelations/styles.css';
import MetaTable from '../../components/MetaTable';
import createToaster from '../../components/Toaster';
import { TextArea, Checkbox, Spinner } from '@blueprintjs/core';
import './styles.css';
import Input from '../../components/Input';
import Selector from '../../components/Selector';
import { fetchProgressStatus } from '../MetaCasesProgressStatus/actions';
import { fetchProgressTypes } from '../MetaCasesProgressType/actions';
import DatePicker from '../../components/DatePicker';
import { fetchDocumentTypes } from '../MetaDocuments/actions';
import { timeValidation, fixDateGmt, formatWithoutTimezone, dateFormatter } from '../../utils/date';
import ScheduleSideDialog from '../../components/ScheduleSideDialog';
import { GetCaseProgress } from './types';

interface ICaseRelatedProgress {
  caseId: string;
}

const CaseRelatedProgress: React.FC<ICaseRelatedProgress> = ({ caseId }) => {
  const dispatch = useDispatch();
  const progresses = useSelector((state: typeof reducers) => state.caserelatedprogress.caseProgresses);
  const create = useSelector((state: typeof reducers) => state.caserelatedprogress.createCaseProgress);
  const inactivate = useSelector((state: typeof reducers) => state.caserelatedprogress.inactivateCaseProgress);
  const sendDocument = useSelector((state: typeof reducers) => state.caserelatedprogress.addDocument);
  const progressStatus = useSelector((state: typeof reducers) => state.metacasesprogressstatus.progressStatus);
  const progressTypes = useSelector((state: typeof reducers) => state.metacasesprogresstype.progressTypes);
  const [connectionId, setConnectionId] = useState<string | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [progressId, setProgressId] = useState<string | null>(null);
  const [progressTypeId, setProgressTypeId] = useState<string | null>(null);
  const [progressSource, setProgressSource] = useState<string | null>(null);
  const [progressText, setProgressText] = useState<string | null>(null);
  const [scheduleDateStart, setScheduleDateStart] = useState<any>();
  const [scheduleDateEnd, setScheduleDateEnd] = useState<any>();
  const [scheduleTitle, setScheduleTitle] = useState<string | null>(null);
  const [isAllDay, setIsAllDay] = useState(false);
  const [scheduleText, setScheduleText] = useState<string | null>(null);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const [caseProgress, setCaseProgress] = useState<any>();
  const [startDate, setStartDate] = useState<Date>();
  const [startTime, setStartTime] = useState<string>('12:00');

  useEffect(() => {
    if (caseId?.length) {
      dispatch(fetchCaseProgresses(caseId));
      dispatch(fetchProgressStatus());
      dispatch(fetchProgressTypes());
      dispatch(fetchDocumentTypes());
    }
  }, [dispatch, caseId]);

  useEffect(() => {
    if (inactivate?.message || inactivate?.fail) {
      const showToast = createToaster({
        message: inactivate?.message || inactivate?.fail,
        intent: inactivate?.fail ? 'danger' : 'success',
        icon: inactivate?.fail ? 'warning-sign' : 'tick',
      });
      showToast();
      if (inactivate?.message) {
        clearVariables();
      }
    }
  }, [inactivate]);

  useEffect(() => {
    if (create?.message || create?.fail) {
      const showToast = createToaster({
        message: create?.message || create?.fail,
        intent: create?.fail ? 'danger' : 'success',
        icon: create?.fail ? 'warning-sign' : 'tick',
      });
      showToast();
      if (create?.message) {
        clearVariables();
      }
    }
  }, [create]);

  const openAndSetDialog = (elem: any) => {
    setCaseProgress(elem);
    setScheduleDialogOpen(true);
  };

  const getTable = () => (
    <div className="table__container">
      <MetaTable
        loading={progresses?.loading || inactivate?.loading || create?.loading || sendDocument?.loading}
        empty={progresses?.data?.empty}
        data={progresses?.data}
        headers={['Data do Andamento', 'Tipo', 'Situação']}
        columns={['case_progress_date', 'case_progress_type', 'case_progress_status']}
        pathId="case_progress_id"
        removeRequest={inactivateProgressRelation}
        editRequest={handleEditRequest}
        expandProgress={openAndSetDialog}
        personId={caseId}
      />
    </div>
  );

  const formatTimeAndDate = (elem: GetCaseProgress) => {
    let minutes = new Date(fixDateGmt(elem.case_progress_date)).getMinutes().toString();
    if (Number(minutes) < 10) {
      minutes = `0${new Date(fixDateGmt(elem.case_progress_date)).getMinutes()}`
    }
    let hours = new Date(fixDateGmt(elem.case_progress_date)).getHours().toString();
    if (Number(hours) < 10) {
      hours = `0${new Date(fixDateGmt(elem.case_progress_date)).getHours()}`
    }
    setStartTime(`${hours}:${minutes}`);
    setStartDate(new Date(fixDateGmt(elem.case_progress_date)));
  };

  const handleEditRequest = (elem: GetCaseProgress) => {
    setConnectionId(elem.case_progress_id!);
    setProgressId(elem.case_progress_status.case_progress_status_id);
    setProgressTypeId(elem.case_progress_type.case_progress_type_id);
    formatTimeAndDate(elem);
    setProgressSource(elem.origin);
    setProgressText(elem.text);
    setIsEdit(true);
  };

  const clearVariables = () => {
    setConnectionId(null);
    setProgressId(null);
    setProgressTypeId(null);
    setProgressSource('');
    setProgressText('');
    setScheduleTitle('');
    setScheduleText('');
    setScheduleDateStart(null);
    setScheduleDateEnd(null);
    setIsAllDay(false);
    setIsEdit(false);
  };

  const sendRelation = (e: SyntheticEvent) => {
    e.preventDefault();
    if (startTime && timeValidation(startTime)) {
      if (scheduleTitle) {
        let startHours = scheduleDateStart.split('T')[1].slice(0, 2);
        if (startHours - 3 <= 0) {
          startHours = '00';
        } else {
          startHours = startHours - 3;
        }
        let endHours = scheduleDateEnd.split('T')[1].slice(0, 2);
        if (endHours - 3 <= 0) {
          endHours = '00';
        } else {
          endHours = endHours - 3;
        }
        return dispatch(
          createProgressRelation(caseId, {
            case_progress_id: connectionId,
            case_progress_type_id: progressTypeId,
            case_progress_status_id: progressId,
            text: progressText,
            case_progress_date: dateFormatter(startDate?.toString()!, startTime),
            origin: progressSource,
            schedule: {
              title: scheduleTitle,
              date_start: formatWithoutTimezone(new Date(scheduleDateStart)),
              date_end: formatWithoutTimezone(new Date(scheduleDateEnd)),
              is_all_day: isAllDay,
              text: scheduleText,
            },
          })
        );
      }
      return dispatch(
        createProgressRelation(caseId, {
          case_progress_id: connectionId,
          case_progress_type_id: progressTypeId,
          case_progress_status_id: progressId,
          text: progressText,
          case_progress_date: dateFormatter(startDate?.toString()!, startTime),
          origin: progressSource,
        })
      );
    }
  };

  const renderScheduleForm = () => (
    <>
      <h2>Agendar um compromisso? (opcional)</h2>
      <p>Para vincular um agendamento inicie a edição com o Andamento desejado presente na lista ao lado.</p>
      <Input
        value={scheduleTitle ?? undefined}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setScheduleTitle(e.target.value)}
        placeholder="Nome do agendamento"
        maxLength={30}
      />
      <DatePicker
        value={scheduleDateStart ? new Date(scheduleDateStart) : undefined}
        timePrecision
        placeholder="Data de início *"
        minLimitDate={new Date(1950,11,11)}
        maxLimitDate={new Date(2100, 11, 11)}
        required={Boolean(scheduleTitle)}
        className="date__picker"
        onChangeMethod={(newDate: Date) => 
          setScheduleDateStart(newDate?.toISOString())
        }
      />
      <DatePicker
        value={scheduleDateEnd ? new Date(scheduleDateEnd) : undefined}
        timePrecision
        minLimitDate={new Date(1950,11,11)}
        placeholder="Data de encerramento *"
        maxLimitDate={new Date(2100, 11, 11)}
        required={Boolean(scheduleTitle)}
        className="date__picker"
        onChangeMethod={(newDate: Date) => setScheduleDateEnd(newDate?.toISOString())}
      />
      <TextArea
        large
        value={scheduleText || undefined}
        placeholder="Texto do agendamento *"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setScheduleText(e.target.value)}
      />
      <Checkbox checked={isAllDay} onChange={() => setIsAllDay((state) => !state)}>
        Dia inteiro
      </Checkbox>
    </>
  );

  const renderProgressForm = () => (
    <>
      <h2>Cadastrar Andamento de Caso</h2>
      <Selector
        required
        setValue={setProgressId}
        value={progressId ?? undefined}
        className="cases__selector"
        caseProgressSituation={progressStatus?.data}
      />
      <Selector
        required
        setValue={setProgressTypeId}
        value={progressTypeId ?? undefined}
        className="cases__selector"
        progressTypes={progressTypes?.data}
      />
      <DatePicker
        className="date__picker"
        placeholder="Data do andamento"
        minLimitDate={new Date(1950,11,11)}
        maxLimitDate={new Date(2100, 11, 11)}
        onChangeMethod={(newDate: Date) => setStartDate(newDate)}
        value={startDate ? startDate : undefined}
      />
      <Input
        maxLength={5}
        placeholder="Horário do andamento (HH:MM)"
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStartTime(e.target.value)}
        value={startTime}
      />
      <Input
        type="text"
        maxLength={10}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProgressSource(e.target.value)}
        value={progressSource ?? undefined}
        placeholder="Origem"
      />
      <TextArea
        large
        value={progressText ?? undefined}
        required
        placeholder="Texto do andamento *"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setProgressText(e.target.value)}
      />
    </>
  );

  return (
    <div className="case__relations--progress">
      <div className="tabs__subline"></div>
      {caseProgress && (
        <ScheduleSideDialog
          loading={false}
          isDialogOpen={scheduleDialogOpen}
          setIsDialogOpen={setScheduleDialogOpen}
          data={caseProgress}
        />
      )}
      <div className="content__container">
        {getTable()}
        <form className="register__form" onSubmit={sendRelation}>
          {renderProgressForm()}
          <div className="slice__line"></div>
          {renderScheduleForm()}
          <div className="slice__line"></div>
          <div className="buttons__container">
            {create?.loading ? <Spinner intent="primary" size={18} /> : <Button name="add" text="Salvar" type="submit" />}
            {isEdit && <Button name="default" text="Cancelar edição" onClick={clearVariables} type="button" />}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CaseRelatedProgress;
