/* eslint-disable import/prefer-default-export */
export const FETCH_PEOPLE_RELATION = 'FETCH_PEOPLE_RELATION';
export const FETCH_PEOPLE_RELATION_SUCCESS = 'FETCH_PEOPLE_RELATION_SUCCESS';
export const FETCH_PEOPLE_RELATION_FAIL = 'FETCH_PEOPLE_RELATION_FAIL';

export const CREATE_PEOPLE_RELATION = 'CREATE_PEOPLE_RELATION';
export const CREATE_PEOPLE_RELATION_SUCCESS = 'CREATE_PEOPLE_RELATION_SUCCESS';
export const CREATE_PEOPLE_RELATION_FAIL = 'CREATE_PEOPLE_RELATION_FAIL';

export const UPDATE_PEOPLE_RELATION = 'UPDATE_PEOPLE_RELATION';
export const UPDATE_PEOPLE_RELATION_SUCCESS = 'UPDATE_PEOPLE_RELATION_SUCCESS';
export const UPDATE_PEOPLE_RELATION_FAIL = 'UPDATE_PEOPLE_RELATION_FAIL';

export const INACTIVATE_PEOPLE_RELATION = 'INACTIVATE_PEOPLE_RELATION';
export const INACTIVATE_PEOPLE_RELATION_SUCCESS = 'INACTIVATE_PEOPLE_RELATION_SUCCESS';
export const INACTIVATE_PEOPLE_RELATION_FAIL = 'INACTIVATE_PEOPLE_RELATION_FAIL';

export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
