/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import { Tabs, Tab, Icon } from '@blueprintjs/core';
import ConnectedPeople from '../../modules/PeopleConnectedPeople';
import Telephones from '../../modules/PeopleTelephones';
import Addresses from '../../modules/PeopleAddresses';
import PeopleConnectedCases from '../../modules/PeopleConnectedCases';
import './styles.css';
import PeopleAttachments from '../../modules/PeopleAttachments';

interface IPersonRelationsProps {
  personId: string;
}

const PersonRelations: React.FC<IPersonRelationsProps> = ({ personId }) => {
  const [selectedTabId, setSelectedTabId] = useState('connectedPeople');
  const handleChangeTab = (tab: string) => setSelectedTabId(tab);

  return (
    <>
      <Tabs renderActiveTabPanelOnly className="person__relations" selectedTabId={selectedTabId} onChange={handleChangeTab}>
        <Tab
          id="connectedPeople"
          title={
            <>
              <Icon icon="people" iconSize={16} />
              Pessoas ligadas
            </>
          }
          panel={<ConnectedPeople personId={personId} />}
        />
        <Tab
          id="telephones"
          title={
            <>
              <Icon icon="phone" iconSize={14} />
              Telefones
            </>
          }
          panel={<Telephones personId={personId} />}
        />
        <Tab
          id="addresses"
          title={
            <>
              <Icon icon="map-marker" iconSize={15} />
              Endereços
            </>
          }
          panel={<Addresses personId={personId} />}
        />
        <Tab
          id="attachments"
          title={
            <>
              <Icon icon="document" iconSize={15} />
              Anexos
            </>
          }
          panel={<PeopleAttachments personId={personId} />}
        />
        <Tab
          id="connectedCases"
          title={
            <>
              <Icon icon="box" iconSize={15} />
              Casos
            </>
          }
          panel={<PeopleConnectedCases personId={personId} />}
        />
      </Tabs>
    </>
  );
};

export default PersonRelations;
