/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  activityTypes: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  activityTypesPaginated: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  activityTypeId: {
    data: null,
    loading: false,
    fail: null,
  },
  createActivityType: {
    loading: false,
    fail: null,
    success: null,
  },
  updateActivityType: {
    loading: false,
    fail: null,
    success: null,
  },
  inactivateActivityType: {
    loading: false,
    fail: null,
    success: null,
  },
};

const metaCases: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_ACTIVITIES_TYPE:
        draft.activityTypes.loading = true;
        draft.activityTypes.empty = true;
        draft.activityTypes.fail = null;
        return;

      case c.FETCH_ACTIVITIES_TYPE_SUCCESS:
        draft.activityTypes.loading = false;
        draft.activityTypes.fail = null;
        if (payload && payload.data.length) {
          draft.activityTypes.data = payload.data;
          draft.activityTypes.empty = false;
        }
        return;

      case c.FETCH_ACTIVITIES_TYPE_FAIL:
        draft.activityTypes.loading = false;
        draft.activityTypes.fail = payload.error;
        draft.activityTypes.data = payload.data;
        return;

      case c.FETCH_ACTIVITIES_TYPE_PAGINATED:
        draft.activityTypesPaginated.loading = true;
        draft.activityTypesPaginated.empty = true;
        draft.activityTypesPaginated.fail = null;
        return;

      case c.FETCH_ACTIVITIES_TYPE_PAGINATED_SUCCESS:
        draft.activityTypesPaginated.loading = false;
        draft.activityTypesPaginated.fail = null;
        if (payload && payload.data.result.length) {
          draft.activityTypesPaginated.data = payload.data;
          draft.activityTypesPaginated.empty = false;
        }
        return;

      case c.FETCH_ACTIVITIES_TYPE_PAGINATED_FAIL:
        draft.activityTypesPaginated.loading = false;
        draft.activityTypesPaginated.fail = payload.error;
        draft.activityTypesPaginated.data = payload.data;
        return;

      case c.FETCH_ACTIVITIES_TYPE_ID:
        draft.activityTypeId.loading = true;
        draft.activityTypeId.fail = null;
        return;

      case c.FETCH_ACTIVITIES_TYPE_ID_SUCCESS:
        draft.activityTypeId.loading = false;
        draft.activityTypeId.fail = null;
        if (payload && payload.data.length) {
          draft.activityTypeId.data = payload.data;
        }
        return;

      case c.FETCH_ACTIVITIES_TYPE_ID_FAIL:
        draft.activityTypeId.loading = false;
        draft.activityTypeId.fail = payload.error;
        draft.activityTypeId.data = payload.data;
        return;

      case c.CREATE_ACTIVITIES_TYPE:
        draft.createActivityType.loading = true;
        draft.createActivityType.fail = null;
        draft.createActivityType.success = null;
        return;

      case c.CREATE_ACTIVITIES_TYPE_SUCCESS:
        draft.createActivityType.loading = false;
        draft.createActivityType.success = payload.data;
        return;

      case c.CREATE_ACTIVITIES_TYPE_FAIL:
        draft.createActivityType.loading = false;
        draft.createActivityType.fail = payload.error;
        draft.createActivityType.success = null;
        return;

      case c.UPDATE_ACTIVITIES_TYPE:
        draft.updateActivityType.loading = true;
        draft.updateActivityType.fail = null;
        draft.updateActivityType.success = null;
        return;

      case c.UPDATE_ACTIVITIES_TYPE_SUCCESS:
        draft.updateActivityType.loading = false;
        draft.updateActivityType.fail = null;
        draft.updateActivityType.success = payload.data;
        return;

      case c.UPDATE_ACTIVITIES_TYPE_FAIL:
        draft.updateActivityType.loading = false;
        draft.updateActivityType.fail = payload.error;
        draft.updateActivityType.success = null;
        return;

      case c.INACTIVATE_ACTIVITIES_TYPE:
        draft.inactivateActivityType.loading = true;
        draft.inactivateActivityType.fail = null;
        draft.inactivateActivityType.success = null;
        return;

      case c.INACTIVATE_ACTIVITIES_TYPE_SUCCESS:
        draft.inactivateActivityType.loading = false;
        draft.inactivateActivityType.fail = null;
        draft.inactivateActivityType.success = payload.data;
        return;

      case c.INACTIVATE_ACTIVITIES_TYPE_FAIL:
        draft.inactivateActivityType.loading = false;
        draft.inactivateActivityType.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.createActivityType.success = null;
        draft.updateActivityType.success = null;
        draft.inactivateActivityType.success = null;
        draft.createActivityType.fail = null;
        draft.updateActivityType.fail = null;
        draft.inactivateActivityType.fail = null;
        return;

      default:
        return;
    }
  });

export default metaCases;
