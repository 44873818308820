/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import update from 'immutability-helper';
import Input from '../../components/Input';
import { reducers } from '../../store/reducers';
import {
  createOccupationType,
  inactivateOccupationType,
  updateOccupationType,
  clearAllStatus,
  fetchOccupationTypesPaginated,
} from './actions';
import MetaTable from '../../components/MetaTable';
import { OccupationType } from './types';
import '../Meta/styles.css';
import Button from '../../components/Button';
import createToaster from '../../components/Toaster';
import { WRITE_PERMISSION, META_MODULE_NAME } from '../Permissions/constants';
import { verifyUserPermission } from '../../utils/permissions';
import Pagination from '../../components/Pagination';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

const MetaOccupations: React.FC = () => {
  const dispatch = useDispatch();
  // BlueprintJS inputRef
  const input = React.useRef() as any;
  const occupationTypesPaginated = useSelector((state: typeof reducers) => state.metapeopleoccupations.occupationTypesPaginated);
  const createOccupationTypes = useSelector((state: typeof reducers) => state.metapeopleoccupations.createOccupationType);
  const updateOccupationTypes = useSelector((state: typeof reducers) => state.metapeopleoccupations.updateOccupationType);
  const inactivateOccupationTypes = useSelector(
    (state: typeof reducers) => state.metapeopleoccupations.inactivateOccupationType
  );
  const [isEdit, setIsEdit] = useState(false);
  const [stateOccupationType, setStateOccupationType] = useState<OccupationType>({
    occupation: '',
    occupation_id: '',
    is_active: false,
    created_at: '',
  });

  useEffect(() => {
    if (createOccupationTypes.success) {
      const showToast = createToaster({
        message: createOccupationTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createOccupationTypes.success, dispatch]);

  useEffect(() => {
    if (updateOccupationTypes.success) {
      const showToast = createToaster({
        message: updateOccupationTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateOccupationTypes.success, dispatch]);

  useEffect(() => {
    if (inactivateOccupationTypes.success) {
      const showToast = createToaster({
        message: inactivateOccupationTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateOccupationTypes.success, dispatch]);

  useEffect(() => {
    if (createOccupationTypes.fail) {
      const showToast = createToaster({
        message: createOccupationTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createOccupationTypes.fail, dispatch]);

  useEffect(() => {
    if (updateOccupationTypes.fail) {
      const showToast = createToaster({
        message: updateOccupationTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateOccupationTypes.fail, dispatch]);

  useEffect(() => {
    if (inactivateOccupationTypes.fail) {
      const showToast = createToaster({
        message: inactivateOccupationTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateOccupationTypes.fail, dispatch]);

  const setInputValue = (value: string) => (input.current.value = value);

  const handleEditRequest = (elem: OccupationType) => {
    setInputValue(elem.occupation);
    setIsEdit(true);
    setStateOccupationType(elem);
  };

  useEffect(() => {
    dispatch(fetchOccupationTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
  }, [dispatch]);

  const resetForm = () => {
    setInputValue('');
    setStateOccupationType((prevState) => ({
      ...prevState,
      occupation: '',
    }));
    setIsEdit(false);
  };

  const addOccupationType = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isEdit) {
      dispatch(createOccupationType(stateOccupationType.occupation));
    } else {
      const newElem = update(stateOccupationType, {
        occupation: { $set: stateOccupationType.occupation },
      });
      dispatch(updateOccupationType(newElem));
    }
    resetForm();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateOccupationType(
      update(stateOccupationType, {
        occupation: { $set: e.target.value },
      })
    );
  };

  const handleTextButton = () => (isEdit ? 'Atualizar profissão' : 'Adicionar profissão');

  const disabledValidation = () => stateOccupationType.occupation === '';

  return (
    <form className="component__container" onSubmit={addOccupationType}>
      {verifyUserPermission(META_MODULE_NAME, WRITE_PERMISSION) && (
        <Input
          value={stateOccupationType?.occupation}
          onChange={onChangeInput}
          required
          inputRef={input}
          placeholder="Digite a profissão que gostaria de adicionar... *"
          button={{
            text: handleTextButton(),
            icon: 'plus',
            disabledValidation: disabledValidation(),
          }}
        />
      )}
      {isEdit && (
        <Button name="backwhite" onClick={resetForm}>
          Cancelar edição
        </Button>
      )}
      <h3>Profissões já cadastrados:</h3>
      <MetaTable
        loading={
          occupationTypesPaginated?.loading ||
          updateOccupationTypes?.loading ||
          inactivateOccupationTypes?.loading ||
          createOccupationTypes?.loading
        }
        empty={occupationTypesPaginated.empty}
        data={occupationTypesPaginated.data.result}
        headers={['Nome', 'Criado em']}
        columns={['occupation', 'created_at']}
        pathId="occupation_id"
        removeRequest={inactivateOccupationType}
        editRequest={handleEditRequest}
        group
      />
      {!occupationTypesPaginated?.empty && (
        <Pagination
          request={fetchOccupationTypesPaginated}
          loading={occupationTypesPaginated?.loading}
          data={occupationTypesPaginated?.data}
        />
      )}
    </form>
  );
};

export default React.memo(MetaOccupations);
