export const FETCH_TAG_TYPE = 'FETCH_TAG_TYPE';
export const FETCH_TAG_TYPE_SUCCESS = 'FETCH_TAG_TYPE_SUCCESS';
export const FETCH_TAG_TYPE_FAIL = 'FETCH_TAG_TYPE_FAIL';
export const FETCH_TAG_TYPE_PAGINATED = 'FETCH_TAG_TYPE_PAGINATED';
export const FETCH_TAG_TYPE_PAGINATED_SUCCESS = 'FETCH_TAG_TYPE_PAGINATED_SUCCESS';
export const FETCH_TAG_TYPE_PAGINATED_FAIL = 'FETCH_TAG_TYPE_PAGINATED_FAIL';
export const FETCH_TAG_TYPE_ID = 'FETCH_TAG_TYPE_ID';
export const FETCH_TAG_TYPE_ID_SUCCESS = 'FETCH_TAG_TYPE_ID_SUCCESS';
export const FETCH_TAG_TYPE_ID_FAIL = 'FETCH_TAG_TYPE__ID_FAIL';
export const CREATE_TAG_TYPE = 'CREATE_TAG_TYPE';
export const CREATE_TAG_TYPE_SUCCESS = 'CREATE_TAG_TYPE_SUCCESS';
export const CREATE_TAG_TYPE_FAIL = 'CREATE_TAG_TYPE_FAIL';
export const UPDATE_TAG_TYPE = 'UPDATE_TAG_TYPE';
export const UPDATE_TAG_TYPE_SUCCESS = 'UPDATE_TAG_TYPE_SUCCESS';
export const UPDATE_TAG_TYPE_FAIL = 'UPDATE_TAG_TYPE_FAIL';
export const INACTIVATE_TAG_TYPE = 'INACTIVATE_TAG_TYPE';
export const INACTIVATE_TAG_TYPE_SUCCESS = 'INACTIVATE_TAG_TYPE_SUCCESS';
export const INACTIVATE_TAG_TYPE_FAIL = 'INACTIVATE_TAG_TYPE_FAIL';
export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
