/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';
import { OccupationType } from './types';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

export const clearAllStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_ALL_STATUS,
  });
};

export const fetchOccupationTypes = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_OCCUPATIONS_TYPE,
    payload: {
      request: {
        url: '/occupations',
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_OCCUPATIONS_TYPE_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_OCCUPATIONS_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchOccupationTypesPaginated = (offset: number, limit: number) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_OCCUPATIONS_TYPE_PAGINATED,
    payload: {
      request: {
        url: `/occupations/paginated?offset=${offset}&limit=${limit}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_OCCUPATIONS_TYPE_PAGINATED_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_OCCUPATIONS_TYPE_PAGINATED_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchOccupationTypesId = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_OCCUPATIONS_TYPE_ID,
    payload: {
      request: {
        url: `/ocupations/${id}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_OCCUPATIONS_TYPE_ID_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_OCCUPATIONS_TYPE_ID_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const createOccupationType = (occupation: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.CREATE_OCCUPATIONS_TYPE,
    payload: {
      request: {
        url: '/occupations',
        method: 'POST',
        data: { occupation },
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.CREATE_OCCUPATIONS_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de anexo criado com sucesso!',
            },
          });
          dispatch(fetchOccupationTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.CREATE_OCCUPATIONS_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const updateOccupationType = (occupationType: OccupationType) => (dispatch: Dispatch) => {
  dispatch({
    type: c.UPDATE_OCCUPATIONS_TYPE,
    payload: {
      request: {
        url: `/occupations/${occupationType.occupation_id}`,
        method: 'PUT',
        data: { occupation: occupationType.occupation },
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.UPDATE_OCCUPATIONS_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de anexo atualizado com sucesso!',
            },
          });
          dispatch(fetchOccupationTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.UPDATE_OCCUPATIONS_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const inactivateOccupationType = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_OCCUPATIONS_TYPE,
    payload: {
      request: {
        url: `/occupations/${id}/inactivate`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_OCCUPATIONS_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de anexo arquivado com sucesso!',
            },
          });
          dispatch(fetchOccupationTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.INACTIVATE_OCCUPATIONS_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};
