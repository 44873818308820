export const FETCH_SCHEDULES = 'FETCH_SCHEDULES';
export const FETCH_SCHEDULES_SUCCESS = 'FETCH_SCHEDULES_SUCCESS';
export const FETCH_SCHEDULES_FAIL = 'FETCH_SCHEDULES_FAIL';

export const FETCH_SCHEDULE_ID = 'FETCH_SCHEDULE_ID';
export const FETCH_SCHEDULE_ID_SUCCESS = 'FETCH_SCHEDULE_ID_SUCCESS';
export const FETCH_SCHEDULE_ID_FAIL = 'FETCH_SCHEDULE_ID_FAIL';

export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS';
export const UPDATE_SCHEDULE_FAIL = 'UPDATE_SCHEDULE_FAIL';

export const UPDATE_SCHEDULE_STATE = 'UPDATE_SCHEDULE_STATE';
export const UPDATE_SCHEDULE_STATE_SUCCESS = 'UPDATE_SCHEDULE_STATE_SUCCESS';
export const UPDATE_SCHEDULE_STATE_FAIL = 'UPDATE_SCHEDULE_STATE_FAIL';

export const INACTIVATE_SCHEDULE = 'INACTIVATE_SCHEDULE';
export const INACTIVATE_SCHEDULE_SUCCESS = 'INACTIVATE_SCHEDULE_SUCCESS';
export const INACTIVATE_SCHEDULE_FAIL = 'INACTIVATE_SCHEDULE_FAIL';

export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
