export const FETCH_COURT_TYPE = 'FETCH_COURT_TYPE';
export const FETCH_COURT_TYPE_SUCCESS = 'FETCH_COURT_TYPE_SUCCESS';
export const FETCH_COURT_TYPE_FAIL = 'FETCH_COURT_TYPE_FAIL';
export const FETCH_COURT_TYPE_PAGINATED = 'FETCH_COURT_TYPE_PAGINATED';
export const FETCH_COURT_TYPE_PAGINATED_SUCCESS = 'FETCH_COURT_TYPE_PAGINATED_SUCCESS';
export const FETCH_COURT_TYPE_PAGINATED_FAIL = 'FETCH_COURT_TYPE_PAGINATED_FAIL';
export const FETCH_COURTS_BY_JUSTICE_AND_COUNTY = 'FETCH_COURTS_BY_JUSTICE_AND_COUNTY';
export const FETCH_COURTS_BY_JUSTICE_AND_COUNTY_SUCCESS = 'FETCH_COURTS_BY_JUSTICE_AND_COUNTY_SUCCESS';
export const FETCH_COURTS_BY_JUSTICE_AND_COUNTY_FAIL = 'FETCH_COURTS_BY_JUSTICE_AND_COUNTY_FAIL';
export const FETCH_COURT_TYPE_ID = 'FETCH_COURT_TYPE_ID';
export const FETCH_COURT_TYPE_ID_SUCCESS = 'FETCH_COURT_TYPE_ID_SUCCESS';
export const FETCH_COURT_TYPE_ID_FAIL = 'FETCH_COURT_TYPE__ID_FAIL';
export const CREATE_COURT_TYPE = 'CREATE_COURT_TYPE';
export const CREATE_COURT_TYPE_SUCCESS = 'CREATE_COURT_TYPE_SUCCESS';
export const CREATE_COURT_TYPE_FAIL = 'CREATE_COURT_TYPE_FAIL';
export const UPDATE_COURT_TYPE = 'UPDATE_COURT_TYPE';
export const UPDATE_COURT_TYPE_SUCCESS = 'UPDATE_COURT_TYPE_SUCCESS';
export const UPDATE_COURT_TYPE_FAIL = 'UPDATE_COURT_TYPE_FAIL';
export const INACTIVATE_COURT_TYPE = 'INACTIVATE_COURT_TYPE';
export const INACTIVATE_COURT_TYPE_SUCCESS = 'INACTIVATE_COURT_TYPE_SUCCESS';
export const INACTIVATE_COURT_TYPE_FAIL = 'INACTIVATE_COURT_TYPE_FAIL';
export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
