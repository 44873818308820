import { IBreadcrumbProps } from '@blueprintjs/core';

export const BREADCRUMB_PATHS: IBreadcrumbProps[] = [
  { href: '/painel/grupos/usuarios', text: 'Grupos e usuários' },
  window.location.pathname.includes('lista')
    ? { href: '/painel/grupos/usuarios', text: 'Usuários' }
    : { href: '/painel/grupos/lista', text: 'Grupos' },
];

export const COLUMNS = ['Usuário', 'Grupo'];

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';
export const FETCH_USERS_PERSON = 'FETCH_USERS_PERSON';
export const FETCH_USERS_PERSON_SUCCESS = 'FETCH_USERS_PERSON_SUCCESS';
export const FETCH_USERS_PERSON_FAIL = 'FETCH_USERS_PERSON_FAIL';
export const FETCH_USERS_PAGINATED = 'FETCH_USERS_PAGINATED';
export const FETCH_USERS_PAGINATED_SUCCESS = 'FETCH_USERS_PAGINATED_SUCCESS';
export const FETCH_USERS_PAGINATED_FAIL = 'FETCH_USERS_PAGINATED_FAIL';
export const INACTIVATE_USER = 'INACTIVATE_USER';
export const INACTIVATE_USER_SUCCESS = 'INACTIVATE_USER_SUCCESS';
export const INACTIVATE_USER_FAIL = 'INACTIVATE_USER_FAIL';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
