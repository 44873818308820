import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './styles.css';
import { Spinner, Button } from '@blueprintjs/core';
import BreadCrumb from '../../components/Breadcrumb';
import { BREADCRUMB_PATHS } from './constants';
import Input from '../../components/Input';
import { goToHere } from '../../utils/navigation';
import PermissionsTable from '../../components/PermissionsTable';
import { reducers } from '../../store/reducers';
import { PostRole } from '../UserGroups/types';
import { mountEntitiesPermissionsObject } from '../../utils/permissionsTable';
import { createRole } from '../Permissions/actions';
import createToaster from '../../components/Toaster';
import update from 'immutability-helper';

const CreateGroup: React.FC = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state: typeof reducers) => state.permissions.entities);
  const { loading, fail, success } = useSelector((state: typeof reducers) => state.permissions.newRoleStatus);
  const [newRole, setNewRole] = useState<PostRole>(mountEntitiesPermissionsObject(''));
  const [roleName, setRoleName] = useState('');

  useEffect(() => {
    if (success || fail) {
      const showToast = createToaster({
        message: success || fail,
        intent: fail ? 'danger' : 'success',
        icon: fail ? 'warning-sign' : 'tick',
      });
      showToast();
    }
  }, [success, fail]);

  const submitRole = () => {
    const newState = update( newRole, { name: { $set: roleName } } );
    dispatch(createRole(newState));
  };

  return (
    <div className="createg__container">
      <div className="createg__header">
        <BreadCrumb breads={BREADCRUMB_PATHS} />
        <div className="createg__subheader">
          <p className="title">Criação de um grupo de usuário</p>
          <div className="combo__button">
            <Button text="Cancelar criação" intent="danger" minimal onClick={() => goToHere('/painel/grupos/lista')} />
            <Button className="create" disabled={roleName.length < 1} text="Criar grupo" onClick={() => submitRole()} intent="primary" minimal />
          </div>
        </div>
      </div>
      <div className="createg__content">
        <p className="description">
          Digite o nome desejado para o novo grupo e em seguido marque as permissões que o mesmo possui.
        </p>
        <Input
          required
          value={roleName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRoleName(e.target.value)}
          placeholder="Nome do grupo de usuário *"
          type="text"
        />
        {newRole && !loading ? (
          <PermissionsTable create role={{ role: null, permissions: data }} callBack={setNewRole} newRole={newRole} />
        ) : (
          <Spinner intent="primary" size={25} />
        )}
      </div>
    </div>
  );
};

export default CreateGroup;