/* eslint-disable import/prefer-default-export */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-underscore-dangle */
import { Dispatch } from 'redux';
import * as c from './constants';
import { FullPersonData } from '../PeopleList/types';
import handleApiError from '../../utils/errors';

export const fetchPerson = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_PERSON,
    payload: {
      request: {
        url: `/persons/${id}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_PERSON_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.FETCH_PERSON_FAIL,
            payload: {
              error: handleApiError(error.toString()),
            },
          });
        },
      },
    },
  });
};

export const updatePerson = (person: FullPersonData) => (dispatch: Dispatch) => {
  dispatch({
    type: c.UPDATE_PERSON,
    payload: {
      request: {
        url: `/persons/${person.person_id}`,
        method: 'PUT',
        data: person,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.UPDATE_PERSON_SUCCESS,
            payload: {
              data: response.data,
            },
          });
          dispatch({
            type: c.CLEAR_ALL_STATUS,
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.UPDATE_PERSON_FAIL,
            payload: {
              error: handleApiError(error?.toString(), error?.response?.data?.detail),
            },
          });
          dispatch({
            type: c.CLEAR_ALL_STATUS,
          });
        },
      },
    },
  });
};

export const inactivatePerson = (person: FullPersonData) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_PERSON,
    payload: {
      request: {
        url: `/persons/${person.person_id}/inactivate`,
        method: 'PUT',
        data: person,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_PERSON_SUCCESS,
          });
          dispatch({
            type: c.CLEAR_ALL_STATUS,
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.INACTIVATE_PERSON_FAIL,
            payload: {
              error: handleApiError(error?.toString(), error?.response?.data?.detail),
            },
          });
          dispatch({
            type: c.CLEAR_ALL_STATUS,
          });
        },
      },
    },
  });
};
