/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import update from 'immutability-helper';
import Input from '../../components/Input';
import { reducers } from '../../store/reducers';
import {
  createDistrictType,
  inactivateDistrictType,
  updateDistrictType,
  clearAllStatus,
  fetchDistrictTypesPaginated,
} from './actions';
import MetaTable from '../../components/MetaTable';
import { DistrictType } from './types';
import '../Meta/styles.css';
import Button from '../../components/Button';
import createToaster from '../../components/Toaster';
import { STATE_LIST } from '../../constants/states';
import Selector from '../../components/Selector';
import { WRITE_PERMISSION, META_MODULE_NAME } from '../Permissions/constants';
import { verifyUserPermission } from '../../utils/permissions';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';
import Pagination from '../../components/Pagination';

const MetaCasesDistrictType: React.FC = () => {
  const dispatch = useDispatch();
  // BlueprintJS inputRef
  const input = React.useRef() as any;
  const districtTypesPaginated = useSelector((state: typeof reducers) => state.metacasesdistricttype.districtTypesPaginated);
  const createDistrictTypes = useSelector((state: typeof reducers) => state.metacasesdistricttype.createDistrictType);
  const updateDistrictTypes = useSelector((state: typeof reducers) => state.metacasesdistricttype.updateDistrictType);
  const inactivateDistrictTypes = useSelector(
    (state: typeof reducers) => state.metacasesdistricttype.inactivateDistrictType
  );
  const [isEdit, setIsEdit] = useState(false);
  const [brState, setBrState] = useState('');
  const [stateDistrictType, setStateDistrictType] = useState<DistrictType>({
    name: '',
    county_id: '',
    state: '',
    is_active: false,
    created_at: '',
  });

  useEffect(() => {
    if (createDistrictTypes.success) {
      const showToast = createToaster({
        message: createDistrictTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createDistrictTypes.success, dispatch]);

  useEffect(() => {
    if (updateDistrictTypes.success) {
      const showToast = createToaster({
        message: updateDistrictTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateDistrictTypes.success, dispatch]);

  useEffect(() => {
    if (inactivateDistrictTypes.success) {
      const showToast = createToaster({
        message: inactivateDistrictTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateDistrictTypes.success, dispatch]);

  useEffect(() => {
    if (createDistrictTypes.fail) {
      const showToast = createToaster({
        message: createDistrictTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createDistrictTypes.fail, dispatch]);

  useEffect(() => {
    if (updateDistrictTypes.fail) {
      const showToast = createToaster({
        message: updateDistrictTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateDistrictTypes.fail, dispatch]);

  useEffect(() => {
    if (inactivateDistrictTypes.fail) {
      const showToast = createToaster({
        message: inactivateDistrictTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateDistrictTypes.fail, dispatch]);

  const setInputValue = (value: string) => (input.current.value = value);

  const handleEditRequest = (elem: DistrictType) => {
    setBrState(elem.state);
    setInputValue(elem.name);
    setIsEdit(true);
    setStateDistrictType(elem);
  };

  useEffect(() => {
    dispatch(fetchDistrictTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
  }, [dispatch]);

  const resetForm = () => {
    setInputValue('');
    setStateDistrictType(
      update(stateDistrictType, {
        name: { $set: '' },
      })
    );
    setIsEdit(false);
    setBrState('');
  };

  const addDistrictType = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isEdit) {
      dispatch(createDistrictType(stateDistrictType.name, brState));
    } else {
      const newElem = update(stateDistrictType, {
        state: { $set: brState },
      });
      dispatch(updateDistrictType(newElem));
    }
    resetForm();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateDistrictType(
      update(stateDistrictType, {
        name: { $set: e.target.value },
      })
    );
  };

  const handleTextButton = () => (isEdit ? 'Atualizar comarca' : 'Adicionar comarca');

  const disabledValidation = () => brState === '' || stateDistrictType.name === '';

  return (
    <form className="component__container" onSubmit={addDistrictType}>
      {verifyUserPermission(META_MODULE_NAME, WRITE_PERMISSION) && (
        <Input
          value={stateDistrictType?.name}
          onChange={onChangeInput}
          inputRef={input}
          required
          placeholder="Digite a comarca que gostaria de adicionar... *"
          button={{
            text: handleTextButton(),
            icon: 'plus',
            disabledValidation: disabledValidation(),
          }}
        />
      )}
      <Selector setValue={setBrState} value={brState} required className="cases__court__selector" options={STATE_LIST} statesList />
      <div>
        {isEdit && (
          <div>
            <Button name="backwhite" onClick={resetForm}>
              Cancelar edição
            </Button>
          </div>
        )}
      </div>
      <h3>Comarcas já cadastrados:</h3>
      <MetaTable
        loading={
          districtTypesPaginated?.loading ||
          updateDistrictTypes?.loading ||
          inactivateDistrictTypes?.loading ||
          createDistrictTypes?.loading
        }
        empty={districtTypesPaginated.empty}
        data={districtTypesPaginated.data.result}
        headers={['Nome', 'Estado', 'Criado em']}
        columns={['name', 'state', 'created_at']}
        pathId="county_id"
        removeRequest={inactivateDistrictType}
        editRequest={handleEditRequest}
        group
      />
      {!districtTypesPaginated?.empty && (
        <Pagination
          request={fetchDistrictTypesPaginated}
          loading={districtTypesPaginated?.loading}
          data={districtTypesPaginated?.data}
        />
      )}
    </form>
  );
};

export default React.memo(MetaCasesDistrictType);
