/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';

export const fetchUsers = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_USERS,
    payload: {
      request: {
        url: '/users',
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_USERS_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_USERS_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchUsersWithPerson = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_USERS_PERSON,
    payload: {
      request: {
        url: '/users/person',
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_USERS_PERSON_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_USERS_PERSON_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchUsersPaginated = (offset: number, limit: number, optionsPath: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_USERS_PAGINATED,
    payload: {
      request: {
        url: `/users/paginated?offset=${offset}&limit=${limit}${optionsPath}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_USERS_PAGINATED_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_USERS_PAGINATED_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const updateUser = (user_id?: string, role_id?: string, person_id?: string | null, password?: string | null, username?: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.UPDATE_USER,
    payload: {
      request: {
        url: `/users/${user_id}`,
        method: 'PUT',
        data: {
          username,
          role_id,
          person_id,
          password
        },
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.UPDATE_USER_SUCCESS,
            payload: {
              data: response.data,
            },
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.UPDATE_USER_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const inactivateUser = (userId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_USER,
    payload: {
      request: {
        url: `/users/${userId}/inactivate`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_USER_SUCCESS,
            payload: {
              data: response.data,
            },
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.INACTIVATE_USER_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};
