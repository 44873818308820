import React, { useEffect, useState, SyntheticEvent } from 'react';
import './styles.css';
import Selector from '../Selector';
import Button from '../Button';
import { fetchTagTypes } from '../../modules/MetaCasesTag/actions';
import { useDispatch } from 'react-redux';
import { TagType } from '../../modules/MetaCasesTag/types';
import { TemporaryCaseType } from '../../modules/CreateCase/types';

interface ITagManagerProps {
  caseData: TemporaryCaseType;
  callBack: (tags: any) => void;
  removeCallBack: any;
  isFormDisabled: boolean;
  handleRemove?: Function;
  tagTypes: TagType[];
}

const TagsManager: React.FC<ITagManagerProps> = ({
  caseData,
  isFormDisabled,
  callBack,
  handleRemove,
  tagTypes,
  removeCallBack,
}) => {
  const dispatch = useDispatch();
  const [tag, setTag] = useState();

  useEffect(() => {
    dispatch(fetchTagTypes());
  }, [dispatch]);

  const tagAlreadyExists = () => caseData.tags.find((item: any) => item.tag_id === tag.tag_id);

  const addTag = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!tagAlreadyExists()) {
      callBack(caseData.tags.concat(tag));
    }
  };

  const handleTagChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newTag = tagTypes.find((item) => item.tag_id === e.target.value);
    setTag(newTag);
  };

  const getTags = () => (
    <>
      {caseData?.tags &&
        caseData.tags.map((tag: TagType) => (
          <span key={tag.tag_id} className="bp3-tag">
            {tag.tag}
            {!isFormDisabled && (
              <button
                type="button"
                aria-label="remove button"
                onClick={() => removeCallBack(tag.tag_id)}
                className="bp3-tag-remove"
              />
            )}
          </span>
        ))}
    </>
  );

  return (
    <div className="row tags__manager">
      <div className="form__box" id="tagForm">
        <div className="container">
          <div className="group">
            <Selector changeMethod={handleTagChange} disabled={isFormDisabled} className="new__selector" tags={tagTypes} />
            <Button
              className="max__height sidebar__button--blue"
              disabled={isFormDisabled || !tag}
              text="Adicionar assunto"
              icon="add"
              onClick={addTag}
            />
          </div>
          <div className="oabs">{getTags()}</div>
        </div>
      </div>
    </div>
  );
};

export default TagsManager;
