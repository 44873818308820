import React, { useState } from 'react';
import { Label } from '@blueprintjs/core';
import './styles.css';
import Selector from '../Selector';
import { STATE_LIST } from '../../constants/states';
import Input from '../Input';
import Button from '../Button';
import { LawyerOab, PersonData } from '../../modules/PeopleList/types';

interface IOabManagerProps {
  personData?: PersonData;
  setPersonData: Function;
  isFormDisabled: boolean;
  handleRemove: Function;
}

const OABManager: React.FC<IOabManagerProps> = ({ personData, isFormDisabled, setPersonData, handleRemove }) => {
  const [brState, setBrState] = useState('AC');
  const [oabNumber, setOabNumber] = useState('');

  const addOab = () => {
    setPersonData({
      oab_id: oabNumber,
      oab_state: brState,
    });
  };

  const getOabTags = () =>
  personData?.lawyers[0].oab_id && personData?.lawyers.map((oab: LawyerOab, index: number) => (
      <span key={`${oab.oab_id}${oab.oab_state}${index}`} className="bp3-tag">
        {`${oab.oab_id} - ${oab.oab_state} `}
        {!isFormDisabled && (
          <button
            type="button"
            aria-label="remove button"
            onClick={() => handleRemove(oab.oab_id)}
            className="bp3-tag-remove"
          />
        )}
      </span>
    ));

  return (
    <div className="row oab__manager">
      <div className="container">
        <div className="group">
          <Label>
            OAB(s)
            <Input
              maxLength={15}
              value={oabNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOabNumber(e.target.value)}
              disabled={isFormDisabled}
              placeholder="Digite a OAB desejada..."
            />
          </Label>
          <Selector
            disabled={isFormDisabled}
            setValue={setBrState}
            value={brState}
            className="selector__padding"
            options={STATE_LIST}
          />
          <Button
            className="max__height sidebar__button--blue"
            disabled={isFormDisabled}
            text="Nova OAB"
            icon="add"
            onClick={() => addOab()}
          />
        </div>
        {personData?.lawyers && personData?.lawyers?.length > 0 && <div className="oabs">{getOabTags()}</div>}
      </div>
    </div>
  );
};

export default OABManager;
