import React, { useState, useEffect, SyntheticEvent } from 'react';
import Button from '../../components/Button';
import { useSelector, useDispatch } from 'react-redux';
import { reducers } from '../../store/reducers';
import { createLawyerRelation, inactivateLawyerRelation, fetchConnectedLawyers } from './actions';
import '../../components/CaseRelations/styles.css';
import MetaTable from '../../components/MetaTable';
import createToaster from '../../components/Toaster';
import Autocomplete from '../../components/Autocomplete';
import { Tooltip, Checkbox } from '@blueprintjs/core';
import { fastRegisterPerson } from '../PeopleList/actions';
import FastRegisterDialog from '../../components/FastRegisterDialog';
import { fetchLegalCasePersonTypes } from '../MetaCasesLegalCasePerson/actions';
import './styles.css';
import { fetchLawyers } from '../CreateCase/actions';
import { RelatedLawyer } from './types';

interface ICaseRelatedLawyers {
  caseId: string;
}

const CaseRelatedLawyers: React.FC<ICaseRelatedLawyers> = ({ caseId }) => {
  const dispatch = useDispatch();
  const people = useSelector((state: typeof reducers) => state.caserelatedlawyers.connectedLawyers);
  const create = useSelector((state: typeof reducers) => state.caserelatedlawyers.create);
  const inactivate = useSelector((state: typeof reducers) => state.caserelatedlawyers.inactivate);
  const apiLawyers = useSelector((state: typeof reducers) => state.createcase.lawyers);
  const fastRegister = useSelector((state: typeof reducers) => state.peoplelist.fastRegister);
  const nameInput = React.useRef() as any;
  const cpfInput = React.useRef() as any;
  const cnpjInput = React.useRef() as any;
  const [connectionId, setConnectionId] = useState('');
  const [spouseName, setSpouseName] = useState('');
  const [isLegalPerson, setIsLegalPerson] = useState(false);
  const { loading } = useSelector((state: typeof reducers) => state.peoplelist.peopleList);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const formField = React.useRef() as React.MutableRefObject<HTMLFormElement>;
  const [isEdit, setIsEdit] = useState(false);
  const [isLawyerCustomer, setIsLawyerCustomer] = useState(false);

  useEffect(() => {
    dispatch(fetchLegalCasePersonTypes());
    dispatch(fetchLawyers('name='));
  }, [dispatch]);

  useEffect(() => {
    if (caseId?.length) {
      dispatch(fetchConnectedLawyers(caseId));
    }
  }, [dispatch, caseId]);

  useEffect(() => {
    if (
      inactivate?.success ||
      inactivate?.fail ||
      create?.success ||
      create?.fail
    ) {
      const showToast = createToaster({
        message:
          inactivate?.success ||
          inactivate?.fail ||
          create?.success ||
          create?.fail,
        intent: inactivate?.fail || create?.fail ? 'danger' : 'success',
        icon: inactivate?.fail || create?.fail ? 'warning-sign' : 'tick',
      });
      showToast();
    }
  }, [inactivate, create]);

  useEffect(() => {
    if (fastRegister.fail || fastRegister.success) {
      const showToast = createToaster({
        message: fastRegister.fail || fastRegister.success,
        intent: fastRegister.fail ? 'danger' : 'success',
        icon: 'warning-sign',
      });
      showToast();
    }
  }, [fastRegister.fail, fastRegister.success])

  useEffect(() => {
    if (fastRegister?.id.length) {
      setConnectionId(fastRegister.id)
    }
  }, [fastRegister]);

  const handleSendFastRegister = () => {
    setIsDialogOpen(false);
    setSpouseName(nameInput?.current?.value);
    if (isLegalPerson) {
      return dispatch(fastRegisterPerson({
        name: nameInput?.current?.value,
        legal_cnpj: cnpjInput?.current?.value,
        is_legal_person: isLegalPerson
      }));
    }
    return dispatch(fastRegisterPerson({
      name: nameInput?.current?.value,
      natural_cpf: cpfInput?.current?.value,
      is_legal_person: isLegalPerson
    }))
  }

  const getTable = () => (
    <div className="table__container">
      <MetaTable
        loading={inactivate?.loading || create?.loading}
        empty={people?.empty}
        data={people?.data}
        headers={['Nome', 'Advogado do cliente', 'OAB']}
        columns={['person_name', 'is_lawyer_customer', 'oab']}
        pathId="lawyer_id"
        removeRequest={inactivateLawyerRelation}
        editRequest={handleEditRequest}
        personId={caseId}
      />
    </div>
  );

  const handleEditRequest = (elem: RelatedLawyer) => {
    setConnectionId(elem.lawyer_id);
    setIsLawyerCustomer(elem.is_lawyer_customer);
    if (elem.person_name) {
      setSpouseName(elem.person_name)  
    }
    setIsEdit(true);
  };

  const clearForm = () => {
    setConnectionId('');
    setSpouseName('');
    setIsLawyerCustomer(false);
    setIsEdit(false);
  };

  const sendRelation = () => {
    dispatch(createLawyerRelation(caseId, {
      lawyer_id: connectionId,
      is_lawyer_customer: isLawyerCustomer
    }))
    clearForm();
  };

  const addSpouseTooltip = () => (
    <Tooltip
      captureDismiss
      content={'Clique aqui para cadastrar pessoa'}
    >
      <Button onClick={(e: SyntheticEvent) => setIsDialogOpen(true)} name="add" className="sidebar__button--blue" icon="add" />
    </Tooltip>
  );

  return (
    <div className="case__relations--people">
      <div className="tabs__subline"></div>
        <FastRegisterDialog
         isDialogOpen={isDialogOpen}
         setIsDialogOpen={setIsDialogOpen}
         loading={loading}
         nameInput={nameInput}
         cpfInput={cpfInput}
         cnpjInput={cnpjInput}
         handleSendFastRegister={handleSendFastRegister}
         isLegalPerson={isLegalPerson}
         setIsLegalPerson={setIsLegalPerson}
        />
      <div className="content__container">
        {getTable()}
        <form className="register__form" ref={formField}>
          <h2>Relacionar pessoa</h2>
          <Autocomplete 
            value={spouseName}
            setValue={setSpouseName}
            idKey="lawyer_id"
            callbackMethod={setConnectionId}
            request={fetchLawyers}
            path="name"
            data={apiLawyers?.data}
            placeholder="Nome do advogado(a)"
            rightElement={addSpouseTooltip()}
            keyOne="lawyer_id"
            nameValue="person_name"
          />
          <div className="checkbox__container">
            <Checkbox checked={isLawyerCustomer} onChange={() => setIsLawyerCustomer((state: boolean) => !state)}>Advogado(a) do cliente</Checkbox>
          </div>
          <div className="buttons__container">
            <Button name="add" text="Salvar" onClick={sendRelation} type="button" />
            {isEdit && <Button name="default" text="Cancelar edição" onClick={clearForm} type="button" />}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CaseRelatedLawyers;
