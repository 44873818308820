/* eslint-disable import/prefer-default-export */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-underscore-dangle */
import { Dispatch } from 'redux';
import * as c from './constants';
import { goToHere } from '../../utils/navigation';
import { UpdateCase } from './types';
import handleApiError from '../../utils/errors';

export const fetchCase = (legal_case_id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_CASE,
    payload: {
      request: {
        url: `/legal_cases/${legal_case_id}/`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_CASE_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.FETCH_CASE_FAIL,
            payload: {
              error: handleApiError(error.toString()),
            },
          });
        },
      },
    },
  });
};

export const updateCase = (caseData: UpdateCase) => (dispatch: Dispatch) => {
  dispatch({
    type: c.UPDATE_CASE,
    payload: {
      request: {
        url: `/legal_cases/${caseData.legal_case_id}`,
        method: 'PUT',
        data: caseData,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.UPDATE_CASE_SUCCESS,
            payload: {
              data: response.data,
            },
          });
          goToHere(`/painel/casos/detalhes/${response.data.data}`);
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.UPDATE_CASE_FAIL,
            payload: {
              error: handleApiError(error.toString(), error.response.data.detail),
            },
          });
        },
      },
    },
  });
};

export const inactivateRelatedCase = (caseId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_CASE,
    payload: {
      request: {
        url: `/legal_cases/${caseId}/inactivate`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_CASE_SUCCESS,
            payload: {
              data: response.data,
            },
          });
          goToHere('/painel/casos/lista');
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.INACTIVATE_CASE_FAIL,
            payload: {
              error: handleApiError(error.toString()),
            },
          });
        },
      },
    },
  });
};
