/* eslint-disable no-param-reassign */
import produce from 'immer';
import { Reducer, State } from './types';
import * as c from './constants';
import { mountTokenFromResponse } from '../../utils/auth';

const INITIAL_STATE: State = {
  fail: null,
  loading: false,
  token: '',
  user: null,
};

const login: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_LOGIN:
        draft.loading = true;
        draft.fail = null;
        return;

      case c.FETCH_LOGIN_SUCCESS:
        draft.loading = false;
        if (payload.data) {
          draft.user = payload.data.user;
          draft.token = mountTokenFromResponse(payload.data);
          sessionStorage.setItem('user', JSON.stringify(draft.user));
          sessionStorage.setItem('token', JSON.stringify(draft.token));
          draft.fail = null;
        } else {
          draft.fail = 'Ocorreu um erro no servidor';
        }
        return;

      case c.FETCH_LOGIN_FAIL:
        draft.loading = false;
        draft.fail = payload.error;
        return;

      default:
        return;
    }
  });

export default login;
