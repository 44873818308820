/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import '../../template.css';
import './styles.css';
import { setButtonArrowActive, setActiveButton } from '../../utils/button';
import { PEOPLE_PATH, ATTACHMENTS_PATH, CASES_PATH } from './constants';
import { isMobile } from '../../utils/window';
import { isCurrentRoute, goToHere } from '../../utils/navigation';

const Meta: React.FC = () => {
  return (
    <>
      {!isMobile() ? (
        <div className="sidebar__container">
          <button type="button" className={setActiveButton(PEOPLE_PATH)} onClick={() => goToHere('/painel/meta/pessoas')}>
            Pessoas
            <div className={setButtonArrowActive(PEOPLE_PATH)} />
          </button>
          <button type="button" className={setActiveButton(CASES_PATH)} onClick={() => goToHere('/painel/meta/casos')}>
            Casos
            <div className={setButtonArrowActive(CASES_PATH)} />
          </button>
          <button
            type="button"
            className={setActiveButton(ATTACHMENTS_PATH)}
            onClick={() => goToHere('/painel/meta/anexos')}
          >
            Anexos
            <div className={setButtonArrowActive(ATTACHMENTS_PATH)} />
          </button>
        </div>
      ) : (
        <>
          <li
            className="bp3-tab"
            role="tab"
            aria-selected={isCurrentRoute(PEOPLE_PATH)}
            onClick={() => goToHere('/painel/meta/pessoas')}
          >
            Pessoas
          </li>
          <li
            className="bp3-tab"
            role="tab"
            aria-selected={isCurrentRoute(CASES_PATH)}
            onClick={() => goToHere('/painel/meta/casos')}
          >
            Casos
          </li>
          <li
            className="bp3-tab"
            role="tab"
            aria-selected={isCurrentRoute(ATTACHMENTS_PATH)}
            onClick={() => goToHere('/painel/meta/anexos')}
          >
            Anexos
          </li>
        </>
      )}
    </>
  );
};

export default Meta;
