import { goToHere } from "./navigation";
import { verifyUserPermission } from "./permissions";
import { SCHEDULE_MODULE_NAME, READ_PERMISSION, PEOPLE_MODULE_NAME, CASES_MODULE_NAME, ROLE_MODULE_NAME, META_MODULE_NAME } from "../modules/Permissions/constants";

/* eslint-disable no-return-assign */
export const mountTokenFromResponse = (data: any): string => {
  return `${data.token_type} ${data.access_token}`;
};

export const logout = () => {
  return Promise.resolve((window.location.href = '/login')).then(() => {
    sessionStorage.clear();
  });
};

export const getResetToken = () => sessionStorage.getItem('PASS_TOKEN');

export const getUsernameFromEmail = (email: string) => email.split('@')[0];

export const getHomePageConsideringUserPermissions = () => {
  if(verifyUserPermission(PEOPLE_MODULE_NAME, READ_PERMISSION)) { return goToHere('/painel/pessoas/lista') }
  if(verifyUserPermission(SCHEDULE_MODULE_NAME, READ_PERMISSION)) { return goToHere('/painel/agenda') }
  if(verifyUserPermission(CASES_MODULE_NAME, READ_PERMISSION)) { return goToHere('/painel/casos/lista') }
  if(verifyUserPermission(ROLE_MODULE_NAME, READ_PERMISSION)) { return goToHere('/painel/grupos/lista') }
  if(verifyUserPermission(META_MODULE_NAME, READ_PERMISSION)) { return goToHere('/painel/meta/pessoas') }
}

export const getLoggedUsername = () => JSON.parse(sessionStorage.getItem('user')!).username;

export const getLoggedUserID = () => JSON.parse(sessionStorage.getItem('user')!).user_id;
