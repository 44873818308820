export const FETCH_DOCUMENTS_TYPE = 'FETCH_DOCUMENTS_TYPE';
export const FETCH_DOCUMENTS_TYPE_SUCCESS = 'FETCH_DOCUMENTS_TYPE_SUCCESS';
export const FETCH_DOCUMENTS_TYPE_FAIL = 'FETCH_DOCUMENTS_TYPE_FAIL';
export const FETCH_DOCUMENTS_TYPE_PAGINATED = 'FETCH_DOCUMENTS_TYPE_PAGINATED';
export const FETCH_DOCUMENTS_TYPE_PAGINATED_SUCCESS = 'FETCH_DOCUMENTS_TYPE_PAGINATED_SUCCESS';
export const FETCH_DOCUMENTS_TYPE_PAGINATED_FAIL = 'FETCH_DOCUMENTS_TYPE_PAGINATED_FAIL';
export const FETCH_DOCUMENTS_TYPE_ID = 'FETCH_DOCUMENTS_TYPE_ID';
export const FETCH_DOCUMENTS_TYPE_ID_SUCCESS = 'FETCH_DOCUMENTS_TYPE_ID_SUCCESS';
export const FETCH_DOCUMENTS_TYPE_ID_FAIL = 'FETCH_DOCUMENTS_TYPE__ID_FAIL';
export const CREATE_DOCUMENTS_TYPE = 'CREATE_DOCUMENTS_TYPE';
export const CREATE_DOCUMENTS_TYPE_SUCCESS = 'CREATE_DOCUMENTS_TYPE_SUCCESS';
export const CREATE_DOCUMENTS_TYPE_FAIL = 'CREATE_DOCUMENTS_TYPE_FAIL';
export const UPDATE_DOCUMENTS_TYPE = 'UPDATE_DOCUMENTS_TYPE';
export const UPDATE_DOCUMENTS_TYPE_SUCCESS = 'UPDATE_DOCUMENTS_TYPE_SUCCESS';
export const UPDATE_DOCUMENTS_TYPE_FAIL = 'UPDATE_DOCUMENTS_TYPE_FAIL';
export const INACTIVATE_DOCUMENTS_TYPE = 'INACTIVATE_DOCUMENTS_TYPE';
export const INACTIVATE_DOCUMENTS_TYPE_SUCCESS = 'INACTIVATE_DOCUMENTS_TYPE_SUCCESS';
export const INACTIVATE_DOCUMENTS_TYPE_FAIL = 'INACTIVATE_DOCUMENTS_TYPE_FAIL';
export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
