import React from 'react';
import Header from '../../components/Header';
import './styles.css';
import { Icon } from '@blueprintjs/core';


const FailPermission: React.FC = () => {
  return (
    <div className="fail__permission__container">
      <Header />
      <div className="center__container">
        <p>
          <Icon iconSize={35} icon="lock"></Icon>
          Você <strong>não possui permissão</strong> para acessar esta página, por favor, peça à algum administrador do módulo <strong>Grupos e Usuários</strong> para
          lhe dar as devidas permissões.
        </p>
      </div>
    </div>
  );
};

export default FailPermission;