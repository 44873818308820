/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox } from '@blueprintjs/core';
import update from 'immutability-helper';
import Input from '../../components/Input';
import { reducers } from '../../store/reducers';
import {
  createLegalCasePersonType,
  inactivateLegalCasePersonType,
  updateLegalCasePersonType,
  clearAllStatus,
  fetchLegalCasePersonTypesPaginated,
} from './actions';
import MetaTable from '../../components/MetaTable';
import { LegalCasePersonType } from './types';
import '../Meta/styles.css';
import Button from '../../components/Button';
import createToaster from '../../components/Toaster';
import { WRITE_PERMISSION, META_MODULE_NAME } from '../Permissions/constants';
import { verifyUserPermission } from '../../utils/permissions';
import Pagination from '../../components/Pagination';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

const MetaCasesLegalCasePerson: React.FC = () => {
  const dispatch = useDispatch();
  // BlueprintJS inputRef
  const input = React.useRef() as any;
  const legalCasePersonTypesPaginated = useSelector((state: typeof reducers) => state.metacaseslegalcaseperson.legalCasePersonTypesPaginated);
  const createLegalCasePersonTypes = useSelector(
    (state: typeof reducers) => state.metacaseslegalcaseperson.createLegalCasePersonType
  );
  const updateLegalCasePersonTypes = useSelector(
    (state: typeof reducers) => state.metacaseslegalcaseperson.updateLegalCasePersonType
  );
  const inactivateLegalCasePersonTypes = useSelector(
    (state: typeof reducers) => state.metacaseslegalcaseperson.inactivateLegalCasePersonType
  );
  const [isEdit, setIsEdit] = useState(false);
  const [isPart, setIsPart] = useState(false);
  const [isActivePole, setIsActivePole] = useState(false);
  const [stateLegalCasePersonType, setStateLegalCasePersonType] = useState<LegalCasePersonType>({
    name: '',
    is_part: false,
    is_active_pole: false,
    person_type_id: '',
    created_at: '',
    is_active: false,
  });

  useEffect(() => {
    dispatch(fetchLegalCasePersonTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
  }, [dispatch]);

  useEffect(() => {
    if (createLegalCasePersonTypes.success) {
      const showToast = createToaster({
        message: createLegalCasePersonTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createLegalCasePersonTypes.success, dispatch]);

  useEffect(() => {
    if (updateLegalCasePersonTypes.success) {
      const showToast = createToaster({
        message: updateLegalCasePersonTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateLegalCasePersonTypes.success, dispatch]);

  useEffect(() => {
    if (inactivateLegalCasePersonTypes.success) {
      const showToast = createToaster({
        message: inactivateLegalCasePersonTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateLegalCasePersonTypes.success, dispatch]);

  useEffect(() => {
    if (createLegalCasePersonTypes.fail) {
      const showToast = createToaster({
        message: createLegalCasePersonTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createLegalCasePersonTypes.fail, dispatch]);

  useEffect(() => {
    if (updateLegalCasePersonTypes.fail) {
      const showToast = createToaster({
        message: updateLegalCasePersonTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateLegalCasePersonTypes.fail, dispatch]);

  useEffect(() => {
    if (inactivateLegalCasePersonTypes.fail) {
      const showToast = createToaster({
        message: inactivateLegalCasePersonTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateLegalCasePersonTypes.fail, dispatch]);

  const setInputValue = (value: string) => (input.current.value = value);

  const setUpNewState = (elem: LegalCasePersonType) =>
    update(stateLegalCasePersonType, {
      name: { $set: elem.name },
      person_type_id: { $set: elem.person_type_id },
      is_part: { $set: elem.is_part },
      is_active_pole: { $set: elem.is_active_pole },
      created_at: { $set: elem.created_at },
      is_active: { $set: elem.is_active },
    });

  const handleEditRequest = (elem: LegalCasePersonType) => {
    setInputValue(elem.name);
    setIsEdit(true);
    setIsPart(elem.is_part);
    setIsActivePole(elem.is_active_pole);
    setStateLegalCasePersonType(setUpNewState(elem));
  };

  const resetForm = () => {
    setInputValue('');
    const newState = update(stateLegalCasePersonType, {
      is_part: { $set: false },
      is_active_pole: { $set: false },
      name: { $set: '' },
    });
    setStateLegalCasePersonType(newState);
    setIsPart(false);
    setIsActivePole(false);
    setIsEdit(false);
  };

  const addLegalCasePersonType = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isEdit) {
      dispatch(createLegalCasePersonType(stateLegalCasePersonType.name, isPart, isActivePole));
    } else {
      const newElem = update(stateLegalCasePersonType, {
        name: { $set: input.current.value },
        is_part: { $set: isPart },
        is_active_pole: { $set: isActivePole },
      });
      dispatch(updateLegalCasePersonType(newElem));
    }
    resetForm();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateLegalCasePersonType(
      update(stateLegalCasePersonType, {
        name: { $set: e.target.value },
      })
    );
  };

  const handleTextButton = () => (isEdit ? 'Atualizar' : 'Adicionar');

  const disabledValidation = () => stateLegalCasePersonType.name === '';

  return (
    <form className="component__container" onSubmit={addLegalCasePersonType}>
      {verifyUserPermission(META_MODULE_NAME, WRITE_PERMISSION) && (
        <Input
          value={stateLegalCasePersonType?.name}
          onChange={onChangeInput}
          inputRef={input}
          required
          placeholder="Digite o tipo de pessoa que gostaria de adicionar..."
          button={{
            text: handleTextButton(),
            icon: 'plus',
            disabledValidation: disabledValidation(),
          }}
        />
      )}
      <div className="checkbox__combo">
        <Checkbox className="isSecret" checked={isPart} onChange={() => setIsPart((prevState) => !prevState)}>
          Parte
        </Checkbox>
        <Checkbox className="isSecret" checked={isActivePole} onChange={() => setIsActivePole((prevState) => !prevState)}>
          Polo ativo
        </Checkbox>
      </div>
      {isEdit && (
        <Button name="backwhite" onClick={resetForm}>
          Cancelar edição
        </Button>
      )}
      <h3>Tipos de pessoa em casos já cadastrados:</h3>
      <MetaTable
        loading={
          legalCasePersonTypesPaginated?.loading ||
          updateLegalCasePersonTypes?.loading ||
          inactivateLegalCasePersonTypes?.loading ||
          createLegalCasePersonTypes?.loading
        }
        empty={legalCasePersonTypesPaginated.empty}
        data={legalCasePersonTypesPaginated.data.result}
        headers={['Nome', 'Parte', 'Polo ativo', 'Criado em']}
        columns={['name', 'is_part', 'is_active_pole', 'created_at']}
        pathId="person_type_id"
        removeRequest={inactivateLegalCasePersonType}
        editRequest={handleEditRequest}
        group
      />
      {!legalCasePersonTypesPaginated?.empty && (
        <Pagination
          request={fetchLegalCasePersonTypesPaginated}
          loading={legalCasePersonTypesPaginated?.loading}
          data={legalCasePersonTypesPaginated?.data}
        />
      )}
    </form>
  );
};

export default React.memo(MetaCasesLegalCasePerson);
