import React, { useState, useEffect } from 'react';
import { Dialog, Spinner, Label, TextArea, Tooltip, NonIdealState } from '@blueprintjs/core';
import Input from '../Input';
import './styles.css';
import { Schedule } from '../../modules/CaseRelatedSchedules/types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProgressTypes } from '../../modules/MetaCasesProgressType/actions';
import { fetchProgressStatus } from '../../modules/MetaCasesProgressStatus/actions';
import { reducers } from '../../store/reducers';
import DatePicker from '../DatePicker';
import { dateFormatter, momentFormatBrDate } from '../../utils/date';
import Selector from '../Selector';
import Button from '../Button';
import { GetCaseProgress } from '../../modules/CaseRelatedProgress/types';
import { inactivateSchedule, updateScheduleState, updateSchedule } from '../../modules/CaseRelatedSchedules/actions';
import createToaster from '../Toaster';
import { fetchProgressById } from '../../modules/CaseRelatedProgress/actions';
import moment from 'moment';

interface IScheduleSideDialog {
  loading?: boolean;
  isDialogOpen: boolean;
  setIsDialogOpen: (arg: boolean) => void;
  data: GetCaseProgress;
  selectedSchedule?: string;
};

const ScheduleSideDialog: React.FC<IScheduleSideDialog> = ({
  loading,
  isDialogOpen,
  setIsDialogOpen,
  data,
  selectedSchedule
}) => {
  const dispatch = useDispatch();
  const progressStatus = useSelector((state: typeof reducers) => state.metacasesprogressstatus.progressStatus);
  const progressTypes = useSelector((state: typeof reducers) => state.metacasesprogresstype.progressTypes);
  const progressById = useSelector((state: typeof reducers) => state.caserelatedprogress.progressById);
  const finalizeSchedule = useSelector((state: typeof reducers) => state.caserelatedschedules.updateScheduleState);
  const scheduleInactivate = useSelector((state: typeof reducers) => state.caserelatedschedules.inactivateSchedule);
  const scheduleUpdate = useSelector((state: typeof reducers) => state.caserelatedschedules.updateSchedule);
  const [caseProgress, setCaseProgress] = useState<GetCaseProgress | null>(null);
  const [progressStatusId, setProgressStatusId] = useState<string | null>(null);
  const [progressTypeId, setProgressTypeId] = useState<string | null>(null);
  const [progressText, setProgressText] = useState('');
  const [progressStartDate, setProgressStartDate] = useState<Date>();
  const [progressStartTime, setProgressStartTime] = useState<string>();
  const [progressSchedules, setProgressSchedules] = useState<Schedule[]>();

  const [rescheduleOpen, setRescheduleOpen] = useState(false);
  const [reStartDate, setReStartDate] = useState<any>();
  const [reStartTime, setReStartTime] = useState<any>();
  const [reEndDate, setReEndDate] = useState<any>();
  const [reEndTime, setReEndTime] = useState<any>();
  const [reSchedule, setReSchedule] = useState<Schedule>();

  useEffect(() => {
    if (progressById.data?.case_progress_id) {
      setCaseProgress(progressById.data!);
    }
  }, [progressById]);

  useEffect(() => {
    if (finalizeSchedule?.fail || finalizeSchedule?.success) {
      const showToast = createToaster({
        message: finalizeSchedule?.fail || finalizeSchedule?.success,
        intent: finalizeSchedule?.fail ? 'danger' : 'success',
        icon: finalizeSchedule?.fail ? 'warning-sign' : 'tick',
      });
      showToast();
      if (finalizeSchedule?.success) {
        dispatch(fetchProgressById(caseProgress?.case_progress_id!));
      }
    }
  }, [finalizeSchedule, caseProgress, dispatch]);

  useEffect(() => {
    if (scheduleInactivate?.fail || scheduleInactivate?.success) {
      const showToast = createToaster({
        message: scheduleInactivate?.fail || scheduleInactivate?.success,
        intent: scheduleInactivate?.fail ? 'danger' : 'success',
        icon: scheduleInactivate?.fail ? 'warning-sign' : 'tick',
      });
      showToast();
      if (scheduleInactivate?.success) {
        dispatch(fetchProgressById(caseProgress?.case_progress_id!));
      }
    }
  }, [scheduleInactivate, caseProgress, dispatch]);

  useEffect(() => {
    if (scheduleUpdate?.fail || scheduleUpdate?.success) {
      const showToast = createToaster({
        message: scheduleUpdate?.fail || scheduleUpdate?.success,
        intent: scheduleUpdate?.fail ? 'danger' : 'success',
        icon: scheduleUpdate?.fail ? 'warning-sign' : 'tick',
      });
      showToast();
      if (scheduleUpdate?.success) {
        dispatch(fetchProgressById(caseProgress?.case_progress_id!));
        setRescheduleOpen(false);
      }
    }
  }, [scheduleUpdate, caseProgress, dispatch]);

  useEffect(() => {
    dispatch(fetchProgressStatus());
    dispatch(fetchProgressTypes());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      setCaseProgress(data);
    }
  }, [data]);

  useEffect(() => {
    if (caseProgress?.case_progress_id) {
      setProgressStatusId(caseProgress?.case_progress_status?.case_progress_status_id);
      setProgressTypeId(caseProgress?.case_progress_type?.case_progress_type_id);
      const minutes = new Date(caseProgress?.case_progress_date).getMinutes();
      const hours = new Date(caseProgress?.case_progress_date).getHours();
      setProgressStartTime(`${formatNumberForLess(hours.toString())}:${formatNumberForLess(minutes.toString())}`);
      setProgressStartDate(new Date(caseProgress?.case_progress_date));
      setProgressText(caseProgress?.text);
      setProgressSchedules(caseProgress?.schedules);
    }
  }, [caseProgress]);

  const formatNumberForLess = (value: string) => {
    let time = value;
    if (Number(time) < 10) {
      time = `0${value}`
    }
    return time;
  };

  const getScheduleStartDate = (item: Schedule) => {
    const date = new Date(item.date_start);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} às ${formatNumberForLess(date.getHours().toString())}:${formatNumberForLess(date.getMinutes().toString())}`;
  };

  const getScheduleEndDate = (item: Schedule) => {
    const date = new Date(item.date_end);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} às ${formatNumberForLess(date.getHours().toString())}:${formatNumberForLess(date.getMinutes().toString())}`;
  };

  const handleOpenRescheduleDialog = (item: Schedule) => {
    setReSchedule(item);
    const startDate = new Date(item.date_start);
    const endDate = new Date(item.date_end);
    const startMinutes = startDate.getMinutes();
    const startHours = startDate.getHours();
    setReStartTime(`${formatNumberForLess(startHours.toString())}:${formatNumberForLess(startMinutes.toString())}`);
    const endMinutes = endDate.getMinutes();
    const endHours = endDate.getHours();
    setReEndTime(`${formatNumberForLess(endHours.toString())}:${formatNumberForLess(endMinutes.toString())}`);
    setReStartDate(startDate);
    setReEndDate(endDate);
    setRescheduleOpen(true);
  };

  const renderSchedulesList = () => (
    <ul>
      {progressSchedules &&
        progressSchedules.map((item: Schedule) => (
          <li className={`schedule__item ${(selectedSchedule && item.schedule_id === selectedSchedule) ? 'active' : ''}`} key={item.schedule_id}>
            <div className="item__header">
              <div className="schedule__date__container">
                <span className="schedule__date">{getScheduleStartDate(item)}</span> até as
                {item.date_end.length ? (
                  <span className="schedule__date">{getScheduleEndDate(item)}</span>
                ) : (
                  <>-</>
                )}
              </div>
              <span>{item.title}</span>
              <Button
                className="remove__schedule"
                icon="trash"
                text="Remover"
                onClick={() => dispatch(inactivateSchedule(item.schedule_id!))}
              />
            </div>
            <p>{item.text}</p>
            <div className="schedule__actions">
              <Button
                icon="calendar"
                className="reschedule"
                text="Reagendar"
                onClick={() => handleOpenRescheduleDialog(item)}
                type="button"
              />
              {item.status !== 'Finalizado' && (
                <Tooltip content="Clique aqui para concluir este agendamento." captureDismiss>
                  <Button
                    className="confirm__button"
                    onClick={() => dispatch(updateScheduleState(item.schedule_id!))}
                    icon="tick-circle"
                    text="Finalizar"
                  />
                </Tooltip>
              )}
            </div>
          </li>
        ))}
    </ul>
  );

  const handleReschedule = () => {
    if (reStartDate && reEndDate) {
      dispatch(
        updateSchedule({
          ...reSchedule!,
          date_start: dateFormatter(reStartDate, reStartTime),
          date_end: dateFormatter(reEndDate, reEndTime),
        })
      );
    }
  };

  const renderRescheduleDialog = () => (
    <Dialog
      className="reschedule__dialog"
      isOpen={rescheduleOpen}
      onClose={() => setRescheduleOpen(false)}
      canEscapeKeyClose
    >
      <>
        <h2>Reagendar: {reSchedule?.title || ''}</h2>
        <div className="input__combo">
          <Label>
            <span>Data de início</span>
            <DatePicker
              className="date__picker"
              minLimitDate={new Date(1950,11,11)}
              maxLimitDate={new Date(2100, 11, 11)}
              onChangeMethod={(newDate: Date) => setReStartDate(newDate)}
              value={reStartDate ? reStartDate : undefined}
            />
          </Label>
          <Label>
            <span>Horário de início (HH:MM)</span>
            <Input
              maxLength={5}
              placeholder="HH:MM"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReStartTime(e.target.value)}
              value={reStartTime}
            />
          </Label>
        </div>
        <div className="input__combo">
          <Label>
            <span>Data de encerramento</span>
            <DatePicker
              className="date__picker"
              onChangeMethod={(newDate: Date) => setReEndDate(newDate)}
              value={reEndDate ? reEndDate : undefined}
              minLimitDate={new Date(1950,11,11)}
              maxLimitDate={new Date(2100, 11, 11)}
            />
          </Label>
          <Label>
            <span>Horário de encerramento (HH:MM)</span>
            <Input
              maxLength={5}
              placeholder="HH:MM"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReEndTime(e.target.value)}
              value={reEndTime}
            />
          </Label>
        </div>
        <div className="button__group">
          {!scheduleUpdate.loading ? (
            <>
              <Button intent="primary" onClick={() => handleReschedule()} text="Confirmar" />
              <Button intent="danger" minimal text="Não" onClick={() => setRescheduleOpen(false)} />
            </>
          ) : (
            <Spinner intent="primary" size={25} />
          )}
        </div>
      </>
    </Dialog>
  );

  return (
    <div className="container__schedule__dialog">
      <Dialog className="schedule__dialog" isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} canEscapeKeyClose>
        {loading ? (
          <Spinner intent="primary" size={25} />
        ) : (
          <div>
            {renderRescheduleDialog()}
            <div className="header__line">
              <Button
                className="bp3-button back__button"
                onClick={() => setIsDialogOpen(false)}
                icon="chevron-left"
                text="Voltar"
                intent="primary"
              />
              <h2>{caseProgress?.case_progress_status?.status}</h2>
              <a className="go__case" href={`/painel/casos/detalhes/${caseProgress?.legal_case_id}`}>Ir para o caso</a>
            </div>
            <form>
              <div className="input__cell">
                <Label>
                  <span>Data do andamento</span>
                  <Input value={progressStartDate ? momentFormatBrDate(progressStartDate) : ''} disabled />
                </Label>
                <Label>
                  <span>Horário do andamento</span>
                  <Input value={progressStartTime} disabled />
                </Label>
              </div>
              <div className="input__cell">
                <Label>
                  <span>Tipo do andamento</span>
                  <Selector required disabled value={progressTypeId ?? undefined} progressTypes={progressTypes?.data} />
                </Label>
                <Label>
                  <span>Status do andamento</span>
                  <Selector required disabled value={progressStatusId ?? undefined} caseProgressSituation={progressStatus?.data} />
                </Label>
              </div>
              <div className="input__cell">
                <Label>
                  <span>Origem</span>
                  <Input value={caseProgress?.origin} disabled />
                </Label>
                <Label>
                  <span>Agendado em</span>
                  <Input value={moment(caseProgress?.created_at).format('DD/MM/YYYY')} disabled />
                </Label>
              </div>
              <Label className="textarea">
                <span>Texto do andamento</span>
                <TextArea className="progress__text" disabled large value={progressText} placeholder="Texto do andamento *" />
              </Label>
              <div className="input__cell">
                <Label>
                  <span>Número do processo</span>
                  <Input value={caseProgress?.process_id ? caseProgress?.process_id : '-'} disabled />
                </Label>
                <Label>
                  <span>Título do caso</span>
                  <Input value={caseProgress?.title} disabled />
                </Label>
              </div>
              <Label className="textarea">
                <span>Vara</span>
                <Input value={caseProgress?.court ? caseProgress?.court : ''} disabled />
              </Label>
              <div className="input__cell">
                <Label>
                  <span>Pessoas relacionadas</span>
                  {caseProgress?.related_lawyers?.map((item: string) => 
                  <li>
                    {item}
                  </li>
                  )}
                </Label>
                <Label>
                  <span>Advogados relacionados</span>
                  {caseProgress?.related_persons?.map((item: string) => 
                  <li>
                    {item}
                  </li>
                  )}
                </Label>
              </div>
            </form>
            <h4 className="schedules__header">Agendamentos</h4>
            {progressSchedules?.length ? (
              renderSchedulesList()
            ) : (
              <NonIdealState icon="inbox" title="Nenhum dado cadastrado" />
            )}
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default ScheduleSideDialog;
