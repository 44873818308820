/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';
import { goToHere } from '../../utils/navigation';

const INITIAL_STATE: State = {
  entity: {
    dataId: {
      role: null,
      permissions: [],
    },
    loadingId: false,
    failId: null,
  },
  entities: {
    data: [],
    loading: false,
    fail: null,
  },
  newRoleStatus: {
    loading: false,
    fail: null,
    success: null,
  },
  deleteStatus: {
    loadingDelete: false,
    failDelete: null,
    successDelete: null,
  },
};

const permissions: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_ENTITIES:
        draft.entities.loading = true;
        draft.entities.fail = null;
        return;

      case c.FETCH_ENTITIES_SUCCESS:
        draft.entities.loading = false;
        draft.entities.fail = null;
        if (payload && payload.data.length) {
          draft.entities.data = payload.data;
        }
        return;

      case c.FETCH_ENTITIES_FAIL:
        draft.entities.loading = false;
        draft.entities.fail = payload.error;
        draft.entities.data = payload.data;
        return;

      case c.FETCH_ENTITIES_BY_ID:
        draft.entity.loadingId = true;
        draft.entity.failId = null;
        return;

      case c.FETCH_ENTITIES_BY_ID_SUCCESS:
        draft.entity.loadingId = false;
        draft.entity.failId = null;
        if (payload && payload.data) {
          draft.entity.dataId = payload.data;
        }
        return;

      case c.FETCH_ENTITIES_BY_ID_FAIL:
        draft.entity.loadingId = false;
        draft.entity.failId = payload.error;
        draft.entity.dataId = payload.data;
        return;

      case c.CREATE_ROLE:
        draft.newRoleStatus.loading = true;
        draft.newRoleStatus.fail = null;
        return;

      case c.CREATE_ROLE_SUCCESS:
        draft.newRoleStatus.loading = false;
        draft.newRoleStatus.success = 'Grupo criado com sucesso!';
        setTimeout(() => {
          goToHere('/painel/grupos/lista');
        }, 1000);
        return;

      case c.CREATE_ROLE_FAIL:
        draft.newRoleStatus.loading = false;
        draft.newRoleStatus.fail = payload.error;
        return;

      case c.UPDATE_ROLE:
        draft.newRoleStatus.loading = true;
        draft.newRoleStatus.fail = null;
        return;

      case c.UPDATE_ROLE_SUCCESS:
        draft.newRoleStatus.loading = false;
        draft.newRoleStatus.success = 'Grupo atualizado com sucesso!';
        setTimeout(() => {
          goToHere('/painel/grupos/lista');
        }, 1000);
        return;

      case c.UPDATE_ROLE_FAIL:
        draft.newRoleStatus.loading = false;
        draft.newRoleStatus.fail = payload.error;
        setTimeout(() => {
          goToHere('/painel/grupos/lista');
        }, 1000);
        return;

      case c.INACTIVATE_ROLE:
        draft.deleteStatus.loadingDelete = true;
        draft.deleteStatus.failDelete = null;
        return;

      case c.INACTIVATE_ROLE_SUCCESS:
        draft.deleteStatus.loadingDelete = false;
        draft.deleteStatus.successDelete = 'Grupo deletado com sucesso!';
        return;

      case c.INACTIVATE_ROLE_FAIL:
        draft.deleteStatus.loadingDelete = false;
        draft.deleteStatus.failDelete = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.newRoleStatus.fail = null;
        draft.newRoleStatus.success = null;
        draft.newRoleStatus.loading = false;
        return;

      default:
        return;
    }
  });

export default permissions;
