/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

export const clearAllStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_ALL_STATUS,
  });
};

export const fetchRelatedCases = (offset: number, limit = DEFAULT_PER_PAGE_LIMIT, path: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_CASES_RELATION,
    payload: {
      request: {
        url: `/persons/${path.split(',')[0]}/legal_cases?name=${path.split(',')[1]}&offset=${offset}&limit=${limit}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_CASES_RELATION_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_CASES_RELATION_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};
