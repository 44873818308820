/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  documentTypes: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  documentTypesPaginated: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  documentTypeId: {
    data: null,
    loading: false,
    fail: null,
  },
  createDocumentType: {
    loading: false,
    fail: null,
    success: null,
  },
  updateDocumentType: {
    loading: false,
    fail: null,
    success: null,
  },
  inactivateDocumentType: {
    loading: false,
    fail: null,
    success: null,
  },
};

const metaCases: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_DOCUMENTS_TYPE:
        draft.documentTypes.loading = true;
        draft.documentTypes.empty = true;
        draft.documentTypes.fail = null;
        return;

      case c.FETCH_DOCUMENTS_TYPE_SUCCESS:
        draft.documentTypes.loading = false;
        draft.documentTypes.fail = null;
        if (payload && payload.data.length) {
          draft.documentTypes.data = payload.data;
          draft.documentTypes.empty = false;
        }
        return;

      case c.FETCH_DOCUMENTS_TYPE_FAIL:
        draft.documentTypes.loading = false;
        draft.documentTypes.fail = payload.error;
        draft.documentTypes.data = payload.data;
        return;

        case c.FETCH_DOCUMENTS_TYPE_PAGINATED:
          draft.documentTypesPaginated.loading = true;
          draft.documentTypesPaginated.empty = true;
          draft.documentTypesPaginated.fail = null;
          return;
  
        case c.FETCH_DOCUMENTS_TYPE_PAGINATED_SUCCESS:
          draft.documentTypesPaginated.loading = false;
          draft.documentTypesPaginated.fail = null;
          if (payload && payload.data.result.length) {
            draft.documentTypesPaginated.data = payload.data;
            draft.documentTypesPaginated.empty = false;
          }
          return;
  
        case c.FETCH_DOCUMENTS_TYPE_PAGINATED_FAIL:
          draft.documentTypesPaginated.loading = false;
          draft.documentTypesPaginated.fail = payload.error;
          draft.documentTypesPaginated.data = payload.data;
          return;

      case c.FETCH_DOCUMENTS_TYPE_ID:
        draft.documentTypeId.loading = true;
        draft.documentTypeId.fail = null;
        return;

      case c.FETCH_DOCUMENTS_TYPE_ID_SUCCESS:
        draft.documentTypeId.loading = false;
        draft.documentTypeId.fail = null;
        if (payload && payload.data.length) {
          draft.documentTypeId.data = payload.data;
        }
        return;

      case c.FETCH_DOCUMENTS_TYPE_ID_FAIL:
        draft.documentTypeId.loading = false;
        draft.documentTypeId.fail = payload.error;
        draft.documentTypeId.data = payload.data;
        return;

      case c.CREATE_DOCUMENTS_TYPE:
        draft.createDocumentType.loading = true;
        draft.createDocumentType.fail = null;
        draft.createDocumentType.success = null;
        return;

      case c.CREATE_DOCUMENTS_TYPE_SUCCESS:
        draft.createDocumentType.loading = false;
        draft.createDocumentType.success = payload.data;
        return;

      case c.CREATE_DOCUMENTS_TYPE_FAIL:
        draft.createDocumentType.loading = false;
        draft.createDocumentType.fail = payload.error;
        draft.createDocumentType.success = null;
        return;

      case c.UPDATE_DOCUMENTS_TYPE:
        draft.updateDocumentType.loading = true;
        draft.updateDocumentType.fail = null;
        draft.updateDocumentType.success = null;
        return;

      case c.UPDATE_DOCUMENTS_TYPE_SUCCESS:
        draft.updateDocumentType.loading = false;
        draft.updateDocumentType.fail = null;
        draft.updateDocumentType.success = payload.data;
        return;

      case c.UPDATE_DOCUMENTS_TYPE_FAIL:
        draft.updateDocumentType.loading = false;
        draft.updateDocumentType.fail = payload.error;
        draft.updateDocumentType.success = null;
        return;

      case c.INACTIVATE_DOCUMENTS_TYPE:
        draft.inactivateDocumentType.loading = true;
        draft.inactivateDocumentType.fail = null;
        draft.inactivateDocumentType.success = null;
        return;

      case c.INACTIVATE_DOCUMENTS_TYPE_SUCCESS:
        draft.inactivateDocumentType.loading = false;
        draft.inactivateDocumentType.fail = null;
        draft.inactivateDocumentType.success = payload.data;
        return;

      case c.INACTIVATE_DOCUMENTS_TYPE_FAIL:
        draft.inactivateDocumentType.loading = false;
        draft.inactivateDocumentType.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.createDocumentType.success = null;
        draft.updateDocumentType.success = null;
        draft.inactivateDocumentType.success = null;
        draft.createDocumentType.fail = null;
        draft.updateDocumentType.fail = null;
        draft.inactivateDocumentType.fail = null;
        return;

      default:
        return;
    }
  });

export default metaCases;
