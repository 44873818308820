/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';
import { LegalCasePersonType } from './types';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

export const clearAllStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_ALL_STATUS,
  });
};

export const fetchLegalCasePersonTypes = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_LEGAL_CASE_PERSON_TYPE,
    payload: {
      request: {
        url: '/legal_cases/person_type',
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_LEGAL_CASE_PERSON_TYPE_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_LEGAL_CASE_PERSON_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchLegalCasePersonTypesPaginated = (offset: number, limit: number) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_LEGAL_CASE_PERSON_TYPE_PAGINATED,
    payload: {
      request: {
        url: `/legal_cases/person_type/paginated?offset=${offset}&limit=${limit}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_LEGAL_CASE_PERSON_TYPE_PAGINATED_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_LEGAL_CASE_PERSON_TYPE_PAGINATED_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchLegalCasePersonTypeId = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_LEGAL_CASE_PERSON_TYPE_ID,
    payload: {
      request: {
        url: `/legal_cases/person_type/${id}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_LEGAL_CASE_PERSON_TYPE_ID_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_LEGAL_CASE_PERSON_TYPE_ID_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const createLegalCasePersonType = (name: string, is_part: boolean, is_active_pole: boolean) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: c.CREATE_LEGAL_CASE_PERSON_TYPE,
    payload: {
      request: {
        url: '/legal_cases/person_type',
        method: 'POST',
        data: {
          name,
          is_part,
          is_active_pole,
        },
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.CREATE_LEGAL_CASE_PERSON_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de pessoa de caso criado com sucesso!',
            },
          });
          dispatch(fetchLegalCasePersonTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.CREATE_LEGAL_CASE_PERSON_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const updateLegalCasePersonType = (legalCasePersonType: LegalCasePersonType) => (dispatch: Dispatch) => {
  dispatch({
    type: c.UPDATE_LEGAL_CASE_PERSON_TYPE,
    payload: {
      request: {
        url: `/legal_cases/person_type/${legalCasePersonType.person_type_id}`,
        method: 'PUT',
        data: legalCasePersonType,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.UPDATE_LEGAL_CASE_PERSON_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de pessoa de caso atualizado com sucesso!',
            },
          });
          dispatch(fetchLegalCasePersonTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.UPDATE_LEGAL_CASE_PERSON_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const inactivateLegalCasePersonType = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_LEGAL_CASE_PERSON_TYPE,
    payload: {
      request: {
        url: `/legal_cases/person_type/${id}/inactivate`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_LEGAL_CASE_PERSON_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de pessoa de caso inativado com sucesso!',
            },
          });
          dispatch(fetchLegalCasePersonTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.INACTIVATE_LEGAL_CASE_PERSON_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};
