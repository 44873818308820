/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import update from 'immutability-helper';
import Input from '../../components/Input';
import { reducers } from '../../store/reducers';
import {
  createDocumentType,
  inactivateDocumentType,
  updateDocumentType,
  clearAllStatus,
  fetchDocumentTypesPaginated,
} from './actions';
import MetaTable from '../../components/MetaTable';
import { DocumentType } from './types';
import '../Meta/styles.css';
import Button from '../../components/Button';
import createToaster from '../../components/Toaster';
import { WRITE_PERMISSION, META_MODULE_NAME } from '../Permissions/constants';
import { verifyUserPermission } from '../../utils/permissions';
import Pagination from '../../components/Pagination';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

const MetaDocuments: React.FC = () => {
  const dispatch = useDispatch();
  // BlueprintJS inputRef
  const input = React.useRef() as any;
  const documentTypesPaginated = useSelector((state: typeof reducers) => state.metadocuments.documentTypesPaginated);
  const createDocumentTypes = useSelector((state: typeof reducers) => state.metadocuments.createDocumentType);
  const updateDocumentTypes = useSelector((state: typeof reducers) => state.metadocuments.updateDocumentType);
  const inactivateDocumentTypes = useSelector((state: typeof reducers) => state.metadocuments.inactivateDocumentType);
  const [isEdit, setIsEdit] = useState(false);
  const [stateDocumentType, setStateDocumentType] = useState<DocumentType>({
    document_type: '',
    document_type_id: '',
    is_active: false,
    created_at: '',
  });

  useEffect(() => {
    if (createDocumentTypes.success) {
      const showToast = createToaster({
        message: createDocumentTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createDocumentTypes.success, dispatch]);

  useEffect(() => {
    if (updateDocumentTypes.success) {
      const showToast = createToaster({
        message: updateDocumentTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateDocumentTypes.success, dispatch]);

  useEffect(() => {
    if (inactivateDocumentTypes.success) {
      const showToast = createToaster({
        message: inactivateDocumentTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateDocumentTypes.success, dispatch]);

  useEffect(() => {
    if (createDocumentTypes.fail) {
      const showToast = createToaster({
        message: createDocumentTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createDocumentTypes.fail, dispatch]);

  useEffect(() => {
    if (updateDocumentTypes.fail) {
      const showToast = createToaster({
        message: updateDocumentTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateDocumentTypes.fail, dispatch]);

  useEffect(() => {
    if (inactivateDocumentTypes.fail) {
      const showToast = createToaster({
        message: inactivateDocumentTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateDocumentTypes.fail, dispatch]);

  const setInputValue = (value: string) => (input.current.value = value);

  const handleEditRequest = (elem: DocumentType) => {
    setInputValue(elem.document_type);
    setIsEdit(true);
    setStateDocumentType(elem);
  };

  useEffect(() => {
    dispatch(fetchDocumentTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
  }, [dispatch]);

  const resetForm = () => {
    setInputValue('');
    setStateDocumentType((prevState) => ({
      ...prevState,
      document_type: '',
    }));
    setIsEdit(false);
  };

  const addDocumentType = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isEdit) {
      dispatch(createDocumentType(stateDocumentType.document_type));
    } else {
      const newElem = update(stateDocumentType, {
        document_type: { $set: stateDocumentType.document_type },
      });
      dispatch(updateDocumentType(newElem));
    }
    resetForm();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateDocumentType(
      update(stateDocumentType, {
        document_type: { $set: e.target.value },
      })
    );
  };

  const handleTextButton = () => (isEdit ? 'Atualizar documento' : 'Adicionar documento');

  const disabledValidation = () => stateDocumentType.document_type === '';

  return (
    <form className="component__container" onSubmit={addDocumentType}>
      {verifyUserPermission(META_MODULE_NAME, WRITE_PERMISSION) && (
        <Input
          value={stateDocumentType?.document_type}
          onChange={onChangeInput}
          inputRef={input}
          placeholder="Digite o tipo de anexo que gostaria de adicionar... *"
          button={{
            text: handleTextButton(),
            icon: 'plus',
            disabledValidation: disabledValidation(),
            onClick: addDocumentType,
          }}
        />
      )}
      {isEdit && (
        <Button name="backwhite" onClick={resetForm}>
          Cancelar edição
        </Button>
      )}
      <h3>Tipos de anexos já cadastrados:</h3>
      <MetaTable
        loading={
          documentTypesPaginated?.loading ||
          updateDocumentTypes?.loading ||
          inactivateDocumentTypes?.loading ||
          createDocumentTypes?.loading
        }
        empty={documentTypesPaginated.empty}
        data={documentTypesPaginated.data.result}
        headers={['Nome', 'Criado em']}
        columns={['document_type', 'created_at']}
        pathId="document_type_id"
        removeRequest={inactivateDocumentType}
        editRequest={handleEditRequest}
        group
      />
      {!documentTypesPaginated?.empty && (
        <Pagination
          request={fetchDocumentTypesPaginated}
          loading={documentTypesPaginated?.loading}
          data={documentTypesPaginated?.data}
        />
      )}
    </form>
  );
};

export default React.memo(MetaDocuments);
