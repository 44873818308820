/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';
import { CreateProgressType, ProgressType } from './types';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

export const clearAllStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_ALL_STATUS,
  });
};

export const fetchProgressTypes = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_PROGRESS_TYPE,
    payload: {
      request: {
        url: '/case_progress/type',
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_PROGRESS_TYPE_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_PROGRESS_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchProgressTypesPaginated = (offset: number, limit: number) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_PROGRESS_TYPE_PAGINATED,
    payload: {
      request: {
        url: `/case_progress/type/paginated?offset=${offset}&limit=${limit}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_PROGRESS_TYPE_PAGINATED_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_PROGRESS_TYPE_PAGINATED_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchProgressTypeId = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_PROGRESS_TYPE_ID,
    payload: {
      request: {
        url: `/case_progress/type/${id}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_PROGRESS_TYPE_ID_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_PROGRESS_TYPE_ID_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const createProgressType = (progressType: CreateProgressType) => (dispatch: Dispatch) => {
  dispatch({
    type: c.CREATE_PROGRESS_TYPE,
    payload: {
      request: {
        url: '/case_progress/type',
        method: 'POST',
        data: progressType,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.CREATE_PROGRESS_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de andamento criado com sucesso!',
            },
          });
          dispatch(fetchProgressTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.CREATE_PROGRESS_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const updateProgressType = (progressType: ProgressType) => (dispatch: Dispatch) => {
  dispatch({
    type: c.UPDATE_PROGRESS_TYPE,
    payload: {
      request: {
        url: `/case_progress/type/${progressType.case_progress_type_id}`,
        method: 'PUT',
        data: progressType,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.UPDATE_PROGRESS_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de andamento atualizado com sucesso!',
            },
          });
          dispatch(fetchProgressTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.UPDATE_PROGRESS_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const inactivateProgressType = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_PROGRESS_TYPE,
    payload: {
      request: {
        url: `/case_progress/type/${id}/inactivate`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_PROGRESS_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de andamento inativado com sucesso!',
            },
          });
          dispatch(fetchProgressTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.INACTIVATE_PROGRESS_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};
