/* eslint-disable import/prefer-default-export */
export const FETCH_RELATED_LAWYERS = 'FETCH_RELATED_LAWYERS';
export const FETCH_RELATED_LAWYERS_SUCCESS = 'FETCH_RELATED_LAWYERS_SUCCESS';
export const FETCH_RELATED_LAWYERS_FAIL = 'FETCH_RELATED_LAWYERS_FAIL';

export const CREATE_LAWYER_RELATION = 'CREATE_LAWYER_RELATION';
export const CREATE_LAWYER_RELATION_SUCCESS = 'CREATE_LAWYER_RELATION_SUCCESS';
export const CREATE_LAWYER_RELATION_FAIL = 'CREATE_LAWYER_RELATION_FAIL';

export const UPDATE_LAWYER_RELATION = 'UPDATE_LAWYER_RELATION';
export const UPDATE_LAWYER_RELATION_SUCCESS = 'UPDATE_LAWYER_RELATION_SUCCESS';
export const UPDATE_LAWYER_RELATION_FAIL = 'UPDATE_LAWYER_RELATION_FAIL';

export const INACTIVATE_LAWYER_RELATION = 'INACTIVATE_LAWYER_RELATION';
export const INACTIVATE_LAWYER_RELATION_SUCCESS = 'INACTIVATE_LAWYER_RELATION_SUCCESS';
export const INACTIVATE_LAWYER_RELATION_FAIL = 'INACTIVATE_LAWYER_RELATION_FAIL';

export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
