/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';
import { RelatedLawyer } from './types';

export const clearAllStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_ALL_STATUS,
  });
};

export const fetchConnectedLawyers = (caseId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_RELATED_LAWYERS,
    payload: {
      request: {
        url: `/legal_cases/${caseId}/lawyers`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_RELATED_LAWYERS_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_RELATED_LAWYERS_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const createLawyerRelation = (caseId: string, lawyer: RelatedLawyer) => (dispatch: Dispatch) => {
  dispatch({
    type: c.CREATE_LAWYER_RELATION,
    payload: {
      request: {
        url: `/legal_cases/${caseId}/lawyers`,
        method: 'PUT',
        data: lawyer,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.CREATE_LAWYER_RELATION_SUCCESS,
          });
          dispatch(fetchConnectedLawyers(caseId));
          dispatch(clearAllStatus())
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.CREATE_LAWYER_RELATION_FAIL,
            payload: {
              error: resError,
            },
          });
          dispatch(clearAllStatus());
        },
      },
    },
  });
};

export const inactivateLawyerRelation = (caseId: string, connectionId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_LAWYER_RELATION,
    payload: {
      request: {
        url: `/legal_cases/${caseId}/lawyers/${connectionId}/inactivate`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_LAWYER_RELATION_SUCCESS,
          });
          dispatch(fetchConnectedLawyers(caseId));
          dispatch(clearAllStatus())
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.INACTIVATE_LAWYER_RELATION_FAIL,
            payload: {
              error: resError,
            },
          });
          dispatch(clearAllStatus());
        },
      },
    },
  });
};
