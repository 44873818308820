/* eslint-disable @typescript-eslint/camelcase */

import { convertBooleanToString } from './ordenation';

/* eslint-disable import/prefer-default-export */
export const mountFetchPeoplePathFilters = (
  order_by_recent?: boolean,
  order_by_name?: boolean,
  is_legal_person?: boolean,
  name?: string
) => {
  return `${name && name.length ? `&name=${name}` : ''}${
    order_by_recent !== undefined ? `&order_by_recent=${convertBooleanToString(!order_by_recent)}` : ''
  }${order_by_name !== undefined ? `&order_by_name=${convertBooleanToString(order_by_name)}` : ''}${
    is_legal_person !== undefined ? `&is_legal_person=${is_legal_person}` : ''
  }`;
};

export const mountFetchCasesPathFilters = (
  order_by_recent?: boolean,
  order_by_title?: boolean,
) => {
  return `${
    order_by_recent !== undefined ? `&order_by_recent=${convertBooleanToString(!order_by_recent)}` : ''
  }${order_by_title !== undefined ? `&order_by_title=${convertBooleanToString(order_by_title)}` : ''}`;
};

export const mountQueryFilters = (value?: string, searchBy?: string) => {
  if (searchBy && value) {
    return `&filter_by=${searchBy}&entry=${value}`;
  }
  return '';
};
