import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reducers } from '../../store/reducers';
import { fetchRelatedCases } from './actions';
import '../../components/PersonRelations/styles.css';
import createToaster from '../../components/Toaster';
import Table from '../../components/GenericTable';
import Pagination from '../../components/Pagination';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';
import Button from '../../components/Button';
import './styles.css';

interface IConnectedCases {
  personId: string;
}

const PeopleConnectedCases: React.FC<IConnectedCases> = ({ personId }) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const relatedCases = useSelector((state: typeof reducers) => state.peopleconnectedcases.relatedCases);

  const mountPath = useCallback(() => {
    return `${personId},${searchValue}`
  }, [searchValue, personId])

  useEffect(() => {
    if (personId?.length) {
      dispatch(fetchRelatedCases(1, DEFAULT_PER_PAGE_LIMIT, mountPath()));
    }
  }, [dispatch, personId, mountPath]);

  useEffect(() => {
    if (relatedCases?.fail) {
      const showToast = createToaster({
        message: relatedCases?.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
    }
  }, [relatedCases]);

  return (
    <>
      <div className="tabs__subline"></div>
      <div className="content__container">
        {relatedCases.data && (
          <div className="table__container connected__cases">
            <div className="bp3-input-group search__input__container">
              <span className="bp3-icon" />
              <input
                type="text"
                className="bp3-input search__input"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                placeholder="Digite o apelido do caso que você deseja encontrar ..."
              />
              <Button
                className="bp3-button bp3-minimal bp3-intent-primary bp3-icon-search search__button"
                onClick={() => dispatch(fetchRelatedCases(1, DEFAULT_PER_PAGE_LIMIT, mountPath()))}
              >
                Buscar caso
              </Button>
            </div>
            <Table
              loading={relatedCases?.loading}
              data={relatedCases?.data.result}
              empty={relatedCases.empty}
              headers={['Número do processo', 'Situação do caso', 'Apelido do caso']}
              columns={['process_id', 'case_status', 'title']}
              expandButton={{ path: `/painel/casos/detalhes`, text: 'Expandir', pathId: 'legal_case_id' }}
            />
            <Pagination request={fetchRelatedCases} optionsPath={mountPath()} loading={false} data={relatedCases?.data} />
          </div>
        )}
      </div>
    </>
  );
};

export default PeopleConnectedCases;
