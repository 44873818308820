/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';
import { TagType } from './types';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

export const clearAllStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_ALL_STATUS,
  });
};

export const fetchTagTypes = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_TAG_TYPE,
    payload: {
      request: {
        url: '/tags',
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_TAG_TYPE_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_TAG_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchTagTypesPaginated = (offset: number, limit: number) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_TAG_TYPE_PAGINATED,
    payload: {
      request: {
        url: `/tags/paginated?offset=${offset}&limit=${limit}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_TAG_TYPE_PAGINATED_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_TAG_TYPE_PAGINATED_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchTagTypesId = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_TAG_TYPE_ID,
    payload: {
      request: {
        url: `/tags/${id}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_TAG_TYPE_ID_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_TAG_TYPE_ID_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const createTagType = (tag: string, description: string) => (dispatch: Dispatch) => {
  if (!description.length) {
    description = 'N/A';
  }
  dispatch({
    type: c.CREATE_TAG_TYPE,
    payload: {
      request: {
        url: '/tags',
        method: 'POST',
        data: { tag, description },
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.CREATE_TAG_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de assunto criado com sucesso!',
            },
          });
          dispatch(fetchTagTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.CREATE_TAG_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const updateTagType = (tagType: TagType) => (dispatch: Dispatch) => {
  dispatch({
    type: c.UPDATE_TAG_TYPE,
    payload: {
      request: {
        url: `/tags/${tagType.tag_id}`,
        method: 'PUT',
        data: { name: tagType.tag, description: tagType.description },
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.UPDATE_TAG_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de assunto atualizado com sucesso!',
            },
          });
          dispatch(fetchTagTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.UPDATE_TAG_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const inactivateTagType = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_TAG_TYPE,
    payload: {
      request: {
        url: `/tags/${id}/inactivate`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_TAG_TYPE_SUCCESS,
            payload: {
              data: 'Tipo de assunto arquivado com sucesso!',
            },
          });
          dispatch(fetchTagTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.INACTIVATE_TAG_TYPE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};
