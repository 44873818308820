/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  caseSchedules: {
    fail: null,
    empty: true,
    loading: false,
    data: [],
  },
  scheduleById: {
    fail: null,
    empty: true,
    loading: false,
    data: null,
  },
  updateSchedule: {
    fail: null,
    success: null,
    loading: false,
    data: null,
  },
  updateScheduleState: {
    fail: null,
    success: null,
    loading: false,
    data: null,
  },
  inactivateSchedule: {
    fail: null,
    success: null,
    loading: false,
  },
};

const caseRelatedSchedules: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_SCHEDULES:
        draft.caseSchedules.loading = true;
        draft.caseSchedules.fail = null;
        return;

      case c.FETCH_SCHEDULES_SUCCESS:
        draft.caseSchedules.loading = false;
        draft.caseSchedules.fail = null;
        if (payload.data && payload.data.length) {
          draft.caseSchedules.empty = false;
        }
        draft.caseSchedules.data = payload.data;
        return;

      case c.FETCH_SCHEDULES_FAIL:
        draft.caseSchedules.loading = false;
        draft.caseSchedules.fail = payload.error;
        return;

      case c.FETCH_SCHEDULE_ID:
        draft.scheduleById.loading = true;
        draft.scheduleById.fail = null;
        return;

      case c.FETCH_SCHEDULE_ID_SUCCESS:
        draft.scheduleById.loading = false;
        draft.scheduleById.fail = null;
        if (payload.data && payload.data.length) {
          draft.scheduleById.empty = false;
        }
        draft.scheduleById.data = payload.data;
        return;

      case c.FETCH_SCHEDULE_ID_FAIL:
        draft.scheduleById.loading = false;
        draft.scheduleById.fail = payload.error;
        return;

      case c.UPDATE_SCHEDULE:
        draft.updateSchedule.loading = true;
        draft.updateSchedule.fail = null;
        draft.updateSchedule.success = null;
        return;

      case c.UPDATE_SCHEDULE_SUCCESS:
        draft.updateSchedule.loading = false;
        draft.updateSchedule.fail = null;
        draft.updateSchedule.success = 'Agendamento atualizado com sucesso!';
        if (payload.data) {
          draft.updateSchedule.data = payload.data;
        }
        return;

      case c.UPDATE_SCHEDULE_FAIL:
        draft.updateSchedule.loading = false;
        draft.updateSchedule.fail = payload.error;
        return;

      case c.UPDATE_SCHEDULE_STATE:
        draft.updateScheduleState.loading = true;
        draft.updateScheduleState.fail = null;
        draft.updateScheduleState.success = null;
        return;

      case c.UPDATE_SCHEDULE_STATE_SUCCESS:
        draft.updateScheduleState.loading = false;
        draft.updateScheduleState.fail = null;
        draft.updateScheduleState.success = 'Status do agendamento atualizado com sucesso!';
        if (payload.data) {
          draft.updateScheduleState.data = payload.data;
        }
        return;

      case c.UPDATE_SCHEDULE_STATE_FAIL:
        draft.updateScheduleState.loading = false;
        draft.updateScheduleState.fail = payload.error;
        return;

      case c.INACTIVATE_SCHEDULE:
        draft.inactivateSchedule.loading = true;
        draft.inactivateSchedule.success = null;
        draft.inactivateSchedule.fail = null;
        return;

      case c.INACTIVATE_SCHEDULE_SUCCESS:
        draft.inactivateSchedule.loading = false;
        draft.inactivateSchedule.success = 'Compromisso arquivado com sucesso!';
        return;

      case c.INACTIVATE_SCHEDULE_FAIL:
        draft.inactivateSchedule.loading = false;
        draft.inactivateSchedule.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.inactivateSchedule.success = null;
        draft.inactivateSchedule.fail = null;
        draft.updateSchedule.success = null;
        draft.updateSchedule.fail = null;
        draft.updateScheduleState.success = null;
        draft.updateScheduleState.fail = null;
        return;

      default:
        return;
    }
  });

export default caseRelatedSchedules;
