/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import '../../template.css';
import './styles.css';
import { setButtonArrowActive, setActiveButton } from '../../utils/button';
import { isMobile } from '../../utils/window';
import { isCurrentRoute, goToHere, mountScheduleDashUserPath } from '../../utils/navigation';
import { getLoggedUsername, getLoggedUserID } from '../../utils/auth';
import { useSelector, useDispatch } from 'react-redux';
import { reducers } from '../../store/reducers';
import { fetchUsersWithPerson } from '../Users/actions';
import { UserWithPerson, BasicUserForSchedule } from '../Users/types';
import Selector from '../../components/Selector';
import { useHistory } from 'react-router-dom';
import { isAdmin } from '../../utils/permissions';

const Schedule: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const usersWithPerson = useSelector((state: typeof reducers) => state.users.usersWithPerson);
  const [scheduleUser, setScheduleUser] = useState<BasicUserForSchedule>({
    user_id: getLoggedUserID(),
    username: getLoggedUsername()
  });

  const handleChangeUser = (newId: string) => {
    const newUser = usersWithPerson.data.find((userItem: UserWithPerson) => userItem.user_id === newId);
    setScheduleUser(() => ({
      user_id: newUser.user_id,
      username: newUser.username
    }));
  };

  useEffect(() => {
    if (scheduleUser.user_id !== getLoggedUserID()) {
      history.push({
        pathname: `/painel/agenda/calendario/${mountScheduleDashUserPath(scheduleUser)}`,
      });
    }
  }, [scheduleUser, history]);

  useEffect(() => {
    dispatch(fetchUsersWithPerson());
    if (window.location.pathname === '/painel/agenda' && (scheduleUser.user_id === getLoggedUserID())) {
      goToHere(`/painel/agenda/calendario/${mountScheduleDashUserPath(scheduleUser)}`);
    }
  }, [dispatch, scheduleUser]);

  return (
    <>
      {!isMobile() ? (
        <div className="sidebar__container--schedule">
          <p className="schedule__of">Agenda de</p>
          <button type="button" className={setActiveButton(`/painel/agenda/calendario/${scheduleUser.user_id}`)} onClick={() => goToHere(`/painel/agenda/calendario/${mountScheduleDashUserPath(scheduleUser)}`)}>
            {scheduleUser.username}
            <div className={setButtonArrowActive(`/painel/agenda/calendario/${mountScheduleDashUserPath(scheduleUser)}`)} />
          </button>
          {isAdmin() && (
            <Selector
              setValue={handleChangeUser}
              className="new__selector"
              usersWithPerson={usersWithPerson.data}
              value={scheduleUser.user_id}
            />
          )}
        </div>
      ) : (
          <>
            <li
              className="bp3-tab"
              role="tab"
              aria-selected={isCurrentRoute(`/painel/agenda/calendario/${mountScheduleDashUserPath(scheduleUser)}`)}
              onClick={() => goToHere(`/painel/agenda/calendario/${mountScheduleDashUserPath(scheduleUser)}`)}
            >
              {scheduleUser.username}
            </li>
          </>
        )}
    </>
  );
};

export default Schedule;
