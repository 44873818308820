/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import * as c from './constants';
import handleApiError from '../../utils/errors';
import { Schedule } from './types';

export const clearAllStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: c.CLEAR_ALL_STATUS,
  });
};

export const fetchCaseSchedules = (caseId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_SCHEDULES,
    payload: {
      request: {
        url: `/legal_cases/${caseId}/schedules`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_SCHEDULES_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_SCHEDULES_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const fetchScheduleById = (scheduleId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_SCHEDULE_ID,
    payload: {
      request: {
        url: `/schedules/${scheduleId}`,
        method: 'GET',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.FETCH_SCHEDULE_ID_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.FETCH_SCHEDULE_ID_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const updateSchedule = (schedule: Schedule) => (dispatch: Dispatch) => {
  dispatch({
    type: c.UPDATE_SCHEDULE,
    payload: {
      request: {
        url: `/schedules/${schedule.schedule_id}`,
        method: 'PUT',
        data: schedule,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.UPDATE_SCHEDULE_SUCCESS,
            payload: {
              data: response.data,
            },
          });
          dispatch(clearAllStatus());
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.UPDATE_SCHEDULE_FAIL,
            payload: {
              error: resError,
            },
          });
          dispatch(clearAllStatus());
        },
      },
    },
  });
};

export const updateScheduleDatePeriod = (schedule: Schedule) => (dispatch: Dispatch) => {
  dispatch({
    type: c.UPDATE_SCHEDULE,
    payload: {
      request: {
        url: `/schedules/${schedule.schedule_id}`,
        method: 'PUT',
        data: schedule,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.UPDATE_SCHEDULE_SUCCESS,
            payload: {
              data: response.data,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.UPDATE_SCHEDULE_FAIL,
            payload: {
              error: resError,
            },
          });
        },
      },
    },
  });
};

export const updateScheduleState = (scheduleId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.UPDATE_SCHEDULE_STATE,
    payload: {
      request: {
        url: `/schedules/${scheduleId}/finalize`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.UPDATE_SCHEDULE_STATE_SUCCESS,
            payload: {
              data: response.data,
            },
          });
          dispatch(clearAllStatus());
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.UPDATE_SCHEDULE_STATE_FAIL,
            payload: {
              error: resError,
            },
          });
          dispatch(clearAllStatus());
        },
      },
    },
  });
};

export const inactivateSchedule = (scheduleId: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.INACTIVATE_SCHEDULE,
    payload: {
      request: {
        url: `/schedules/${scheduleId}/inactivate`,
        method: 'PUT',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.INACTIVATE_SCHEDULE_SUCCESS,
            payload: {
              data: response.data,
            },
          });
          dispatch(clearAllStatus());
        },
        onError: ({ getState, dispatch, error }: any) => {
          const resError = handleApiError(error.toString());
          dispatch({
            type: c.INACTIVATE_SCHEDULE_FAIL,
            payload: {
              error: resError,
            },
          });
          dispatch(clearAllStatus());
        },
      },
    },
  });
};
