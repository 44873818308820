import React from 'react';
import { DateInput } from '@blueprintjs/datetime';
import { Icon } from '@blueprintjs/core';
import { humanizeDateWithTime, jsDateToHuman, momentFormatBrDate, getNumbersForCreateDateObj } from '../../utils/date';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/pt-br';
import './styles.css';

interface IDatePickerProps {
  value?: Date;
  defaultValue?: Date;
  onChangeMethod: (date: Date) => void;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  timePrecision?: boolean;
  inputRef?: any;
  minLimitDate?: Date;
  maxLimitDate?: Date;
}

const DatePicker: React.FC<IDatePickerProps> = ({
  value,
  defaultValue,
  onChangeMethod,
  className,
  disabled,
  required,
  placeholder,
  timePrecision,
  inputRef,
  minLimitDate,
  maxLimitDate
}) => {
  return (
    <>
      {timePrecision ? (
        <DateInput
          ref={inputRef}
          inputProps={{ required: required }}
          formatDate={(date: Date) => humanizeDateWithTime(date)}
          localeUtils={MomentLocaleUtils}
          fill
          disabled={disabled}
          placeholder={placeholder}
          locale="pt-BR"
          minDate={minLimitDate}
          maxDate={maxLimitDate}
          className={className}
          rightElement={<Icon iconSize={Icon.SIZE_LARGE} icon="calendar"></Icon>}
          onChange={(newDate: Date) => onChangeMethod(newDate)}
          parseDate={(str) => getNumbersForCreateDateObj(str)}
          value={value}
          defaultValue={defaultValue}
          timePrecision="minute"
          closeOnSelection={false}
          outOfRangeMessage="Data inválida"
        />
      ) : (
        <DateInput
          ref={inputRef}
          inputProps={{ required: required }}
          formatDate={(date: Date) => momentFormatBrDate(date)}
          localeUtils={MomentLocaleUtils}
          minDate={minLimitDate}
          maxDate={maxLimitDate}
          fill
          disabled={disabled}
          placeholder={placeholder}
          locale="pt-br"
          className={className}
          rightElement={<Icon iconSize={Icon.SIZE_LARGE} icon="calendar"></Icon>}
          onChange={(newDate: Date) => onChangeMethod(newDate)}
          parseDate={(str) => jsDateToHuman(str)}
          value={value}
          defaultValue={defaultValue}
        />
      )}
    </>
  );
};

export default DatePicker;
