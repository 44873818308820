import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reducers } from '../../store/reducers';
import { fetchRolesPaginated } from './actions';
import createToaster from '../../components/Toaster';
import Input from '../../components/Input';
import Table from '../../components/GenericTable';
import BBreadcrumb from '../../components/Breadcrumb';
import { BREADCRUMB_PATHS } from '../Groups/constants';
import { inactivateRole } from '../Permissions/actions';
import Pagination from '../../components/Pagination';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

const GroupsList: React.FC = () => {
  const dispatch = useDispatch();
  const rolesPaginated = useSelector((state: typeof reducers) => state.usergroups.rolesPaginated);
  const { failDelete, loadingDelete, successDelete } = useSelector(
    (state: typeof reducers) => state.permissions.deleteStatus
  );
  // BlueprintJS inputRef
  const searchInput = React.useRef() as any;

  if (successDelete || failDelete) {
    const showToast = createToaster({
      message: successDelete || failDelete,
      intent: failDelete ? 'danger' : 'success',
      icon: failDelete ? 'warning-sign' : 'tick',
    });
    showToast();
  }

  useEffect(() => {
    dispatch(fetchRolesPaginated(1, DEFAULT_PER_PAGE_LIMIT, ''));
  }, [dispatch]);

  const getTable = () => (
    <>
      <Table
        loading={rolesPaginated?.loading || loadingDelete}
        data={rolesPaginated?.data?.result}
        empty={false}
        headers={['Nome']}
        columns={['name']}
        moreButton={{ removeRequest: inactivateRole, pathId: 'role_id' }}
        expandButton={{ path: `/painel/grupos/lista/permissoes`, text: 'Ver permissões', pathId: 'role_id' }}
      />
      {!rolesPaginated?.empty && (
        <Pagination
          request={fetchRolesPaginated}
          loading={rolesPaginated?.loading}
          data={rolesPaginated?.data}
          optionsPath={`&name=${searchInput?.current?.value}`}
        />
      )}
    </>
  );

  const submitSearch = () =>
    dispatch(fetchRolesPaginated(1, DEFAULT_PER_PAGE_LIMIT, `&name=${searchInput?.current?.value}`));

  return (
    <div className="background__container">
      <div className="general__container">
        <BBreadcrumb breads={BREADCRUMB_PATHS} />
        <h3>
          Estes são os grupos cadastradas na plataforma, na listagem, clique em &#34;Nome&#34; para filtros de ordenação.
        </h3>
        <Input
          inputRef={searchInput}
          placeholder="Digite o nome do grupo desejado"
          button={{ text: 'Buscar grupo', icon: 'search', onClick: submitSearch }}
        />
        <>{getTable()}</>
      </div>
    </div>
  );
};

export default GroupsList;
