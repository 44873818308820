import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IInputGroupProps } from '@blueprintjs/core';
import './styles.css';
import Input from '../Input';
import { cpfMask, cnpjMask } from '../../utils/masks';
import { useDispatch } from 'react-redux';

interface IAutocomplete extends IInputGroupProps {
  button?: {
    text: string;
    icon?: IInputGroupProps['leftIcon'];
    disabledValidation?: boolean;
    onClick?: () => void;
  };
  rightElement?: JSX.Element;
  callbackMethod?: (arg?: any) => void;
  request: Function;
  path: string;
  data: any;
  idKey: string;
  value: string;
  setValue: Function;
  required?: boolean;
  nameValue: string;
  keyOne: string;
  // BlueprintJS refs
  reference?: any;
  disabled?: boolean;
}

const Autocomplete: React.FC<IAutocomplete> = (props) => {
  const {
    placeholder,
    rightElement,
    callbackMethod,
    request,
    path,
    data,
    idKey,
    value,
    setValue,
    required,
    nameValue,
    keyOne,
    reference,
    disabled,
  } = props;
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<any>([]);
  const suggestionsRef = useRef() as any;
  const dispatch = useDispatch();

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    dispatch(request(`${path}=${e.target.value}`))
  };

  useEffect(() => {
    if (!isSuggestionsOpen) {
      setIsSuggestionsOpen(true);
    }
    setSuggestions(data);
  }, [data, isSuggestionsOpen]);

  useEffect(() => {
    if (suggestions.length === 1 && suggestions[0][nameValue] === value) {
      setIsSuggestionsOpen(false);
    }
  }, [suggestions, nameValue, value]);

  const handleOutSideMenuclick = useCallback(
    (event) => {
      if (suggestionsRef.current && event && !suggestionsRef.current.contains(event.target)) {
        setIsSuggestionsOpen(false);
      }
    },
    [suggestionsRef],
  );

  const handleSelect = (item: any) => {
    setValue(item[nameValue]);
    setSuggestions([]);
    if (callbackMethod) {
      callbackMethod(item[idKey]);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleOutSideMenuclick);
    return () => {
      window.removeEventListener('mousedown', handleOutSideMenuclick);
    };
  }, [handleOutSideMenuclick]);



  const getSuggestions = () => {
    if (value?.length && isSuggestionsOpen && !disabled && suggestions.length > 0 && suggestions[0][nameValue] !== value) {
      return (
        <ul className={handleClass()} ref={suggestionsRef}>
          {suggestions.map((item: any) => (
            <li key={item[keyOne]} onClick={() => handleSelect(item)}>
              <p>{item[nameValue]}</p>
              <p>{cpfMask(item.natural_cpf) || cnpjMask(item.legal_cnpj) || item.oab_id || item.title}</p>
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const handleClass = () => (isSuggestionsOpen ? 'suggestions__list' : 'suggestions__list--closed');

  return (
    <>
      {rightElement ? (
        <div className="autocomplete__input">
          <Input
            disabled={disabled}
            required={required}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeInput(e)}
            large
            rightElement={rightElement}
            placeholder={placeholder}
            value={value}
            type="text"
          />
        </div>
      ) : (
        <div className="autocomplete__input">
          <Input
            disabled={disabled}
            inputRef={reference}
            onChange={onChangeInput}
            required={required}
            large
            placeholder={placeholder}
            type="text"
          />
        </div>
      )}
      {getSuggestions()}
    </>
  );
};

export default Autocomplete;
