export const FETCH_PHONES_TYPE = 'FETCH_PHONES_TYPE';
export const FETCH_PHONES_TYPE_SUCCESS = 'FETCH_PHONES_TYPE_SUCCESS';
export const FETCH_PHONES_TYPE_FAIL = 'FETCH_PHONES_TYPE_FAIL';
export const FETCH_PHONES_TYPE_PAGINATED = 'FETCH_PHONES_TYPE_PAGINATED';
export const FETCH_PHONES_TYPE_PAGINATED_SUCCESS = 'FETCH_PHONES_TYPE_PAGINATED_SUCCESS';
export const FETCH_PHONES_TYPE_PAGINATED_FAIL = 'FETCH_PHONES_TYPE_PAGINATED_FAIL';
export const FETCH_PHONES_TYPE_ID = 'FETCH_PHONES_TYPE_ID';
export const FETCH_PHONES_TYPE_ID_SUCCESS = 'FETCH_PHONES_TYPE_ID_SUCCESS';
export const FETCH_PHONES_TYPE_ID_FAIL = 'FETCH_PHONES_TYPE__ID_FAIL';
export const CREATE_PHONES_TYPE = 'CREATE_PHONES_TYPE';
export const CREATE_PHONES_TYPE_SUCCESS = 'CREATE_PHONES_TYPE_SUCCESS';
export const CREATE_PHONES_TYPE_FAIL = 'CREATE_PHONES_TYPE_FAIL';
export const UPDATE_PHONES_TYPE = 'UPDATE_PHONES_TYPE';
export const UPDATE_PHONES_TYPE_SUCCESS = 'UPDATE_PHONES_TYPE_SUCCESS';
export const UPDATE_PHONES_TYPE_FAIL = 'UPDATE_PHONES_TYPE_FAIL';
export const INACTIVATE_PHONES_TYPE = 'INACTIVATE_PHONES_TYPE';
export const INACTIVATE_PHONES_TYPE_SUCCESS = 'INACTIVATE_PHONES_TYPE_SUCCESS';
export const INACTIVATE_PHONES_TYPE_FAIL = 'INACTIVATE_PHONES_TYPE_FAIL';
export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
