/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import update from 'immutability-helper';
import Input from '../../components/Input';
import { reducers } from '../../store/reducers';
import {
  createSecretaryType,
  inactivateSecretaryType,
  updateSecretaryType,
  clearAllStatus,
  fetchSecretaryTypesPaginated,
} from './actions';
import MetaTable from '../../components/MetaTable';
import { SecretaryType } from './types';
import '../Meta/styles.css';
import Button from '../../components/Button';
import createToaster from '../../components/Toaster';
import Selector from '../../components/Selector';
import '../MetaCasesCourtType/styles.css';
import { WRITE_PERMISSION, META_MODULE_NAME } from '../Permissions/constants';
import { verifyUserPermission } from '../../utils/permissions';
import { fetchDistrictTypes } from '../MetaCasesDistrictType/actions';
import Pagination from '../../components/Pagination';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';

const MetaCasesSecretaryType: React.FC = () => {
  const dispatch = useDispatch();
  // BlueprintJS inputRef
  const input = React.useRef() as any;
  const secretaryTypesPaginated = useSelector((state: typeof reducers) => state.metacasessecretarytype.secretaryTypesPaginated);
  const { data, empty } = useSelector((state: typeof reducers) => state.metacasesdistricttype.districtTypes);
  const createSecretaryTypes = useSelector((state: typeof reducers) => state.metacasessecretarytype.createSecretaryType);
  const updateSecretaryTypes = useSelector((state: typeof reducers) => state.metacasessecretarytype.updateSecretaryType);
  const inactivateSecretaryTypes = useSelector(
    (state: typeof reducers) => state.metacasessecretarytype.inactivateSecretaryType
  );
  const [isEdit, setIsEdit] = useState(false);
  const [stateSecretaryType, setStateSecretaryType] = useState({
    name: '',
    county_id: '',
    secretary_id: '',
    created_at: '',
    county: {},
    is_active: true,
  });
  const [countyId, setCountyId] = useState('');

  useEffect(() => {
    dispatch(fetchSecretaryTypesPaginated(1, DEFAULT_PER_PAGE_LIMIT));
    dispatch(fetchDistrictTypes());
  }, [dispatch]);

  useEffect(() => {
    if (createSecretaryTypes.success) {
      const showToast = createToaster({
        message: createSecretaryTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createSecretaryTypes.success, dispatch]);

  useEffect(() => {
    if (updateSecretaryTypes.success) {
      const showToast = createToaster({
        message: updateSecretaryTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateSecretaryTypes.success, dispatch]);

  useEffect(() => {
    if (inactivateSecretaryTypes.success) {
      const showToast = createToaster({
        message: inactivateSecretaryTypes.success,
        intent: 'success',
        icon: 'tick',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateSecretaryTypes.success, dispatch]);

  useEffect(() => {
    if (createSecretaryTypes.fail) {
      const showToast = createToaster({
        message: createSecretaryTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [createSecretaryTypes.fail, dispatch]);

  useEffect(() => {
    if (updateSecretaryTypes.fail) {
      const showToast = createToaster({
        message: updateSecretaryTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [updateSecretaryTypes.fail, dispatch]);

  useEffect(() => {
    if (inactivateSecretaryTypes.fail) {
      const showToast = createToaster({
        message: inactivateSecretaryTypes.fail,
        intent: 'danger',
        icon: 'warning-sign',
      });
      showToast();
      dispatch(clearAllStatus());
    }
  }, [inactivateSecretaryTypes.fail, dispatch]);

  const setInputValue = (value: string) => (input.current.value = value);

  const setUpNewState = (elem: SecretaryType) =>
    update(stateSecretaryType, {
      name: { $set: elem.name },
      county_id: { $set: elem.county_id },
      secretary_id: { $set: elem.secretary_id },
      created_at: { $set: elem.created_at },
      county: { $set: elem.county },
      is_active: { $set: elem.is_active },
    });

  const handleEditRequest = (elem: SecretaryType) => {
    setInputValue(elem.name);
    setIsEdit(true);
    setStateSecretaryType(setUpNewState(elem));
    setCountyId(elem.county_id);
  };

  const resetForm = () => {
    setInputValue('');
    setIsEdit(false);
  };

  const addSecretaryType = (e: SyntheticEvent) => {
    if (e) {
      e.preventDefault();
    }
    const secretaryTypeName = input.current.value;
    if (!isEdit) {
      dispatch(createSecretaryType(secretaryTypeName, countyId));
    } else {
      const newElem = update(stateSecretaryType, {
        name: { $set: secretaryTypeName },
      });
      dispatch(updateSecretaryType(newElem, countyId));
    }
    resetForm();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateSecretaryType(
      update(stateSecretaryType, {
        name: { $set: e.target.value },
      })
    );
  };

  const handleTextButton = () => (isEdit ? 'Atualizar secretaria' : 'Adicionar secretaria');

  const disabledValidation = () => countyId === '' || stateSecretaryType.name === '';

  return (
    <form className="component__container" onSubmit={addSecretaryType}>
      {verifyUserPermission(META_MODULE_NAME, WRITE_PERMISSION) && (
        <Input
          value={stateSecretaryType?.name}
          onChange={onChangeInput}
          inputRef={input}
          required
          placeholder="Digite o tipo de secretaria que gostaria de adicionar... *"
          button={{
            text: handleTextButton(),
            icon: 'plus',
            disabledValidation: disabledValidation(),
            onClick: addSecretaryType,
          }}
        />
      )}
      {!empty ? (
        <Selector setValue={setCountyId} required value={countyId} className="cases__court__selector" counties={data} />
      ) : (
        <h4 className="empty__attribute">
          Por favor, crie uma <strong>comarca</strong> antes de inserir um tipo de secretaria.
        </h4>
      )}
      <div>
        {isEdit && (
          <Button name="backwhite" onClick={resetForm}>
            Cancelar edição
          </Button>
        )}
      </div>
      <h3>Tipos de secretaria já cadastradas:</h3>
      <MetaTable
        loading={
          secretaryTypesPaginated?.loading ||
          updateSecretaryTypes?.loading ||
          inactivateSecretaryTypes?.loading ||
          createSecretaryTypes?.loading
        }
        empty={secretaryTypesPaginated.empty}
        data={secretaryTypesPaginated.data.result}
        headers={['Nome', 'Comarca', 'Criado em']}
        columns={['name', 'county', 'created_at']}
        pathId="secretary_id"
        removeRequest={inactivateSecretaryType}
        editRequest={handleEditRequest}
        group
      />
      {!secretaryTypesPaginated?.empty && (
        <Pagination
          request={fetchSecretaryTypesPaginated}
          loading={secretaryTypesPaginated?.loading}
          data={secretaryTypesPaginated?.data}
        />
      )}
    </form>
  );
};

export default React.memo(MetaCasesSecretaryType);
