import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import { Tooltip, Button, Dialog, Spinner, Checkbox, Icon } from '@blueprintjs/core';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import { fastRegisterPerson } from '../../modules/PeopleList/actions';
import 'moment/locale/pt-br';
import { fetchOccupationTypes } from '../../modules/MetaPeopleOccupations/actions';
import '../StepEnvolvedPerson/styles.css';
import Input from '../../components/Input';
import Autocomplete from '../../components/Autocomplete';
import { reducers } from '../../store/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { cpfMask } from '../../utils/masks';
import MetaTable from '../../components/MetaTable';
import { fetchLawyers, fetchLawyerByPersonId } from '../CreateCase/actions';
import { RelationedLawyer, RelationedLawyerLocal, PostCase, RelationedLawyerType } from '../CreateCase/types';
import { OccupationType } from '../MetaPeopleOccupations/types';
import Selector from '../../components/Selector';
import { STATE_LIST } from '../../constants/states';

type StepEnvolvedLawyersProps = {
  newCase: PostCase;
  setNewCase: Function;
  lawyers: RelationedLawyer[];
  setLawyers: Function;
};

const StepEnvolvedLawyers: React.FC<StepEnvolvedLawyersProps> = ({ newCase, setNewCase, lawyers, setLawyers }) => {
  const dispatch = useDispatch();
  // BlueprintJS inputRef
  const nameInput = React.useRef() as any;
  const cpfInput = React.useRef() as any;
  const oabInput = React.useRef() as any;
  const apiLawyers = useSelector((state: typeof reducers) => state.createcase.lawyers);
  const lawyerByPersonId = useSelector((state: typeof reducers) => state.createcase.lawyerByPersonId);
  const { loading } = useSelector((state: typeof reducers) => state.peoplelist.peopleList);
  const fastRegister = useSelector((state: typeof reducers) => state.peoplelist.fastRegister);
  const occupationTypes = useSelector((state: typeof reducers) => state.metapeopleoccupations.occupationTypes);
  const [envolvedPersonId, setEnvolvedPersonId] = useState('');
  const [envolvedPersonName, setEnvolvedPersonName] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isClient, setIsClient] = useState(false);
  const [personTypeError, setPersonTypeError] = useState(false);
  const [lawyerOccupationId, setLawyerOccupationId] = useState('');
  const [brState, setBrState] = useState('AC');

  useEffect(() => {
    dispatch(fetchLawyers('name='));
    dispatch(fetchOccupationTypes());
  }, [dispatch]);

  useEffect(() => {
    if (fastRegister.success) {
      setIsDialogOpen(false);
    }
    if (fastRegister.id) {
      dispatch(fetchLawyerByPersonId(fastRegister.id));
      setEnvolvedPersonId(fastRegister.id);
    }
  }, [fastRegister, dispatch]);

  useEffect(() => {
    if (lawyerByPersonId?.data?.length) {
      setEnvolvedPersonId(lawyerByPersonId?.data[0].lawyer_id);
    }
  }, [lawyerByPersonId]);

  useEffect(() => {
    if (occupationTypes?.data?.length) {
      const occupationId = occupationTypes.data.find((item: OccupationType) => item.occupation.toLocaleLowerCase().includes('adv')).occupation_id;
      setLawyerOccupationId(occupationId);
    }
  }, [occupationTypes]);

  const mountConnectedPerson = (): RelationedLawyer => ({
    lawyer_id: envolvedPersonId,
    is_lawyer_customer: isClient
  });

  const mountConnectedPersonLocal = (): RelationedLawyerLocal => ({
    lawyer_id: envolvedPersonId,
    is_lawyer_customer: isClient,
    lawyer_name: envolvedPersonName,
  });

  const clearFields = () => {
    setEnvolvedPersonId('');
    setEnvolvedPersonName('');
    setIsClient(false);
  };

  const alreadyAdded = () => newCase.lawyers.find((item: RelationedLawyerType) => item.lawyer_id === envolvedPersonId);

  const connectLawyer = () => {
    if (envolvedPersonId.length && !alreadyAdded()) {
      setPersonTypeError(false);
      const newArray = update(newCase.lawyers, { $set: newCase.lawyers.concat(mountConnectedPerson()) });
      setNewCase((state: PostCase) => ({
        ...state,
        lawyers: newArray
      }));
      setLawyers((result: any) => [...result, mountConnectedPersonLocal()]);
      clearFields();
    } else {
      setPersonTypeError(true);
    }
  };
  
  const removePerson = (id: string) => {
    const newLawyers = lawyers.filter((item: RelationedLawyer) => item.lawyer_id !== id)
    setNewCase((state: PostCase) => ({
      ...state,
      lawyers: newLawyers
    }));
    setLawyers(newLawyers);
  };

  const handleSendFastRegister = () => {
    setEnvolvedPersonName(nameInput?.current?.value);
    return dispatch(
      fastRegisterPerson({
        name: nameInput?.current?.value,
        natural_cpf: cpfInput?.current?.value,
        is_legal_person: false,
        occupation_id: lawyerOccupationId,
        lawyer: {
          oab_state: oabInput?.current?.value,
          oab_addendum: brState
        }
      })
    );
  };

  const dialogRender = () => (
    <Dialog
      className="register__dialog"
      isOpen={isDialogOpen}
      title="Cadastro rápido de pessoa (advogado)"
      onClose={() => setIsDialogOpen(false)}
      canEscapeKeyClose
    >
      {loading || lawyerByPersonId?.loading || fastRegister?.loading ? (
        <Spinner intent="primary" size={25} />
      ) : (
        <>
          <div className="input__group">
            <Input inputRef={nameInput} placeholder="Nome" />
            <Input inputRef={cpfInput} mask={cpfMask} placeholder="CPF" />
          </div>
          <div className="oab__form">
            <Input inputRef={oabInput} maxLength={15} placeholder="Número da OAB" />
            <Selector
              setValue={setBrState}
              value={brState}
              className="state__selector"
              options={STATE_LIST}
            />
          </div>
          <div className="checkbox__group">
            <Checkbox checked={false} disabled>
              Pessoa jurídica
            </Checkbox>
            <Checkbox checked={true}>
              Pessoa física
            </Checkbox>
            <Checkbox checked={true}>
              Advogado
            </Checkbox>
          </div>
          <div className="button__group">
            <Button intent="danger" minimal text="Cancelar" onClick={() => setIsDialogOpen(false)} />
            <Button intent="primary" onClick={() => handleSendFastRegister()} text="Salvar" />
          </div>
        </>
      )}
    </Dialog>
  );

  const addEnvolvedPersonTooltip = () => (
    <Tooltip captureDismiss content={'Clique aqui para cadastrar um novo advogado'}>
      <Button onClick={() => setIsDialogOpen(true)} className="input__button--add" name="add" icon="add" />
    </Tooltip>
  );

  return (
    <div className="envolved__person__container">
      {dialogRender()}
      <h3>Passo 3 de 3</h3>
      <p className="left__p">
        Adicione <strong>pelo menos um</strong> advogado(a) à este caso. Caso a pessoa ainda não esteja cadastrada no sistema, você pode adicioná-la clicando no
        botão "+".
      </p>
      <form action="">
        <div className="left__side">
          <div className="input__combo">
            <div className="autocomplete__input">
              <Autocomplete
                idKey="lawyer_id"
                callbackMethod={setEnvolvedPersonId}
                request={fetchLawyers}
                path="name"
                value={envolvedPersonName}
                setValue={setEnvolvedPersonName}
                required
                data={apiLawyers?.data}
                placeholder="Advogado *"
                rightElement={addEnvolvedPersonTooltip()}
                keyOne="lawyer_id"
                nameValue="person_name"
              />
            </div>
            <Button
              onClick={() => connectLawyer()}
              text="Relacionar advogado"
              className="button__connect"
              icon="add"
              name="add"
            />
          </div>
          {personTypeError && (
            <h4 className="empty__attribute">
              <Icon icon="warning-sign"></Icon>
              {alreadyAdded() ? (
                <span>Advogado já adicionado.</span>
              ) : (
                <span>Por favor, <strong>selecione um advogado</strong> antes de adicionar a relação.</span>
              )}
            </h4>
          )}
          <Checkbox checked={isClient} onChange={() => setIsClient((prevState) => !prevState)}>
            Advogado do cliente
          </Checkbox>
        </div>
      </form>
      <div className="table__container">
        <MetaTable
          loading={false}
          empty={!lawyers?.length}
          data={lawyers}
          headers={['Nome', 'Advogado do cliente']}
          columns={['lawyer_name', 'is_lawyer_customer']}
          localId="lawyer_id"
          removeRequest={removePerson}
        />
      </div>
    </div>
  );
};

export default StepEnvolvedLawyers;
