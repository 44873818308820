/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import update from 'immutability-helper';
import { Spinner, Tooltip } from '@blueprintjs/core';
import Input from '../Input';
import './styles.css';
import Button from '../Button';
import { User } from '../../modules/Users/types';
import { CreateUser } from './types';
import Selector from '../Selector';
import { fetchRoles } from '../../modules/UserGroups/actions';
import { updateUser } from '../../modules/Users/actions';
import { registerNewUser } from './actions';
import { reducers } from '../../store/reducers';
import Autocomplete from '../Autocomplete';
import FastRegisterDialog from '../FastRegisterDialog';
import { clearSuggestionsList, fastRegisterPerson, fetchAllPeople } from '../../modules/PeopleList/actions';
import createToaster from '../Toaster';
import { fetchOccupationTypes } from '../../modules/MetaPeopleOccupations/actions';
import { OccupationType } from '../../modules/MetaPeopleOccupations/types';
import { isAdmin } from '../../utils/permissions';

interface IRegisterUserProps {
  user: User;
  isEditable?: boolean;
}

const RegisterUser: React.FC<IRegisterUserProps> = ({ user, isEditable }) => {
  const { data } = useSelector((state: typeof reducers) => state.usergroups.roles);
  const { success, fail, loading } = useSelector((state: typeof reducers) => state.registeruser);
  const allPeople = useSelector((state: typeof reducers) => state.peoplelist.allPeople);
  const fastRegister = useSelector((state: typeof reducers) => state.peoplelist.fastRegister);
  const occupationTypes = useSelector((state: typeof reducers) => state.metapeopleoccupations.occupationTypes);
  // BlueprintJS inputRef
  const passwordInput = React.useRef() as any;
  // BlueprintJS inputRef
  const usernameInput = React.useRef() as any;
  const nameInput = React.useRef() as any;
  const cpfInput = React.useRef() as any;
  const cnpjInput = React.useRef() as any;
  const dispatch = useDispatch();
  const [spouseId, setSpouseId] = useState<string | null>(null);
  const [spouseName, setSpouseName] = useState('');
  const [isLegalPerson, setIsLegalPerson] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const formField = React.useRef() as React.MutableRefObject<HTMLFormElement>;
  const [lawyerOccupationId, setLawyerOccupationId] = useState<string | null>(null);
  const [isExternalLawyer, setIsExternalLawyer] = useState(false);
  const [isLawyer, setIsLawyer] = useState(false);
  const [newUser, setNewUser] = useState<CreateUser>({
    username: '',
    roles: [],
    password: '',
    person_id: null
  });

  const [editUser, setEditUser] = useState({
    username: '',
    user_id: user?.user_id || '',
    roles: user?.roles || [],
  });

  useEffect(() => {
    if (newUser.roles.length && newUser.roles[0].name.toLowerCase().includes("extern")) {
      return setIsExternalLawyer(true);
    }
    return setIsExternalLawyer(false);
  }, [newUser.roles]);

  useEffect(() => {
    if (occupationTypes?.data?.length) {
      const occupationId = occupationTypes.data.find((item: OccupationType) => item.occupation.toLocaleLowerCase().includes('adv')).occupation_id;
      setLawyerOccupationId(occupationId);
    }
  }, [occupationTypes]);

  useEffect(() => {
    usernameInput.current.value = user.username;
    const newState = update(editUser, {
      user_id: { $set: user.user_id },
      username: { $set: user.username },
    });
    setEditUser(newState);
  }, [user, editUser, usernameInput]);

  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(fetchAllPeople('name='));
    dispatch(fetchOccupationTypes());
  }, [dispatch]);

  useEffect(() => {
    if (passwordInput.current !== null) {
      passwordInput.current.type = 'password';
    }
  }, [passwordInput]);

  useEffect(() => {
    if (fastRegister.fail || fastRegister.success) {
      const showToast = createToaster({
        message: fastRegister.fail || fastRegister.success,
        intent: fastRegister.fail ? 'danger' : 'success',
        icon: fastRegister.fail ? 'warning-sign' : 'tick',
      });
      showToast();
    }
    if (fastRegister.success) {
      setSpouseId(fastRegister.id);
      setIsDialogOpen(false);
    }
    dispatch(clearSuggestionsList());
  }, [fastRegister.fail, fastRegister.success, fastRegister.id, dispatch]);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isEditable) {
      return dispatch(registerNewUser({
        ...newUser,
        roles: newUser.roles,
        person_id: spouseId,
      }));
    }
    isAdmin() && !newUser.password.length
    ? dispatch(updateUser(editUser.user_id, editUser.roles[0].role_id, spouseId, newUser.username)) 
    : dispatch(updateUser(editUser.user_id, editUser.roles[0].role_id, spouseId, newUser.password, newUser.username))
  };

  const registerNotification = () => {
    if (success) {
      return <div className="register__notification register__notification--success">{success}</div>;
    }
    if (fail) {
      return <div className="register__notification register__notification--error">{fail}</div>;
    }
    return null;
  };

  const getRightSetToSelector = () => (isEditable ? setEditUser : setNewUser);
  const getRightValueToSelector = () => (isEditable ? editUser : newUser);
  const getSubmitButtonText = () => (isEditable ? 'Atualizar usuário' : 'Cadastrar usuário');
  const openDialog = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsDialogOpen(true);
  };

  const onChangeUsername = (inputValue: string) => {
    setNewUser((prevState: CreateUser) => ({
      ...prevState,
      username: inputValue,
    }));
  };

  const onChangePassword = (inputValue: string) => {
    setNewUser((prevState: CreateUser) => ({
      ...prevState,
      password: inputValue,
    }));
  };

  const addSpouseTooltip = () => (
    <Tooltip captureDismiss content={'Clique aqui para cadastrar pessoa'}>
      <Button
        onClick={(e: SyntheticEvent) => openDialog(e)}
        name="add"
        className="sidebar__button--blue"
        icon="add"
      />
    </Tooltip>
  );

  const handleSendFastRegister = () => {
    setSpouseName(nameInput?.current?.value);
    if (isLegalPerson) {
      return dispatch(
        fastRegisterPerson({
          name: nameInput?.current?.value,
          legal_cnpj: cnpjInput?.current?.value,
          is_legal_person: isLegalPerson,
        })
      );
    }
    return dispatch(
      fastRegisterPerson({
        name: nameInput?.current?.value,
        natural_cpf: cpfInput?.current?.value,
        is_legal_person: isLegalPerson,
        occupation_id: (isLawyer || isExternalLawyer) ? lawyerOccupationId : null
      })
    );
  };

  return (
    <div className="register__user">
      <FastRegisterDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        loading={fastRegister?.loading}
        nameInput={nameInput}
        cpfInput={cpfInput}
        cnpjInput={cnpjInput}
        handleSendFastRegister={handleSendFastRegister}
        isLegalPerson={isLegalPerson}
        setIsLegalPerson={setIsLegalPerson}
        setIsLawyer={setIsLawyer}
        isLawyer={isLawyer}
      />
      <p className="description">Cadastrar usuário:</p>
      <p className="description sub__text">
        Para se cadastrar um usuário, a pessoa deve estar previamente cadastrada na plataforma.
      </p>
      {!loading ? (
        <div>
          <form ref={formField} onSubmit={handleSubmit}>
            <Selector
              required
              setValue={getRightSetToSelector()}
              userValue={getRightValueToSelector()}
              className="selector__padding"
              roles={data}
            />
            <Autocomplete
              idKey="person_id"
              callbackMethod={setSpouseId}
              value={spouseName}
              setValue={setSpouseName}
              data={allPeople?.data}
              request={fetchAllPeople}
              path={`name`}
              placeholder="Pessoa"
              rightElement={addSpouseTooltip()}
              keyOne="person_id"
              nameValue="name"
            />
            <Input
              type="email"
              placeholder="Novo usuário (email)"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeUsername(e.target.value)}
              inputRef={usernameInput}
            />
            {(!isEditable || isAdmin()) && (
              <Input
                inputRef={passwordInput}
                placeholder="Senha"
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePassword(e.target.value)}
              />
            )}
            <Button name="add" text={getSubmitButtonText()} type="submit" />
          </form>
          {registerNotification()}
        </div>
      ) : (
        <Spinner intent="primary" size={25} />
      )}
    </div>
  );
};

export default RegisterUser;
