/* eslint-disable import/prefer-default-export */
export const FETCH_ADDRESS_RELATION = 'FETCH_ADDRESS_RELATION';
export const FETCH_ADDRESS_RELATION_SUCCESS = 'FETCH_ADDRESS_RELATION_SUCCESS';
export const FETCH_ADDRESS_RELATION_FAIL = 'FETCH_ADDRESS_RELATION_FAIL';

export const CREATE_ADDRESS_RELATION = 'CREATE_ADDRESS_RELATION';
export const CREATE_ADDRESS_RELATION_SUCCESS = 'CREATE_ADDRESS_RELATION_SUCCESS';
export const CREATE_ADDRESS_RELATION_FAIL = 'CREATE_ADDRESS_RELATION_FAIL';

export const INACTIVATE_ADDRESS_RELATION = 'INACTIVATE_ADDRESS_RELATION';
export const INACTIVATE_ADDRESS_RELATION_SUCCESS = 'INACTIVATE_ADDRESS_RELATION_SUCCESS';
export const INACTIVATE_ADDRESS_RELATION_FAIL = 'INACTIVATE_ADDRESS_RELATION_FAIL';

export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
