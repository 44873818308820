/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import { Dispatch } from 'redux';
import * as c from './constants';
import { CreateUser } from './types';
import handleApiError from '../../utils/errors';
import { reloadPage } from '../../utils/navigation';

export const registerNewUser = (payload: CreateUser) => (dispatch: Dispatch) => {
  dispatch({
    type: c.POST_NEW_USER,
    payload: {
      request: {
        url: '/users',
        method: 'POST',
        data: payload,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          dispatch({
            type: c.POST_NEW_USER_SUCCESS,
            payload: {
              data: 'Usuário cadastrado com sucesso!',
            },
          });
          reloadPage();
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.POST_NEW_USER_FAIL,
            payload: {
              error: handleApiError(error.toString()),
            },
          });
        },
      },
    },
  });
};
