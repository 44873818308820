/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  createCase: {
    loading: false,
    fail: null,
    success: null,
  },
  lawyers: {
    data: [],
    fail: null,
    empty: true,
    loading: false,
  },
  lawyerByPersonId: {
    data: null,
    fail: null,
    empty: true,
    loading: false,
  },
};

const cases: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.CREATE_CASE:
        draft.createCase.loading = true;
        draft.createCase.fail = null;
        draft.createCase.success = null;
        return;

      case c.CREATE_CASE_SUCCESS:
        draft.createCase.loading = false;
        draft.createCase.success = payload.data;
        return;

      case c.CREATE_CASE_FAIL:
        draft.createCase.loading = false;
        draft.createCase.fail = payload.error;
        draft.createCase.success = null;
        return;

      case c.FETCH_LAWYERS:
        draft.lawyers.loading = true;
        return;

      case c.FETCH_LAWYERS_SUCCESS:
        draft.lawyers.loading = false;
        draft.lawyers.fail = null;
        if (payload && payload.data.length) {
          draft.lawyers.data = payload.data;
          draft.lawyers.empty = false;
        }
        return;

      case c.FETCH_LAWYERS_FAIL:
        draft.lawyers.loading = false;
        draft.lawyers.fail = payload.error;
        draft.lawyers.data = payload.data;
        return;

      case c.FETCH_LAWYER_BY_PERSON_ID:
        draft.lawyerByPersonId.loading = true;
        return;

      case c.FETCH_LAWYER_BY_PERSON_ID_SUCCESS:
        draft.lawyerByPersonId.loading = false;
        draft.lawyerByPersonId.fail = null;
        if (payload && payload.data.length) {
          draft.lawyerByPersonId.data = payload.data;
          draft.lawyerByPersonId.empty = false;
        }
        return;

      case c.FETCH_LAWYER_BY_PERSON_ID_FAIL:
        draft.lawyerByPersonId.loading = false;
        draft.lawyerByPersonId.fail = payload.error;
        draft.lawyerByPersonId.data = payload.data;
        return;

      default:
        return;
    }
  });

export default cases;
