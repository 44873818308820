/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  relatedDocuments: {
    data: [],
    fail: null,
    empty: true,
    loading: false,
    success: null,
  },
  inactivate: {
    fail: null,
    success: null,
    loading: false,
  }
};

const caseRelatedDocuments: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_RELATED_DOCUMENTS:
        draft.relatedDocuments.data = [];
        draft.relatedDocuments.loading = true;
        return;

      case c.FETCH_RELATED_DOCUMENTS_SUCCESS:
        draft.relatedDocuments.loading = false;
        if (payload.data && payload.data.result.length) {
          draft.relatedDocuments.data = payload.data;
          draft.relatedDocuments.empty = false;
        } else {
          draft.relatedDocuments.empty = true;
        }
        return;

      case c.FETCH_RELATED_DOCUMENTS_FAIL:
        draft.relatedDocuments.loading = false;
        draft.relatedDocuments.fail = payload.error;
        return;


      case c.INACTIVATE_CASE_DOCUMENT_RELATION:
        draft.inactivate.loading = true;
        draft.inactivate.fail = null;
        draft.inactivate.success = null;
        return;

      case c.INACTIVATE_CASE_DOCUMENT_RELATION_SUCCESS:
        draft.inactivate.loading = false;
        draft.inactivate.fail = null;
        draft.inactivate.success = 'Relação arquivada com sucesso!';
        return;

      case c.INACTIVATE_CASE_DOCUMENT_RELATION_FAIL:
        draft.inactivate.loading = false;
        draft.inactivate.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.inactivate.success = null;
        draft.inactivate.fail = null;
        return;

      default:
        return;
    }
  });

export default caseRelatedDocuments;
