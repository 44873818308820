/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, SyntheticEvent, useCallback, createRef } from 'react';
import { Label, Button, Spinner, IBreadcrumbProps, TextArea } from '@blueprintjs/core';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCourtsByJusticeAndCounty } from '../MetaCasesCourtType/actions';
import { fetchDistrictTypes } from '../MetaCasesDistrictType/actions';
import { fetchTagTypes } from '../MetaCasesTag/actions';
import { fetchSecretariesByCounty } from '../MetaCasesSecretaryType/actions';
import { fetchCase, updateCase, inactivateRelatedCase } from './actions';
import { reducers } from '../../store/reducers';
import update from 'immutability-helper';
import BreadCrumb from '../../components/Breadcrumb';
import Input from '../../components/Input';
import { goToHere } from '../../utils/navigation';
import './styles.css';
import Selector from '../../components/Selector';
import PopoverEditRemove from '../../components/PopoverEditRemove';
import DatePicker from '../../components/DatePicker';
import TagsManager from '../../components/TagsManager';
import { mountCaseDetails, mountTagsList } from '../../utils/legalCases';
import { TemporaryCaseType } from '../CreateCase/types';
import CaseRelations from '../../components/CaseRelations';
import { fetchProgressStatus } from '../MetaCasesProgressStatus/actions';
import { TagType } from '../MetaCasesTag/types';
import createToaster from '../../components/Toaster';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { formatNumberToCurrency, clearValue, handleCurrencyChange } from '../../utils/form';
import Autocomplete from '../../components/Autocomplete';
import { fetchAllActiveCases } from '../CasesList/actions';
import { formatDateToServer, getDateObjectApplyingGMT, momentFormatBrDate } from '../../utils/date';
import { fetchJusticeTypes } from '../MetaCasesJusticeType/actions';
import { useHistory } from 'react-router-dom';

type Match = {
  path: string;
  url: string;
  isExact: boolean;
  params: any;
};

interface IPersonProps {
  match: Match;
}

const CaseDetails: React.FC<IPersonProps> = ({ match }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state: typeof reducers) => state.casedetails.case);
  const caseUpdate = useSelector((state: typeof reducers) => state.casedetails.caseUpdate);
  const caseInactivate = useSelector((state: typeof reducers) => state.casedetails.caseInactivate);
  const allCases = useSelector((state: typeof reducers) => state.caseslist.allCases);
  const progressStatus = useSelector((state: typeof reducers) => state.metacasesprogressstatus.progressStatus);
  const courtsByJusticeAndCounty = useSelector(
    (state: typeof reducers) => state.metacasescourttype.courtsByJusticeAndCounty
  );
  const tagTypes = useSelector((state: typeof reducers) => state.metacasestag.tagTypes);
  const secretariesByCountyId = useSelector((state: typeof reducers) => state.metacasessecretarytype.secretariesByCountyId);
  const justiceTypes = useSelector((state: typeof reducers) => state.metacasesjusticetype.justiceTypes);
  const districtTypes = useSelector((state: typeof reducers) => state.metacasesdistricttype.districtTypes);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [caseData, setCaseData] = useState<TemporaryCaseType>(mountCaseDetails());
  const [originalCaseData, setOriginalCaseData] = useState<any>();
  const [justiceId, setJusticeId] = useState('');
  const [secretaryId, setSecretaryId] = useState('');
  const [courtId, setCourtId] = useState('');
  const [countyId, setCountyId] = useState('');
  const [progressId, setProgressId] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [currencyValue, setCurrencyValue] = useState(0);
  const [autoCaseProcessNumber, setAutoCaseProcessNumber] = useState<string | null>(null);
  const autoRef = createRef() as any;
  const history = useHistory();
  const BREADCRUMB_PATHS: IBreadcrumbProps[] = [
    { href: '/painel/casos', text: 'Casos' },
    { href: '/painel/casos/detalhes', text: `${caseData ? caseData?.title : ''}` },
  ];

  useEffect(() => {
    if (autoRef && autoCaseProcessNumber?.length) {
      autoRef.current.value = autoCaseProcessNumber;
    }
  }, [autoCaseProcessNumber, autoRef]);

  const setNewJustice = useCallback(() => {
    if (countyId) {
      dispatch(fetchSecretariesByCounty(countyId));
      setCaseData((state: TemporaryCaseType) => ({
        ...state,
        process_county_id: countyId,
      }));
    }
    if (justiceId) {
      setCaseData((state: TemporaryCaseType) => ({
        ...state,
        process_justice_type_id: justiceId,
      }));
    }
    if (justiceId && countyId) {
      dispatch(fetchCourtsByJusticeAndCounty(justiceId, countyId));
    }
  }, [justiceId, countyId, setCaseData, dispatch]);

  useEffect(() => {
    if (
      (justiceId && caseData.process_justice_type_id !== justiceId) ||
      (countyId && caseData.process_county_id !== countyId)
    ) {
      setNewJustice();
    }
  }, [justiceId, countyId, caseData, setNewJustice]);

  const setNewCourt = useCallback(() => {
    setCaseData((state: TemporaryCaseType) => ({
      ...state,
      process_court_id: courtId,
    }));
  }, [courtId, setCaseData]);

  useEffect(() => {
    if (courtId && caseData.process_court_id !== courtId) {
      setNewCourt();
    }
  }, [courtId, caseData, setNewCourt]);

  const setCaseDataCallBack = (newTags: TagType[]) => {
    setCaseData((state: TemporaryCaseType) => ({
      ...state,
      tags: newTags,
    }));
  };

  const removeTag = (tagId: string) => {
    setCaseData((state: TemporaryCaseType) => ({
      ...state,
      tags: caseData.tags.filter((item: any) => item.tag_id !== tagId),
    }));
  };

  const setNewSecretary = useCallback(() => {
    if (caseData.process_secretary_id !== secretaryId) {
      setCaseData((state: TemporaryCaseType) => ({
        ...state,
        process_secretary_id: secretaryId,
      }));
    }
  }, [secretaryId, caseData, setCaseData]);

  useEffect(() => {
    if (secretaryId) {
      setNewSecretary();
    }
  }, [secretaryId, caseData, setNewSecretary]);

  useEffect(() => {
    if (progressId) {
      setCaseData((state: TemporaryCaseType) => ({
        ...state,
        case_status_id: progressId,
      }));
    }
  }, [progressId]);

  useEffect(() => {
    if (caseUpdate.failUpdate || caseUpdate.messageUpdate || caseInactivate.fail || caseInactivate.message) {
      const showToast = createToaster({
        message: caseUpdate.failUpdate || caseUpdate.messageUpdate || caseInactivate.fail || caseInactivate.message,
        intent: caseUpdate.failUpdate || caseInactivate.fail ? 'danger' : 'success',
        icon: caseUpdate.failUpdate || caseInactivate.fail ? 'warning-sign' : 'tick',
      });
      showToast();
    }
    if (caseUpdate.messageUpdate) {
      setIsFormDisabled(true);
      goToHere(`/painel/casos/detalhes/${caseUpdate?.dataUpdate?.data}`);
    }
  }, [caseUpdate, caseInactivate]);

  useEffect(() => {
    dispatch(fetchCase(match.params.id));
    dispatch(fetchTagTypes());
    dispatch(fetchDistrictTypes());
    dispatch(fetchJusticeTypes());
    dispatch(fetchProgressStatus());
    dispatch(fetchAllActiveCases('name='));
  }, [dispatch, match]);

  useEffect(() => {
    const newData = mountCaseDetails(data);
    setOriginalCaseData(newData);
    setCaseData(newData);
    setProgressId(data?.case_status?.case_progress_status_id);
    setCurrencyValue(data?.lawsuit_value);
    setCountyId(data?.process_county?.county_id);
    setJusticeId(data?.process_justice_type?.justice_type_id);
    if (data?.process_justice_type && data?.process_county) {
      dispatch(fetchCourtsByJusticeAndCounty(data?.process_justice_type?.justice_type_id, data?.process_county?.county_id));
    }
    if (data?.process_county) {
      dispatch(fetchSecretariesByCounty(data?.process_county.county_id));
    }
    setCourtId(data?.process_court?.court_id);
    setSecretaryId(data?.process_secretary?.secretary_id);
    setAutoCaseProcessNumber(data?.process_originating);
  }, [data, dispatch]);

  const handleCancel = () => {
    setCaseData(originalCaseData);
    setProgressId(originalCaseData?.case_status_id);
    setCurrencyValue(originalCaseData?.lawsuit_value);
    setCountyId(originalCaseData?.process_county_id);
    setJusticeId(originalCaseData?.process_justice_type_id);
    setCourtId(originalCaseData?.process_court_id);
    setSecretaryId(originalCaseData?.process_secretary_id);
    setAutoCaseProcessNumber(originalCaseData?.process_originating);
    if (autoRef) {
      autoRef.current.value = originalCaseData?.process_originating;
    }
    setIsFormDisabled(true);
  };

  useEffect(() => {
    setCaseData(update(caseData, { process_court_id: { $set: courtId } }));
  }, [courtId, caseData]);

  useEffect(() => {
    setCaseData((state: TemporaryCaseType) => ({
      ...state,
      lawsuit_value: currencyValue,
    }));
  }, [currencyValue]);

  const processNumberExists = () => caseData?.process_id !== null;

  const handleChangeCurrency = (value: string) => {
    const formattedValue = clearValue(value);
    if (Number(formattedValue) > 0) {
      let newValue = handleCurrencyChange(formattedValue);
      return setCurrencyValue(newValue);
    }
    return setCurrencyValue(0);
  };

  const getCaseForm = () => (
    <div id="case-form">
      <div className="row">
        <Label className="details">
          <span>Número</span>
          <Input
            maxLength={25}
            placeholder="-"
            value={caseData?.process_id ?? ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCaseData(update(caseData, { process_id: { $set: e.target.value } }))
            }
            disabled={isFormDisabled}
          />
        </Label>
        <Label className="details">
          <span>Originário</span>
          <Autocomplete
            disabled={isFormDisabled}
            reference={autoRef}
            className="auto"
            value={autoCaseProcessNumber || ''}
            setValue={setAutoCaseProcessNumber}
            request={fetchAllActiveCases}
            path="name"
            idKey="legal_case_id"
            data={allCases?.data}
            placeholder="-"
            keyOne="legal_case_id"
            nameValue="process_id"
          />
        </Label>
        <Label className="details">
          <span>Situação</span>
          <Selector
            required
            setValue={setProgressId}
            disabled={isFormDisabled}
            value={progressId}
            className="new__selector"
            caseProgressSituation={progressStatus.data}
          />
        </Label>
        <Label className="details">
          <span>Comarca</span>
          {processNumberExists() ? (
            <Selector
              setValue={setCountyId}
              disabled={!processNumberExists() || districtTypes.empty || isFormDisabled}
              required={processNumberExists()}
              className="new__selector"
              counties={districtTypes.data}
              value={countyId}
            />
          ) : (
              <div className="no__value">-</div>
            )}
        </Label>
      </div>
      <div className="row">
        <Label className="details">
          <span>Data de distrubuição</span>
          {processNumberExists() && caseData?.date_dispatch ? (
            <DatePicker
              disabled={!processNumberExists() || isFormDisabled}
              className="date__picker"
              minLimitDate={new Date(1950,11,11)}
              maxLimitDate={new Date(2100, 11, 11)}
              value={getDateObjectApplyingGMT(caseData?.date_dispatch.toString())}
              onChangeMethod={(newDate: Date) =>
                setCaseData((state: TemporaryCaseType) => ({
                  ...state,
                  date_dispatch: formatDateToServer(momentFormatBrDate(newDate)),
                }))
              }
            />
          ) : (
              <DatePicker
                disabled={!processNumberExists() || isFormDisabled}
                className="date__picker"
                minLimitDate={new Date(1950,11,11)}
                maxLimitDate={new Date(2100, 11, 11)}
                placeholder="Selecione uma data"
                onChangeMethod={(newDate: Date) =>
                  setCaseData((state: TemporaryCaseType) => ({
                    ...state,
                    date_dispatch: formatDateToServer(momentFormatBrDate(newDate)),
                  }))
                }
              />
            )}
        </Label>
        <Label className="details">
          <span>Última página</span>
          <Input
            placeholder="-"
            value={caseData?.page_last_copy ?? undefined}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCaseData((state: TemporaryCaseType) => ({
                ...state,
                page_last_copy: e.target.value,
              }))
            }
            disabled={isFormDisabled}
          />
        </Label>
        <Label className="details">
          <span>Valor da causa</span>
          {processNumberExists() ? (
            <input
              type="text"
              required={processNumberExists()}
              disabled={isFormDisabled}
              className="bp3-input currency"
              value={formatNumberToCurrency(currencyValue)}
              onChange={(e) => handleChangeCurrency(e.target.value)}
            />
          ) : (
              <div className="no__value">-</div>
            )}
        </Label>
        <Label className="details">
          <span>Nome da ação</span>
          <Input
            maxLength={400}
            value={caseData?.lawsuit_name ?? undefined}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCaseData((state: TemporaryCaseType) => ({
                ...state,
                lawsuit_name: e.target.value,
              }))
            }
            disabled={isFormDisabled}
          />
        </Label>
      </div>
      <div className="row">
        <Label className="details">
          <span>Tipo de Justiça</span>
          {processNumberExists() && justiceTypes.data.length ? (
            <Selector
              required={processNumberExists()}
              disabled={!processNumberExists() || isFormDisabled}
              setValue={setJusticeId}
              className="new__selector"
              justiceTypes={justiceTypes.data}
              value={caseData?.process_justice_type_id || ''}
            />
          ) : (
              <div className="no__value">-</div>
            )}
        </Label>
        <Label className="details">
          <span>Vara</span>
          {processNumberExists() && courtsByJusticeAndCounty?.data.length ? (
            <Selector
              required={processNumberExists()}
              disabled={
                !processNumberExists() ||
                !justiceId ||
                justiceTypes.empty ||
                !countyId ||
                districtTypes.empty ||
                isFormDisabled
              }
              setValue={setCourtId}
              className="new__selector"
              courtTypes={courtsByJusticeAndCounty?.data}
              value={caseData?.process_court_id || ''}
            />
          ) : (
              <div className="no__value">-</div>
            )}
        </Label>
        <Label className="details">
          <span>Secretaria</span>
          {processNumberExists() &&
            secretariesByCountyId.data?.length &&
            countyId.length ? (
              <Selector
                required={processNumberExists()}
                disabled={!processNumberExists() || secretariesByCountyId.empty || isFormDisabled}
                setValue={setSecretaryId}
                className="new__selector"
                secretaries={secretariesByCountyId?.data}
                value={caseData?.process_secretary_id || ''}
              />
            ) : (
              <div className="no__value">-</div>
            )}
        </Label>
        <Label className="details">
          <span>Informações adicionais</span>
          <TextArea
            className="add__info"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setCaseData(update(caseData, { additional_info: { $set: e.target.value } }))
            }
            large
            disabled={isFormDisabled}
            value={caseData?.additional_info ?? ''}
          />
        </Label>
      </div>
      <div className="row">
        <Label className="label__tags details">
          <span>Assuntos deste caso</span>
          <TagsManager
            callBack={setCaseDataCallBack}
            removeCallBack={removeTag}
            tagTypes={tagTypes.data}
            isFormDisabled={isFormDisabled}
            caseData={caseData}
          />
        </Label>
      </div>
    </div>
  );

  const saveButtonsVisibility = () => (!isFormDisabled ? 'save__buttons' : 'none');

  const saveCase = (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(
      updateCase({
        title: caseData.title,
        lawsuit_name: caseData.lawsuit_name,
        legal_case_id: caseData.legal_case_id,
        is_legal_process: caseData.is_legal_process,
        case_status_id: caseData.case_status_id,
        process_originating: autoCaseProcessNumber,
        process_id: caseData.process_id,
        process_county_id: caseData.process_county_id,
        process_justice_type_id: caseData.process_justice_type_id,
        process_court_id: caseData.process_court_id,
        process_secretary_id: caseData.process_secretary_id,
        lawsuit_value: caseData.lawsuit_value,
        date_dispatch: caseData.date_dispatch,
        page_last_copy: caseData.page_last_copy,
        tags: mountTagsList(caseData.tags),
        additional_info: caseData.additional_info,
      })
    );
  };

  return (
    <div className="case__details__container">
      <ConfirmationDialog
        headerText="Tem certeza que deseja excluir este caso?"
        confirmMethod={() => dispatch(inactivateRelatedCase(caseData.legal_case_id!))}
        setIsOpen={setIsDialogOpen}
        isOpen={isDialogOpen}
      />
      <div className="content__head">
        <Button
          className="bp3-button sidebar__button--blue"
          onClick={() => history.goBack()}
          icon="chevron-left"
          text="Voltar"
        />
        <BreadCrumb breads={BREADCRUMB_PATHS} />
      </div>
      {caseData || caseUpdate.loadingUpdate || caseInactivate.loading ? (
        <div>
          <form onSubmit={saveCase}>
            <div className="title__buttons">
              <div className="case__name">
                <Input
                  required
                  className="case__title"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCaseData((state: TemporaryCaseType) => ({
                      ...state,
                      title: e.target.value,
                    }))
                  }
                  value={caseData?.title || ''}
                  disabled={isFormDisabled}
                />
                {isFormDisabled && (
                  <PopoverEditRemove
                    className="popover__edit-remove"
                    formDisabled={isFormDisabled}
                    editRequest={() => setIsFormDisabled(!isFormDisabled)}
                    removeRequest={() => setIsDialogOpen(true)}
                  />
                )}
              </div>
              <div className={saveButtonsVisibility()}>
                <Button intent="danger" minimal onClick={() => handleCancel()} text="Cancelar" />
                <Button
                  className="button--save"
                  disabled={
                    processNumberExists() &&
                    (!justiceId || !countyId)
                  }
                  text="Salvar edição"
                  type="submit"
                />
              </div>
            </div>
            {caseData && <div className="main__content">{getCaseForm()}</div>}
          </form>
          <div className="bottom__content">
            <CaseRelations caseId={caseData?.legal_case_id!} />
          </div>
        </div>
      ) : (
          <Spinner intent="primary" size={25} />
        )}
    </div>
  );
};

export default CaseDetails;
