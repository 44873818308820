export const FETCH_OCCUPATIONS_TYPE = 'FETCH_OCCUPATIONS_TYPE';
export const FETCH_OCCUPATIONS_TYPE_SUCCESS = 'FETCH_OCCUPATIONS_TYPE_SUCCESS';
export const FETCH_OCCUPATIONS_TYPE_FAIL = 'FETCH_OCCUPATIONS_TYPE_FAIL';
export const FETCH_OCCUPATIONS_TYPE_PAGINATED = 'FETCH_OCCUPATIONS_TYPE_PAGINATED';
export const FETCH_OCCUPATIONS_TYPE_PAGINATED_SUCCESS = 'FETCH_OCCUPATIONS_TYPE_PAGINATED_SUCCESS';
export const FETCH_OCCUPATIONS_TYPE_PAGINATED_FAIL = 'FETCH_OCCUPATIONS_TYPE_PAGINATED_FAIL';
export const FETCH_OCCUPATIONS_TYPE_ID = 'FETCH_OCCUPATIONS_TYPE_ID';
export const FETCH_OCCUPATIONS_TYPE_ID_SUCCESS = 'FETCH_OCCUPATIONS_TYPE_ID_SUCCESS';
export const FETCH_OCCUPATIONS_TYPE_ID_FAIL = 'FETCH_OCCUPATIONS_TYPE__ID_FAIL';
export const CREATE_OCCUPATIONS_TYPE = 'CREATE_OCCUPATIONS_TYPE';
export const CREATE_OCCUPATIONS_TYPE_SUCCESS = 'CREATE_OCCUPATIONS_TYPE_SUCCESS';
export const CREATE_OCCUPATIONS_TYPE_FAIL = 'CREATE_OCCUPATIONS_TYPE_FAIL';
export const UPDATE_OCCUPATIONS_TYPE = 'UPDATE_OCCUPATIONS_TYPE';
export const UPDATE_OCCUPATIONS_TYPE_SUCCESS = 'UPDATE_OCCUPATIONS_TYPE_SUCCESS';
export const UPDATE_OCCUPATIONS_TYPE_FAIL = 'UPDATE_OCCUPATIONS_TYPE_FAIL';
export const INACTIVATE_OCCUPATIONS_TYPE = 'INACTIVATE_OCCUPATIONS_TYPE';
export const INACTIVATE_OCCUPATIONS_TYPE_SUCCESS = 'INACTIVATE_OCCUPATIONS_TYPE_SUCCESS';
export const INACTIVATE_OCCUPATIONS_TYPE_FAIL = 'INACTIVATE_OCCUPATIONS_TYPE_FAIL';
export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
