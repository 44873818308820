import React, { useEffect, useState, useCallback, useRef, SyntheticEvent } from 'react';
import './styles.css';
import { Popover, Button as BButton, Position, Spinner, NonIdealState } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { COLUMNS, BREADCRUMB_PATHS } from './constants';
import RegisterPerson from '../../components/RegisterPerson';
import { reducers } from '../../store/reducers';
import { fetchPeople } from './actions';
import BreadCrumb from '../../components/Breadcrumb';
import PopoverFilterOptions from '../../components/PopoverFilterOptions';
import { isMobile } from '../../utils/window';
import Button from '../../components/Button';
import { cnpjMask, cpfMask } from '../../utils/masks';
import Pagination from '../../components/Pagination';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';
import { verifyUserPermission } from '../../utils/permissions';
import { PEOPLE_MODULE_NAME, WRITE_PERMISSION } from '../Permissions/constants';
import { mountFetchPeoplePathFilters } from '../../utils/requestPath';

const PeopleList: React.FC = () => {
  const dispatch = useDispatch();
  const [phisicalPerson, setPhisicalPerson] = useState<boolean>(false);
  const [juridicalPerson, setJuridicalPerson] = useState<boolean>(false);
  const [isLegalPeople, setIsLegalPeople] = useState<boolean | undefined>(undefined);
  const [mostRecent, setMostRecent] = useState<boolean | undefined>(undefined);
  const [alphabetic, setAlphabetic] = useState<boolean | undefined>(undefined);
  const [toggleRegisterForm, setToggleRegisterForm] = useState<boolean>(false);
  const searchInput = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [filterPath, setFilterPath] = useState<string>('');

  const getMostRecent = () => {
    if (mostRecent) {
      setMostRecent((state) => !state);
    } else {
      setMostRecent(true);
    }
  };

  const getAlphabetic = () => {
    if (alphabetic) {
      setAlphabetic((state) => !state);
    } else {
      setAlphabetic(true);
    }
  };

  const tableFilters = [
    {
      value: mostRecent,
      request: getMostRecent,
      text: 'Recentes',
    },
    {
      value: alphabetic,
      request: getAlphabetic,
      text: 'Ordem alfabética',
    },
  ];

  useEffect(() => {
    dispatch(fetchPeople(1, DEFAULT_PER_PAGE_LIMIT, filterPath));
  }, [filterPath, dispatch]);

  const handlePeopleTypeRequest = useCallback((e?: SyntheticEvent) => {
    if (e) {
      e.preventDefault();
      sessionStorage.setItem('people_search', searchInput.current.value);
      if (!phisicalPerson && juridicalPerson) {
        setIsLegalPeople(true);
        setFilterPath(mountFetchPeoplePathFilters(mostRecent, alphabetic, isLegalPeople, searchInput.current.value))
      } else if (phisicalPerson && !juridicalPerson) {
        setIsLegalPeople(false);
        setFilterPath(mountFetchPeoplePathFilters(mostRecent, alphabetic, isLegalPeople, searchInput.current.value))
      } else {
        setIsLegalPeople(undefined);
        setFilterPath(mountFetchPeoplePathFilters(mostRecent, alphabetic, isLegalPeople, searchInput.current.value))
      }
    }
  }, [phisicalPerson, juridicalPerson, mostRecent, alphabetic, searchInput, isLegalPeople]);

  useEffect(() => {
    handlePeopleTypeRequest();
  }, [phisicalPerson, juridicalPerson, alphabetic, mostRecent, handlePeopleTypeRequest]);

  const setNaturalPeople = () => {
    setPhisicalPerson((state) => !state);
  };

  const setLegalPeople = () => {
    setJuridicalPerson((state) => !state);
  };

  const searchBarFilters = [
    {
      value: phisicalPerson,
      request: setNaturalPeople,
      text: 'Pessoas físicas',
    },
    {
      value: juridicalPerson,
      request: setLegalPeople,
      text: 'Pessoas jurídicas',
    },
  ];

  useEffect(() => {
    const peopleSearchStorage = sessionStorage.getItem('people_search');
    if (peopleSearchStorage) {
      searchInput.current.value = peopleSearchStorage;
    }
    setFilterPath(mountFetchPeoplePathFilters(mostRecent, alphabetic, isLegalPeople, searchInput.current.value))
    dispatch(fetchPeople(1, DEFAULT_PER_PAGE_LIMIT, filterPath));
  }, [dispatch, alphabetic, filterPath, isLegalPeople, mostRecent, searchInput]);

  const { empty, loading, data } = useSelector((state: typeof reducers) => state.peoplelist.peopleList);

  const getHeadColumns = () => COLUMNS.map((column) => <th key={column}>{column}</th>);

  const mobRegisterVisibilityCondition = !isMobile() || (isMobile() && toggleRegisterForm);
  const mobPeopleListVisibilityCondition = !isMobile() || (isMobile() && !toggleRegisterForm);

  const getTable = () =>
    data.result && !empty ? (
      <table className="bp3-html-table bp3-html-table-striped bp3-interactive">
        <thead>
          <tr>
            <th>
              <Popover
                className="popover"
                content={<PopoverFilterOptions filters={tableFilters} />}
                position={Position.BOTTOM}
              >
                <BButton text="Nome" rightIcon="caret-down" />
              </Popover>
            </th>
            {getHeadColumns()}
          </tr>
        </thead>
        <tbody>
          {data.result.map((person: any) => (
            <tr key={person.person_id}>
              <td>{person.name}</td>
              <td>{cnpjMask(person.legal_cnpj) || cpfMask(person.natural_cpf)}</td>
              <td>{person.email}</td>
              <td>
                <Button path={`/painel/pessoas/individuo/${person.person_id}`} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <NonIdealState
        icon="search"
        title="Nenhuma pessoa encontrada"
        description="Não foram encontrados resultados a partir dos dados que você digitou"
      />
    );

  return (
    <div className="people__container">
      {mobPeopleListVisibilityCondition ? (
        <div className="people__list">
          <BreadCrumb breads={BREADCRUMB_PATHS} />
          <p className="description">
            Estas são as pessoas cadastradas na plataforma, na listagem, clique em &#34;Nome&#34; para filtros de ordenação.
          </p>
          <form className="bp3-input-group" onSubmit={handlePeopleTypeRequest}>
            <span className="bp3-icon" />
            <input
              type="text"
              className="bp3-input"
              ref={searchInput}
              placeholder="Digite o nome da pessoa que você deseja encontrar ..."
            />
            <Popover
              className="popover search"
              content={<PopoverFilterOptions filters={searchBarFilters} />}
              position={Position.BOTTOM}
            >
              <BButton rightIcon="caret-down" text="Filtrar" />
            </Popover>
            <Button
              className="bp3-button bp3-minimal bp3-intent-primary bp3-icon-search"
              onClick={() => handlePeopleTypeRequest()}
            >
              Buscar pessoa
            </Button>
          </form>
          <Button name="mobileAdd" onClick={() => setToggleRegisterForm(!toggleRegisterForm)} text="Cadastrar pessoa" />
          {loading ? (
            <div className="table__loader">
              <Spinner intent="primary" size={25} />
            </div>
          ) : (
            <>
              {getTable()}
              <Pagination
                request={fetchPeople}
                optionsPath={filterPath}
                loading={loading}
                data={data}
              />
            </>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="register__container">
        {mobRegisterVisibilityCondition && verifyUserPermission(PEOPLE_MODULE_NAME, WRITE_PERMISSION) ? (
          <RegisterPerson mobileToggle={toggleRegisterForm} setMobileToggle={setToggleRegisterForm} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PeopleList;
