/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  courtTypes: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  courtTypesPaginated: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  courtsByJusticeAndCounty: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  courtTypesId: {
    data: null,
    loading: false,
    fail: null,
  },
  createCourtType: {
    loading: false,
    fail: null,
    success: null,
  },
  updateCourtType: {
    loading: false,
    fail: null,
    success: null,
  },
  inactivateCourtType: {
    loading: false,
    fail: null,
    success: null,
  },
};

const metaCases: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_COURT_TYPE:
        draft.courtTypes.loading = true;
        draft.courtTypes.empty = true;
        draft.courtTypes.fail = null;
        return;

      case c.FETCH_COURT_TYPE_SUCCESS:
        draft.courtTypes.loading = false;
        draft.courtTypes.fail = null;
        if (payload && payload.data.length) {
          draft.courtTypes.data = payload.data;
          draft.courtTypes.empty = false;
        }
        return;

      case c.FETCH_COURT_TYPE_FAIL:
        draft.courtTypes.loading = false;
        draft.courtTypes.fail = payload.error;
        draft.courtTypes.data = payload.data;
        return;

      case c.FETCH_COURT_TYPE_PAGINATED:
        draft.courtTypesPaginated.loading = true;
        draft.courtTypesPaginated.empty = true;
        draft.courtTypesPaginated.fail = null;
        return;

      case c.FETCH_COURT_TYPE_PAGINATED_SUCCESS:
        draft.courtTypesPaginated.loading = false;
        draft.courtTypesPaginated.fail = null;
        if (payload && payload.data.result.length) {
          draft.courtTypesPaginated.data = payload.data;
          draft.courtTypesPaginated.empty = false;
        }
        return;

      case c.FETCH_COURT_TYPE_PAGINATED_FAIL:
        draft.courtTypesPaginated.loading = false;
        draft.courtTypesPaginated.fail = payload.error;
        draft.courtTypesPaginated.data = payload.data;
        return;

      case c.FETCH_COURT_TYPE_ID:
        draft.courtTypesId.loading = true;
        draft.courtTypesId.fail = null;
        return;

      case c.FETCH_COURT_TYPE_ID_SUCCESS:
        draft.courtTypesId.loading = false;
        draft.courtTypesId.fail = null;
        if (payload && payload.data.length) {
          draft.courtTypesId.data = payload.data;
        }
        return;

      case c.FETCH_COURT_TYPE_ID_FAIL:
        draft.courtTypesId.loading = false;
        draft.courtTypesId.fail = payload.error;
        draft.courtTypesId.data = payload.data;
        return;

      case c.FETCH_COURTS_BY_JUSTICE_AND_COUNTY:
        draft.courtsByJusticeAndCounty.data = [];
        draft.courtsByJusticeAndCounty.loading = true;
        draft.courtsByJusticeAndCounty.fail = null;
        draft.courtsByJusticeAndCounty.empty = true;
        return;

      case c.FETCH_COURTS_BY_JUSTICE_AND_COUNTY_SUCCESS:
        draft.courtsByJusticeAndCounty.loading = false;
        draft.courtsByJusticeAndCounty.fail = null;
        if (payload && payload.data.length) {
          draft.courtsByJusticeAndCounty.data = payload.data;
          draft.courtsByJusticeAndCounty.empty = false;
        }
        return;

      case c.FETCH_COURTS_BY_JUSTICE_AND_COUNTY_FAIL:
        draft.courtsByJusticeAndCounty.loading = false;
        draft.courtsByJusticeAndCounty.fail = payload.error;
        draft.courtsByJusticeAndCounty.data = payload.data;
        return;

      case c.CREATE_COURT_TYPE:
        draft.createCourtType.loading = true;
        draft.createCourtType.fail = null;
        draft.createCourtType.success = null;
        return;

      case c.CREATE_COURT_TYPE_SUCCESS:
        draft.createCourtType.loading = false;
        draft.createCourtType.success = payload.data;
        return;

      case c.CREATE_COURT_TYPE_FAIL:
        draft.createCourtType.loading = false;
        draft.createCourtType.fail = payload.error;
        draft.createCourtType.success = null;
        return;

      case c.UPDATE_COURT_TYPE:
        draft.updateCourtType.loading = true;
        draft.updateCourtType.fail = null;
        draft.updateCourtType.success = null;
        return;

      case c.UPDATE_COURT_TYPE_SUCCESS:
        draft.updateCourtType.loading = false;
        draft.updateCourtType.fail = null;
        draft.updateCourtType.success = payload.data;
        return;

      case c.UPDATE_COURT_TYPE_FAIL:
        draft.updateCourtType.loading = false;
        draft.updateCourtType.fail = payload.error;
        draft.updateCourtType.success = null;
        return;

      case c.INACTIVATE_COURT_TYPE:
        draft.inactivateCourtType.loading = true;
        draft.inactivateCourtType.fail = null;
        draft.inactivateCourtType.success = null;
        return;

      case c.INACTIVATE_COURT_TYPE_SUCCESS:
        draft.inactivateCourtType.loading = false;
        draft.inactivateCourtType.fail = null;
        draft.inactivateCourtType.success = payload.data;
        return;

      case c.INACTIVATE_COURT_TYPE_FAIL:
        draft.inactivateCourtType.loading = false;
        draft.inactivateCourtType.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.createCourtType.success = null;
        draft.updateCourtType.success = null;
        draft.inactivateCourtType.success = null;
        draft.createCourtType.fail = null;
        draft.updateCourtType.fail = null;
        draft.inactivateCourtType.fail = null;
        return;

      default:
        return;
    }
  });

export default metaCases;
