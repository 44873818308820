/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/camelcase */
import { IconName } from '@blueprintjs/icons';

export const DEFAULT_PERMISSIONS = [
  {
    permission: 'r&w&d',
    entity: {
      entity_id: 'dbd7df3f-7d43-4fb4-a35d-b8e4cd3169f0',
      name: 'meta_data',
      created_at: '2020-04-06',
    },
  },
  {
    permission: 'r&w&d',
    entity: {
      entity_id: 'be6e41c6-34e4-42c7-91cd-08fded81c3ff',
      name: 'legal_cases',
      created_at: '2020-04-06',
    },
  },
  {
    permission: 'r&w&d',
    entity: {
      entity_id: '525237ef-9ade-4e28-8cc9-194dc9bdde64',
      name: 'person',
      created_at: '2020-04-06',
    },
  },
  {
    permission: 'r&w&d',
    entity: {
      entity_id: 'c4cb006e-6dc3-4e2e-8b31-a34079e4ee5c',
      name: 'schedule',
      created_at: '2020-04-06',
    },
  },
  {
    permission: 'r&w&d',
    entity: {
      entity_id: 'd2ec89cb-bf5f-4765-9d99-04d6b4ac516d',
      name: 'document',
      created_at: '2020-04-06',
    },
  },
  {
    permission: 'r&w&d',
    entity: {
      entity_id: '92e07ceb-1e5b-4246-8197-37386658a99a',
      name: 'user',
      created_at: '2020-04-06',
    },
  },
  {
    permission: 'r&w&d',
    entity: {
      entity_id: '3a6eac64-6a47-4c0c-a822-fb708e3558b0',
      name: 'role',
      created_at: '2020-04-06',
    },
  },
];

export const EMPTY_PERMISSIONS = [
  {
    permission: '',
    entity: {
      entity_id: 'dbd7df3f-7d43-4fb4-a35d-b8e4cd3169f0',
      name: 'meta_data',
      created_at: '2020-04-06',
    },
  },
  {
    permission: '',
    entity: {
      entity_id: 'be6e41c6-34e4-42c7-91cd-08fded81c3ff',
      name: 'legal_cases',
      created_at: '2020-04-06',
    },
  },
  {
    permission: '',
    entity: {
      entity_id: '525237ef-9ade-4e28-8cc9-194dc9bdde64',
      name: 'person',
      created_at: '2020-04-06',
    },
  },
  {
    permission: '',
    entity: {
      entity_id: 'c4cb006e-6dc3-4e2e-8b31-a34079e4ee5c',
      name: 'schedule',
      created_at: '2020-04-06',
    },
  },
  {
    permission: '',
    entity: {
      entity_id: 'd2ec89cb-bf5f-4765-9d99-04d6b4ac516d',
      name: 'document',
      created_at: '2020-04-06',
    },
  },
  {
    permission: '',
    entity: {
      entity_id: '92e07ceb-1e5b-4246-8197-37386658a99a',
      name: 'user',
      created_at: '2020-04-06',
    },
  },
  {
    permission: '',
    entity: {
      entity_id: '3a6eac64-6a47-4c0c-a822-fb708e3558b0',
      name: 'role',
      created_at: '2020-04-06',
    },
  },
];

export const modulesIcons: IconName[] = ['clean', 'box', 'people', 'calendar', 'document', 'user', 'layers'];
