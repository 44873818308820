/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner, Popover, Position, Button, NonIdealState } from '@blueprintjs/core';
import translateBoolean from '../../utils/translate';
import './styles.css';
import PopoverFilterOptions from '../PopoverFilterOptions';
import PopoverEditRemove from '../PopoverEditRemove';
import { momentFormatBrDate } from '../../utils/date';
import { WRITE_PERMISSION, META_MODULE_NAME, INACTIVATE_PERMISSION } from '../../modules/Permissions/constants';
import { verifyUserPermission, isNonRemovableOccupation } from '../../utils/permissions';
import { phoneMask } from '../../utils/masks';
import ConfirmationDialog from '../ConfirmationDialog';

type TableRow = {
  justice_type: string;
  justice_county: string;
  court_type: string;
  created_at: string;
  county: string;
  phone_type_obj: string;
  phone_number: string;
  connected_name: string;
  person_related_name: string;
  person_type: string;
  case_status: string;
  case_progress_type: string;
  case_progress_status: string;
  case_progress_date: string;
  oab: string;
};

interface ITable {
  loading: boolean;
  empty: boolean;
  data: any[];
  headers: string[];
  columns: string[];
  tableRequest?: any;
  editRequest?: any;
  removeRequest?: any;
  pathId?: string;
  hasFilter?: boolean;
  group?: boolean;
  personId?: string;
  localId?: string;
  expandButton?: Function;
  expandProgress?: Function;
};

const MetaTable: React.FC<ITable> = (props) => {
  const dispatch = useDispatch();
  const {
    loading,
    data,
    empty,
    headers,
    columns,
    tableRequest,
    pathId,
    removeRequest,
    editRequest,
    hasFilter,
    group,
    personId,
    localId,
    expandButton,
    expandProgress
  } = props;

  const [mostRecent, setMostRecent] = useState(true);
  const [alphabetic, setAlphabetic] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [temporaryElem, setTemporaryElem] = useState('');

  const tableFilters = [
    {
      value: mostRecent,
      setValue: setMostRecent,
      request: tableRequest,
      text: 'Recentes',
    },
    {
      value: alphabetic,
      setValue: setAlphabetic,
      request: tableRequest,
      text: 'Ordem alfabética',
    },
  ];

  const getTableHeaders = () =>
    headers.map((header: string, j) =>
      hasFilter && j === 0 ? (
        <th key={header}>
          <Popover
            className="popover"
            content={<PopoverFilterOptions filters={tableFilters} />}
            position={Position.BOTTOM_LEFT}
          >
            <Button text={header} rightIcon="caret-down" minimal className="popover__button" />
          </Popover>
        </th>
      ) : (
        <th key={header}>{header}</th>
      )
    );

  const handleRemoveRequest = (elem: any) => {
    if (pathId) {
      setTemporaryElem(elem[pathId]);
    }
    if (localId) {
      setTemporaryElem(elem[localId]);
    }
    return setIsDialogOpen(true);
  };

  const moreOptionsPopover = (elem: any) => {
    if (pathId) {
      if (personId) {
        return <PopoverEditRemove
          elem={elem}
          editRequest={editRequest}
          removeRequest={() => handleRemoveRequest(elem)}
          mobilePath={pathId}
        />
      } 
      return <PopoverEditRemove
        elem={elem}
        editRequest={editRequest}
        removeRequest={() => handleRemoveRequest(elem)}
        mobilePath={pathId}
      />
    }
    if (localId) {
      return <PopoverEditRemove
      elem={elem}
      editRequest={editRequest}
      removeRequest={() => handleRemoveRequest(elem)}
      mobilePath={localId}
    />
    }
  }

  const verifyLawyerType = (elem: any) => elem.occupation && isNonRemovableOccupation(elem.occupation);

  const getOptionsButtons = (elem: any) => 
    pathId && (
      <div>
        {verifyUserPermission(META_MODULE_NAME, WRITE_PERMISSION) && (
          <Button intent="primary" icon="edit" minimal disabled={verifyLawyerType(elem)} onClick={() => editRequest(elem)} text="Editar" />
        )}
        {verifyUserPermission(META_MODULE_NAME, INACTIVATE_PERMISSION) && (
          <Button
            intent="danger"
            icon="trash"
            minimal
            disabled={verifyLawyerType(elem)}
            onClick={() => handleRemoveRequest(elem)}
            text="Excluir"
          />
        )}
      </div>
    );

  const getTableData = (column: string, elem: any) => {
    const columnTypes: TableRow = {
      justice_type: elem.justice_type?.name,
      justice_county: elem.county?.name,
      court_type: elem.court?.name,
      created_at: momentFormatBrDate(elem.created_at),
      county: elem.county?.name,
      phone_type_obj: elem.phone_type?.phone_type,
      phone_number: phoneMask(`${elem?.area_code?.toString()}${elem?.phone_number}`),
      connected_name: elem?.descendant?.name,
      person_related_name: elem?.person?.name,
      person_type: elem?.person_type?.name,
      case_status: elem?.case_status?.status,
      case_progress_type: elem?.case_progress_type?.progress_type,
      case_progress_status: elem?.case_progress_status?.status,
      case_progress_date: momentFormatBrDate(elem?.case_progress_date),
      oab: `${elem.lawyer_oab_id}-${elem.lawyer_oab_state}`
    }
    return columnTypes[column as keyof TableRow] ?? translateBoolean(elem[column])
  };

  const getTableBody = () =>
    data.map((elem: any, j: number) => (
      <tr key={elem + j}>
        {columns.map((column: string) => {
          return <td key={column}>{ getTableData(column, elem) }</td>;
        })}
        {group && <td>{getOptionsButtons(elem)}</td>}
        {!group && <td>{moreOptionsPopover(elem)}</td>}
        {expandButton && <Button intent="primary" rightIcon="caret-left" onClick={() => expandButton(pathId && elem[pathId])} text="Expandir" />}
        {expandProgress && <td><Button className="expand__progress" intent="primary" rightIcon="caret-right" onClick={() => expandProgress(elem)} text="Expandir" /></td>}
      </tr>
    ));

  const handleConfirmationMethod = () => {
    if (personId) {
      dispatch(removeRequest(personId, temporaryElem))
      return setIsDialogOpen(false)
    }
    removeRequest(temporaryElem)
    return setIsDialogOpen(false)
  };

  return (
    <>
      {loading ? (
        <div className="table__loader">
          <Spinner intent="primary" size={25} />
        </div>
      ) : data?.length && !empty ? (
        <table className="table__component bp3-html-table bp3-html-table-striped bp3-interactive">
          <ConfirmationDialog
            headerText="Tem certeza que deseja excluir este item?"
            confirmMethod={() => handleConfirmationMethod()}
            setIsOpen={setIsDialogOpen}
            isOpen={isDialogOpen}
          />
          <thead>
            <tr>{getTableHeaders()}</tr>
          </thead>
          <tbody>{getTableBody()}</tbody>
        </table>
      ) : (
        <NonIdealState icon="inbox" title="Nenhum dado cadastrado" />
      )}
    </>
  );
};

export default React.memo(MetaTable);
