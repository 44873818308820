/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  phoneRelation: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  createPhoneRelation: {
      fail: null,
      success: null,
      loading: false,
  },
  inactivate: {
    loading: false,
    fail: null,
    success: null,
  },
};

const phoneRelation: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_PHONE_RELATION:
        draft.phoneRelation.loading = true;
        draft.phoneRelation.empty = true;
        draft.phoneRelation.fail = null;
        return;

      case c.FETCH_PHONE_RELATION_SUCCESS:
        draft.phoneRelation.loading = false;
        draft.phoneRelation.fail = null;
        if (payload && payload.data.length) {
          draft.phoneRelation.data = payload.data;
          draft.phoneRelation.empty = false;
        }
        return;

      case c.FETCH_PHONE_RELATION_FAIL:
        draft.phoneRelation.loading = false;
        draft.phoneRelation.fail = payload.error;
        draft.phoneRelation.data = payload.data;
        return;

      case c.CREATE_PHONE_RELATION:
        draft.createPhoneRelation.loading = true;
        draft.createPhoneRelation.fail = null;
        draft.createPhoneRelation.success = null;
        return;

      case c.CREATE_PHONE_RELATION_SUCCESS:
        draft.createPhoneRelation.loading = false;
        draft.createPhoneRelation.success = 'Relação criada com sucesso!';
        return;

      case c.CREATE_PHONE_RELATION_FAIL:
        draft.createPhoneRelation.loading = false;
        draft.createPhoneRelation.fail = payload.error;
        draft.createPhoneRelation.success = null;
        return;

      case c.INACTIVATE_PHONE_RELATION:
        draft.inactivate.loading = true;
        draft.inactivate.fail = null;
        draft.inactivate.success = null;
        return;

      case c.INACTIVATE_PHONE_RELATION_SUCCESS:
        draft.inactivate.loading = false;
        draft.inactivate.fail = null;
        draft.inactivate.success = 'Relação arquivada com sucesso!';
        return;

      case c.INACTIVATE_PHONE_RELATION_FAIL:
        draft.inactivate.loading = false;
        draft.inactivate.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.createPhoneRelation.success = null;
        draft.inactivate.success = null;
        draft.createPhoneRelation.fail = null;
        draft.inactivate.fail = null;
        return;

      default:
        return;
    }
  });

export default phoneRelation;
