/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import { Dispatch } from 'redux';
import * as c from './constants';

export const fetchLogin = (payload: { email: string; password: string }) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_LOGIN,
    payload: {
      request: {
        url: '/login',
        method: 'POST',
        data: payload,
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          const resData = response.data;
          dispatch({
            type: c.FETCH_LOGIN_SUCCESS,
            payload: {
              data: resData,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.FETCH_LOGIN_FAIL,
            payload: {
              error: 'Login failed',
            },
          });
        },
      },
    },
  });
};
