/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  roles: {
    data: [],
    loading: false,
    fail: null,
  },
  rolesPaginated: {
    data: [],
    loading: false,
    fail: null,
  },
};

const groups: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_ROLES:
        draft.roles.loading = true;
        draft.roles.fail = null;
        return;

      case c.FETCH_ROLES_SUCCESS:
        draft.roles.loading = false;
        draft.roles.fail = null;
        if (payload && payload.data.length) {
          draft.roles.data = payload.data;
        }
        return;

      case c.FETCH_ROLES_FAIL:
        draft.roles.loading = false;
        draft.roles.fail = payload.error;
        draft.roles.data = payload.data;
        return;

      case c.FETCH_ROLES_PAGINATED:
        draft.rolesPaginated.loading = true;
        draft.rolesPaginated.fail = null;
        return;

      case c.FETCH_ROLES_PAGINATED_SUCCESS:
        draft.rolesPaginated.loading = false;
        draft.rolesPaginated.fail = null;
        if (payload && payload.data.result.length) {
          draft.rolesPaginated.data = payload.data;
        }
        return;

      case c.FETCH_ROLES_PAGINATED_FAIL:
        draft.rolesPaginated.loading = false;
        draft.rolesPaginated.fail = payload.error;
        draft.rolesPaginated.data = payload.data;
        return;

      default:
        return;
    }
  });

export default groups;
