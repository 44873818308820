import Home from './modules/Home';
import People from './modules/People';
import Cases from './modules/Cases';
import Groups from './modules/Groups';
import Meta from './modules/Meta';
import PeopleList from './modules/PeopleList';
import Login from './modules/Login';
import ForgotPassword from './modules/ForgotPassword';
import ResetPassword from './modules/ResetPassword';
import Person from './modules/Person';
import MetaAttachments from './modules/Attachments';
import MetaPeople from './modules/MetaPeople';
import MetaCases from './modules/MetaCases';
import Users from './modules/Users';
import GroupsList from './modules/UserGroups';
import Permissions from './modules/Permissions';
import CreateGroup from './modules/CreateGroup';
import CreateCase from './modules/CreateCase';
import CasesList from './modules/CasesList';
import FailPermission from './modules/FailPermission';
import CaseDetails from './modules/CaseDetails';
import Schedule from './modules/Schedule';
import ScheduleDashboard from './modules/ScheduleDashboard';

export type Route = {
  path: string;
  exact?: boolean;
  component: any;
};

export type Routes = Route[];

export const permissionDenied: Route = {
  path: '/permissao-negada',
  exact: true,
  component: FailPermission,
};

const routes: Routes = [
  {
    path: '/',
    exact: true,
    component: Login,
  },
  {
    path: '/painel',
    exact: false,
    component: Home,
  },
  {
    path: '/painel/pessoas',
    exact: false,
    component: People,
  },
  {
    path: '/painel/pessoas/lista',
    exact: true,
    component: PeopleList,
  },
  {
    path: '/painel/pessoas/individuo/:id',
    exact: true,
    component: Person,
  },
  {
    path: '/painel/meta/anexos',
    exact: true,
    component: MetaAttachments,
  },
  {
    path: '/painel/meta/pessoas',
    exact: true,
    component: MetaPeople,
  },
  {
    path: '/painel/meta/casos',
    exact: true,
    component: MetaCases,
  },
  {
    path: '/painel/casos',
    exact: false,
    component: Cases,
  },
  {
    path: '/painel/casos/lista',
    exact: true,
    component: CasesList,
  },
  {
    path: '/painel/casos/criar',
    exact: true,
    component: CreateCase,
  },
  {
    path: '/painel/casos/detalhes/:id',
    exact: true,
    component: CaseDetails,
  },
  {
    path: '/painel/grupos',
    exact: false,
    component: Groups,
  },
  {
    path: '/painel/grupos/lista',
    exact: true,
    component: GroupsList,
  },
  {
    path: '/painel/grupos/usuarios',
    exact: true,
    component: Users,
  },
  {
    path: '/painel/grupos/lista/permissoes/:id',
    exact: true,
    component: Permissions,
  },
  {
    path: '/painel/grupos/lista/criar',
    exact: true,
    component: CreateGroup,
  },
  {
    path: '/painel/meta',
    exact: false,
    component: Meta,
  },
  {
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    path: '/login/recover-password',
    exact: true,
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    exact: false,
    component: ResetPassword,
  },
  {
    path: '/painel/agenda',
    exact: false,
    component: Schedule,
  },
  {
    path: '/painel/agenda/calendario/:id',
    exact: true,
    component: ScheduleDashboard,
  },
];

export default routes;
