/* eslint-disable import/prefer-default-export */
export const FETCH_FOLDER_RELATION = 'FETCH_FOLDER_RELATION';
export const FETCH_FOLDER_RELATION_SUCCESS = 'FETCH_FOLDER_RELATION_SUCCESS';
export const FETCH_FOLDER_RELATION_FAIL = 'FETCH_FOLDER_RELATION_FAIL';

export const CREATE_FOLDER_RELATION = 'CREATE_FOLDER_RELATION';
export const CREATE_FOLDER_RELATION_SUCCESS = 'CREATE_FOLDER_RELATION_SUCCESS';
export const CREATE_FOLDER_RELATION_FAIL = 'CREATE_FOLDER_RELATION_FAIL';

export const UPDATE_FOLDER_RELATION = 'UPDATE_FOLDER_RELATION';
export const UPDATE_FOLDER_RELATION_SUCCESS = 'UPDATE_FOLDER_RELATION_SUCCESS';
export const UPDATE_FOLDER_RELATION_FAIL = 'UPDATE_FOLDER_RELATION_FAIL';

export const INACTIVATE_FOLDER_RELATION = 'INACTIVATE_FOLDER_RELATION';
export const INACTIVATE_FOLDER_RELATION_SUCCESS = 'INACTIVATE_FOLDER_RELATION_SUCCESS';
export const INACTIVATE_FOLDER_RELATION_FAIL = 'INACTIVATE_FOLDER_RELATION_FAIL';

export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
