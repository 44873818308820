/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  casesList: {
    fail: null,
    empty: false,
    loading: false,
    data: [],
  },
  allCases: {
    fail: null,
    empty: false,
    loading: false,
    data: [],
  },
};

const people: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_CASES:
        draft.casesList.loading = true;
        return;

      case c.FETCH_CASES_SUCCESS:
        draft.casesList.loading = false;
        if (payload.data && payload.data.result.length) {
          draft.casesList.data = payload.data;
          draft.casesList.empty = false;
        } else {
          draft.casesList.empty = true;
        }
        return;

      case c.FETCH_CASES_FAIL:
        draft.casesList.loading = false;
        draft.casesList.fail = payload.error;
        return;

      case c.FETCH_ALL_CASES:
        draft.allCases.loading = true;
        return;

      case c.FETCH_ALL_CASES_SUCCESS:
        draft.allCases.loading = false;
        if (payload.data && payload.data.data.length) {
          draft.allCases.data = payload.data.data;
          draft.allCases.empty = false;
        } else {
          draft.allCases.empty = true;
        }
        return;

      case c.FETCH_ALL_CASES_FAIL:
        draft.allCases.loading = false;
        draft.allCases.fail = payload.error;
        return;

      case c.CLEAR_CREATE_STATUS:
        return;

      default:
        return;
    }
  });

export default people;
