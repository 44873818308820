/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Spinner, NonIdealState, Popover, Position } from '@blueprintjs/core';
import { useDispatch } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import translateBoolean from '../../utils/translate';
import './styles.css';
import PopoverEditRemove from '../PopoverEditRemove';
import Button from '../Button';
import PopoverFilterOptions from '../PopoverFilterOptions';
import { INACTIVATE_PERMISSION } from '../../modules/Permissions/constants';
import { verifyUserPermission, getModuleName, isNonRemovableRole } from '../../utils/permissions';
import ConfirmationDialog from '../ConfirmationDialog';

type TableRow = {
  roles: string;
  username: string;
  case_status: string;
  person_related_name: string;
  person_type: string;
  is_active_pole: string;
};

interface ITable {
  loading: boolean;
  empty: boolean;
  data: any[];
  headers: string[];
  columns: string[];
  moreButton?: {
    editRequest?: any;
    removeRequest?: any;
    pathId: string;
    secondPathId?: string;
  };
  expandButton?: {
    path: string;
    text?: string;
    pathId: string | '';
  } | null;
  className?: string;
  hasFilter?: boolean;
  tableRequest?: () => (dispatch: Dispatch<AnyAction>) => void;
}

const Table: React.FC<ITable> = (props) => {
  const dispatch = useDispatch();
  const { loading, data, headers, columns, moreButton, expandButton, className, hasFilter, tableRequest, empty } = props;

  const [mostRecent, setMostRecent] = useState(true);
  const [alphabetic, setAlphabetic] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [temporaryElem, setTemporaryItem] = useState<any>();

  const tableFilters = [
    {
      value: mostRecent,
      setValue: setMostRecent,
      request: tableRequest,
      text: 'Recentes',
    },
    {
      value: alphabetic,
      setValue: setAlphabetic,
      request: tableRequest,
      text: 'Ordem alfabética',
    },
  ];

  const getTableHeaders = () =>
    headers.map((header: string, j) =>
      j === 0 && hasFilter ? (
        <th key={header}>
          <Popover
            className="popover"
            content={<PopoverFilterOptions filters={tableFilters} />}
            position={Position.BOTTOM_LEFT}
          >
            <Button text={header} rightIcon="caret-down" minimal className="popover__button" />
          </Popover>
        </th>
      ) : (
        <th key={header}>{header}</th>
      )
    );

  const getDynamicColumns = (elem: any) =>
    columns.map((column: string) => {
      return <td key={column}>{getTableRow(column, elem)}</td>;
    });

  const getTableRow = (column: string, elem: any) => {
    const columnTypes: TableRow = {
      roles: elem[column]?.length ? elem[column][0]?.name : '',
      username: elem[column],
      case_status: elem.case_status?.status,
      person_related_name: elem?.person?.name,
      person_type: elem?.person_type?.name,
      is_active_pole: translateBoolean(elem?.person_type?.is_active_pole),
    };
    return columnTypes[column as keyof TableRow] ?? translateBoolean(elem[column]);
  };

  const handleInactivateArgs = () => {
    if (moreButton?.secondPathId) {
      dispatch(moreButton!.removeRequest(temporaryElem[moreButton!.pathId], temporaryElem[moreButton.secondPathId]));
    } else {
      dispatch(moreButton!.removeRequest(temporaryElem[moreButton!.pathId]));
    }
    return setIsDialogOpen(false);
  }

  const handleOpenInactivate = (elem: any) => {
    setTemporaryItem(elem);
    setIsDialogOpen(true);
  };

  const verifyRoleName = (elem: any) => elem.role_id && isNonRemovableRole(elem.name);

  const moreOptionsPopover = (elem: any) =>
    moreButton &&
    verifyUserPermission(getModuleName(), INACTIVATE_PERMISSION) && (
      <PopoverEditRemove
        elem={elem}
        editRequest={moreButton.editRequest}
        removeRequest={() => handleOpenInactivate(elem)}
        disabled={verifyRoleName(elem)}
        mobilePath={expandButton?.path}
        mobileText={expandButton?.text}
      />
    );

  const getExpandButton = (id: string) =>
    expandButton?.text && <Button path={`${expandButton.path}/${id}`} text={expandButton.text} />;

  const getTableBody = () =>
    data.length &&
    expandButton &&
    data.map((elem: any, i) => (
      <tr key={`${elem.pathId}${i}`}>
        {getDynamicColumns(elem)}
        <td className="button__column" key={elem[expandButton.pathId]}>
          {moreOptionsPopover(elem)}
          {getExpandButton(elem[expandButton.pathId])}
        </td>
      </tr>
    ));

  const getTable = () =>
    data && !empty ? (
      <>
        <table
          className={`bp3-html-table bp3-html-table-striped 
        bp3-interactive bp3-html-table-bordered generic-table ${className}`}
        >
          <ConfirmationDialog
            headerText="Tem certeza que deseja excluir este item?"
            confirmMethod={() => handleInactivateArgs()}
            setIsOpen={setIsDialogOpen}
            isOpen={isDialogOpen}
          />
          <thead>
            <tr key="tableHeader">{getTableHeaders()}</tr>
          </thead>
          <tbody>{getTableBody()}</tbody>
        </table>
      </>
    ) : (
      <NonIdealState icon="inbox" title="Nenhum dado cadastrado" />
    );

  return (
    <>
      {loading ? (
        <div className="table__loader">
          <Spinner intent="primary" size={25} />
        </div>
      ) : (
        getTable()
      )}
    </>
  );
};

export default Table;
