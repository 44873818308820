import React, { useEffect, useState, useRef, useCallback, SyntheticEvent } from 'react';
import './styles.css';
import { Popover, Button as BButton, Position, Spinner, NonIdealState, Tooltip } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { COLUMNS, BREADCRUMB_PATHS } from './constants';
import { reducers } from '../../store/reducers';
import { fetchCases } from './actions';
import BreadCrumb from '../../components/Breadcrumb';
import PopoverFilterOptions from '../../components/PopoverFilterOptions';
import { isMobile } from '../../utils/window';
import Button from '../../components/Button';
import Pagination from '../../components/Pagination';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';
import { LegalCaseFull } from './types';
import { fetchProgressStatus } from '../MetaCasesProgressStatus/actions';
import { mountQueryFilters, mountFetchCasesPathFilters } from '../../utils/requestPath';

const CasesList: React.FC = () => {
  const dispatch = useDispatch();
  const [fetchByFolder, setFetchByFolder] = useState(false);
  const [fetchByTitle, setFetchByTitle] = useState(true);
  const [fetchByProcessNumber, setFetchByProcessNumber] = useState(false);
  const [fetchByLawyer, setFetchByLawyer] = useState(false);
  const [fetchByPerson, setFetchByPerson] = useState(false);
  const [fetchByProgress, setFetchByProgress] = useState(false);
  const [mostRecent, setMostRecent] = useState<boolean | undefined>(undefined);
  const [alphabetic, setAlphabetic] = useState<boolean | undefined>(undefined);
  const [toggleRegisterForm, setToggleRegisterForm] = useState(false);
  const [searchType, setSearchType] = useState('title');
  const searchInput = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [filterPath, setFilterPath] = useState('');

  useEffect(() => {
    const caseSearchStorage = sessionStorage.getItem('case_search');
    if (caseSearchStorage) {
      searchInput.current.value = caseSearchStorage;
    }
  }, [searchInput]);

  const handleFetchRequest = useCallback((e?: SyntheticEvent) => {
    if (e) {
      e.preventDefault();
    }
    sessionStorage.setItem('case_search', searchInput.current.value);
    return dispatch(
      fetchCases(
        1,
        DEFAULT_PER_PAGE_LIMIT,
        `${mountQueryFilters(searchInput.current.value, searchType)}${mountFetchCasesPathFilters(mostRecent, alphabetic)}`
      )
    );
  }, [dispatch, alphabetic, mostRecent, searchType, searchInput]);

  useEffect(() => {
    handleFetchRequest();
    setFilterPath(`${mountQueryFilters(searchInput.current.value, searchType)}${mountFetchCasesPathFilters(mostRecent, alphabetic)}`)
  }, [fetchByFolder, handleFetchRequest, alphabetic, mostRecent, searchType, searchInput]);

  const getMostRecent = () => {
    if (mostRecent) {
      setMostRecent((state) => !state);
    } else {
      setMostRecent(true);
    }
  };

  const getAlphabetic = () => {
    if (alphabetic) {
      setAlphabetic((state) => !state);
    } else {
      setAlphabetic(true);
    }
  };

  const tableFilters = [
    {
      value: mostRecent,
      request: getMostRecent,
      text: 'Recentes',
    },
    {
      value: alphabetic,
      request: getAlphabetic,
      text: 'Ordem alfabética',
    },
  ];

  const getByTitle = () => {
    setFetchByLawyer(false);
    setFetchByPerson(false);
    setFetchByProgress(false);
    setFetchByProcessNumber(false);
    if (fetchByTitle) {
      setFetchByTitle((state) => !state);
      setFetchByFolder(true);
      setSearchType('folder');
    } else {
      setFetchByTitle(true);
      setFetchByFolder(false);
      setSearchType('title');
    }
  };

  const getByFolder = () => {
    setFetchByLawyer(false);
    setFetchByPerson(false);
    setFetchByProgress(false);
    setFetchByProcessNumber(false);
    if (fetchByFolder) {
      setFetchByFolder((state) => !state);
      setFetchByTitle(true);
      setSearchType('title');
    } else {
      setFetchByFolder(true);
      setFetchByTitle(false);
      setSearchType('folder');
    }
  };

  const getByProcessNumber = () => {
    setFetchByLawyer(false);
    setFetchByPerson(false);
    setFetchByProgress(false);
    setFetchByFolder(false);
    if (fetchByProcessNumber) {
      setFetchByTitle(true);
      setFetchByProcessNumber(false);
      setSearchType('title');
    } else {
      setFetchByTitle(false);
      setFetchByProcessNumber(true);
      setSearchType('process');
    }
  };

  const getByPerson = () => {
    setFetchByLawyer(false);
    setFetchByProgress(false);
    setFetchByFolder(false);
    setFetchByProcessNumber(false);
    if (fetchByPerson) {
      setFetchByPerson((state) => !state)
      setFetchByTitle(true);
      setSearchType('title');
    } else {
      setFetchByPerson(true);
      setFetchByTitle(false);
      setSearchType('person');
    }
  };

  const getByLawyer = () => {
    setFetchByProgress(false);
    setFetchByProcessNumber(false);
    setFetchByFolder(false);
    setFetchByPerson(false);
    if (fetchByLawyer) {
      setFetchByLawyer((state) => !state);
      setFetchByTitle(true);
      setSearchType('title');
    } else {
      setFetchByLawyer(true);
      setFetchByTitle(false);
      setSearchType('lawyer')
    }
  };
  
  const getByProgress = () => {
    setFetchByPerson(false);
    setFetchByLawyer(false);
    setFetchByProcessNumber(false);
    setFetchByFolder(false);
    if (fetchByProgress) {
      setFetchByProgress((state) => !state);
      setFetchByTitle(true);
      setSearchType('title');
    } else {
      setFetchByProgress(true);
      setFetchByTitle(false);
      setSearchType('case_progress');
    }
  };

  const searchBarFilters = [
    {
      value: fetchByTitle,
      request: getByTitle,
      text: 'Por apelido',
    },
    {
      value: fetchByFolder,
      request: getByFolder,
      text: 'Por pasta',
    },
    {
      value: fetchByProcessNumber,
      request: getByProcessNumber,
      text: 'Por número de processo',
    },
    {
      value: fetchByPerson,
      request: getByPerson,
      text: 'Por pessoa relacionada'
    },
    {
      value: fetchByLawyer,
      request: getByLawyer,
      text: 'Por advogado(a) relacionado'
    },
    {
      value: fetchByProgress,
      request: getByProgress,
      text: 'Por andamento'
    }
  ];

  useEffect(() => {
    dispatch(
      fetchCases(
        1,
        DEFAULT_PER_PAGE_LIMIT,
        `${mountQueryFilters(searchInput.current.value, searchType)}${mountFetchCasesPathFilters(mostRecent, alphabetic)}`
      )
    );
    dispatch(fetchProgressStatus());
  }, [dispatch, alphabetic, mostRecent, searchInput, searchType]);

  const { empty, loading, data } = useSelector((state: typeof reducers) => state.caseslist.casesList);

  const getHeadColumns = () => COLUMNS.map((column) => <th key={column}>{column}</th>);

  const mobPeopleListVisibilityCondition = !isMobile() || (isMobile() && !toggleRegisterForm);

  const getTooltipListOfPeople = (relatedPeople: string[]) => (
    <div>
      {relatedPeople.map((item: string) => <p>{item}</p>)}
    </div>   
  );

  const getTooltipListOfFolders = (folders: string[]) => (
    <div>
      {folders.map((item: string) => <p>{item}</p>)}
    </div>
  )

  const getTable = () =>
    data?.result?.length && !empty ? (
      <table className="bp3-html-table bp3-html-table-striped bp3-interactive">
        <thead>
          <tr>
            <th>
              <Popover
                className="popover"
                content={<PopoverFilterOptions filters={tableFilters} />}
                position={Position.BOTTOM}
              >
                <BButton text="Apelido do caso" rightIcon="caret-down" />
              </Popover>
            </th>
            {getHeadColumns()}
          </tr>
        </thead>
        <tbody>
          {data?.result?.map((caseItem: LegalCaseFull) => (
            <tr key={caseItem.legal_case_id}>
              <td>{caseItem.title}</td>
              <td>{caseItem.process_id || '-'}</td>
              <td>{caseItem?.case_status || '-'}</td>
              <td>
                {caseItem?.phyiscal_folders.length ? (
                  <Tooltip captureDismiss content={getTooltipListOfFolders(caseItem?.phyiscal_folders)}>
                    <p>{caseItem?.phyiscal_folders[0]}</p>
                  </Tooltip>
                ) : (
                  <p>N/A</p>
                )}
              </td>
              <td>
                {caseItem?.related_people.length ? (
                  <Tooltip captureDismiss content={getTooltipListOfPeople(caseItem?.related_people)}>
                    <p>{caseItem?.related_people[0]}</p>
                  </Tooltip>
                ) : (
                  <p>N/A</p>
                )}
              </td>
              <td>
                <Button path={`/painel/casos/detalhes/${caseItem.legal_case_id}`} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <NonIdealState
        icon="search"
        title="Nenhum caso encontrado"
        description="Não foram encontrados resultados a partir dos dados que você digitou"
      />
    );


  return (
    <div className="people__container">
      {mobPeopleListVisibilityCondition && (
        <div className="cases__list">
          <BreadCrumb breads={BREADCRUMB_PATHS} />
          <p className="description">
            Estes são os casos cadastrados na plataforma, na listagem, clique em &#34;Nome&#34; para filtros de ordenação.
          </p>
          <form className="bp3-input-group" onSubmit={handleFetchRequest}>
            <span className="bp3-icon" />
            <input
              type="text"
              className="bp3-input"
              ref={searchInput}
              placeholder="Digite o apelido do caso que você deseja encontrar ..."
            />
            <Popover
              className="popover search"
              content={<PopoverFilterOptions filters={searchBarFilters} />}
              position={Position.BOTTOM}
            >
              <BButton rightIcon="caret-down" text="Filtrar" />
            </Popover>
            <Button
              className="bp3-button bp3-minimal bp3-intent-primary bp3-icon-search"
              onClick={() => handleFetchRequest()}
            >
              Buscar caso
            </Button>
          </form>
          <Button name="mobileAdd" onClick={() => setToggleRegisterForm(!toggleRegisterForm)} text="Cadastrar caso" />
          {loading ? (
            <div className="table__loader">
              <Spinner intent="primary" size={25} />
            </div>
          ) : (
            <>
              {getTable()}
              {!empty && (
                <Pagination request={fetchCases} optionsPath={filterPath} loading={loading} data={data} />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CasesList;
