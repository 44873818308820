/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import { Reducer, State } from './types';
import * as c from './constants';

const INITIAL_STATE: State = {
  fail: null,
  loading: false,
  message: null,
};

const resetPassword: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_FORGOT_PASSWORD:
        draft.loading = true;
        draft.fail = null;
        draft.message = null;
        return;

      case c.FETCH_FORGOT_PASSWORD_SUCCESS:
        draft.loading = false;
        draft.message = 'Email enviado com sucesso!';
        return;

      case c.FETCH_FORGOT_PASSWORD_FAIL:
        draft.loading = false;
        draft.fail = 'Ops, tivemos um erro no servidor!';
        draft.message = null;
        return;

      default:
        return;
    }
  });

export default resetPassword;
