import React, { useEffect, useState } from 'react';
import './styles.css';
import '../CreateGroup/styles.css';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../components/Button';
import BreadCrumb from '../../components/Breadcrumb';
import { BREADCRUMB_PATHS, WRITE_PERMISSION, ROLE_MODULE_NAME } from './constants';
import PopoverEditRemove from '../../components/PopoverEditRemove';
import { isMobile } from '../../utils/window';
import { reducers } from '../../store/reducers';
import { fetchEntities, fetchEntitiesById, updateRole } from './actions';
import PermissionsTable from '../../components/PermissionsTable';
import { RolePermissions } from '../UserGroups/types';
import createToaster from '../../components/Toaster';
import { goToHere } from '../../utils/navigation';
import { UpdateEntity } from './types';
import { verifyUserPermission } from '../../utils/permissions';

type Match = {
  path: string;
  url: string;
  isExact: boolean;
  params: any;
};

interface IPermissionsProps {
  match: Match;
}

const Permissions: React.FC<IPermissionsProps> = ({ match }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state: typeof reducers) => state.permissions.entities);
  const { dataId } = useSelector((state: typeof reducers) => state.permissions.entity);
  const { fail, success } = useSelector((state: typeof reducers) => state.permissions.newRoleStatus);
  const [currentRole, setCurrentRole] = useState('');
  const [updateRolePermissions, setUpdateRolePermissions] = useState<RolePermissions>(dataId);

  useEffect(() => {
    dispatch(fetchEntitiesById(match.params.id));
  }, [dispatch, match]);

  useEffect(() => {
    setUpdateRolePermissions(dataId);
  }, [setUpdateRolePermissions, dataId]);

  useEffect(() => {
    dispatch(fetchEntities());
  }, [dispatch]);

  const handleCurrentRoleUpdate = () => {
    const userPermissions = JSON.parse(sessionStorage.getItem('permissions')!);
    if (userPermissions?.role?.name === currentRole) {
      sessionStorage.setItem('permissions', JSON.stringify(updateRolePermissions));
    }
  };

  if (success || fail) {
    const showToast = createToaster({
      message: success || fail,
      intent: success ? 'success' : 'danger',
      icon: success ? 'tick' : 'warning-sign',
    });
    showToast();
    if (fail) {
      handleCurrentRoleUpdate();
    }
  }

  const getTitle = () => dataId?.role?.name;
  const saveRole = () => {
    let permissionsArray: UpdateEntity[] = [];
    updateRolePermissions.permissions.forEach((item) => permissionsArray.push({ entity_id: item.entity.entity_id, permission: item.permission }));
    if (updateRolePermissions.role) {
      setCurrentRole(updateRolePermissions?.role?.name);
    }
    dispatch(updateRole(permissionsArray, updateRolePermissions.role?.role_id));
  };

  return (
    <div className="permissions__container">
      <div className="createg__subheader">
        <p className="title">Edição de um grupo de usuário</p>
        <div className="combo__button">
          <Button text="Cancelar edição" intent="danger" minimal onClick={() => goToHere('/painel/grupos/lista')} />
          {verifyUserPermission(ROLE_MODULE_NAME, WRITE_PERMISSION) && (
            <Button className="create" text="Salvar" onClick={() => saveRole()} intent="primary" minimal />
          )}
        </div>
      </div>
      <div className="permissions__header">
        {isMobile() ? <PopoverEditRemove className="popover__edit-remove" /> : <></>}
        <Button
          className="bp3-button sidebar__button--blue"
          onClick={() => goToHere('/painel/grupos/lista')}
          icon="chevron-left"
          text="Voltar"
        />
        <BreadCrumb breads={BREADCRUMB_PATHS} />
      </div>
      {data && dataId && (
        <div className="permissions__content">
          <div className="info__container">
            <p className="title">{getTitle()}</p>
            <p className="description">
              As permissões de acesso são separadas pelas sessões principais da plataforma. Para fazer a manutenção dos
              mesmos, selecione o grupo desejado e marque os itens no menu abaixo.
            </p>
          </div>
          {updateRolePermissions !== undefined && (
            <PermissionsTable
              newRole={data}
              create={false}
              role={updateRolePermissions}
              callBack={setUpdateRolePermissions}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Permissions;
