/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  progressStatus: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  progressStatusPaginated: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  progressStatusId: {
    data: null,
    loading: false,
    fail: null,
  },
  createProgressStatus: {
    loading: false,
    fail: null,
    success: null,
  },
  updateProgressStatus: {
    loading: false,
    fail: null,
    success: null,
  },
  inactivateProgressStatus: {
    loading: false,
    fail: null,
    success: null,
  },
};

const metaCases: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_PROGRESS_STATUS:
        draft.progressStatus.loading = true;
        draft.progressStatus.empty = true;
        draft.progressStatus.fail = null;
        return;

      case c.FETCH_PROGRESS_STATUS_SUCCESS:
        draft.progressStatus.loading = false;
        draft.progressStatus.fail = null;
        if (payload && payload.data.length) {
          draft.progressStatus.data = payload.data;
          draft.progressStatus.empty = false;
        }
        return;

      case c.FETCH_PROGRESS_STATUS_FAIL:
        draft.progressStatus.loading = false;
        draft.progressStatus.fail = payload.error;
        draft.progressStatus.data = payload.data;
        return;

      case c.FETCH_PROGRESS_STATUS_PAGINATED:
        draft.progressStatusPaginated.loading = true;
        draft.progressStatusPaginated.empty = true;
        draft.progressStatusPaginated.fail = null;
        return;

      case c.FETCH_PROGRESS_STATUS_PAGINATED_SUCCESS:
        draft.progressStatusPaginated.loading = false;
        draft.progressStatusPaginated.fail = null;
        if (payload && payload.data.result.length) {
          draft.progressStatusPaginated.data = payload.data;
          draft.progressStatusPaginated.empty = false;
        }
        return;

      case c.FETCH_PROGRESS_STATUS_PAGINATED_FAIL:
        draft.progressStatusPaginated.loading = false;
        draft.progressStatusPaginated.fail = payload.error;
        draft.progressStatusPaginated.data = payload.data;
        return;

      case c.FETCH_PROGRESS_STATUS_ID:
        draft.progressStatusId.loading = true;
        draft.progressStatusId.fail = null;
        return;

      case c.FETCH_PROGRESS_STATUS_ID_SUCCESS:
        draft.progressStatusId.loading = false;
        draft.progressStatusId.fail = null;
        if (payload && payload.data.length) {
          draft.progressStatusId.data = payload.data;
        }
        return;

      case c.FETCH_PROGRESS_STATUS_ID_FAIL:
        draft.progressStatusId.loading = false;
        draft.progressStatusId.fail = payload.error;
        draft.progressStatusId.data = payload.data;
        return;

      case c.CREATE_PROGRESS_STATUS:
        draft.createProgressStatus.loading = true;
        draft.createProgressStatus.fail = null;
        draft.createProgressStatus.success = null;
        return;

      case c.CREATE_PROGRESS_STATUS_SUCCESS:
        draft.createProgressStatus.loading = false;
        draft.createProgressStatus.success = payload.data;
        return;

      case c.CREATE_PROGRESS_STATUS_FAIL:
        draft.createProgressStatus.loading = false;
        draft.createProgressStatus.fail = payload.error;
        draft.createProgressStatus.success = null;
        return;

      case c.UPDATE_PROGRESS_STATUS:
        draft.updateProgressStatus.loading = true;
        draft.updateProgressStatus.fail = null;
        draft.updateProgressStatus.success = null;
        return;

      case c.UPDATE_PROGRESS_STATUS_SUCCESS:
        draft.updateProgressStatus.loading = false;
        draft.updateProgressStatus.fail = null;
        draft.updateProgressStatus.success = payload.data;
        return;

      case c.UPDATE_PROGRESS_STATUS_FAIL:
        draft.updateProgressStatus.loading = false;
        draft.updateProgressStatus.fail = payload.error;
        draft.updateProgressStatus.success = null;
        return;

      case c.INACTIVATE_PROGRESS_STATUS:
        draft.inactivateProgressStatus.loading = true;
        draft.inactivateProgressStatus.fail = null;
        draft.inactivateProgressStatus.success = null;
        return;

      case c.INACTIVATE_PROGRESS_STATUS_SUCCESS:
        draft.inactivateProgressStatus.loading = false;
        draft.inactivateProgressStatus.fail = null;
        draft.inactivateProgressStatus.success = payload.data;
        return;

      case c.INACTIVATE_PROGRESS_STATUS_FAIL:
        draft.inactivateProgressStatus.loading = false;
        draft.inactivateProgressStatus.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.createProgressStatus.success = null;
        draft.updateProgressStatus.success = null;
        draft.inactivateProgressStatus.success = null;
        draft.createProgressStatus.fail = null;
        draft.updateProgressStatus.fail = null;
        draft.inactivateProgressStatus.fail = null;
        return;

      default:
        return;
    }
  });

export default metaCases;
