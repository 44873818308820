/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, SyntheticEvent, useEffect } from 'react';
import { Tabs, Tab, TextArea, Spinner, Tooltip } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../Input';
import './styles.css';
import { reducers } from '../../store/reducers';
import createToaster from '../Toaster';
import { cpfMask, cnpjMask } from '../../utils/masks';
import Button from '../Button';
import { isMobile } from '../../utils/window';
import { register, fetchAllPeople, fastRegisterPerson } from '../../modules/PeopleList/actions';
import Selector from '../Selector';
import { fetchOccupationTypes } from '../../modules/MetaPeopleOccupations/actions';
import { fetchActivityTypes } from '../../modules/MetaPeopleActivities/actions';
import Autocomplete from '../Autocomplete';
import FastRegisterDialog from '../FastRegisterDialog';
import { LegalPerson, NaturalPerson } from '../../modules/PeopleList/types';
import { isValidCpf, isValidCnpj } from '../../utils/documentsValidations';

interface IRegisterPersonProps {
  mobileToggle: boolean;
  setMobileToggle: (arg: boolean) => void;
}

const RegisterPerson: React.FC<IRegisterPersonProps> = ({ mobileToggle, setMobileToggle }) => {
  const dispatch = useDispatch();
  // BlueprintJS refs
  const nameInput = React.useRef() as any;
  const cpfInput = React.useRef() as any;
  const cnpjInput = React.useRef() as any;
  const mainFormCpfInput = React.useRef() as any;
  const mainFormCnpjInput = React.useRef() as any;
  const allPeople = useSelector((state: typeof reducers) => state.peoplelist.allPeople);
  const occupationTypes = useSelector((state: typeof reducers) => state.metapeopleoccupations.occupationTypes);
  const activityTypes = useSelector((state: typeof reducers) => state.metapeopleactivities.activityTypes);
  const createPerson = useSelector((state: typeof reducers) => state.peoplelist.createPerson);
  const fastRegister = useSelector((state: typeof reducers) => state.peoplelist.fastRegister);
  const { loading } = useSelector((state: typeof reducers) => state.peoplelist.peopleList);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [spouseId, setSpouseId] = useState<string | null>(null);
  const [spouseName, setSpouseName] = useState('');
  const [occupationTypeId, setOccupationTypeId] = useState<string | null>(null);
  const [activityId, setActivityId] = useState<string | null>(null);
  const [isLegalPerson, setIsLegalPerson] = useState(false);
  const [validCpf, setValidCpf] = useState<boolean>();
  const [validCnpj, setValidCnpj] = useState(true);
  const [legalPerson, setLegalPerson] = useState<LegalPerson>({
    is_legal_person: true,
    name: '',
    related_person_id: spouseId,
    legal_cnpj: '',
    legal_ie: '',
    email: '',
    business_activity_id: activityId,
    annotation: '',
  });
  const [naturalPerson, setNaturalPerson] = useState<NaturalPerson>({
    is_legal_person: false,
    name: '',
    natural_cpf: '',
    natural_rg: '',
    email: '',
    occupation_id: occupationTypeId,
    marital_status: '',
    related_person_id: spouseId,
    nationality: '',
    annotation: '',
  });
  useEffect(() => {
    if (spouseId?.length) {
      if (isLegalPerson) {
        return setLegalPerson((state: LegalPerson) => ({ ...state, related_person_id: spouseId }));
      }
      return setNaturalPerson((state: NaturalPerson) => ({ ...state, related_person_id: spouseId }));
    }
  }, [spouseId, isLegalPerson]);

  useEffect(() => {
    if (occupationTypeId && occupationTypeId.length) {
      setNaturalPerson((state: NaturalPerson) => ({ ...state, occupation_id: occupationTypeId }));
    }
  }, [occupationTypeId]);

  useEffect(() => {
    if (activityId && activityId.length) {
      setLegalPerson((state: LegalPerson) => ({ ...state, business_activity_id: activityId }));
    }
  }, [activityId]);

  const [selectedTabId, setSelectedTabId] = useState('phisical');
  const handleChangeTab = (tab: string) => setSelectedTabId(tab);

  useEffect(() => {
    if (selectedTabId === 'phisical') {
      return setIsLegalPerson(false);
    }
    return setIsLegalPerson(true);
  }, [selectedTabId]);

  useEffect(() => {
    dispatch(fetchOccupationTypes());
    dispatch(fetchActivityTypes());
    dispatch(fetchAllPeople('name='));
  }, [dispatch]);

  useEffect(() => {
    if (fastRegister?.id.length) {
      setSpouseId(fastRegister.id);
    }
  }, [fastRegister]);

  useEffect(() => {
    setIsDialogOpen(false);
  }, [spouseId]);

  useEffect(() => {
    if (createPerson.fail || createPerson.message || fastRegister.fail || fastRegister.success) {
      const showToast = createToaster({
        message: createPerson.fail || createPerson.message || fastRegister.fail || fastRegister.success,
        intent: createPerson.fail || fastRegister.fail ? 'danger' : 'success',
        icon: createPerson.fail || fastRegister.fail ? 'warning-sign' : 'tick',
      });
      showToast();
    }
    if (createPerson.message) {
      clearForm();
    }
  }, [createPerson.fail, createPerson.message, fastRegister.fail, fastRegister.success]);

  const getRightPerson = () => isLegalPerson ? legalPerson : naturalPerson;

  const addLegalPerson = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isValidCnpj(legalPerson.legal_cnpj)) {
      return mainFormCnpjInput.current.focus();
    }
    return dispatch(register(getRightPerson()));
  };

  const addNaturalPerson = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isValidCpf(naturalPerson.natural_cpf)) {
      return mainFormCpfInput.current.focus();
    }
    return dispatch(register(getRightPerson()));
  };

  useEffect(() => {
    if (!isValidCpf(naturalPerson.natural_cpf)) {
      setValidCpf(false);
    } else {
      setValidCpf(true);
    }
  }, [naturalPerson.natural_cpf]);

  useEffect(() => {
    if (!isValidCnpj(legalPerson.legal_cnpj)) {
      setValidCnpj(false);
    } else {
      setValidCnpj(true);
    }
  }, [legalPerson.legal_cnpj]);

  const addSpouseTooltip = () => (
    <Tooltip captureDismiss content={'Clique aqui para cadastrar pessoa'}>
      <Button
        onClick={(e: SyntheticEvent) => {
          e.preventDefault();
          return setIsDialogOpen(true);
        }}
        name="add"
        className="sidebar__button--blue"
        icon="add"
      />
    </Tooltip>
  );

  const clearForm = () => {
    setLegalPerson((state) => ({
      ...state,
      name: '',
      legal_cnpj: '',
      legal_ie: '',
      email: '',
      business_activity_id: null,
      annotation: ''
    }));
    setNaturalPerson((state) => ({
      ...state,
      name: '',
      natural_cpf: '',
      natural_rg: '',
      email: '',
      marital_status: '',
      occupation_id: null,
      nationality: '',
      annotation: ''
    }));
    setSpouseId(null);
    setSpouseName('');
  };

  const phisicalPersonForm = () => (
    <>
      <form onSubmit={addNaturalPerson}>
        <Input
          placeholder="Nome *"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNaturalPerson((state: NaturalPerson) => ({ ...state, name: e.target.value }))
          }
          required
        />
        <Input
          className={!validCpf ? 'input__component invalid' : 'input__component'}
          inputRef={mainFormCpfInput}
          value={naturalPerson.natural_cpf}
          placeholder="CPF *"
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNaturalPerson((state: NaturalPerson) => ({ ...state, natural_cpf: cpfMask(e.target.value) }))
          }
        />
        <Input
          placeholder="RG"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNaturalPerson((state: NaturalPerson) => ({ ...state, natural_rg: e.target.value }))
          }
        />
        <Input
          type="email"
          placeholder="Email"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNaturalPerson((state: NaturalPerson) => ({ ...state, email: e.target.value }))
          }
        />
        <Selector
          className="selector--register__person"
          setValue={setOccupationTypeId}
          occupationTypes={occupationTypes.data}
        />
        <Input
          placeholder="Estado civil"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNaturalPerson((state: NaturalPerson) => ({ ...state, marital_status: e.target.value }))
          }
        />
        <Autocomplete
          idKey="person_id"
          callbackMethod={setSpouseId}
          request={fetchAllPeople}
          path="name"
          value={spouseName}
          setValue={setSpouseName}
          data={allPeople?.data}
          placeholder="Cônjuge"
          rightElement={addSpouseTooltip()}
          keyOne="person_id"
          nameValue="name"
        />
        <Input
          placeholder="Nacionalidade"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNaturalPerson((state: NaturalPerson) => ({ ...state, nationality: e.target.value }))
          }
        />
        <TextArea
          large
          placeholder="Informações adicionais ..."
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setNaturalPerson((state: NaturalPerson) => ({ ...state, annotation: e.target.value }))
          }
        />
        <Button text="Cadastrar pessoa física" type="submit" name="add" className="sidebar__button--blue" icon="add" />
      </form>
    </>
  );

  const juridicPersonForm = () => (
    <>
      <form onSubmit={addLegalPerson}>
        <Input
          placeholder="Razão social *"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setLegalPerson((state: any) => ({ ...state, name: e.target.value }))
          }
          required
        />
        <Autocomplete
          value={spouseName}
          setValue={setSpouseName}
          idKey="person_id"
          callbackMethod={setSpouseId}
          request={fetchAllPeople}
          path="name"
          data={allPeople?.data}
          placeholder="Sócio responsável"
          rightElement={addSpouseTooltip()}
          keyOne="person_id"
          nameValue="name"
        />
        <Input
          className={!validCnpj ? 'input__component invalid' : 'input__component'}
          inputRef={mainFormCnpjInput}
          value={legalPerson.legal_cnpj}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setLegalPerson((state: LegalPerson) => ({ ...state, legal_cnpj: cnpjMask(e.target.value) }))
          }
          placeholder="CNPJ *"
          required
        />
        <Input
          placeholder="IE"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setLegalPerson((state: LegalPerson) => ({ ...state, legal_ie: e.target.value }))
          }
        />
        <Input
          type="email"
          placeholder="Email"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setLegalPerson((state: LegalPerson) => ({ ...state, email: e.target.value }))
          }
        />
        <Selector className="selector--register__person" activityTypes={activityTypes.data} setValue={setActivityId} />
        <TextArea
          large
          placeholder="Informações adicionais ...."
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setLegalPerson((state: LegalPerson) => ({ ...state, annotation: e.target.value }))
          }
        />
        <Button text="Cadastrar pessoa jurídica" type="submit" name="add" className="sidebar__button--blue" icon="add" />
      </form>
    </>
  );

  const handleSendFastRegister = () => {
    setSpouseName(nameInput?.current?.value);
    if (isLegalPerson) {
      return dispatch(
        fastRegisterPerson({
          name: nameInput?.current?.value,
          legal_cnpj: cnpjInput?.current?.value,
          is_legal_person: isLegalPerson,
        })
      );
    }
    return dispatch(
      fastRegisterPerson({
        name: nameInput?.current?.value,
        natural_cpf: cpfInput?.current?.value,
        is_legal_person: isLegalPerson,
      })
    );
  };

  return (
    <div className="register__person">
      <FastRegisterDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        loading={loading}
        nameInput={nameInput}
        cpfInput={cpfInput}
        cnpjInput={cnpjInput}
        handleSendFastRegister={handleSendFastRegister}
        isLegalPerson={isLegalPerson}
        setIsLegalPerson={setIsLegalPerson}
      />
      {createPerson.loading ? (
        <Spinner intent="primary" size={25} />
      ) : (
        <>
          {isMobile() ? (
            <Button
              className="bp3-button sidebar__button--blue"
              onClick={() => setMobileToggle(!mobileToggle)}
              icon="chevron-left"
              text="Voltar"
            />
          ) : (
            <></>
          )}
          <p className="description">Cadastrar pessoa:</p>
          <Tabs selectedTabId={selectedTabId} onChange={handleChangeTab}>
            <Tab id="phisical" title="Pessoa física" panel={phisicalPersonForm()} />
            <Tab id="juridic" title="Pessoa jurídica" panel={juridicPersonForm()} />
            <Tabs.Expander />
          </Tabs>
        </>
      )}
    </div>
  );
};

export default RegisterPerson;
