/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, useCallback, SyntheticEvent } from 'react';
import './styles.css';
import { Label, Button, Spinner, IBreadcrumbProps, TextArea, Checkbox } from '@blueprintjs/core';
import { useSelector, useDispatch } from 'react-redux';
import { reducers } from '../../store/reducers';
import update from 'immutability-helper';
import PersonRelations from '../../components/PersonRelations';
import { cpfMask, cnpjMask } from '../../utils/masks';
import createToaster from '../../components/Toaster';
import { isNaturalPerson } from '../../utils/people';
import BreadCrumb from '../../components/Breadcrumb';
import Input from '../../components/Input';
import { fetchPerson, updatePerson, inactivatePerson } from './actions';
import { goToHere } from '../../utils/navigation';
import Selector from '../../components/Selector';
import { fetchOccupationTypes } from '../MetaPeopleOccupations/actions';
import { fetchActivityTypes } from '../MetaPeopleActivities/actions';
import { FullPersonData, LawyerOab } from '../PeopleList/types';
import OABManager from '../../components/OabManager';
import PopoverEditRemove from '../../components/PopoverEditRemove';

type Match = {
  path: string;
  url: string;
  isExact: boolean;
  params: any;
};

interface IPersonProps {
  match: Match;
}

const Person: React.FC<IPersonProps> = ({ match }) => {
  const dispatch = useDispatch();

  const { failUpdate, loadingUpdate, messageUpdate, dataUpdate } = useSelector(
    (state: typeof reducers) => state.person.personUpdate
  );
  const { data } = useSelector((state: typeof reducers) => state.person.person);
  const inactivatePersonData = useSelector((state: typeof reducers) => state.person.personInactivate);
  const occupationTypes = useSelector((state: typeof reducers) => state.metapeopleoccupations.occupationTypes);
  const activityTypes = useSelector((state: typeof reducers) => state.metapeopleactivities.activityTypes);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [personData, setPersonData] = useState<FullPersonData>();
  const [originalPersonData, setOriginalPersonData] = useState<FullPersonData>();
  const [occupationId, setOccupationId] = useState('');
  const [activityId, setActivityId] = useState('');
  const BREADCRUMB_PATHS: IBreadcrumbProps[] = [
    { href: '/painel/pessoas', text: 'Pessoas' },
    { href: '/painel/pessoas/individuo', text: `${personData ? personData?.name : ''}` },
  ];

  useEffect(() => {
    dispatch(fetchPerson(match.params.id));
  }, [dispatch, match]);

  useEffect(() => {
    setPersonData(data);
    setOriginalPersonData(data);
  }, [data]);

  useEffect(() => {
    if (dataUpdate?.person_id) {
      goToHere(`/painel/pessoas/individuo/${dataUpdate?.person_id}`);
    }
  }, [dataUpdate]);

  useEffect(() => {
    if (occupationId.length) {
      setPersonData(update(personData, { occupation_id: { $set: occupationId } }));
    }
  }, [occupationId, personData]);

  useEffect(() => {
    if (activityId.length) {
      setPersonData(update(personData, { business_activity_id: { $set: activityId } }));
    }
  }, [activityId, personData]);

  const handleProfessionalActivityRequest = useCallback(() => {
    if (!occupationTypes.data.length && !activityTypes.data.length) {
      personData?.is_legal_person ? dispatch(fetchActivityTypes()) : dispatch(fetchOccupationTypes());
    }
  }, [personData, dispatch, occupationTypes, activityTypes]);

  useEffect(() => {
    handleProfessionalActivityRequest();
  }, [personData, handleProfessionalActivityRequest]);

  const handleAnnotationChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPersonData((prevState: any) => {
      return {
        ...prevState,
        annotation: e.target.value,
      };
    });
  };

  const getJuridicalForm = () => (
    <div id="juridical">
      <div className="side__column">
        <Label>
          Cnpj
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPersonData(update(personData, { legal_cnpj: { $set: cnpjMask(e.target.value) } }))
            }
            disabled={isFormDisabled}
            value={cnpjMask(personData?.legal_cnpj)}
          />
        </Label>
        <Label>
          IE
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPersonData(update(personData, { legal_ie: { $set: e.target.value } }))
            }
            value={personData?.legal_ie}
            disabled={isFormDisabled}
          />
        </Label>
      </div>
      <div className="mid__column">
        <Label>
          Atividade
          <Selector
            className="new__selector"
            value={personData?.business_activity_id}
            disabled={isFormDisabled}
            activityTypes={activityTypes.data}
            setValue={setActivityId}
          />
        </Label>
      </div>
      <div className="mid__column">
        <Label>
          Email
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPersonData(update(personData, { email: { $set: e.target.value } }))
            }
            type="email"
            value={personData?.email}
            disabled={isFormDisabled}
          />
        </Label>
      </div>
      <div className="side__column">
        {personData?.annotation && (
          <Label>
            Informações adicionais
            <TextArea
              className="add__info"
              onChange={handleAnnotationChange}
              large
              disabled={isFormDisabled}
              defaultValue={personData?.annotation}
            />
          </Label>
        )}
      </div>
    </div>
  );

  const addOabToPersonData = (oab: LawyerOab) => {
    if (personData?.lawyers.length === 1 && personData?.lawyers[0].oab_id == null) {
      return setPersonData({
        ...personData,
        lawyers: [oab]
      })
    }
    return setPersonData(update(personData, { lawyers: { $push: [oab] } }));
  };

  const removeOab = (oabId: string) => {
    if (personData?.lawyers.length === 1) {
      return setPersonData({
        ...personData,
        lawyers: []
      })
    }
    const index = personData?.lawyers.findIndex((oab) => oab.oab_id === oabId);
    if (index) {
      return setPersonData(update(personData, { lawyers: { $splice: [[index, 1]] } }));
    }
  };

  const getNaturalForm = () => (
    <div id="natural">
      <div className="row">
        <div className="job">
          <Label>
            Profissão
            <Selector
              className="new__selector"
              value={personData?.occupation_id}
              disabled={isFormDisabled}
              occupationTypes={occupationTypes.data}
              setValue={setOccupationId}
            />
            {personData?.occupation?.occupation.includes('Adv') && (
              <Checkbox
                disabled={isFormDisabled}
                checked={personData.is_employee}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPersonData(update(personData, { is_employee: { $set: !personData?.is_employee } }))
                }
              >
                Advogado do escritório
              </Checkbox>
            )}
          </Label>
        </div>
        <Label>
          Cpf
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPersonData(update(personData, { natural_cpf: { $set: cpfMask(e.target.value) } }))
            }
            value={cpfMask(personData?.natural_cpf)}
            disabled={isFormDisabled}
          />
        </Label>
        <Label>
          Nacionalidade
          <Input
            value={personData?.nationality || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPersonData(update(personData, { nationality: { $set: e.target.value } }))
            }
            disabled={isFormDisabled}
          />
        </Label>
        <Label>
          Estado civil
          <Input
            value={personData?.marital_status || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPersonData(update(personData, { marital_status: { $set: e.target.value } }))
            }
            disabled={isFormDisabled}
          />
        </Label>
      </div>
      <div className="row">
        <Label>
          E-mail
          <Input
            value={personData?.email || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPersonData(update(personData, { email: { $set: e.target.value } }))
            }
            type="email"
            disabled={isFormDisabled}
          />
        </Label>
        <Label>
          RG
          <Input
            value={personData?.natural_rg || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPersonData(update(personData, { natural_rg: { $set: e.target.value } }))
            }
            disabled={isFormDisabled}
          />
        </Label>
        <Label>
          Informações adicionais
          <TextArea
            className="add__info"
            value={personData?.annotation || ''}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setPersonData(update(personData, { annotation: { $set: e.target.value } }))
            }
            large
            disabled={isFormDisabled}
          />
        </Label>
      </div>
      {personData?.occupation?.occupation.includes('Adv') && (
        <OABManager
          personData={personData}
          setPersonData={addOabToPersonData}
          isFormDisabled={isFormDisabled}
          handleRemove={removeOab}
        />
      )}
    </div>
  );

  const handleCancel = useCallback(() => {
    setPersonData(originalPersonData);
    setIsFormDisabled(true);
  }, [originalPersonData, setPersonData, setIsFormDisabled]);

  useEffect(() => {
    if (failUpdate || messageUpdate) {
      const showToast = createToaster({
        message: failUpdate || messageUpdate,
        intent: failUpdate ? 'danger' : 'success',
        icon: failUpdate ? 'warning-sign' : 'tick',
      });
      showToast();
      if (failUpdate) {
        handleCancel();
      }
    }
  }, [messageUpdate, failUpdate, handleCancel]);

  useEffect(() => {
    if (inactivatePersonData.success || inactivatePersonData.fail) {
      const showToast = createToaster({
        message: inactivatePersonData.fail || inactivatePersonData.success,
        intent: inactivatePersonData.fail ? 'danger' : 'success',
        icon: inactivatePersonData.success ? 'warning-sign' : 'tick',
      });
      showToast();
    }
  }, [inactivatePersonData]);

  const saveButtonsVisibility = () => (!isFormDisabled ? 'save__buttons' : 'none');

  const savePerson = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsFormDisabled(true);
    return dispatch(updatePerson(personData!));
  };

  return (
    <div className="person__container">
      <div className="content__head">
        <Button
          className="bp3-button sidebar__button--blue"
          onClick={() => goToHere('/painel/pessoas/lista')}
          icon="chevron-left"
          text="Voltar"
        />
        <BreadCrumb breads={BREADCRUMB_PATHS} />
      </div>
      {personData && occupationTypes.data && !loadingUpdate ? (
        <>
          <form onSubmit={savePerson}>
            <div className="title__buttons">
              <div className="person__name">
                <Input
                  className="person__title"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPersonData(update(personData, { name: { $set: e.target.value } }))
                  }
                  value={personData?.name}
                  disabled={isFormDisabled}
                />
                {isFormDisabled && (
                  <> 
                    <PopoverEditRemove
                      className="popover__edit-remove"
                      formDisabled={isFormDisabled}
                      editRequest={() => setIsFormDisabled(!isFormDisabled)}
                      removeRequest={() => dispatch(inactivatePerson(personData))}
                    />
                  {inactivatePersonData.loading && (
                    <Spinner className="remove__spinner" intent="primary" size={25} />
                  )}
                  </>
                )}
              </div>
              <div className={saveButtonsVisibility()}>
                <Button intent="danger" minimal onClick={() => handleCancel()} text="Cancelar" />
                <Button className="button--save" text="Salvar edição" type="submit" />
              </div>
            </div>
          </form>
          <div className="main__content">{isNaturalPerson(personData) ? getNaturalForm() : getJuridicalForm()}</div>
          <div className="bottom__content">
            <PersonRelations personId={personData?.person_id} />
          </div>
        </>
      ) : (
        <Spinner intent="primary" size={25} />
      )}
    </div>
  );
};

export default Person;
