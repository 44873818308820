import React, { useState, useEffect, SyntheticEvent } from 'react';
import Button from '../../components/Button';
import { useSelector, useDispatch } from 'react-redux';
import { reducers } from '../../store/reducers';
import { fetchConnectedPeople, createPersonRelation, inactivatePeopleRelation } from './actions';
import '../../components/CaseRelations/styles.css';
import Selector from '../../components/Selector';
import createToaster from '../../components/Toaster';
import { CaseRelatedPerson } from './types';
import Autocomplete from '../../components/Autocomplete';
import { Tooltip, Checkbox } from '@blueprintjs/core';
import { fastRegisterPerson, fetchAllPeople } from '../PeopleList/actions';
import FastRegisterDialog from '../../components/FastRegisterDialog';
import { fetchLegalCasePersonTypes } from '../MetaCasesLegalCasePerson/actions';
import './styles.css';
import Table from '../../components/GenericTable';

interface ICaseRelatedPeople {
  caseId: string;
}

const CaseRelatedPeople: React.FC<ICaseRelatedPeople> = ({ caseId }) => {
  const dispatch = useDispatch();
  const people = useSelector((state: typeof reducers) => state.caserelatedpeople.connectedPeople);
  const create = useSelector((state: typeof reducers) => state.caserelatedpeople.create);
  const inactivate = useSelector((state: typeof reducers) => state.caserelatedpeople.inactivate);
  const allPeople = useSelector((state: typeof reducers) => state.peoplelist.allPeople);
  const fastRegister = useSelector((state: typeof reducers) => state.peoplelist.fastRegister);
  const legalCasePersonTypes = useSelector((state: typeof reducers) => state.metacaseslegalcaseperson.legalCasePersonTypes);
  const nameInput = React.useRef() as any;
  const cpfInput = React.useRef() as any;
  const cnpjInput = React.useRef() as any;
  const [connectionId, setConnectionId] = useState('');
  const [spouseName, setSpouseName] = useState('');
  const [isLegalPerson, setIsLegalPerson] = useState(false);
  const { loading } = useSelector((state: typeof reducers) => state.peoplelist.peopleList);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const formField = React.useRef() as React.MutableRefObject<HTMLFormElement>;
  const [isEdit, setIsEdit] = useState(false);
  const [personTypeId, setPersonTypeId] = useState('');
  const [isCustomer, setIsCustomer] = useState(false);
  const [isApplicant, setIsApplicant] = useState(false);

  useEffect(() => {
    dispatch(fetchLegalCasePersonTypes());
    dispatch(fetchAllPeople('name='));
  }, [dispatch]);

  useEffect(() => {
    if (caseId?.length) {
      dispatch(fetchConnectedPeople(caseId));
    }
  }, [dispatch, caseId]);

  useEffect(() => {
    if (inactivate?.success || inactivate?.fail || create?.success || create?.fail) {
      const showToast = createToaster({
        message: inactivate?.success || inactivate?.fail || create?.success || create?.fail,
        intent: inactivate?.fail || create?.fail ? 'danger' : 'success',
        icon: inactivate?.fail || create?.fail ? 'warning-sign' : 'tick',
      });
      showToast();
    }
  }, [inactivate, create]);

  useEffect(() => {
    if (fastRegister.fail || fastRegister.success) {
      const showToast = createToaster({
        message: fastRegister.fail || fastRegister.success,
        intent: fastRegister.fail ? 'danger' : 'success',
        icon: fastRegister.fail ? 'warning-sign' : 'tick',
      });
      showToast();
    }
  }, [fastRegister.fail, fastRegister.success]);

  useEffect(() => {
    if (fastRegister?.id.length) {
      setConnectionId(fastRegister.id);
    }
  }, [fastRegister]);

  const handleSendFastRegister = () => {
    setIsDialogOpen(false);
    setSpouseName(nameInput?.current?.value);
    if (isLegalPerson) {
      return dispatch(
        fastRegisterPerson({
          name: nameInput?.current?.value,
          legal_cnpj: cnpjInput?.current?.value,
          is_legal_person: isLegalPerson,
        })
      );
    }
    return dispatch(
      fastRegisterPerson({
        name: nameInput?.current?.value,
        natural_cpf: cpfInput?.current?.value,
        is_legal_person: isLegalPerson,
      })
    );
  };

  const getTable = () => (
    <div className="table__container">
      <Table
        loading={inactivate?.loading || create?.loading}
        data={people?.data}
        empty={people?.empty}
        headers={['Nome', 'Tipo', 'Cliente', 'Polo ativo']}
        columns={['person_related_name', 'person_type', 'is_customer', 'is_active_pole']}
        expandButton={{ path: `/painel/pessoas/individuo`, text: 'Expandir', pathId: 'person_id' }}
        moreButton={{ editRequest: handleEditRequest, removeRequest: inactivatePeopleRelation, pathId: 'legal_case_id', secondPathId: 'person_id' }}
      />
    </div>
  );

  const handleEditRequest = (elem: CaseRelatedPerson) => {
    setConnectionId(elem.person_id);
    setPersonTypeId(elem.person_type?.person_type_id || '');
    setSpouseName(elem.person?.name || '');
    setIsCustomer(elem.is_customer);
    setIsApplicant(elem.is_applicant);
    setIsEdit(true);
  };

  const clearForm = () => {
    setConnectionId('');
    setPersonTypeId('');
    setSpouseName('');
    setIsCustomer(false);
    setIsApplicant(false);
    setIsEdit(false);
  };

  const sendRelation = () => {
    dispatch(
      createPersonRelation(caseId, {
        person_id: connectionId,
        person_type_id: personTypeId,
        is_customer: isCustomer,
        is_applicant: isApplicant,
      })
    );
    clearForm();
  };

  const addSpouseTooltip = () => (
    <Tooltip captureDismiss content={'Clique aqui para cadastrar pessoa'}>
      <Button
        onClick={(e: SyntheticEvent) => setIsDialogOpen(true)}
        name="add"
        className="sidebar__button--blue"
        icon="add"
      />
    </Tooltip>
  );

  return (
    <div className="case__relations--people">
      <div className="tabs__subline"></div>
      <FastRegisterDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        loading={loading}
        nameInput={nameInput}
        cpfInput={cpfInput}
        cnpjInput={cnpjInput}
        handleSendFastRegister={handleSendFastRegister}
        isLegalPerson={isLegalPerson}
        setIsLegalPerson={setIsLegalPerson}
      />
      <div className="content__container">
        {getTable()}
        <form className="register__form" ref={formField}>
          <h2>Relacionar pessoa</h2>
          <Autocomplete
            value={spouseName}
            setValue={setSpouseName}
            idKey="person_id"
            callbackMethod={setConnectionId}
            request={fetchAllPeople}
            path="name"
            data={allPeople?.data}
            placeholder="Nome da pessoa"
            rightElement={addSpouseTooltip()}
            keyOne="person_id"
            nameValue="name"
          />
          <Selector
            required
            className="person__type__selector"
            value={personTypeId}
            setValue={setPersonTypeId}
            personTypes={legalCasePersonTypes.data}
          />
          <div className="checkbox__container">
            <Checkbox checked={isCustomer} onChange={() => setIsCustomer((state: boolean) => !state)}>
              Cliente
            </Checkbox>
          </div>
          <div className="buttons__container">
            <Button name="add" text="Salvar" onClick={sendRelation} type="button" />
            {isEdit && <Button name="default" text="Cancelar edição" onClick={clearForm} type="button" />}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CaseRelatedPeople;
