/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import { Dispatch } from 'redux';
import * as c from './constants';

export const recoveryPassword = (email: string) => (dispatch: Dispatch) => {
  dispatch({
    type: c.FETCH_FORGOT_PASSWORD,
    payload: {
      request: {
        url: `/login/recover-password/${email}`,
        method: 'POST',
      },
      options: {
        onSuccess: ({ getState, dispatch, response }: any) => {
          const resData = response.data;
          dispatch({
            type: c.FETCH_FORGOT_PASSWORD_SUCCESS,
            payload: {
              data: resData,
            },
          });
        },
        onError: ({ getState, dispatch, error }: any) => {
          dispatch({
            type: c.FETCH_FORGOT_PASSWORD_FAIL,
            payload: {
              error: 'Login failed',
            },
          });
        },
      },
    },
  });
};
