import React, { useState, useEffect, createRef } from 'react';
import Button from '../../components/Button';
import { useSelector, useDispatch } from 'react-redux';
import { reducers } from '../../store/reducers';
import { fetchRelatedFolders, createFolderRelation, inactivateFolderRelation } from './actions';
import '../../components/CaseRelations/styles.css';
import MetaTable from '../../components/MetaTable';
import createToaster from '../../components/Toaster';
import { GetFolders } from './types';
import { Checkbox, TextArea } from '@blueprintjs/core';
import './styles.css';
import Input from '../../components/Input';

interface ICaseRelatedPeople {
  caseId: string;
}

const CaseRelatedPeople: React.FC<ICaseRelatedPeople> = ({ caseId }) => {
  const dispatch = useDispatch();
  const inputNumber = createRef() as any;
  const inputInitials = createRef() as any;
  const inputLetter = createRef() as any;
  const folders = useSelector((state: typeof reducers) => state.caserelatedfolders.relatedFolders);
  const create = useSelector((state: typeof reducers) => state.caserelatedfolders.create);
  const inactivate = useSelector((state: typeof reducers) => state.caserelatedfolders.inactivate);
  const [connectionId, setConnectionId] = useState<string | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [initials, setInitials] = useState<string | null>(null);
  const [letter, setLetter] = useState<string | null>(null);
  const [number, setNumber] = useState<number | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [isDead, setIsDead] = useState<boolean>(false);
  const [isInBox, setIsInBox] = useState<boolean>(false);


  useEffect(() => {
    if (caseId?.length) {
      dispatch(fetchRelatedFolders(caseId));
    }
  }, [dispatch, caseId]);

  useEffect(() => {
    if (
      inactivate?.success ||
      inactivate?.fail ||
      create?.success ||
      create?.fail
    ) {
      const showToast = createToaster({
        message:
          inactivate?.success ||
          inactivate?.fail ||
          create?.success ||
          create?.fail,
        intent: inactivate?.fail || create?.fail ? 'danger' : 'success',
        icon: inactivate?.fail || create?.fail ? 'warning-sign' : 'tick',
      });
      showToast();
    }
  }, [inactivate, create]);

  const getTable = () => (
    <div className="table__container">
      <MetaTable
        loading={inactivate?.loading || create?.loading}
        empty={folders?.empty}
        data={folders?.data}
        headers={['Sigla', 'Número', 'Letra', 'Caixa', 'Morto']}
        columns={['initials', 'number', 'letter', 'is_in_box', 'is_dead']}
        pathId="physical_folder_id"
        removeRequest={inactivateFolderRelation}
        editRequest={handleEditRequest}
        personId={caseId}
      />
    </div>
  );

  const handleEditRequest = (elem: GetFolders) => {
    setConnectionId(elem.physical_folder_id);
    setLetter(elem.letter);
    setNumber(elem.number);
    setInitials(elem.initials);
    setDescription(elem.description);
    setIsDead(elem.is_dead);
    setIsInBox(elem.is_in_box);
    setIsEdit(true);
  };

  const clearVariables = () => {
    setConnectionId(null);
    setLetter(null);
    setInitials(null);
    setNumber(null);
    setDescription(' ');
    setIsDead(false);
    setIsInBox(false);
    setIsEdit(false);
  };

  const clearInputs = () => {
    inputNumber.current.value = '';
    inputInitials.current.value = '';
    inputLetter.current.value = '';
  };

  const clearForm = () => {
    clearInputs()
    clearVariables();
  };

  const sendRelation = () => {
    dispatch(createFolderRelation(caseId, {
      initials: initials,
      letter: letter,
      number: number,
      description: description,
      is_dead: isDead,
      is_in_box: isInBox,
      physical_folder_id: connectionId
    }))
    clearVariables();
    clearInputs();
  };

  return (
    <div className="case__relations--folders">
      <div className="tabs__subline"></div>
      <div className="content__container">
        {getTable()}
        <form className="register__form">
          <h2>Relacionar pasta</h2>
          <Input
            type="text"
            inputRef={inputInitials}
            maxLength={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInitials(e.target.value)}
            value={initials || undefined}
            placeholder="Sigla"
          />
          <Input
            type="number"
            maxLength={10}
            inputRef={inputNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNumber(Number(e.target.value))}
            value={number?.toString() || undefined}
            placeholder="Número"
          />
          <Input
            type="text"
            inputRef={inputLetter}
            maxLength={1}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLetter(e.target.value.toUpperCase())}
            value={letter || undefined}
            placeholder="Letra"
          />
          <div className="checkbox__container">
            <Checkbox checked={isInBox} onChange={() => setIsInBox((state: boolean) => !state)}>Caixa</Checkbox>
            <Checkbox checked={isDead} onChange={() => setIsDead((state: boolean) => !state)}>Morto</Checkbox>
          </div>
          <TextArea
            large
            required
            value={description || undefined}
            placeholder="Descrição *"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
          />
          <div className="buttons__container">
            <Button name="add" text="Salvar" onClick={sendRelation} type="button" />
            {isEdit && <Button name="default" text="Cancelar edição" onClick={clearForm} type="button" />}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CaseRelatedPeople;
