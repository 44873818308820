export const FETCH_SECRETARY_TYPE = 'FETCH_SECRETARY_TYPE';
export const FETCH_SECRETARY_TYPE_SUCCESS = 'FETCH_SECRETARY_TYPE_SUCCESS';
export const FETCH_SECRETARY_TYPE_FAIL = 'FETCH_SECRETARY_TYPE_FAIL';
export const FETCH_SECRETARY_TYPE_PAGINATED = 'FETCH_SECRETARY_TYPE_PAGINATED';
export const FETCH_SECRETARY_TYPE_PAGINATED_SUCCESS = 'FETCH_SECRETARY_TYPE_PAGINATED_SUCCESS';
export const FETCH_SECRETARY_TYPE_PAGINATED_FAIL = 'FETCH_SECRETARY_TYPE_PAGINATED_FAIL';
export const FETCH_SECRETARY_TYPE_ID = 'FETCH_SECRETARY_TYPE_ID';
export const FETCH_SECRETARY_TYPE_ID_SUCCESS = 'FETCH_SECRETARY_TYPE_ID_SUCCESS';
export const FETCH_SECRETARY_TYPE_ID_FAIL = 'FETCH_SECRETARY_TYPE__ID_FAIL';
export const FETCH_SECRETARIES_BY_COUNTY_ID = 'FETCH_SECRETARIES_BY_COUNTY_ID';
export const FETCH_SECRETARIES_BY_COUNTY_ID_SUCCESS = 'FETCH_SECRETARIES_BY_COUNTY_ID_SUCCESS';
export const FETCH_SECRETARIES_BY_COUNTY_ID_FAIL = 'FETCH_SECRETARIES_BY_COUNTY_ID_FAIL';
export const CREATE_SECRETARY_TYPE = 'CREATE_SECRETARY_TYPE';
export const CREATE_SECRETARY_TYPE_SUCCESS = 'CREATE_SECRETARY_TYPE_SUCCESS';
export const CREATE_SECRETARY_TYPE_FAIL = 'CREATE_SECRETARY_TYPE_FAIL';
export const UPDATE_SECRETARY_TYPE = 'UPDATE_SECRETARY_TYPE';
export const UPDATE_SECRETARY_TYPE_SUCCESS = 'UPDATE_SECRETARY_TYPE_SUCCESS';
export const UPDATE_SECRETARY_TYPE_FAIL = 'UPDATE_SECRETARY_TYPE_FAIL';
export const INACTIVATE_SECRETARY_TYPE = 'INACTIVATE_SECRETARY_TYPE';
export const INACTIVATE_SECRETARY_TYPE_SUCCESS = 'INACTIVATE_SECRETARY_TYPE_SUCCESS';
export const INACTIVATE_SECRETARY_TYPE_FAIL = 'INACTIVATE_SECRETARY_TYPE_FAIL';
export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
