import React, { useEffect, useState, useCallback, createRef } from 'react';
import './styles.css';
import Input from '../../components/Input';
import { TextArea, Icon } from '@blueprintjs/core';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import 'moment/locale/pt-br';
import { useDispatch, useSelector } from 'react-redux';
import { reducers } from '../../store/reducers';
import Selector from '../../components/Selector';
import DatePicker from '../../components/DatePicker';
import { fetchCourtsByJusticeAndCounty } from '../MetaCasesCourtType/actions';
import { fetchSecretariesByCounty } from '../MetaCasesSecretaryType/actions';
import { fetchTagTypes } from '../MetaCasesTag/actions';
import { fetchDistrictTypes } from '../MetaCasesDistrictType/actions';
import { TagType } from '../MetaCasesTag/types';
import { formatDateToServer, momentFormatBrDate } from '../../utils/date';
import { PostCase } from '../CreateCase/types';
import { fetchProgressStatus } from '../MetaCasesProgressStatus/actions';
import { formatNumberToCurrency, clearValue, handleCurrencyChange } from '../../utils/form';
import Autocomplete from '../../components/Autocomplete';
import { fetchAllActiveCases } from '../CasesList/actions';
import { fetchJusticeTypes } from '../MetaCasesJusticeType/actions';

type StepDataCaseFormProps = {
  newCase: PostCase;
  setNewCase: Function;
  formRef: React.MutableRefObject<HTMLFormElement>;
  trySubmit: boolean;
};

const StepDataCaseForm: React.FC<StepDataCaseFormProps> = ({ newCase, setNewCase, formRef, trySubmit }) => {
  const dispatch = useDispatch();
  const autoRef = createRef() as any;
  const progressStatus = useSelector((state: typeof reducers) => state.metacasesprogressstatus.progressStatus);
  const courtsByJusticeAndCounty = useSelector(
    (state: typeof reducers) => state.metacasescourttype.courtsByJusticeAndCounty
  );
  const tagTypes = useSelector((state: typeof reducers) => state.metacasestag.tagTypes);
  const secretariesByCountyId = useSelector((state: typeof reducers) => state.metacasessecretarytype.secretariesByCountyId);
  const justiceTypes = useSelector((state: typeof reducers) => state.metacasesjusticetype.justiceTypes);
  const districtTypes = useSelector((state: typeof reducers) => state.metacasesdistricttype.districtTypes);
  const allCases = useSelector((state: typeof reducers) => state.caseslist.allCases);
  const [justiceId, setJusticeId] = useState<string | null>(null);
  const [secretaryId, setSecretaryId] = useState<string | null>(null);
  const [courtId, setCourtId] = useState<string | null>(null);
  const [countyId, setCountyId] = useState<string | null>(null);
  const [progressId, setProgressId] = useState('');
  const [originatingProcess, setOriginatingProcess] = useState<string | null>(null);

  const setNewCounty = useCallback(() => {
    if (countyId) {
      dispatch(fetchSecretariesByCounty(countyId));
      setNewCase({
        ...newCase,
        process_county_id: countyId,
      });
      if (justiceId) {
        dispatch(fetchCourtsByJusticeAndCounty(justiceId, countyId));
      }
    }
  }, [countyId, justiceId, newCase, setNewCase, dispatch]);

  useEffect(() => {
    if (countyId && newCase.process_county_id !== countyId) {
      setNewCounty();
    }
  }, [countyId, setNewCounty, newCase]);

  const setNewJustice = useCallback(() => {
    if (justiceId) {
      setNewCase({
        ...newCase,
        process_justice_type_id: justiceId,
      });
    }
    if (justiceId && countyId) {
      dispatch(fetchCourtsByJusticeAndCounty(justiceId, countyId));
    }
  }, [justiceId, countyId, newCase, setNewCase, dispatch]);

  useEffect(() => {
    if (
      justiceId &&
      newCase.process_justice_type_id !== justiceId
    ) {
      setNewJustice();
    }
  }, [justiceId, countyId, newCase, setNewJustice]);

  useEffect(() => {
    if (courtId && newCase.process_court_id !== courtId) {
      setNewCase({
        ...newCase,
        process_court_id: courtId,
      });
    }
  }, [courtId, newCase, setNewCase]);

  const setNewSecretary = useCallback(() => {
    setNewCase({
      ...newCase,
      process_secretary_id: secretaryId,
    });
  }, [secretaryId, newCase, setNewCase]);

  useEffect(() => {
    if (secretaryId && newCase.process_secretary_id !== secretaryId) setNewSecretary();
  }, [secretaryId, newCase, setNewSecretary]);

  useEffect(() => {
    if (progressId) {
      setNewCase((state: PostCase) => ({
        ...state,
        case_status_id: progressId,
      }));
    }
  }, [progressId, setNewCase]);

  useEffect(() => {
    dispatch(fetchProgressStatus());
    dispatch(fetchTagTypes());
    dispatch(fetchJusticeTypes());
    dispatch(fetchDistrictTypes());
    dispatch(fetchAllActiveCases('name='));
  }, [dispatch]);

  const handleTagChange = (e: any) => {
    if (!newCase.tags.find((item: string) => item === e.target.value)) {
      setNewCase({
        ...newCase,
        tags: newCase.tags.concat(e.target.value),
      });
    }
  };

  const handleRemoveTag = (e: any) => {
    const newTags = newCase.tags.filter((elem: any) => elem !== e);
    setNewCase({
      ...newCase,
      tags: newTags,
    });
  };

  const processNumberExists = () => newCase?.process_id !== null;

  const getTagName = (tagId: string) => {
    const name = tagTypes.data.find((item: TagType) => item.tag_id === tagId);
    return name.tag;
  };

  const handleEmptyStringToNull = (value: string) => (value?.length < 1 ? null : value);

  const handleChangeCurrency = (value: string) => {
    const formattedValue = clearValue(value);
    if (Number(formattedValue) > 0) {
      let newValue = handleCurrencyChange(formattedValue);
      return setNewCase((state: PostCase) => ({ ...state, lawsuit_value: newValue }));
    }
    return setNewCase((state: PostCase) => ({ ...state, lawsuit_value: 0 }));
  };

  useEffect(() => {
    if (!newCase?.process_id?.length) {
      setCountyId(null);
      setJusticeId(null);
      setCourtId(null);
      setSecretaryId(null);
    }
  }, [newCase]);

  useEffect(() => {
    if (newCase.process_originating !== originatingProcess) {
      setNewCase({
        ...newCase,
        process_originating: originatingProcess,
      });
    }
    if (autoRef) {
      autoRef.current.value = originatingProcess;
    }
  }, [originatingProcess, autoRef, newCase, setNewCase]);

  return (
    <div className="datacase__container">
      <h3>Passo 1 de 3</h3>
      <p className="left__p">
        Preencha as informações abaixo para cadastrar um novo caso. Os campos com asterisco (*) são obrigatórios, e os campos
        desativados dependem do número do processo para serem ativados.
      </p>
      {!formRef?.current?.checkValidity() && trySubmit && (
        <h4 className="empty__attribute">
          <Icon icon="warning-sign"></Icon>
          Por favor, preencha todos os <strong>campos obrigatórios</strong> antes de prosseguir com o cadastro.
        </h4>
      )}
      <form ref={formRef} action="">
        <div className="left__side">
          <div className="input__combo">
            <Input
              type="text"
              placeholder="Número do processo"
              maxLength={25}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewCase({
                  ...newCase,
                  process_id: handleEmptyStringToNull(e.target.value),
                })
              }
            />
            <Autocomplete
              reference={autoRef}
              className="auto__input"
              value={originatingProcess || ''}
              setValue={setOriginatingProcess}
              request={fetchAllActiveCases}
              path="name"
              idKey="legal_case_id"
              data={allCases?.data}
              placeholder="Originário"
              keyOne="legal_case_id"
              nameValue="process_id"
            />
          </div>
          <Input
            type="text"
            placeholder="Digite o apelido do caso *"
            maxLength={100}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewCase({
                ...newCase,
                title: handleEmptyStringToNull(e.target.value),
              })
            }
            required
          />
          <TextArea
            large
            placeholder="Nome da ação *"
            required
            maxLength={400}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setNewCase({
                ...newCase,
                lawsuit_name: handleEmptyStringToNull(e.target.value),
              })
            }
          />
          <div className="input__combo date__combo">
            <Selector
              required
              setValue={setProgressId}
              value={progressId}
              className="cases__seletor"
              caseProgressSituation={progressStatus.data}
            />
            <DatePicker
              placeholder="Selecione uma data"
              minLimitDate={new Date(1950,11,11)}
              maxLimitDate={new Date(2100, 11, 11)}
              disabled={!processNumberExists()}
              required={processNumberExists()}
              className="date__picker"
              onChangeMethod={(newDate: Date) =>
                setNewCase({ ...newCase, date_dispatch: formatDateToServer(momentFormatBrDate(newDate)) })
              }
            />
          </div>
          <div className="input__combo">
            <Input
              type="number"
              placeholder="Última página"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewCase({
                  ...newCase,
                  page_last_copy: Number(handleEmptyStringToNull(e.target.value)),
                })
              }
            />
            <input
              type="text"
              className="bp3-input currency__input"
              placeholder="Valor da causa"
              value={formatNumberToCurrency(newCase?.lawsuit_value || 0)}
              onChange={(e) => handleChangeCurrency(e.target.value)}
            />
          </div>
          <Selector changeMethod={handleTagChange} className="cases__seletor" tags={tagTypes.data} />
          <ul className="tag__list">
            {newCase.tags.map((tag: string) => (
              <li key={tag}>
                {getTagName(tag)} <Icon icon="small-cross" onClick={() => handleRemoveTag(tag)}></Icon>
              </li>
            ))}
          </ul>
        </div>
        <div className="right__side">
          <p className="right__p">
            Havendo número de processo, os campos "Vara" e "Secretaria" serão ativados e obrigatórios.
          </p>
          {districtTypes.empty && processNumberExists() && (
            <h4 className="empty__attribute">
              <Icon icon="warning-sign"></Icon>
              Por favor, crie uma <strong>Comarca</strong> antes de prosseguir com o cadastro do processo.
            </h4>
          )}
          <Selector
            setValue={setCountyId}
            disabled={!processNumberExists() || districtTypes.empty}
            required={processNumberExists()}
            className="cases__seletor"
            counties={districtTypes.data}
            value={countyId || ''}
          />
          {justiceTypes.empty && <h4 className="empty__attribute">Não existem tipos de justiça cadastrados.</h4>}
          <Selector
            required={processNumberExists()}
            disabled={!processNumberExists()}
            setValue={setJusticeId}
            className="cases__seletor"
            justiceTypes={justiceTypes.data}
            value={justiceId || ''}
          />
          <Selector
            required={processNumberExists()}
            disabled={!processNumberExists() || !justiceId || !countyId || justiceTypes.empty || districtTypes.empty}
            setValue={setCourtId}
            className="cases__seletor"
            courtTypes={courtsByJusticeAndCounty.data}
            value={courtId || ''}
          />
          <Selector
            required={processNumberExists()}
            disabled={!processNumberExists() || !countyId || secretariesByCountyId.empty}
            setValue={setSecretaryId}
            className="cases__seletor"
            secretaries={secretariesByCountyId.data}
            value={secretaryId || ''}
          />
        </div>
      </form>
    </div>
  );
};

export default StepDataCaseForm;
