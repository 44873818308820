import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reducers } from '../../store/reducers';
import { inactivateCaseDocumentRelation } from './actions';
import createToaster from '../../components/Toaster';
import './styles.css';
import '../CasesList/styles.css';
import { fetchCaseProgresses, createDocumentProgressRelation } from '../CaseRelatedProgress/actions';
import { NonIdealState, Dialog, Button as BButton, Classes, TextArea, Spinner, Popover, Position } from '@blueprintjs/core';
import PopoverEditRemove from '../../components/PopoverEditRemove';
import Input from '../../components/Input';
import Selector from '../../components/Selector';
import { fetchDocumentTypes } from '../MetaDocuments/actions';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { fetchCaseRelatedDocuments } from './actions';
import { COLUMNS } from './constants';
import { GetPersonAttachments } from '../PeopleAttachments/types';
import { goToHere } from '../../utils/navigation';
import Pagination from '../../components/Pagination';
import { DEFAULT_PER_PAGE_LIMIT } from '../../components/Pagination/constants';
import PopoverFilterOptions from '../../components/PopoverFilterOptions';
import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import { momentFormatServerDate } from '../../utils/date';

interface ICaseRelatedDocuments {
  caseId: string;
}

const CaseRelatedDocuments: React.FC<ICaseRelatedDocuments> = ({ caseId }) => {
  const dispatch = useDispatch();
  const inactivate = useSelector((state: typeof reducers) => state.caserelateddocuments.inactivate);
  const relatedDocuments = useSelector((state: typeof reducers) => state.caserelateddocuments.relatedDocuments);
  const progresses = useSelector((state: typeof reducers) => state.caserelatedprogress.caseProgresses);
  const documentTypes = useSelector((state: typeof reducers) => state.metadocuments.documentTypes);
  const sendDocument = useSelector((state: typeof reducers) => state.caserelatedprogress.addDocument);
  const [isOpen, setIsOpen] = useState(false);
  const [documentTitle, setDocumentTitle] = useState<string | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [fileType, setFileType] = useState<string | null>(null);
  const [documentDescription, setDocumentDescription] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [documentId, setDocumentId] = useState<string | null>();
  const [progressId, setProgressId] = useState<string | null>();
  const [progressDate, setProgressDate] = useState(new Date());
  const [searchValue, setSearchValue] = useState('');
  const [filterByName, setFilterByName] = useState<boolean>(false);

  const mountPath = () =>
    `${caseId}?filter_by=${!filterByName ? 'name' : 'file_name'}&entry=${searchValue}&order_by_name=asc`

  useEffect(() => {
    if (caseId?.length) {
      dispatch(fetchCaseRelatedDocuments(1, DEFAULT_PER_PAGE_LIMIT, `${caseId}?filter_by=name&entry=&order_by_name=asc`));
      dispatch(fetchDocumentTypes());
      dispatch(fetchCaseProgresses(caseId));
    }
  }, [dispatch, caseId]);

  useEffect(() => {
    if (sendDocument?.fail || sendDocument?.message) {
      const showToast = createToaster({
        message: sendDocument?.fail || sendDocument?.message,
        intent: sendDocument?.fail ? 'danger' : 'success',
        icon: sendDocument?.fail ? 'warning-sign' : 'tick',
      });
      showToast();
      setFiles([]);
    }
    if (sendDocument.message) {
      dispatch(fetchCaseRelatedDocuments(1, DEFAULT_PER_PAGE_LIMIT, `${caseId}?filter_by=name&entry=&order_by_name=asc`));
      clearUploadFields();
      setIsOpen(false);
    }
  }, [sendDocument, caseId, dispatch]);

  const clearUploadFields = () => {
    setFiles([]);
    setFileType(null);
    setDocumentDescription(null);
    setDocumentTitle(null);
  };

  useEffect(() => {
    if (inactivate?.success || inactivate?.fail) {
      const showToast = createToaster({
        message: inactivate?.success || inactivate?.fail,
        intent: inactivate?.fail ? 'danger' : 'success',
        icon: inactivate?.fail ? 'warning-sign' : 'tick',
      });
      if (inactivate.success) {
        dispatch(fetchCaseRelatedDocuments(1, DEFAULT_PER_PAGE_LIMIT, `${caseId}?filter_by=name&entry=&order_by_name=asc`));
      }
      showToast();
    }
  }, [inactivate, dispatch, caseId]);

  const documentInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length) {
      Array.prototype.forEach.call(e.target.files, (file) => {
        setFiles((state: File[]) => [...state, file]);
      });
    }
  };

  const mountFormData = () => {
    let formData = new FormData();
    Array.prototype.forEach.call(files, (file) => {
      formData.append('files', file);
    });
    formData.append('name', documentTitle!);
    formData.append('document_type_id', fileType!);
    formData.append('description', documentDescription!);
    formData.append('case_progress_date', momentFormatServerDate(progressDate));
    return formData;
  };

  const handleConfirmation = () => {
    setIsDialogOpen(false);
    return dispatch(inactivateCaseDocumentRelation(caseId, progressId!, documentId!));
  };

  const handleRemoveItem = (document: GetPersonAttachments) => {
    setIsDialogOpen(true);
    setDocumentId(document.document_id);
    setProgressId(document.case_progress_id);
  };

  const getHeadColumns = () => COLUMNS.map((column) => <th key={column}>{column}</th>);

  const getTable = () =>
    !relatedDocuments.empty ? (
      <table className="bp3-html-table bp3-html-table-striped bp3-interactive">
        <thead>
          <tr>
            {getHeadColumns()}
          </tr>
        </thead>
        <tbody>
          {relatedDocuments?.data?.result?.map((docItem: GetPersonAttachments) => (
            <tr key={docItem.case_progress_id}>
              <td>
                <span title="Baixar" onClick={() => goToHere(docItem.url)} className="th__filename">{docItem.file_name}</span></td>
              <td>{docItem.name}</td>
              <td>{docItem.document_type?.document_type}</td>
              <td>
                <PopoverEditRemove
                  className="popover__edit-remove"
                  removeRequest={() => handleRemoveItem(docItem)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <NonIdealState
        icon="search"
        title="Nenhum caso encontrado"
        description="Não foram encontrados resultados a partir dos dados que você digitou"
      />
    );

  const handleChangeFilter = () => {
    setFilterByName((prevState) => !prevState);
  };

  const searchBarFilters = [
    {
      value: filterByName,
      request: handleChangeFilter,
      text: 'Nome do arquivo',
    },
    {
      value: !filterByName,
      request: handleChangeFilter,
      text: 'Rótulo',
    },
  ];

  const handleRequest = (e: SyntheticEvent) => {
    if (e) {
      e.preventDefault();
      dispatch(fetchCaseRelatedDocuments(1, DEFAULT_PER_PAGE_LIMIT, mountPath()));
    }
  };

  return (
    <div className="case__relations--documents">
      <div className="tabs__subline"></div>
      <div className="upload__file__button">
        <BButton icon="upload" intent="primary" onClick={() => setIsOpen(true)}>
          Upload
        </BButton>
      </div>
      <form className="bp3-input-group documents__search" onSubmit={handleRequest}>
        <span className="bp3-icon" />
        <input
          type="text"
          className="bp3-input"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
          placeholder="Digite o nome da pessoa que você deseja encontrar ..."
        />
        <Popover
          className="popover search"
          content={<PopoverFilterOptions filters={searchBarFilters} />}
          position={Position.BOTTOM}
        >
          <BButton rightIcon="caret-down" text="Filtrar" />
        </Popover>
        <Button
          type="submit"
          className="bp3-button bp3-minimal bp3-intent-primary bp3-icon-search"
        >
          Buscar pessoa
            </Button>
      </form>
      <ConfirmationDialog
        headerText="Tem certeza que deseja excluir este item?"
        confirmMethod={() => handleConfirmation()}
        setIsOpen={setIsDialogOpen}
        isOpen={isDialogOpen}
      />
      <div className="content__container">
        <Dialog
          className="upload__files__dialog"
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          canEscapeKeyClose>
          <>
            <h2>Cadastro de arquivos</h2>
            <>
              <div className="input__file--container">
                <input className={`${Classes.INPUT} file__input`} type="file" onChange={documentInputChange} multiple />
              </div>
              <Input
                required={Boolean(progressId) && Boolean(files.length)}
                value={documentTitle ?? undefined}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDocumentTitle(e.target.value)}
                placeholder="Rótulo *"
                maxLength={30}
              />
              <Selector
                required={Boolean(progressId) && Boolean(files.length)}
                value={fileType ?? undefined}
                className={`${Classes.INPUT} relations__selector`}
                documentTypes={documentTypes?.data}
                setValue={setFileType}
              />
              <Selector
                required={Boolean(progressId) && Boolean(files.length)}
                value={progressId ?? undefined}
                className={`${Classes.INPUT} last`}
                caseRelatedProgresses={progresses?.data}
                setValue={setProgressId}
              />
              <DatePicker
                value={progressDate}
                placeholder="Data do andamento *"
                className="date__picker"
                onChangeMethod={(newDate: Date) => setProgressDate(newDate)}
              />
              <TextArea
                large
                placeholder="Descrição"
                value={documentDescription ?? undefined}
                maxLength={400}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setDocumentDescription(e.target.value)}
              />
            </>
            <div className="button__group">
              {!sendDocument.loading ? (
                <>
                  <BButton intent="danger" minimal text="Fechar" onClick={() => setIsOpen(false)} />
                  <BButton
                    text="Salvar arquivo"
                    icon="upload"
                    className="button--save__files"
                    disabled={!progressId || !files.length || !documentTitle || !fileType}
                    onClick={() => dispatch(createDocumentProgressRelation(caseId, progressId!, mountFormData()))}
                  />
                </>
              ) : (
                <Spinner intent="primary" size={25} />
              )}
            </div>
          </>
        </Dialog>
        {getTable()}
        {!relatedDocuments.empty && (
          <Pagination request={fetchCaseRelatedDocuments} optionsPath={mountPath()} loading={relatedDocuments.loading} data={relatedDocuments.data} />
        )}
      </div>
    </div>
  );
};

export default CaseRelatedDocuments;
