export const PEOPLE_PATH = '/meta/pessoas';
export const ATTACHMENTS_PATH = '/meta/anexos';
export const CASES_PATH = '/meta/casos';

export const FETCH_PHONES = 'FETCH_PHONES';
export const FETCH_PHONES_SUCCESS = 'FETCH_PHONES_SUCCESS';
export const FETCH_PHONES_FAIL = 'FETCH_PHONES_FAIL';

export const FETCH_SUBJECTS = 'FETCH_SUBJECTS';
export const FETCH_SUBJECTS_SUCCESS = 'FETCH_SUBJECTS_SUCCESS';
export const FETCH_SUBJECTS_FAIL = 'FETCH_SUBJECTS_FAIL';
