import React from 'react';
import Tabs from '../../components/Tabs';
import DocumentTypes from '../MetaDocuments';

const MetaAttachments: React.FC = () => {
  const tabsList = [
    {
      title: 'Tipos de documento',
      panel: <DocumentTypes />,
    },
  ];

  return (
    <div className="background__container meta__data meta__tabs">
      <h1>Use o menu abaixo para alterar entre as tabelas</h1>
      <Tabs id="DocumentsTab" tabs={tabsList}>
        {' '}
      </Tabs>
    </div>
  );
};

export default MetaAttachments;
