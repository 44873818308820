import React, { useState } from 'react';
import './styles.css';
import { Navbar, Button, Icon } from '@blueprintjs/core';
import { setActiveNavTab, goToHere } from '../../utils/navigation';

const MobileMenu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const PATHS: any = {
    people: (
      <>
        <Icon icon="people" />
        PESSOAS
      </>
    ),
    schedule: (
      <>
        <Icon icon="calendar" />
        AGENDA
      </>
    ),
    cases: (
      <>
        <Icon icon="box" />
        CASOS
      </>
    ),
    groups: (
      <>
        <Icon icon="user" />
        GRUPOS E USUÁRIOS
      </>
    ),
    meta: (
      <>
        <Icon icon="database" />
        META
      </>
    ),
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const renderMenu = () => (menuOpen ? `mobile__menu--open` : `mobile__menu`);
  const getCurrentRoute = () => window.location.pathname.split('/', 3)[2];

  return (
    <>
      <header className="top__bar">
        <Icon icon="menu" iconSize={25} onClick={toggleMenu} />
        {PATHS[getCurrentRoute()]}
      </header>
      <Navbar id="mobile__menu" className={renderMenu()}>
        <Icon icon="cross" iconSize={28} onClick={toggleMenu} className="close__icon" />
        <Button
          className={`mobile__button ${setActiveNavTab('/agenda')}`}
          icon="calendar"
          text="Agenda"
          onClick={() => goToHere(`/painel/agenda`)}
        />
        <Button
          className={`mobile__button ${setActiveNavTab('pessoas/lista')}`}
          icon="people"
          text="Pessoas"
          onClick={() => goToHere(`/painel/pessoas/lista`)}
        />
        <Button
          className={`mobile__button ${setActiveNavTab('/casos')}`}
          icon="box"
          text="Casos"
          onClick={() => goToHere(`/painel/casos`)}
        />
        <Button
          className={`mobile__button ${setActiveNavTab('/grupos')}`}
          icon="user"
          text="Grupos e Usuários"
          onClick={() => goToHere(`/painel/grupos/lista`)}
        />
        <Button
          className={`mobile__button ${setActiveNavTab('/meta')}`}
          icon="database"
          text="Meta-Dados"
          onClick={() => goToHere(`/painel/meta/pessoas`)}
        />
      </Navbar>
    </>
  );
};

export default MobileMenu;
