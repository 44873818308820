export const FETCH_ACTIVITIES_TYPE = 'FETCH_ACTIVITIES_TYPE';
export const FETCH_ACTIVITIES_TYPE_SUCCESS = 'FETCH_ACTIVITIES_TYPE_SUCCESS';
export const FETCH_ACTIVITIES_TYPE_FAIL = 'FETCH_ACTIVITIES_TYPE_FAIL';
export const FETCH_ACTIVITIES_TYPE_PAGINATED = 'FETCH_ACTIVITIES_TYPE_PAGINATED';
export const FETCH_ACTIVITIES_TYPE_PAGINATED_SUCCESS = 'FETCH_ACTIVITIES_TYPE_PAGINATED_SUCCESS';
export const FETCH_ACTIVITIES_TYPE_PAGINATED_FAIL = 'FETCH_ACTIVITIES_TYPE_PAGINATED_FAIL';
export const FETCH_ACTIVITIES_TYPE_ID = 'FETCH_ACTIVITIES_TYPE_ID';
export const FETCH_ACTIVITIES_TYPE_ID_SUCCESS = 'FETCH_ACTIVITIES_TYPE_ID_SUCCESS';
export const FETCH_ACTIVITIES_TYPE_ID_FAIL = 'FETCH_ACTIVITIES_TYPE__ID_FAIL';
export const CREATE_ACTIVITIES_TYPE = 'CREATE_ACTIVITIES_TYPE';
export const CREATE_ACTIVITIES_TYPE_SUCCESS = 'CREATE_ACTIVITIES_TYPE_SUCCESS';
export const CREATE_ACTIVITIES_TYPE_FAIL = 'CREATE_ACTIVITIES_TYPE_FAIL';
export const UPDATE_ACTIVITIES_TYPE = 'UPDATE_ACTIVITIES_TYPE';
export const UPDATE_ACTIVITIES_TYPE_SUCCESS = 'UPDATE_ACTIVITIES_TYPE_SUCCESS';
export const UPDATE_ACTIVITIES_TYPE_FAIL = 'UPDATE_ACTIVITIES_TYPE_FAIL';
export const INACTIVATE_ACTIVITIES_TYPE = 'INACTIVATE_ACTIVITIES_TYPE';
export const INACTIVATE_ACTIVITIES_TYPE_SUCCESS = 'INACTIVATE_ACTIVITIES_TYPE_SUCCESS';
export const INACTIVATE_ACTIVITIES_TYPE_FAIL = 'INACTIVATE_ACTIVITIES_TYPE_FAIL';
export const CLEAR_ALL_STATUS = 'CLEAR_ALL_STATUS';
