/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import { Reducer, State } from './types';
import * as c from './constants';

const INITIAL_STATE: State = {
  fail: null,
  loading: false,
  message: null,
};

const resetPassword: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_RESET_PASSWORD:
        draft.loading = true;
        draft.fail = null;
        draft.message = null;
        return;

      case c.FETCH_RESET_PASSWORD_SUCCESS:
        draft.loading = false;
        if (payload.data) {
          draft.message = 'Senha atualizada com sucesso!';
          draft.fail = null;
          sessionStorage.removeItem('PASS_TOKEN');
        } else {
          draft.fail = 'Ocorreu um erro no servidor';
        }
        return;

      case c.FETCH_RESET_PASSWORD_FAIL:
        draft.loading = false;
        draft.fail = payload.error;
        draft.message = null;
    }
  });

export default resetPassword;
