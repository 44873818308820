/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as c from './constants';
import { Reducer, State } from './types';

const INITIAL_STATE: State = {
  connectedPeople: {
    data: [],
    loading: false,
    empty: true,
    fail: null,
  },
  create: {
    fail: null,
    success: null,
    loading: false,
  },
  inactivate: {
    fail: null,
    success: null,
    loading: false,
  }
};

const peopleRelation: Reducer = (state = INITIAL_STATE, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case c.FETCH_PEOPLE_RELATION:
        draft.connectedPeople.loading = true;
        draft.connectedPeople.empty = true;
        draft.connectedPeople.fail = null;
        return;

      case c.FETCH_PEOPLE_RELATION_SUCCESS:
        draft.connectedPeople.loading = false;
        draft.connectedPeople.fail = null;
        if (payload && payload.data.length) {
          draft.connectedPeople.data = payload.data;
          draft.connectedPeople.empty = false;
        }
        return;

      case c.FETCH_PEOPLE_RELATION_FAIL:
        draft.connectedPeople.loading = false;
        draft.connectedPeople.fail = payload.error;
        draft.connectedPeople.data = payload.data;
        return;

      case c.CREATE_PERSON_RELATION:
        draft.create.loading = true;
        draft.create.fail = null;
        draft.create.success = null;
        return;

      case c.CREATE_PERSON_RELATION_SUCCESS:
        draft.create.loading = false;
        draft.create.success = 'Relação salva com sucesso!';
        return;

      case c.CREATE_PERSON_RELATION_FAIL:
        draft.create.loading = false;
        draft.create.fail = payload.error;
        draft.create.success = null;
        return;

      case c.INACTIVATE_CASE_RELATED_PERSON:
        draft.inactivate.loading = true;
        draft.inactivate.fail = null;
        draft.inactivate.success = null;
        return;

      case c.INACTIVATE_CASE_RELATED_PERSON_SUCCESS:
        draft.inactivate.loading = false;
        draft.inactivate.fail = null;
        draft.inactivate.success = 'Relação arquivada com sucesso!';
        return;

      case c.INACTIVATE_CASE_RELATED_PERSON_FAIL:
        draft.inactivate.loading = false;
        draft.inactivate.fail = payload.error;
        return;

      case c.CLEAR_ALL_STATUS:
        draft.create.success = null;
        draft.inactivate.success = null;
        draft.create.fail = null;
        draft.inactivate.fail = null;
        if (!draft.connectedPeople.data.length) {
          draft.connectedPeople.empty = true;
        }
        return;

      default:
        return;
    }
  });

export default peopleRelation;
